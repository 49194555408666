import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Inject,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { logger } from '@idr/shared/utils';

export interface ConfirmDialogOptions {
    readonly headline: string;
    readonly message: TemplateRef<unknown>;
    readonly submitLabel: string;

    readonly dangerousSubmit?: boolean;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgTemplateOutlet, A11yModule, DragDropModule, MatButtonModule, MatDialogModule, MatIconModule],
    selector: 'hg-confirm-dialog',
    templateUrl: './confirm.dialog.html',
    standalone: true,
    styleUrl: './confirm.dialog.scss',
})
export class ConfirmDialog {
    @ViewChild('submit') submitButton?: ElementRef<HTMLButtonElement>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: ConfirmDialogOptions,
        public readonly dialogRef: MatDialogRef<ConfirmDialog>,
    ) {}

    @HostListener('document:keydown.escape')
    close(): void {
        logger.debug('[ConfirmDialog] close ->');
        this.dialogRef.close(false);
    }
}
