<section>
    <!-- FIXME this (the group-family select) should be another component if you ask me -->
    <mat-select
        matTooltip="Produktfamilie wechseln"
        matTooltipPosition="right"
        [value]="active()?.name"
        data-cy="config-header">
        <mat-select-trigger>Produktfamilie - {{ active()?.name }}</mat-select-trigger>
        @for (productGroup of model.productGroups(); track productGroup.groupId) {
            <mat-option
                class="productGroups"
                [value]="productGroup.name"
                (click)="navigator.goToGroup(productGroup)"
                [routerLink]="[productGroup.groupId, 'logo']">
                {{ productGroup.name }}
                @if (productGroup.state === IS_DRAFT) {
                    <mat-icon svgIcon="construction" />
                }
            </mat-option>
        }
    </mat-select>
</section>
@if (isDraft()) {
    <aside>
        Zuletzt gespeichert {{ meta().modifiedOn | date: "medium" }} von
        {{ meta().modifiedBy }}
    </aside>
}
