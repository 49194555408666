import { inject, InjectionToken, Provider, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ProductGroupConfiguration } from '@idr/model/config';
import { logger } from '@idr/shared/utils';
import { combineLatest, map, shareReplay, skip, startWith, switchMap, tap } from 'rxjs';
import { ConfigModel } from '../model/config-model';

export type ActivatedProductGroup = ProductGroupConfiguration & {
    /**
     * Set to `true` when this group got changed within current client session at least once,
     * otherwise `false` or `undefined`.
     */
    readonly changed?: boolean;
};

export type ActivatedProductGroupSignal = Signal<ActivatedProductGroup | undefined>;

export const ACTIVATED_PRODUCT_GROUP: InjectionToken<ActivatedProductGroupSignal> = new InjectionToken(
    'ActivatedProductGroup',
);

export const ActivatedProductGroupProvider: Provider = {
    provide: ACTIVATED_PRODUCT_GROUP,
    useFactory: (): ActivatedProductGroupSignal => {
        const route = inject(ActivatedRoute);
        const model = inject(ConfigModel);
        return toSignal(
            // Here we need to combine the already resolved group (via resolver) & all proceeding changes done by user
            combineLatest([
                route.data.pipe(map(data => data.productGroup as ProductGroupConfiguration)),
                route.params.pipe(map(params => params.groupId)),
            ]).pipe(
                switchMap(([dataFromRoute, groupId]) =>
                    combineLatest([
                        model.get$(groupId).pipe(
                            // the first value we aren't interested in; we already got it from the route...
                            // only proceeding values are of interest because we want to get all the changes that user does, e.g. patching config
                            skip(1),
                            // this case shouldn't happen, but, let's be safe and fallback to dataFromRoute
                            // (IF we have a valid routed group, we shouldn't get `undefined` from the model)
                            map(config => config ?? dataFromRoute),
                            startWith(dataFromRoute),
                        ),
                        model.changed$.pipe(
                            map(changed => changed.contains(groupId)),
                            startWith(false),
                        ),
                    ]),
                ),
                map(([active, changed]) => ({ ...active, changed })),
                tap(active => logger.debug('[ACTIVATED_PRODUCT_GROUP] ->', active)),
                shareReplay(1),
            ),
        );
    },
};
