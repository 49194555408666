import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Product, ProductId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { LocalStorageKey, LocalStorageService } from './local-storage';

@Injectable({ providedIn: 'root' })
export class ActiveProduct {
    readonly hasImprint$: Observable<boolean>;

    /**
     * A long living observable of current activated product.
     * It'll emit after set for {@link product} was called.
     *
     * In components, you should use this to be informed when the user switches the product.
     */
    readonly product$: Observable<Product | undefined>;

    // sadly we can't initial the signal with an initial value; that's why it'll be undefined initially
    // this because of how the app is currently structured...
    // `ActiveProduct` is injected to `ProductResolver` & that one sets the initial value for product
    // Other dependent component, services, ... come later
    readonly productSignal: Signal<Product | undefined>;

    readonly #logPrefix = '[ActiveProduct]';

    readonly #product$ = new BehaviorSubject<Product | undefined>(undefined);

    constructor(private readonly localStorage: LocalStorageService) {
        this.product$ = this.#product$.pipe(
            distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
            shareReplay(1),
        );

        this.productSignal = toSignal(this.product$);

        this.hasImprint$ = this.product$.pipe(
            filter(Boolean),
            map(product => !!product.imprint),
        );
    }

    get id(): ProductId | undefined {
        return this.product?.id;
    }

    /**
     * Will be set by `ProductResolver` and used by other services and components.
     */
    set product(product: Product) {
        if (!product) {
            return;
        }

        logger.debug(this.#logPrefix, 'activeProduct ->', product);
        this.localStorage.set(LocalStorageKey.LAST_ACTIVE_PRODUCT, product.id);
        this.#product$.next(product);
    }

    /**
     * In services, you can use this to get current product directly.
     * It'll be set since one of the first things that are run in our app is the `ProductResolver` that sets this.
     *
     * @see ProductResolver
     */
    // FIXME: That is not ALWAYS set... If for example the application fails at navigation level (e.g. with PI19348/document) without giving a doc id,
    //  the product resolver would not have run yet and the product will be undefined...
    //  I would prefer to have the subject as replay subject and never have an initial value of undefined to it.
    get product(): Product | undefined {
        return this.#product$.value;
    }
}
