import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterEvent,
} from '@angular/router';
import { logger } from '@idr/shared/utils';
import { ActiveProduct, CommonNavigationService, ErrorId, MessageService } from '@idr/ui/shared';
import { from, Subject } from 'rxjs';
import { delay, delayWhen, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AppStateService } from '../core/app-state/app-state.service';

/**
 * This logic was formerly inside AppComponent, then moved into a directive, and now it is a standalone class that gets created with the
 * {@link APP_INITIALIZER} mechanism as part of our {@link AppRoutingModule}.
 *
 * After auth change (getting rid of legacy mobile API auth mechanism) initial routing in app was different.
 * The problem there was that the `router.events` subscription just came too late. Since internally (Angular) `router.events` is only
 * a simple subject that doesn't replay its last value, if you come too late, you don't get informed.
 * But, this was not expected by us.
 *
 * Now we initialise and subscribe already while app getting bootstrapped. This is also the point when Angular will already start routing.
 * But, it is just early enough to get informed for first routing events.
 */
export class RouterEventsLogic {
    private readonly emptyPageError$: Subject<void> = new Subject<void>();

    private readonly logPrefix = '[RouterEventsLogic]';

    constructor(
        private readonly router: Router,
        private readonly appState: AppStateService,
        commonNavigation: CommonNavigationService,
        private readonly activeProduct: ActiveProduct,
        private readonly messageService: MessageService,
    ) {
        // this is a special case ... when there was no navigation done yet at all inside our app we should ensure that
        // at least startpage is shown (for errors) & for initial navigation that startpage of default product is shown
        // (both cases are identically only logs will be different since there are several causes for failed)
        this.emptyPageError$
            .pipe(
                switchMap(() => this.activeProduct.product$),
                filter(product => product !== undefined),
                // we only need the first/last one. When nothing happened yet this will be the default product
                take(1),
                // we need to put this at the end of call stack because otherwise current navigation will be canceled
                // and, we end up here again ... this would trigger an error "Cannot activate an already activated outlet"
                delay(0),
                delayWhen(() => {
                    logger.debug(
                        this.logPrefix,
                        `Since there was an error and app isn't loaded yet. Fallback => load startpage.`,
                    );
                    return from(commonNavigation.toStartpage());
                }),
            )
            .subscribe();

        activeProduct.product$
            .pipe(
                take(1),
                switchMap(product =>
                    this.router.events.pipe(
                        tap(event => {
                            if (event instanceof NavigationStart) {
                                logger.debug(this.logPrefix, `got NavigationStart event -> busy = true`, event);
                                this.appState.busy = true;
                            }
                        }),
                        filter(event => event instanceof RouterEvent),
                        map(event => {
                            const canceled: boolean = event instanceof NavigationCancel;
                            const ended: boolean = event instanceof NavigationEnd;
                            const failed: boolean = event instanceof NavigationError;
                            if (failed) {
                                const error: NavigationError = event as NavigationError;
                                logger.error(this.logPrefix, `Routing failed. A NavigationError occurred`, error);
                                if (product) this.messageService.postError(ErrorId.NAVIGATION_ERROR, error.error);
                            }
                            return [failed, canceled, ended];
                        }),
                        tap(([failed]) => {
                            // if app isn't loaded fully yet, we need to show error page - otherwise we risk a blank screen... :(
                            if (this.appState.loading && failed) {
                                const isPageEmpty = this.appState.isPageEmpty;
                                logger.error(
                                    this,
                                    `Routing failed. loaded?`,
                                    !this.appState.loading,
                                    'isEmpty',
                                    isPageEmpty,
                                    'failed',
                                    failed,
                                );
                                if (isPageEmpty) {
                                    this.emptyPageError$.next();
                                }
                            }
                        }),
                        tap(([failed, canceled, ended]) => {
                            if (canceled || ended || failed) {
                                logger.debug(this.logPrefix, `navigation finished -> loaded = true & busy = false`);
                                this.appState.loading = false;
                                this.appState.busy = false;
                            }
                        }),
                    ),
                ),
            )
            .subscribe();
    }
}
