import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { bootstrapApplication } from '@angular/platform-browser';
import { SettingsDTO } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { APP_SETTINGS, Environment } from '@idr/ui/shared';
import { AppComponent } from '../app/app/components/app.component';
import { getSettings } from './get-settings';
import { MAIN_PROVIDERS } from './main-providers';

const logPrefix = '[main.ts]';

/**
 * It's a special case with loading of settings.json.
 * We need it before any of the interceptors are used. (chicken & egg problem)
 * When we load it here, it definitely will be loaded first and further checks are not needed,
 * all other services can just access the settings without any checks.
 */
export const loadSettingsAndInitApplication = async (environment: Environment) => {
    if (!window.fetch) {
        // because of jest
        return;
    }

    registerLocaleData(localeDe, 'de');

    try {
        const settings: SettingsDTO = await getSettings(environment);
        const providers = [...MAIN_PROVIDERS, { provide: APP_SETTINGS, useValue: settings }];
        await bootstrapApplication(AppComponent, { providers });
    } catch (error) {
        logger.error(logPrefix, 'Error while bootstrapping the application', error);

        if (environment.production) {
            // This will work only in deployed app -> /error is a route that is served by nginx
            // see {@link docker/ui/error.html}
            window.location.href = '/error.html';
        }
    }
};
