import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiName } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { CLIENT_SESSION_ID_HEADER, CORRELATION_ID_HEADER } from '@idr/ui/consts';
import { ApiService } from '../api';
import { WINDOW } from '../dom';

@Injectable()
export class LogIdsHeaderHttpInterceptor implements HttpInterceptor {
    readonly #clientSessionId: `${string}-${string}-${string}-${string}-${string}`;

    constructor(
        @Inject(WINDOW) private readonly window: Window,
        private readonly apiService: ApiService,
    ) {
        this.#clientSessionId = window.crypto.randomUUID();
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler) {
        const apiName: ApiName | undefined = this.apiService.findApiNameForUrl(request.url);
        if (!apiName) {
            return next.handle(request);
        }

        const correlationId = this.window.crypto.randomUUID();
        logger.debug(
            `[LogIdsHeaderHttpInterceptor] intercept -> will add ${CORRELATION_ID_HEADER}:${correlationId} header to url`,
            request.url,
        );
        const modifiedRequest = request.clone({
            headers: request.headers
                .set(CLIENT_SESSION_ID_HEADER, this.#clientSessionId)
                .set(CORRELATION_ID_HEADER, correlationId),
        });
        return next.handle(modifiedRequest);
    }
}
