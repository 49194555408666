/**
 * Cuts ending `/` from given url if present.
 */
export const cutSlash = (url: string): string => {
    if (url?.endsWith('/')) {
        return url.slice(0, -1);
    }
    return url;
};

/**
 * Cuts leading `/` from given url if present.
 */
export const cutLeadingSlash = (url: string | undefined): string | undefined => {
    if (url?.startsWith('/')) {
        return url.slice(1);
    }
    return url;
};

/**
 * Whether the provided URL indicates that a document is shown as well (can be an embed preview or the "full-view document page").
 */
export const containsDocument = (url: string): boolean => {
    return /\/document\/(?!imprint)/.test(url);
};

export const containsTopicPage = (url: string): boolean => {
    return /\/topicPage/.test(url);
};
/**
 * this is for hot documents in search - hitlist
 */
export const containsHot = (url: string): boolean => {
    return /\/hot\/preview\/*contenthub:%2F%2Fhot/.test(url);
};

/**
 * this is for news (everywhere)
 */
export const containsNews = (url: string): boolean => {
    return /\/news\/.+?\/contenthub/.test(url);
};

/**
 * Whether the provided URL indicates that a lexicon is shown as well (embedded in column view).
 */
export const containsLexicon = (url: string): boolean => {
    return /\/lexicon/.test(url);
};

export const hasSelectedDocument = (url: string): boolean => {
    return url.includes('sel=');
};

export const hasSelectedTopicPageDocument = (url: string): boolean => {
    return /sel=.*HI\d+_ueb/.test(url);
};

export const containsFilterParam = (url: string): boolean => {
    return url.includes('filter=');
};

/**
 * Whether the provided URL starts with a product id.
 */
export const startsWithProductId = (url: string): boolean => {
    return /^\/PI\d+\/?/.test(url);
};

export const isColumnPage = (url: string): boolean => {
    return /^\/PI\d+\/column\//.test(url);
};

export const isDocumentPage = (url: string): boolean => {
    return /^\/PI\d+\/document\//.test(url);
};

/**
 * Whether the provided URL is that of the error page.
 */
export const isErrorPage = (url: string): boolean => {
    return /\/error$/.test(url);
};

export const isFilterPage = (url: string): boolean => {
    return /^\/PI\d+\/filter\//.test(url);
};

/**
 * Whether the provided URL is that of the startpage/homepage.
 * It checks if the URL is of the format `/PI123` or `/PI123?param=value`.
 * (Also allows for a trailing slash)
 */
export const isStartPage = (url: string): boolean => {
    return /^\/PI\d+\/?(?:\?.*)?$/.test(url);
};

/**
 * Whether the provided URL is that of the search page.
 */
export const isSearchPage = (url: string): boolean => {
    return /\/search/.test(url);
};

export const isLexiconPage = (url: string): boolean => {
    return /^\/PI\d+\/lexicon\/LI\d+/.test(url);
};

export const isNewAndChangedPage = (url: string): boolean => {
    return /^\/PI\d+\/new-and-changed.*$/.test(url);
};

/**
 * Whether the provided URL is that of the imprint page.
 */
export const isImprintPage = (url: string): boolean => {
    return /\/imprint/.test(url);
};

/**
 * Converts a path array to a href link.
 */
export const hrefFromPathArray = (path: string[]): string => {
    return (
        path
            // Remove the empty parts from the path array
            ?.filter(part => part !== '')
            // We should remove the leading and trailing slashes from the routerLink parts
            // because when we join them with slashes, we will get double slashes.
            // We should only ignore parts that just contain a slash.
            // Encode the URL because it might contain parts like contenthub://
            ?.map(part => {
                if (part === '/') {
                    return part;
                }

                const withoutLeadingTrailingSlash = part.replace(/^\/|\/$/g, '');
                return encodeURIComponent(withoutLeadingTrailingSlash);
            })
            ?.join('/')
            // depending on the path, the end result might still contain double slashes, so we have to remove them
            ?.replace(/\/\//g, '/')
    );
};

/**
 * @returns the path parts of the given url as an array. (It keeps the query params in the parts)
 * @example
 * urlAsParts('/PI123/document/123') => ['PI123', 'document', '123']
 * urlAsParts('/PI123/document/123?sel=456') => ['PI123', 'document', '123?sel=456']
 */
export const urlAsParts = (url: string): string[] => {
    return (
        url
            .split('/')
            .filter(Boolean)
            // https://jira.haufe.io/browse/NAUA-7424
            // https://gitlab.haufedev.systems/aurora/frontend/aurora.reloaded.client/-/merge_requests/1245
            .map(urlpart => decodeURI(urlpart))
    );
};

/**
 * @returns the url without the query params
 * @example
 * urlWithoutQueryParams('/PI123/document/123?sel=456') => '/PI123/document/123'
 */
export const urlWithoutQueryParams = (url: string): string => {
    return url.split('?')[0];
};

/**
 * @returns the document id from the given url.
 * @example
 * documentIdFromUrl('/PI123/document/HI123?sel=456') => 'HI123'
 */
export const documentIdFromUrl = (url: string): string | undefined => {
    const parts = urlAsParts(urlWithoutQueryParams(url));
    const documentIdIndex = parts.indexOf('document');
    return documentIdIndex !== -1 ? parts[documentIdIndex + 1] : undefined;
};
