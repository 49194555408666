import { computed, Directive, effect, ElementRef, HostListener, Inject, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { logger } from '@idr/shared/utils';
import { rxEffects } from '@rx-angular/state/effects';
import { debounceTime, fromEvent, Subject } from 'rxjs';
import { WINDOW } from '../services';

export const EVENT_DELAY = 5;

const cancelEvent = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
};

/**
 * Adds file drag and drop to attached element.
 *
 * This directive sets `isDragging` css class on attached element while user drags something onto the element.
 *
 * @see isDragging is emitted while user is dragging
 * @see fileDropped is emitted as soon as user dropped a file onto the element
 */
@Directive({
    host: {
        '[class.isDragging]': 'dragTargetsElement()', // eslint-disable-line @typescript-eslint/naming-convention
    },
    selector: '[hgDragAndDropFiles]',
    standalone: true,
})
export class DragAndDropFilesDirective {
    readonly #logPrefix = '[DragAndDropFilesDirective]';

    // first we queue the events locally because that way we can debounce them
    // => we get to many and also ugly flickering effects if we don't debounce...
    readonly #isDragging$ = new Subject<{
        readonly eventTarget?: Node;
        readonly value: boolean;
    }>();

    readonly #debouncedIsDragging$ = toSignal(this.#isDragging$.pipe(debounceTime(EVENT_DELAY)), {
        initialValue: { value: false },
    });

    readonly isDragging = output<boolean>();

    readonly dragTargetsElement = computed(() => {
        const data = this.#debouncedIsDragging$();
        return data.value && this.elementRef.nativeElement.contains(data.eventTarget as Node);
    });

    readonly fileDropped = output<FileList>();

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        @Inject(WINDOW) window: Window,
    ) {
        rxEffects(({ register }) => {
            register(fromEvent(window, 'dragover'), evt => {
                this.#isDragging$.next({ eventTarget: evt.target as Node, value: true });
                cancelEvent(evt);
            });

            register(fromEvent(window, 'dragleave'), evt => {
                this.#isDragging$.next({ eventTarget: evt.target as Node, value: false });
                cancelEvent(evt);
            });
        });

        effect(() => this.isDragging.emit(this.#debouncedIsDragging$().value));
    }

    @HostListener('window:drop', ['$event'])
    onDrop(evt: DragEvent) {
        logger.debug(this.#logPrefix, 'onDrop', evt);
        cancelEvent(evt);
        this.#isDragging$.next({ value: false });
        if (!this.elementRef.nativeElement.contains(evt.target as Node)) {
            return;
        }

        if (!evt.dataTransfer?.files || evt.dataTransfer.files.length === 0) {
            return;
        }

        this.fileDropped.emit(evt.dataTransfer.files);
    }
}
