import { Injectable } from '@angular/core';
import { ContentHubId } from '@idr/shared/model';
import { NewsDocument } from '@idr/ui/content-hub';
import { LocalStorageKey, LocalStorageService } from '@idr/ui/shared';
import { map, Observable, ReplaySubject } from 'rxjs';

// We decided to go with 6 months because in the news list we display news up to 6 months old.
// Each record in local storage holds about 0.12KB. A typical news list for a product holds about 400 records for 6 months.
// That means that with the 5MB limit of local storage we could store a little over 42.000 records.
// which is more than enough for our needs.
export const READ_NEWS_LIFETIME_IN_MONTHS = 6;

@Injectable({ providedIn: 'root' })
export class ReadNewsService {
    private readonly readNews$: ReplaySubject<Map<ContentHubId, string>> = new ReplaySubject(1);

    // We use a cache to make the operations less expensive.
    // The cache is updated when we insert a new item, and then we update the local storage with it.
    // It holds the content hub id and the unix timestamp of the news date in string format.
    // Unfortunately we can only store the date in string format.
    private readonly readNewsCache: Map<ContentHubId, string>;

    constructor(private readonly localStorage: LocalStorageService) {
        this.readNewsCache = this.readNewsFromLocalStorage;
        this.readNews$.next(this.readNewsCache);
    }

    /**
     * Returns true if the given contentHubId is in the read news list.
     */
    public isRead$(contentHubId: ContentHubId): Observable<boolean> {
        return this.readNews$.pipe(map(readNews => readNews.has(contentHubId)));
    }

    /**
     * Adds the given news document's contentHubId to the read news list and stores the list in local storage.
     */
    public markNewsItemAsRead(newsDocument: NewsDocument): void {
        if (this.readNewsCache.has(newsDocument.contentHubId)) {
            return;
        }
        this.readNewsCache.set(newsDocument.contentHubId, newsDocument.chronologicalSortDate.getTime().toString());
        this.pruneOldRecordsFromCache();
        this.readNews$.next(this.readNewsCache);
        this.storeToLocalStorage(this.readNewsCache);
    }

    /**
     * Converts the local storage "read" news value to a map.
     */
    private localStorageToMap(localStorageValue: string | null): Map<ContentHubId, string> {
        // We have to use null if the value is not found in local storage otherwise the JSON.parse will fail.
        return new Map(JSON.parse(localStorageValue ?? '[]'));
    }

    private get readNewsFromLocalStorage(): Map<ContentHubId, string> {
        return this.localStorageToMap(this.localStorage.get(LocalStorageKey.READ_NEWS));
    }

    private storeToLocalStorage(readNewsMap: Map<ContentHubId, string>): void {
        this.localStorage.set(LocalStorageKey.READ_NEWS, JSON.stringify(Array.from(readNewsMap.entries())));
    }

    /**
     * There is a requirement to keep the last X months of read news. We clear the older items so that we don't fill the localstorage space.
     */
    private pruneOldRecordsFromCache(): void {
        for (const [contentHubId, newsDateUnixTimestampAsString] of this.readNewsCache.entries()) {
            const nowInUnix = new Date().getTime();
            const newsDate = new Date(parseInt(newsDateUnixTimestampAsString, 10));
            const newsDatePlusLifeTime = newsDate.setMonth(newsDate.getMonth() + READ_NEWS_LIFETIME_IN_MONTHS);
            if (newsDatePlusLifeTime < nowInUnix) {
                this.readNewsCache.delete(contentHubId);
            }
        }
    }
}
