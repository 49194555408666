import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'hg-loading-wheel',
    standalone: true,
    styleUrls: ['./loading-wheel.component.scss'],
    template: '<div class="load"></div>',
})
export class LoadingWheelComponent {
    @HostBinding('attr.role') readonly role = 'presentation';
}
