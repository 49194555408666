import { HotConfig, HotDocumentsDTO, HotMetaDTO } from '@idr/shared/model';
import { Realm } from '@idr/ui/shared';
import { ContentHubDocuments } from './content-hub-documents';
import { HotDocument } from './hot-document';

export class HotDocuments extends ContentHubDocuments<HotDocument, HotMetaDTO> {
    private constructor(dto: HotDocumentsDTO, realm: Realm, hotConfig: HotConfig) {
        super(dto, entryDto => HotDocument.fromEntryDTO(entryDto, realm, hotConfig));
    }

    static fromDTO(dto: HotDocumentsDTO, realm: Realm, hotConfig: HotConfig) {
        return new HotDocuments(dto, realm, hotConfig);
    }
}
