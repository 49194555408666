<idr-product-logo [style.visibility]="showProductLogo ? 'visible' : 'hidden'" />

<section class="search">
    <mat-select
        #categorySelect
        *ngLet="searchableCategories$ | async as categories"
        (click)="categorySelect.open(); categorySelectOpened = true"
        (closed)="categorySelectOpened = false"
        panelClass="search-category"
        data-cy="search-category">
        <mat-option
            *ngFor="let category of categories"
            [value]="category">
            {{ category.title }}
        </mat-option>
    </mat-select>
    <div #border></div>
    <idr-header-search-input (submitCalled)="onSubmitCalled($event, categorySelect.value)" />
</section>

<idr-header-menu />
