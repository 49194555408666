import { Injectable } from '@angular/core';
import { ApiName } from '@idr/shared/model';
import { SettingsService } from '../settings/settings.service';

@Injectable({ providedIn: 'root' })
export class ApiService {
    /**
     * Returns the static content base url.
     * This is needed because the static content is fetched using GET requests that need to have the auth cookie attached.
     * Thus, they have to be fetched from the same domain as the auth cookie.
     */
    readonly staticContentBaseUrl: string;

    constructor(private readonly settingsService: SettingsService) {
        // For the static content we have to use the ApiName.AUTH_COOKIE because the static content is fetched using GET requests that
        // are triggered by the browser and not the http client of angular. Thus, the interceptor is not able to parse them and change the
        // relative url to the correct one that corresponds to the microservice.
        this.staticContentBaseUrl = `${this.getApiEndpointUrl(ApiName.AUTH_COOKIE)}/static_content`;
    }

    findApiNameForUrl(url: string): ApiName | undefined {
        return Object.values(ApiName).find((apiNameValue: string) => url.startsWith(apiNameValue));
    }

    getApiEndpointUrl(apiName: ApiName): string {
        return `${this.settingsService.bffHost}/api/${this.#getApiEndpoint(apiName)}`;
    }

    getVersionInfoApiEndpoint(apiName: ApiName): string {
        const baseUrl = `${this.settingsService.bffHost}/api`;
        if (apiName === ApiName.CONTENTHUB) {
            // The reason that we have to differentiate here is that we are trying to get the version from the content hub proxy microservice that we have.
            // So the request has to be made in the base of the content hub proxy microservice.
            // All other requests have an additional path segment that is needed by the microservice to know that the requests should be forwarded.
            return `${baseUrl}/${this.settingsService.bffContentHubEndpoint}`;
        }
        return `${baseUrl}/${this.#getApiEndpoint(apiName)}`;
    }

    #getApiEndpoint(apiName: ApiName): string {
        switch (apiName) {
            case ApiName.CRS:
                return 'crs/webservices/CRS/2';
            case ApiName.PRODUCT_CONFIG:
                return 'productconfiguration';
            case ApiName.MY_DESK_CONFIG:
                return 'mydesk_productconfig';
            case ApiName.OUTPUT_MANAGER:
                return 'outputmanager';
            case ApiName.AUTHOR_INFORMATION:
                return 'authorinformation';
            case ApiName.BOOKMARKS:
                return 'bookmarks';
            case ApiName.SUGGESTIONS:
                return 'suggest';
            case ApiName.AUTH_COOKIE:
                return 'auth_cookie';
            case ApiName.EMAIL:
                return 'email';
            case ApiName.USER_SETTINGS:
                return 'usersettings';
            case ApiName.CONTENTHUB:
                return `${this.settingsService.bffContentHubEndpoint}/contenthub/content/v1`;
            default:
                throw new Error(`[ApiService] - getApiEndpoint - API endpoint for ${apiName} is not defined`);
        }
    }
}
