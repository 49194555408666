import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiName,
    ReloadedUserSettings,
    ReloadedUserSettingsDTO,
    UserSettings,
    UserSettingsDTO,
} from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { combineLatest, firstValueFrom, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { catchError, filter, map, retry, shareReplay, take, tap, withLatestFrom } from 'rxjs/operators';
import { CACHE_IT_HTTP_HEADER_KEY, CONSUME_GENERIC_ERROR_HANDLER } from '../http';
import { ProfileService } from '../profile/profile.service';

export const USER_SETTINGS_BASE_URL = `${ApiName.USER_SETTINGS}/userconfig`;

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
    readonly #logPrefix = '[UserSettingsService]';

    /**
     * This is the "local" user settings that are stored in memory. It is used a "cache" to avoid making too many API calls.
     * It also serves as a fallback in case the API is not available.
     */
    readonly #userSettings$ = new ReplaySubject<UserSettings>(1);
    readonly userSettingsWithoutFallback$: Observable<ReloadedUserSettingsDTO | undefined>;
    /**
     * This indicates if the API is available or not.
     * We should avoid overwriting the user settings that are stored in the API with the fallback values to avoid losing data.
     * When the app works in the fallback mode we only use the locally stored (in-memory) user settings.
     */
    readonly #isInFallbackMode$ = new ReplaySubject<boolean>(1);

    constructor(
        private readonly http: HttpClient,
        private readonly profileService: ProfileService,
    ) {
        this.#initUserSettings();
        this.userSettingsWithoutFallback$ = this.setupUserSettingsWithoutFallback$();
    }

    /**
     * Retrieves the user settings. It will only return the last value -> not a long-living observable.
     */
    get settings$(): Observable<ReloadedUserSettingsDTO> {
        return this.#userSettings$.pipe(
            take(1),
            // We fall back to an empty object in case the API doesn't even have a "reloaded" object.
            map((userSettings: UserSettings) => userSettings.values.reloaded ?? {}),
        );
    }

    /**
     * Updates the user settings local "cached" data and also saves the updated settings to API if it's available.
     *
     * @param data partial user settings that you want to update.
     * @return true if the operation was successful and false if not
     */
    async updateSettings(data: Partial<ReloadedUserSettingsDTO>): Promise<boolean> {
        return firstValueFrom(this.updateSettings$(data));
    }

    /**
     * Updates the user settings local "cached" data and also saves the updated settings to API if it's available.
     *
     * @param data partial user settings that you want to update.
     * @return true if the operation was successful and false if not
     */
    updateSettings$(data: Partial<ReloadedUserSettingsDTO>): Observable<boolean> {
        return this.#userSettings$.pipe(
            // IMPORTANT: take(1) is needed here, otherwise we will end up in an infinite loop because we also update the _userSettings$ below.
            take(1),
            map((existingSettings: UserSettings) => existingSettings.updateValues(data)),
            tap((updatedSettings: UserSettings) => this.#userSettings$.next(updatedSettings)),
            withLatestFrom(this.#isInFallbackMode$),
            switchMap(([updatedSettings, isInFallBackMode]) => {
                if (isInFallBackMode) {
                    logger.debug(
                        this.#logPrefix,
                        'updateSettings$ - isInFallBackMode -> will only keep settings locally (in-memory)',
                        updatedSettings,
                    );
                    return of(true);
                }
                return this.#updateSettingsOnAPI$(updatedSettings.values);
            }),
        );
    }

    get #requestUserSettings$(): Observable<UserSettings> {
        return this.profileService.userId$.pipe(
            switchMap(userId =>
                this.http.get<UserSettingsDTO>(`${USER_SETTINGS_BASE_URL}/${userId}`, {
                    headers: {
                        [CACHE_IT_HTTP_HEADER_KEY]: 'false',
                        // We have to add this header to avoid error.http-interceptor from "consuming" the error
                        // and returning just a string of what went wrong.
                        // We have to access the status code that the error returned and that is lost if it's consumed by the interceptor.
                        [CONSUME_GENERIC_ERROR_HANDLER]: 'false',
                    },
                }),
            ),
            // When the user never had saved settings, the API returns a 404
            // We have to handle this case and return an empty object because the updateSettings$ method
            // would fail otherwise and this lead to an issue with beta popups and tour -> NAUA-7257
            catchError(err => {
                if (err.status === 404) {
                    logger.debug(this.#logPrefix, '#requestUserSettings$ - User has no settings yet');
                    return of({} as UserSettingsDTO);
                }
                // In other cases (e.g. 500) we don't want to return an empty object since that would cause the updateSettings$ method
                // to "overwrite" the user settings with an empty object and that means that the user would lose all his settings
                logger.debug(this.#logPrefix, '#requestUserSettings$ - Error while getting user settings', err);
                throw err;
            }),
            map(userSettingsDTO => UserSettings.fromDTO(userSettingsDTO)),
            shareReplay(1),
            retry(1),
        );
    }

    #initUserSettings(): void {
        const fallbackSettings = UserSettings.fromDTO({
            reloaded: new ReloadedUserSettings().withFallbackSettings().values,
        });

        const userSettingsFromApi$ = this.#requestUserSettings$.pipe(
            catchError(err => {
                logger.error(this.#logPrefix, '#initUserSettings.request FAILED with', err);
                this.#isInFallbackMode$.next(true);

                // If for whatever reason the API fails we fall back to the default settings so that everything is disabled.
                this.#userSettings$.next(fallbackSettings);
                logger.debug(this.#logPrefix, 'Falling back to default settings', fallbackSettings.values);
                return of(fallbackSettings);
            }),
        );

        this.profileService.isAnonymous$
            .pipe(
                tap(isAnonymous => logger.debug(this.#logPrefix, 'isAnonymous$', isAnonymous)),
                tap(isAnonymous => this.#isInFallbackMode$.next(isAnonymous)),
                switchMap(isAnonymous => (isAnonymous ? of(fallbackSettings) : userSettingsFromApi$)),
                tap(settings => this.#userSettings$.next(settings)),
            )
            .subscribe();
    }

    #updateSettingsOnAPI$(settings: UserSettingsDTO): Observable<boolean> {
        return this.profileService.userId$.pipe(
            switchMap(userId => this.http.put<UserSettingsDTO>(`${USER_SETTINGS_BASE_URL}/${userId}`, settings)),
            retry(1),
            tap(() => logger.debug(UserSettingsService, '#updateSettingsOnAPI$ - updated settings on API', settings)),
            map(userSettingsDTO => UserSettings.fromDTO(userSettingsDTO)),
            map(() => true),
            catchError(err => {
                logger.error(this.#logPrefix, '#updateSettingsOnAPI$ FAILED with', err);
                return of(false);
            }),
        );
    }

    /**
     * This will retrieve user settings without fallback data for tracking the user settings.
     * Whenever the user settings change, we'll send an event to Google Tag Manager updating the data layer.
     */
    setupUserSettingsWithoutFallback$(): Observable<ReloadedUserSettingsDTO | undefined> {
        return combineLatest([this.#userSettings$, this.#isInFallbackMode$]).pipe(
            // It doesn't make sense to track default/fallback settings... We are interested in the user's settings.
            filter(([, isInFallBackMode]) => !isInFallBackMode),
            // We only need to track the reloaded values
            map(([userSettings]) => userSettings.values.reloaded),
        );
    }

    // There is also a delete endpoint but YAGNI
}
