import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, input, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { logger } from '@idr/shared/utils';
import { GtmArea } from '@idr/ui/tracking';
import { RxLet } from '@rx-angular/template/let';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { DrawerId } from '../../model';
import { LocalStorageCompositeKeyPrefix, LocalStorageService } from '../../services';
import { PanelPosition } from '../wing-panel-button/position';
import { WingPanelButtonComponent } from '../wing-panel-button/wing-panel-button.component';

export const getDrawerLocalStorageKey = (id: DrawerId): string => {
    return `${LocalStorageCompositeKeyPrefix.DRAWER_IS_OPEN}${id}`;
};

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, MatTooltipModule, RxLet, WingPanelButtonComponent],
    selector: 'hg-drawer',
    standalone: true,
    styleUrls: ['./drawer.component.scss'],
    templateUrl: './drawer.component.html',
})
export class DrawerComponent implements OnInit {
    /**
     * Used for saving the state in localStorage.
     */
    drawerId = input.required<DrawerId>();
    gtmArea = input.required<GtmArea>();

    /**
     * The label for the drawer. It is shown next to the toggle icon and serves as "header" for the drawer.
     *
     * When the drawer is closed it'll be still visible.
     */
    summaryLabel = input.required<string>();

    panelPosition = input<PanelPosition>('left');

    readonly tooltip: Signal<string>;

    readonly isOpen$: Observable<boolean>;

    readonly #logPrefix = '[DrawerComponent]';

    readonly #isOpen$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private readonly destroyRef: DestroyRef,
        private readonly localStorageService: LocalStorageService,
    ) {
        this.isOpen$ = this.#isOpen$.asObservable();

        const opened = toSignal(this.isOpen$);
        this.tooltip = computed(() => `${this.summaryLabel()} ` + (opened() ? 'ausblenden' : 'einblenden'));
    }

    ngOnInit(): void {
        const drawerId = this.drawerId();
        this.#isOpen$.next(this.#getInitialValue(drawerId));

        // The opened state can also change by the window resize.
        // When the browser window is too small the drawer can be closed to save space.
        // This happens in DocumentWithDrawersComponent.
        this.localStorageService
            .valueChanged$(getDrawerLocalStorageKey(drawerId))
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                map(value => coerceBooleanProperty(value)),
                tap(isOpen => this.#isOpen$.next(isOpen)),
            )
            .subscribe();
    }

    toggle(): void {
        // isOpen$ will be updated by the localStorageService valueChanged$ observable.
        this.isOpen$
            .pipe(
                take(1),
                tap((isOpen: boolean) =>
                    this.localStorageService.set(getDrawerLocalStorageKey(this.drawerId()), String(!isOpen)),
                ),
            )
            .subscribe();
    }

    #getInitialValue(id: DrawerId): boolean {
        const key: string = getDrawerLocalStorageKey(id);

        if (!this.localStorageService.contains(key)) {
            logger.debug(
                this.#logPrefix,
                '#getInitialValue',
                `No value found in localStorage for key "${key}". Setting true as default.`,
            );
            this.localStorageService.set(key, String(true));
            // By default, we want to have the drawer open.
            // Thus, if we don't find any value in localStorage we set it and return true.
            return true;
        }

        return coerceBooleanProperty(this.localStorageService.get(key));
    }
}
