import { ChangeDetectionStrategy, Component, computed, effect, input, output, Signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { HasProductId, ProductId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ProductSelectOption } from '../../model/product-select-option';

/**
 * Contains information about the current state of {@link ProductSelectComponent}.
 *
 * It contains current {@link selectedProducts} and
 *  the specific product selection change ({@link product}) that triggered this event.
 */
export interface Change {
    /**
     * The product that just got changed (either selected or deselected).
     */
    readonly product: HasProductId & {
        /**
         * `true` when product just got selected/checked or `false` when it got deselected/unchecked
         */
        readonly selected: boolean;
    };
    /**
     * The complete list of all *selected* products.
     * If none are selected, this list is empty.
     */
    readonly selectedProducts: ProductId[];
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, MatCheckbox],
    selector: 'mc-product-select',
    standalone: true,
    styleUrl: './product-select.component.scss',
    template: `
        <p>Produkte</p>
        <section [formGroup]="formGroup()">
            @for (option of options(); track option.id) {
                <mat-checkbox
                    [attr.data-cy]="option.id"
                    [formControlName]="option.id"
                    (change)="emitState(option.id, $event.checked)"
                    >{{ getLabelFor(option.id) }}
                </mat-checkbox>
            }
        </section>
    `,
})
export class ProductSelectComponent {
    readonly #logPrefix = '[ProductSelectComponent]';

    readonly #productNames: { [key: ProductId]: string } = {};

    /**
     * Emits a {@link Change} event each time any selection update was made by user.
     */
    readonly change = output<Change>();

    /**
     * @required
     *
     * The products that are in principle selectable.
     * Each option should also contain information about its individual blocked state.
     *
     * @see ProductSelectOption.blocked
     */
    readonly options = input.required<ProductSelectOption[]>();

    /**
     * Defines the form. It depends on provided {@link options}.
     */
    readonly formGroup: Signal<FormGroup>;

    /**
     * @optional
     *
     * Defines which products are selected by this component.
     */
    readonly selected = input<ProductId[]>();

    constructor(formBuilder: FormBuilder) {
        // The form group depends on the options available.
        // We don't know number of products and which beforehand.
        this.formGroup = computed(() => {
            const controls: { [key: ProductId]: boolean } = {};
            this.options().forEach(product => {
                /** Whether product is selected shall depend on {@link selected}. */
                controls[product.id] = (this.selected() ?? []).includes(product.id);
                this.#productNames[product.id] = product.name;
            });
            return formBuilder.group(controls);
        });

        // We need to keep the template checkboxes state consistent with the block state as well
        effect(() => {
            const formGroup = this.formGroup();
            const options = this.options();
            for (const option of options) {
                const checkbox = formGroup.controls[option.id];
                const isChecked = checkbox.value as boolean;
                if (option.blocked() && !isChecked) {
                    checkbox.disable();
                    continue;
                }
                checkbox.enable();
            }
        });
    }

    emitState(id: ProductId, selected: boolean) {
        const selectedProducts = this.selectedProducts;
        logger.debug(this.#logPrefix, 'emitState ->', { id, selected }, selectedProducts);
        this.change.emit({ product: { id, selected }, selectedProducts });
    }

    getLabelFor(id: ProductId): string {
        return this.#productNames[id];
    }

    /**
     * @returns current selected products
     */
    get selectedProducts(): ProductId[] {
        const formGroup = this.formGroup();
        const productIds = Object.keys(formGroup.controls) as ProductId[];
        return productIds.filter(id => formGroup.controls[id].value === true);
    }
}
