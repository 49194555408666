import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';

// Copied from here: https://github.com/ng-web-apis/common/blob/master/projects/common/src/tokens/window.ts
// Maybe we should just add the whole lib to our package.json
export const WINDOW = new InjectionToken<Window>('An abstraction over global window object', {
    providedIn: 'root',
    factory: () => {
        const { defaultView } = inject(DOCUMENT);

        if (!defaultView) {
            throw new Error('Window is not available');
        }

        return defaultView;
    },
});
