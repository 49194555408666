import { Injectable } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { LEXINFORM_CALLER_PARAM_NAME } from '@idr/ui/consts';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DrawerId } from '../../model';

const KEY_PREFIX = 'idr';

export const LocalStorageCompositeKeyPrefix = {
    DRAWER_IS_OPEN: 'drawer_is_open.', // drawer_is_open is a composite key. It consists of the DrawerId (drawer-id.ts) (e.g. drawer_is_open:related_documents, drawer_is_open:table_of_contents)
};
export type LocalStorageCompositeKeyPrefix =
    (typeof LocalStorageCompositeKeyPrefix)[keyof typeof LocalStorageCompositeKeyPrefix];

export const LocalStorageKey = {
    ECONDA_PRIVACY_MODE: 'emos_privacy',
    HISTORY_DOCUMENTS: 'history_documents',
    HISTORY_SEARCH: 'history_search',
    LAST_ACTIVE_PRODUCT: 'active_product',
    READ_NEWS: 'read_news',
    RELATED_DOCS_CLOSED_DUE_TO_RESIZE: 'related_docs_closed_due_to_resize',
    LEXINFORM_CALLER: LEXINFORM_CALLER_PARAM_NAME,
    /**
     * The auth realm is stored in local storage so that we can use it when the user visits the application without a realm.
     * (e.g. when reloads the page)
     */
    REALM: 'auth_realm',
};

/**
 * Helper method to prefix a local storage key with idr::
 * This way we ensure that there are no conflicts with other local storage keys.
 */
export const prefixLocalStorageKey = (key: LocalStorageKey, prefix = KEY_PREFIX) => {
    return `${prefix}::${key}`;
};

export type LocalStorageKey = (typeof LocalStorageKey)[keyof typeof LocalStorageKey] | `drawer_is_open.${DrawerId}`;

/**
 * Simple wrapper for `localStorage`.
 *
 * Also prefixes keys, so we hinder them from conflicting with keys from potential other apps.
 */
@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    readonly #localStorage: Storage | undefined;

    readonly #logPrefix = '[LocalStorageService]';

    private readonly _valuesChanged$: Subject<string> = new Subject<string>();

    constructor() {
        const dummy = `${KEY_PREFIX}_dummy`;
        try {
            localStorage.setItem(dummy, dummy);
            localStorage.removeItem(dummy);
            this.#localStorage = localStorage;
        } catch (error) {
            logger.warn(this.#logPrefix, "Couldn't store dummy data in `localStorage`. Won't use `localStorage`.");
            logger.debug(JSON.stringify(error));
        }
    }

    contains(key: LocalStorageKey): boolean {
        return this.get(key) !== undefined;
    }

    /**
     * @return the value of given `key` as string or `undefined` if not found.
     */
    get(key: LocalStorageKey): string | undefined {
        let found: string | null = null;
        if (this.#localStorage) {
            found = this.#localStorage.getItem(prefixLocalStorageKey(key));
        }
        // this is only because Storage will return `null` for unset values,
        // but we decided to use `undefined` for unset values...
        return found !== null && found !== undefined ? found : undefined;
    }

    remove(key: LocalStorageKey): void {
        if (this.#localStorage) {
            this.#localStorage.removeItem(prefixLocalStorageKey(key));
        }
        this._valuesChanged$.next(key);
    }

    set(key: LocalStorageKey, value: string): void {
        if (this.#localStorage) {
            this.#localStorage.setItem(prefixLocalStorageKey(key), value);
        }
        this._valuesChanged$.next(key);
    }

    /**
     * Will be triggered when any of the values are changed.
     * If you want to be notified when a specific value changes, use {@link valueChanged$} instead,
     * because that will make sure that it doesn't notify again when the same value is set again.
     *
     * @return the key that was changed.
     */
    get valuesChanged$(): Observable<string> {
        return this._valuesChanged$.asObservable();
    }

    /**
     * Will be triggered when the specific local storage key changes value.
     *
     * @return the updated value of the given key.
     */
    valueChanged$(key: LocalStorageKey): Observable<string | undefined> {
        return this.valuesChanged$.pipe(
            filter(k => k === key),
            map(() => this.get(key)),
            distinctUntilChanged(),
        );
    }
}
