import { logger } from '@idr/shared/utils';
import { isHaufeDomainUrl } from '@idr/ui/consts';

import { REALM_PARAM_NAME } from './auth-params';

const LOGGER_PREFIX = '[Realms] -';

/**
 * This is a list of the known realms that we regularly use and are known to our BFF.
 * However, the auth_realm param that is passed to the client can have any value. So we shouldn't assume that the realm is ONLY one of these values.
 */
export const Realm = {
    DATEV: 'idesk-datev',
    PARTNER: 'idesk-partner',
    OFFLINE: 'idesk-offline',
    HAUFE_INTERNAL_IDESK: 'haufe-internal-idesk',
    LOCAL: 'local', // used only for e2e tests
    DEFAULT: 'haufepublic', // The default realm (i.e. atlantic login is used whenever the realm is not specified)
};

export type Realm = (typeof Realm)[keyof typeof Realm] | string;

/**
 * Adds the realm to the given url if it is an external url and is Haufe related.
 * This is required by the CSSO so that the other application knows which realm the user is logged in to.
 */
export const urlWithRealmQueryParam = (urlAsString: string, realm: Realm): string => {
    // // We only add the realm to the url for external urls.
    if (!urlAsString.startsWith('http')) {
        return urlAsString;
    }

    try {
        const url = new URL(urlAsString);
        const withRealm = addRealmToUrl(url, realm);
        const finalUrl = addRequireLoginParam(withRealm);
        // ensure that searchParams are decoded properly to fix ease of use in testing
        finalUrl.search = decodeURIComponent(finalUrl.search);
        return finalUrl.href;
    } catch {
        logger.warn(
            LOGGER_PREFIX,
            `addRealmToUrl - Could not add the required query params in the url: ${urlAsString}`,
        );
        return urlAsString;
    }
};

/**
 * will add the param require_login=true
 * this is needed only for onlinetraining links to ensure proper session handling on their side
 * @param url
 */
const addRequireLoginParam = (url: URL): URL => {
    if (url.hostname === 'onlinetraining.haufe.de') {
        url.searchParams.set('require_login', '1');
    }
    return url;
};

/**
 * This is a list of the realms that are specific to the iDesk.
 * These realms are only known to our BFF and shouldn't be forwarded to other applications as they might get confused.
 * We had that issue in the past when implemented NAUA-7927.
 */
const IDESK_SPECIFIC_REALMS = [Realm.DATEV, Realm.PARTNER, Realm.OFFLINE, Realm.HAUFE_INTERNAL_IDESK, Realm.LOCAL];

const addRealmToUrl = (url: URL, realm: Realm): URL => {
    // We should avoid adding our idesk specific realms to the url to avoid confusing other apps.
    if (IDESK_SPECIFIC_REALMS.includes(realm)) {
        return url;
    }

    if (!isHaufeDomainUrl(url)) {
        logger.debug(LOGGER_PREFIX, `addRealmToUrl - The url is not a Haufe related url: ${url.href}`);
        return url;
    }

    url.searchParams.set(REALM_PARAM_NAME, realm);
    logger.debug(LOGGER_PREFIX, `addRealmToUrl - The url was updated to: ${url.href}`);
    return url;
};
