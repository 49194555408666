import { computed, Inject, Injectable, Signal } from '@angular/core';
import { ProductId } from '@idr/shared/model';
import { ACTIVATED_PRODUCT_GROUP, ActivatedProductGroupSignal } from '../routing/activated-product-group';
import { ProductSelectOptionsState } from './product-select-options-state';

@Injectable()
export class PartnerLogoProductOptionsState extends ProductSelectOptionsState {
    constructor(@Inject(ACTIVATED_PRODUCT_GROUP) private readonly active: ActivatedProductGroupSignal) {
        super(active);
    }

    protected override get configuration(): Signal<{ readonly products?: ProductId[] }[] | undefined> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return computed(() => this.active()!.configuration.partnerLogos);
    }
}
