import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { ApiName, Product, SearchSuggestionResponse } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ActiveProduct } from '@idr/ui/shared';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SearchSuggestion } from '../../shared/components/search-input/suggestion/search-suggestion';

@Injectable({ providedIn: 'root' })
export class SuggestionService {
    readonly #suggestions = signal<SearchSuggestion[]>([]);
    readonly suggestions = this.#suggestions.asReadonly();

    constructor(
        private readonly http: HttpClient,
        private readonly activeProduct: ActiveProduct,
    ) {}

    setSuggestionsState(suggestions: SearchSuggestion[]) {
        logger.debug('[SuggestionService] set SearchSuggestion', suggestions);
        this.#suggestions.set(suggestions);
    }

    getSuggestions$(query: string): Observable<SearchSuggestion[]> {
        if (query.length === 0) {
            return of([]);
        }
        const product: Product = this.activeProduct.product;
        return this.http.get<SearchSuggestionResponse>(`${ApiName.SUGGESTIONS}/suggest/${product.id}/${query}`).pipe(
            map(searchSuggestionResponse => SearchSuggestion.fromDTOs(searchSuggestionResponse.suggestions, query)),
            catchError(error => {
                logger.debug(`[SuggestionService] #getSuggestions(${query}); returned error from backend`, error);
                return of([]);
            }),
        );
    }
}
