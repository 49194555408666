/**
 * This patches the global encodeURIComponent function to avoid issues.
 * Parenthesis like "(" ")" in our routes (for example as part of the search query) can potentially break our routing
 *
 * @see https://github.com/angular/angular/issues/10280
 * @see https://jira.haufe.io/browse/NAUA-3100
 */
export const patchEncodeUriComponent = () => {
    const encode: typeof encodeURIComponent = encodeURIComponent;
    window['encodeURIComponent'] = (component: string) => {
        return encode(component).replace(/[!'()*]/g, c => {
            // Also encode !, ', (, ), and *
            return '%' + c.charCodeAt(0).toString(16);
        });
    };
};
