import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import {
    ApiName,
    CombinedSubChapterId,
    CrsDocumentDTO,
    CrsDocumentWrapperDTO,
    CrsExceptionDTO,
    CrsExportOptionsDTO,
    CrsFootnoteDTO,
    CrsFootnotesWrapperDTO,
    CrsOutlineDTO,
    CrsOutlineEntryDTO,
    CrsOutlinesWrapperDTO,
    cutAnchor,
    DocumentId,
    DocumentQuery,
    getDeduplicatedWithoutAnchorIds,
    NormKey,
} from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ActiveProduct, MessageService } from '@idr/ui/shared';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
    createTocForFlatDocumentWithoutToc,
    getFlatDocumentParts,
    multipartDtosFromFlatDto,
} from '../../utils/flat-document-handling';
import { CrsExceptionTypes } from '../../models';
import { Toc } from '@idr/ui/document';
import { CrsBaseService } from './crs-base.service';

/**
 * @Deprecated will be replaced by Odin.
 */
@Injectable({ providedIn: 'root' })
export class CrsDocumentService extends CrsBaseService {
    readonly #logPrefix = '[CrsDocumentService]';

    constructor(
        private readonly activeProduct: ActiveProduct,
        messageService: MessageService,
        httpClient: HttpClient,
    ) {
        super(messageService, httpClient);
    }

    /**
     * Returns the outline {@link CrsOutlinesWrapperDTO} for requested document.
     *
     * @param documentId The id of the requested document (e.g. HI1465483)
     * @param ignoreCrsException (default `false`) set to `true` to avoid displaying a message in case CRS can't find requested document
     * @return
     *     - valid {@link CrsOutlinesWrapperDTO}
     *     - `never` if requested document wasn't found and {@param ignoreCrsException} equals `false`
     *     - {@link CrsExceptionDTO} if requested document wasn't found and {@param ignoreCrsException} equals `true`
     */
    getToc$(documentId: DocumentId, ignoreCrsException = false): Observable<CrsOutlinesWrapperDTO | CrsExceptionDTO> {
        const outlinesPathUrl = `${ApiName.CRS}/outlines/product/${this.activeProduct.id}/ID/${documentId}`;
        return this.makeCrsGetRequest<CrsOutlinesWrapperDTO | CrsExceptionDTO>(outlinesPathUrl, {
            ignoreException: ignoreCrsException,
        });
    }

    /**
     * Returns a document in the format of {@link CrsDocumentDTO}
     *
     * @param documentId The id of the requested document (e.g. HI1465483)
     * @param query the query used for highlighting terms inside the document related to given query
     * @param productId the product id the document shall be requested in (e.g. PI42323)
     *
     * @return a valid {@link CrsDocumentDTO}
     *
     * @throws {@link CrsExceptionDTO} if requested document wasn't found
     */
    getDocument$(documentId: DocumentId, query = '', productId = this.activeProduct.id): Observable<CrsDocumentDTO> {
        logger.debug(this.#logPrefix, 'getDocument$ ->', documentId, query);
        let apiUrl = `${ApiName.CRS}/documents/product/${productId}/ID/${cutAnchor(documentId)}`;
        if (query) {
            apiUrl = `${apiUrl}${new DocumentQuery(query).asQueryParam}`;
        }
        return this.makeCrsGetRequest<CrsDocumentWrapperDTO>(apiUrl, {
            ignoreException: true,
            suppressErrorMessagePopupForExceptionType: CrsExceptionTypes.OSRParser,
        }).pipe(
            map((crsWrapperDto: CrsDocumentWrapperDTO) => crsWrapperDto.document?.[0]),
            map(dto => {
                // Topicpages (THEMENSEITE) are marked by CRS as multipart: false but we should not try to split them either...
                if (dto && !dto.isMultipart && dto.docclass.type !== 'THEMENSEITE') {
                    return multipartDtosFromFlatDto([documentId], dto)[0];
                }
                return dto;
            }),
        );
    }

    /**
     * Loads a list of {@link IDeskDocument}'s for `documentIds`
     * (optional: with its content highlighted by given `query`)
     *
     * When server returns CrsExceptionDTO we return []
     */
    getDocuments$(ids: DocumentId[], query = ''): Observable<CrsDocumentDTO[]> {
        logger.debug(this.#logPrefix, 'getDocuments$ ->', ids);

        // Since we cannot have mixed flat document and multipart document ids we can assume the following for the simplicity:
        // - If we retrieve a document with an id that is a flat document then all the others will be flat as well.
        // - The root document id of a flat document doesn't indicate that it's a flat document. We have to retrieve the document to know.
        //   But if there are requests for document ids like HI<number>-<anchor> then we can assume that those are flat document sections that come from the HI<number> document.
        const deduplicatedWithoutAnchorIds: DocumentId[] = getDeduplicatedWithoutAnchorIds(ids);

        // Might be a flat document...
        if (deduplicatedWithoutAnchorIds.length === 1) {
            return this.#getMultiPartDocuments$(deduplicatedWithoutAnchorIds, query).pipe(
                // We know it's only one document
                map(dtos => dtos[0]),
                map(dto => {
                    // Topicpages (THEMENSEITE) are marked by CRS as multipart: false but we should not try to split them either...
                    if (!dto.isMultipart && dto.docclass.type !== 'THEMENSEITE') {
                        return multipartDtosFromFlatDto(ids, dto);
                    }
                    return [dto];
                }),
            );
        }

        return this.#getMultiPartDocuments$(deduplicatedWithoutAnchorIds, query);
    }

    getTocForFlatDocumentWithoutToc$(documentId: DocumentId): Observable<Toc> {
        const apiUrl = `${ApiName.CRS}/documents/product/${this.activeProduct.id}/ID/${cutAnchor(documentId)}`;
        return this.makeCrsGetRequest<CrsDocumentWrapperDTO>(apiUrl, {
            ignoreException: true,
        }).pipe(
            map((crsWrapperDto: CrsDocumentWrapperDTO) => crsWrapperDto.document?.[0]),
            map(dto => {
                const parts = getFlatDocumentParts(dto);
                return createTocForFlatDocumentWithoutToc(parts, dto.title);
            }),
        );
    }

    getFootnote$(documentId: DocumentId, index: number): Observable<SafeHtml | undefined> {
        return this.#getFootnotes$(documentId).pipe(
            map((footnotes: Map<number, SafeHtml>) => {
                if (!footnotes.has(index)) {
                    logger.warn(this.#logPrefix, `Couldn't find footnote nr${index} for '${documentId}'`);
                    return 'Fußnote konnte nicht gefunden werden';
                }
                return footnotes.get(index);
            }),
        );
    }

    getExportOptions$(documentId: DocumentId): Observable<CrsExportOptionsDTO | CrsExceptionDTO> {
        const exportOptionsUrl = `${ApiName.CRS}/exportOptions/product/${this.activeProduct.id}/ID/${documentId}`;
        return this.makeCrsGetRequest<CrsExportOptionsDTO | CrsExceptionDTO>(exportOptionsUrl);
    }

    /**
     * Returns the HI of the root document in the selected version.
     *
     * @param normKey holds the version of the document in the format of "Fassung VZ 2020|1|EStG"
     */
    getRootDocumentIdForVersion$(normKey: NormKey): Observable<DocumentId> {
        return this.#getDocumentForVersion$(normKey).pipe(map(crsDocumentDTO => crsDocumentDTO.rootid));
    }

    /**
     * Returns the HI of the document in the selected version.
     *
     * @param normKey holds the version of the document in the format of "Fassung VZ 2020|1|EStG"
     */
    getDocumentIdForVersion$(normKey: NormKey): Observable<DocumentId> {
        return this.#getDocumentForVersion$(normKey).pipe(map(crsDocumentDTO => crsDocumentDTO.docid));
    }

    /**
     * Tries to find the correct document-id for a chapter based on given {@param combineId}.
     * We can use {@link getToc$} to find that our...
     *
     * @return Observable of undefined if there was an error or given {@param combineId} doesn't relate consistent with {@link getToc$}
     */
    getSubChapterIdBasedFor$(combinedId: CombinedSubChapterId): Observable<DocumentId | undefined> {
        if (!combinedId) {
            return of(undefined);
        }

        return this.getToc$(combinedId.rootId).pipe(
            map((dto: CrsOutlinesWrapperDTO | CrsExceptionDTO) => {
                if ((dto as CrsExceptionDTO).exception) {
                    logger.error(
                        this.#logPrefix,
                        `getSubChapterIdBasedFor$ FAILED. Couldn't find outline for`,
                        combinedId.rootId,
                        dto,
                    );
                    return undefined;
                }

                const outline: CrsOutlineDTO = (dto as CrsOutlinesWrapperDTO).product?.[0]?.outline?.[0];
                if (!outline) {
                    logger.error(
                        this.#logPrefix,
                        `getSubChapterIdBasedFor$ FAILED. Couldn't find outline for`,
                        combinedId.rootId,
                        dto,
                    );
                    return undefined;
                }

                if (outline.entry.length < combinedId.nthSubChapter) {
                    logger.error(
                        this.#logPrefix,
                        `getSubChapterIdBasedFor$ FAILED. Index for requested chapter is higher than available chapters.`,
                    );
                    return undefined;
                }

                const relatedOutlineEntry: CrsOutlineEntryDTO = outline.entry[combinedId.nthSubChapter - 1];
                const subChapterId: DocumentId = relatedOutlineEntry.anchor
                    ? `${relatedOutlineEntry.docid}-${relatedOutlineEntry.anchor}`
                    : relatedOutlineEntry.docid;
                logger.debug(this.#logPrefix, 'getSubChapterIdBasedFor$ SUCCESS', combinedId, `->`, subChapterId);
                return subChapterId;
            }),
        );
    }

    #getDocumentForVersion$(normKey: NormKey): Observable<CrsDocumentDTO> {
        return this.makeCrsGetRequest<CrsDocumentWrapperDTO>(
            `${ApiName.CRS}/documents/product/${this.activeProduct.id}/normkey/${normKey}`,
        ).pipe(map((crsWrapperDto: CrsDocumentWrapperDTO) => crsWrapperDto.document[0]));
    }

    /**
     * Loads all footnotes of a document.
     *
     * @param documentId HI of a document, usually a chapter
     */
    #getFootnotes$(documentId: DocumentId): Observable<Map<number, SafeHtml>> {
        // https://gitlab.haufedev.systems/aurora/crs/aurora.crs.Products.ContentretrievalREST/-/blob/master/src/Products/ContentretrievalREST/browser/v2/doc/footnotes.md
        // we request all footnotes for given document at once and extract the requested one afterward...
        // => the first call might be a little slower but any other footnote in the same document will pop up without any backend call
        //    (since it's a stupid CRS call our CachingInterceptor will save this request, so we don't need to cache it in this service)
        const apiUrl = `${ApiName.CRS}/footnotes/product/${this.activeProduct.id}/ID/${documentId}`;

        return this.makeCrsGetRequest<CrsFootnotesWrapperDTO>(apiUrl).pipe(
            map((crsWrapperDto: CrsFootnotesWrapperDTO) => {
                const footnotes: Map<number, SafeHtml> = new Map<number, SafeHtml>();
                crsWrapperDto?.footnote.forEach((footNote: CrsFootnoteDTO) => {
                    footnotes.set(footNote.index, footNote.text);
                });
                return footnotes;
            }),
        );
    }

    #getMultiPartDocuments$(ids: DocumentId[], query: string): Observable<CrsDocumentDTO[]> {
        const documentQuery: DocumentQuery = new DocumentQuery(query);
        // https://gitlab.haufedev.systems/aurora/crs/aurora.crs.Products.ContentretrievalREST/-/blob/master/src/Products/ContentretrievalREST/browser/v2/doc/documents.md
        const baseApiUrl = `${ApiName.CRS}/documents/product/${this.activeProduct.id}/ID/${ids.join('/ID/')}`;
        const apiUrl = `${baseApiUrl}${documentQuery.asQueryParam}`;

        return this.makeCrsGetRequest<CrsDocumentWrapperDTO>(apiUrl, {
            // We want to throw the error message so that the in-doc-search component knows if there is an error
            // related to the in-doc search query and can display an error message to the user.
            // However, we want to suppress the error message popup because the error message is already displayed in the in-doc-search component.
            throwError: true,
            suppressErrorMessagePopupForExceptionType: CrsExceptionTypes.OSRParser,
        }).pipe(
            map((crsWrapperDto: CrsDocumentWrapperDTO) => crsWrapperDto.document),
            tap(documents => logger.debug(this.#logPrefix, '#getMultiPartDocuments$ -> SUCCESS', ids, documents)),
        );
    }
}
