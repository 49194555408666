import { inject, InjectionToken } from '@angular/core';
import { ProductId } from '@idr/shared/model';
import { LocalStorageKey, LocalStorageService } from '@idr/ui/shared';

/**
 * Gives you the last active product.
 * This depends on {@link LocalStorageKey.LAST_ACTIVE_PRODUCT} being set in {@see LocalStorageService}.
 *
 * So someone hopefully sets it as expected ({@link ActiveProduct} should do it). :)
 */
export const LAST_ACTIVE_PRODUCT_ID: InjectionToken<ProductId | undefined> = new InjectionToken('LastActiveProduct', {
    providedIn: 'root',
    factory: () => {
        const localStorage = inject(LocalStorageService);
        return localStorage.get(LocalStorageKey.LAST_ACTIVE_PRODUCT);
    },
});
