import { SearchSuggestionDTO } from '@idr/shared/model';

export class SearchSuggestion {
    public selected = false;

    private constructor(
        // full search term/suggestion. searchTerm == prefix+suffix
        public readonly searchTerm: string,
        // this is what the user entered. Will be highlighted when the suggestion shown
        public readonly prefix: string,
        public readonly suffix: string,
    ) {}

    public static fromDTOs(dtos: SearchSuggestionDTO[], query: string): SearchSuggestion[] {
        return dtos.map(dto => SearchSuggestion.fromDTO(dto, query));
    }

    public static fromDTO(dto: SearchSuggestionDTO, query: string): SearchSuggestion {
        const prefix: string = dto.search_term.substr(0, query.length);
        const suffix: string = dto.search_term.substr(query.length);
        return new SearchSuggestion(dto.search_term, prefix, suffix);
    }
}
