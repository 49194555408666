import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { isStartPage, logger } from '@idr/shared/utils';
import { DomService, WINDOW } from '@idr/ui/shared';
import { rxEffects } from '@rx-angular/state/effects';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { HeaderComponent } from '../../header/components/header.component';
import { SitemapComponent } from '../../header/components/sitemap/sitemap.component';
import { SideBarComponent } from '../../side-bar/components/side-bar.component';

/**
 * This components is the base for all '/<product-id>/*' routes.
 * The '/<product-id>' route is our "root" route representing the current used|activated|shown product.
 *
 * It constructs the overall layout of our app. It contains a router-outlet in the main area for
 * rendering "contentPage" components like StartPageComponent, SearchPageComponent, DocumentPageComponent..
 *
 * Why do we have such a "placeholder" component? (Why can't the layout be done in the AppComponent?)
 * Because we don't support an empty route as "entrypoint". We always need at least the <product-id> in our routes.
 * The ProductResolver will take care of an empty route and redirect it to a "valid" route.
 * The AppComponent is what gets bootstrapped and it can't be used for routing configuration.
 * So we need another "placeholder" component that serves as the root component with an <router-outlet> for our content components...
 *
 * @see ROUTE_DEFINITIONS
 * @see ProductResolver
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'idr-app-layout',
    standalone: true,
    styleUrls: ['./app-layout.component.scss'],
    imports: [RouterOutlet, HeaderComponent, SideBarComponent, SitemapComponent],
    templateUrl: './app-layout.component.html',
})
export class AppLayoutComponent implements OnInit {
    @HostBinding('class.extend-main') public extendMain = false;

    @HostBinding('class.overlap-sideBar') public pinSideBar = false;

    /**
     * Used for switching between our 2 layout modes. There is the "StartPage" and "SubPage" mode.
     * While on "StartPage" the sideBar is part of the full app layout so closing/opening it will also update the width of the main area.
     * While on a "SubPage" the sidebar isn't part of the full app layout instead it becomes a popup that slides over the main area
     * (whenever opened).
     *
     * Also the default states differ: on "StartPage" the sideBar is opened. On a "SubPage" the sideBar is closed.
     *
     * <b>Important:</b> we need <code>{ static: true }</code> here since it is already used while initialising this component in the
     * very first change-detection cycle.
     *
     * @see ngOnInit
     * @see updateLayout
     */
    @ViewChild('navigation', { static: true }) private readonly sideBarRef: SideBarComponent;

    private readonly sideBarLowerBreakpoint: number;
    private readonly sideBarUpperBreakpoint: number; // FIXME use this one for showing sidebar even on subpage

    constructor(
        @Inject(WINDOW) window: Window,
        private readonly domService: DomService,
        private readonly router: Router,
    ) {
        this.sideBarLowerBreakpoint = domService.getSCSSPropertyAsNumber('--sidebar-toggle-lower-breakpoint');
        this.sideBarUpperBreakpoint = domService.getSCSSPropertyAsNumber('--sidebar-toggle-upper-breakpoint');

        rxEffects(({ register }) =>
            register(router.events.pipe(filter(event => event instanceof NavigationStart)), (event: NavigationStart) =>
                this.updateLayout(event.url),
            ),
        );

        // FIXME: missing unit tests
        rxEffects(({ register }) =>
            register(
                fromEvent(window, 'resize').pipe(
                    debounceTime(100),
                    filter(() => this.isStartPage),
                ),
                () => this.checkSideBarStateOnStartpage(),
            ),
        );
    }

    private get currentUrl(): string {
        return this.router.routerState.snapshot.url;
    }

    private get isStartPage(): boolean {
        return isStartPage(this.currentUrl);
    }

    public ngOnInit(): void {
        this.updateLayout(this.currentUrl);
    }

    /**
     *
     * @param toggled `false` when sideBar got closed and `true` when sideBar got opened
     */
    public onSideBarToggle(toggled: boolean): void {
        // when sideBar is pinned we can ignore the toggle state
        this.extendMain = this.pinSideBar ? true : !toggled;
        logger.debug(
            `[AppLayoutComponent] onSideBarToggle -> extendMain=${this.extendMain}, pinSideBar=${this.pinSideBar}`,
        );
    }

    /**
     * Decides weather or not to toggle and/or pin the sideBar.
     *
     * The sideBar is pinned on all pages except the startPage.
     *      - "pinned" means whenever it is opened it'll overlap the content area.
     *      - not "pinned" means opening the sideBar will decrease the content area since the sideBar.
     *        Both areas need to be visible completely always. (startPage case)
     *
     * Additionally, it'll will *automatically*:
     *      - open the sideBar whenever the startPage is shown.
     *      - close the sideBar whenever any page except the startPage is shown.
     */
    private updateLayout(url: string): void {
        this.pinSideBar = !isStartPage(url);
        if (!this.pinSideBar) {
            this.checkSideBarStateOnStartpage();
        } else {
            this.sideBarRef.close();
        }
        logger.debug(
            `[AppLayoutComponent] updateLayout -> extendMain=${this.extendMain}, pinSideBar=${this.pinSideBar}`,
        );
    }

    private checkSideBarStateOnStartpage(): void {
        if (this.sideBarRef.opened) {
            if (this.domService.innerWidth <= this.sideBarLowerBreakpoint) {
                this.sideBarRef.close(); // this will trigger a toggle event and as a result `extendMain` will be set
            } else {
                this.extendMain = false;
            }
        } else {
            if (this.domService.innerWidth > this.sideBarLowerBreakpoint) {
                this.sideBarRef.open(); // this will trigger a toggle event and as a result `extendMain` will be set
            } else {
                this.extendMain = true;
            }
        }
    }
}
