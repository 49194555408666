import { computed, inject, InjectionToken, Signal } from '@angular/core';
import { IS_DRAFT } from '@idr/model/config';
import { logger } from '@idr/shared/utils';
import { CURRENT_PRODUCT_CONFIGURATION_TOKEN, DRAFT_MODE_IS_REQUESTED } from '@idr/ui/services';
import { Realm, REALM } from '@idr/ui/shared';

/**
 * This token will signal you if the app is in draft mode (`true`) or not (`false`).
 *
 * The following conditions need to be fulfilled in order to be in draft mode (`true`).
 *  - user is logged in via {@link Realm.HAUFE_INTERNAL_IDESK}
 *  - the url contains at any point the `draft` query parameter with a truthy value
 *
 * In any other case you'll get `false` (meaning no draft mode).
 *
 * @see REALM
 * @see DRAFT_MODE_IS_REQUESTED
 * @see CURRENT_PRODUCT_CONFIGURATION_TOKEN
 */
export const IN_DRAFT_MODE: InjectionToken<Signal<boolean>> = new InjectionToken('IN_DRAFT_MODE', {
    providedIn: 'root',
    factory: () => {
        const logPrefix = '[IN_DRAFT_MODE]';
        const realm = inject(REALM);
        const draftIsRequested = inject(DRAFT_MODE_IS_REQUESTED);
        const productConfiguration = inject(CURRENT_PRODUCT_CONFIGURATION_TOKEN);
        const productHasDraft = computed(() => productConfiguration()?.state === IS_DRAFT);
        // Only Haufe internal users shall be able to see the draft mode
        // => all Haufe users are able to log into any product using the `haufe-internal-idesk` realm.
        const isRealmAllowed = realm === Realm.HAUFE_INTERNAL_IDESK;
        return computed(() => {
            const _draftIsRequested = draftIsRequested(); // eslint-disable-line @typescript-eslint/naming-convention
            const _productHasDraft = productHasDraft(); // eslint-disable-line @typescript-eslint/naming-convention

            if (isRealmAllowed && _draftIsRequested && _productHasDraft) {
                logger.debug(logPrefix, 'User is allowed, url condition is met & product has draft ->', true);
                return true;
            }

            logger.warn(
                logPrefix,
                'User is allowed:',
                isRealmAllowed,
                'url condition is met:',
                _draftIsRequested,
                'product has draft:',
                _productHasDraft,
            );
            return false;
        });
    },
});
