import { ContentHubDocumentDTO, NewsAuthorMetaDTO } from '@idr/shared/model';
import { prefetchImage } from '@idr/ui/shared';

export interface ContentHubAuthor {
    readonly authorName: string;
    readonly id: string;
    readonly imageUrl: string;

    readonly addressCity?: string;
    readonly lastName?: string;
    readonly postTitle?: string;
    readonly preview?: string;
    readonly shortDescription?: string;
    readonly showPopup: boolean;
}

export const contentHubAuthorFromDto = (dto: ContentHubDocumentDTO<NewsAuthorMetaDTO>): ContentHubAuthor => {
    const document = dto['ch:haufe-document'];
    const meta = dto['ch:haufe-document']['ch:meta'];

    // we do not want the enclosing divs in the preview
    let preview = document['chb:preview']?.content;
    if (preview) {
        preview = preview.replace(/<div[^>]*>?/gm, '');
        preview = preview.replace(/<\/div[^>]*>?/gm, '').trim();
    }

    const imageUrl = meta['pr:imageUrl'].content as string;
    const authorName = meta['pr:authorName']?.content as string; // there are firstName and preTitle; but we use this
    const shortDescription = meta['pr:shortDescription']?.content;

    // we do not show popup for author "Haufe Online Redaktion" or if there are no additional infos about the author
    const hasRealImage: boolean = imageUrl?.search('fallback') === -1;
    const hasPersonAuthor: boolean = authorName?.trim() !== 'Haufe Online Redaktion';
    const hasShortDescription = !!shortDescription;
    const hasPreview = !!preview;

    if (hasPersonAuthor) {
        prefetchImage(imageUrl);
    }

    return {
        authorName,
        id: meta['ch:contentHubId'],
        imageUrl,
        lastName: meta['pr:lastName']?.content,
        postTitle: meta['pr:postTitle']?.content,
        // profileLinkText: meta['pr:profileLinkText']?.content,
        shortDescription,
        preview,
        addressCity: meta['pr:addressCity']?.content,
        showPopup: hasPersonAuthor && (hasRealImage || hasShortDescription || hasPreview),
    };
};
