import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { ActiveProduct } from '@idr/ui/shared';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LastSearch } from './../model/last-search';
import { Sitemap, SitemapItem } from './../model/sitemap';

export const NewBookmarkAction = {
    USER_CANCELED: 'user-canceled',
    USER_SUBMITTED: 'user-submitted',
    BACKEND_ACCEPTED: 'backend-accepted',
};

export type NewBookmarkAction = (typeof NewBookmarkAction)[keyof typeof NewBookmarkAction];

@Injectable({ providedIn: 'root' })
export class AppStateService {
    // FIXME: This service is becoming more and more complex. We should create smaller services like SitemapService etc.
    //  We should also unit test all methods of this service.

    /**
     * Since the tour is not aware if the beta related popups are being shown currently,
     * we need to have this state somewhere globally accessible that will be also a singleton
     * to prevent the tour from showing up if the beta related popups are being shown.
     * Product tour will start after all the beta related popups are finished.
     */
    public readonly betaPopupsAreVisible$: Observable<boolean>;

    public readonly busy$: Observable<boolean>;

    public readonly lastSearch$: Observable<LastSearch>;

    public readonly loading$: Observable<boolean>;

    public readonly newBookmarkAction$: Observable<NewBookmarkAction>;

    /**
     * Informs about the {@link Sitemap} of current active content / page.
     *
     * This needs to be set with {@link sitemap}.
     *
     * @see StartPageComponent
     * @see SearchPageComponent
     * @see DocumentPageComponent
     */
    public readonly sitemap$: Observable<Sitemap>;

    /**
     * This ensures that the visitCount has been updated in the userSettings before trying to access by the user.
     * This makes sure we avoid race conditions.
     */
    public readonly visitCountHasUpdated$: Observable<void>;

    /**
     * Loading is only initially true. It's always false once the app is loaded.
     */
    private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    private readonly _busy$: Subject<boolean> = new BehaviorSubject(true);
    private readonly _lastSearch$: BehaviorSubject<LastSearch> = new BehaviorSubject(undefined);
    private readonly _betaPopupsAreVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    // FIXME extract this to be a separate (global) injectable .. I think its bad to have it here since its too specific
    // FIXME it isn't tested in any way
    private readonly _newBookmarkAction$: Subject<NewBookmarkAction> = new Subject();

    private readonly _sitemap$: BehaviorSubject<Sitemap> = new BehaviorSubject(undefined);

    private readonly _visitCountHasUpdated$: ReplaySubject<void> = new ReplaySubject(1);

    constructor(
        private readonly activeProduct: ActiveProduct,
        @Inject(DOCUMENT) private readonly document: Document,
    ) {
        this.betaPopupsAreVisible$ = this._betaPopupsAreVisible$.pipe(distinctUntilChanged());
        this.busy$ = this._busy$.pipe(distinctUntilChanged());
        this.lastSearch$ = this._lastSearch$.asObservable();
        this.loading$ = this._loading$.pipe(distinctUntilChanged());
        this.newBookmarkAction$ = this._newBookmarkAction$.pipe(distinctUntilChanged());
        this.sitemap$ = this._sitemap$.asObservable();
        this.visitCountHasUpdated$ = this._visitCountHasUpdated$.asObservable();
    }

    public addingNewBookmarkCanceled(): void {
        logger.debug(`[AppStateService] addingNewBookmarkCanceled`);
        this._newBookmarkAction$.next(NewBookmarkAction.USER_CANCELED);
    }

    public addingNewBookmarkFinished(): void {
        this._newBookmarkAction$.next(NewBookmarkAction.BACKEND_ACCEPTED);
    }

    public addingNewBookmarkStarted(): void {
        this._newBookmarkAction$.next(NewBookmarkAction.USER_SUBMITTED);
    }

    public set betaPopupsAreVisible(value: boolean) {
        this._betaPopupsAreVisible$.next(value);
    }

    public set busy(busy: boolean) {
        logger.debug(`[AppStateService] busy -> ${busy}`);
        this._busy$.next(busy);
    }

    public get loading(): boolean {
        return this._loading$.value;
    }

    public set loading(loading: boolean) {
        logger.debug(`[AppStateService] loading ->`, loading);
        this._loading$.next(loading);
    }

    public get isPageEmpty(): boolean {
        const appContent: HTMLElement = this.document.querySelector('idr-app > div');
        if (!appContent?.innerText) {
            return true;
        }
        return appContent.innerText.trim().length === 0;
    }

    public set sitemap(sitemap: Sitemap) {
        logger.debug(`[AppStateService] sitemap ->`, sitemap);
        if (sitemap === undefined) {
            this._sitemap$.next(undefined);
            return;
        }

        const sitemapWithStartpage: Sitemap = [
            // startpage entry shall only be a link if we aren't on startpage... (NAUA-4975)
            new SitemapItem('Startseite', sitemap.length > 0 ? `/${this.activeProduct.product.id}` : undefined),
            ...sitemap,
        ];
        this._sitemap$.next(sitemapWithStartpage);
    }

    /**
     * Will clear the contents of current sitemap.
     */
    public clearSitemap(): void {
        logger.debug(`[AppStateService] clearSitemap`);
        this.sitemap = undefined;
    }

    public set lastSearch(lastSearch: LastSearch) {
        logger.debug('[AppStateService] lastSearch ->', lastSearch);
        this._lastSearch$.next(lastSearch);
    }

    public visitCountHasUpdated() {
        this._visitCountHasUpdated$.next();
    }
}
