import { CrsDocClassDTO, DocCategory, DocClassification, DocType } from '../crs';
import { OdinDocumentClassification } from '../odin';

const BOOK_DESCRIPTION = 'Buch';

// TODO: This should normally be in the crs-facade lib, but because of circular imports it's here...
export const createDocumentClassificationFromDto = (
    docClassDto: CrsDocClassDTO,
    isBook = false,
): OdinDocumentClassification => {
    if (!docClassDto || (!docClassDto.type && !docClassDto.category)) {
        // AP: not sure if there is such a case...
        // In the Odin API we always assume that we have a type and a category
        return { description: '', type: '' };
    }

    if (docClassDto.displayname) {
        return {
            description: docClassDto.displayname,
            subType: docClassDto.category as string,
            type: docClassDto.type,
        };
    }

    // Classification logic is taken from IDesk2
    const type = DocType[docClassDto.type] ?? '';
    const category = docClassDto.category ? DocCategory[docClassDto.category] : undefined;

    // Don't show types for IDesk1 BEITRAG documents.
    if (!isBook && !category && type === DocType.BEITRAG) {
        return { description: '', type: '' };
    }

    const description = isBook ? BOOK_DESCRIPTION : getDescription(category, type);

    return { description, subType: docClassDto.category, type: docClassDto.type };
};

const getDescription = (category: string | undefined, type: string): string => {
    if (!category) {
        return type === DocType.MUSTERDOKUMENT ? DocType.ARBEITSHILFE : type;
    }
    return getDescriptionFromCategory(category, type);
};

const getDescriptionFromCategory = (category: string, type: string): string => {
    switch (type) {
        case DocType.BEITRAG:
            return category === DocCategory.LEXIKON || category === DocCategory.BUCHUNGSSATZ ? category : type;
        case DocType.RECHTSQUELLE:
        case DocType.NORM:
            return category;
        case DocType.ARBEITSHILFE:
        case DocType.MUSTERDOKUMENT:
            return category === DocCategory.SONSTIGES ? DocType.ARBEITSHILFE : category;
        case DocType.KOMMENTAR:
            return getDescriptionForKommentar(category, type);
        default:
            return type;
    }
};

const getDescriptionForKommentar = (category: string, type: string): string => {
    switch (category) {
        case DocCategory.ENTSCHEIDUNG:
            return DocClassification.ENTSCHEIDUNG;
        case DocCategory.NORM:
            return DocClassification.NORM;
        case DocCategory.VERWALTUNGSANWEISUNG:
            return DocClassification.VERWALTUNGSANWEISUNG;
        default:
            return type;
    }
};
