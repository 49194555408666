import { ApiName } from '../api';
import { DocumentId } from '../document/document-id';
import { ProductId } from '../product/product-id';

/**
 * Hits will be sorted descending by relevance (top rated hit first, 2nd top rated hit next, ...)
 */
export type CrsSortByRelevanceDesc = 'relevanz-';

export type CrsSortOrder = CrsSortByRelevanceDesc;

export type CrsDefaultRange = 'aurora_default';

export const CRS_DEFAULT_DOC_COUNT = 15;

export interface CrsCustomRange {
    readonly startIndex: number;
    readonly endIndex: number;
}

export type CrsRange = CrsDefaultRange | CrsCustomRange;

export const CrsClusterBy = {
    NOT_CLUSTERED: 0,
    CLUSTER_BY_ONE_LEVEL: 1,
    CLUSTER_BY_TWO_LEVELS: 2,
};

export type CrsClusterBy = (typeof CrsClusterBy)[keyof typeof CrsClusterBy];

export const CrsSortBy = {
    ALPHABETICAL_ASCENDING: 'titel+',
    ALPHABETICAL_DESCENDING: 'titel-',
    DATE_ASCENDING: 'datum+',
    DATE_DESCENDING: 'datum-',
    DEFAULT: 'system',
    STATE_ASCENDING: 'stand+',
    STATE_DESCENDING: 'stand-',
    RELEVANCE_DESCENDING: 'relevanz-',
};

export type CrsSortBy = (typeof CrsSortBy)[keyof typeof CrsSortBy];

/**
 * copy & pasted from `de.haufe.aurora.domainmodel.hitlister.HitField`
 */
export const CrsHitField = {
    /** legal question of judgments/decisions ('Rechtsfrage') **/
    LEGAL_QUESTION: 'THE',
    /** the document's provider id - can OVS, Haufe or null **/
    PROVIDER_ID: 'PRV',
    /** VAInfo text equal to title of first section after main document title */
    VA_INFO: 'VAI',
};

export type CrsHitField = (typeof CrsHitField)[keyof typeof CrsHitField];

export interface CrsCommonParams {
    readonly productId: ProductId;
    readonly clustering: CrsClusterBy;
    readonly sorting: CrsSortBy;
}

export class CrsRelatedDocumentsParams implements CrsCommonParams {
    constructor(
        public readonly productId: ProductId,
        public readonly documentId: DocumentId,
        public readonly hitPath?: string,
        public readonly range: CrsRange = 'aurora_default',
        public readonly clustering: CrsClusterBy = CrsClusterBy.CLUSTER_BY_TWO_LEVELS,
        public readonly sorting: CrsSortBy = CrsSortBy.RELEVANCE_DESCENDING,
    ) {}

    private get _range(): string {
        if (this.range === 'aurora_default') {
            return this.range;
        }
        const customRange: CrsCustomRange = this.range;
        return `${customRange.startIndex}-${customRange.endIndex}`;
    }

    public get asCrsUri(): string {
        // don't wonder... for this API endpoint the "hitPath" param is called "path" instead of "hitpath" ...
        const path: string = !!this.hitPath && this.hitPath.length > 0 ? `/path/${this.hitPath}|*` : '';
        return (
            `${ApiName.CRS}/auroraRelatedDocs/product/${this.productId}` + //
            `/ID/${this.documentId}` + //
            `/cluster_by/${this.clustering}` + //
            // `/show_empty_clusters/${...}` + //
            `/sortorder/${this.sorting}` + //
            `/range/${this._range}` + //
            path
        );
    }
}
