import { JsonPipe } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DomService } from '../dom';
import { getHeaderParamAsBool } from './http-utils';

export const CONSUME_GENERIC_ERROR_HANDLER = 'consume-generic-error-handler';

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {
    constructor(private readonly domService: DomService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!getHeaderParamAsBool(CONSUME_GENERIC_ERROR_HANDLER, request.headers)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError(error => {
                if ([401, 403].includes(error?.status)) {
                    // The 401 and 403 errors will be caught by the auth interceptor
                    return throwError(() => error);
                }

                if (error?.status === 503 && error?.headers?.get('x-maintenancepage')) {
                    logger.debug('Redirecting to maintenance.html');
                    // maintenance.html is a static file that is served by nginx when maintenance mode is enabled
                    this.domService.document.location.href = '/maintenance.html';
                    return throwError(() => 'Maintenance mode is active');
                }
                return this.#handleError(request, error);
            }),
        );
    }

    // https://angular.io/guide/http#getting-error-details
    #handleError(request: HttpRequest<unknown>, error: HttpErrorResponse): Observable<never> {
        const logPrefix: string = request.urlWithParams;
        this.#logError(logPrefix, error);
        // return an observable with a user-facing error message
        return throwError(() => ({ error, message: logPrefix + ': Failed. See logs.' }));
    }

    #logError(logPrefix: string, error: HttpErrorResponse): void {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            logger.error(`${logPrefix}: An error occurred: ${error.error.message}`);
            return;
        }
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        logger.error(
            `${logPrefix}: Backend returned code ${error.status}. Body was: ${new JsonPipe().transform(error.error)}`,
        );
    }
}
