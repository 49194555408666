import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { logger } from '@idr/shared/utils';
import { ActiveProduct } from './active-product';

@Injectable({ providedIn: 'root' })
export class CommonNavigationService {
    constructor(
        private readonly router: Router,
        private readonly activeProduct: ActiveProduct,
    ) {}

    toStartpage() {
        const startPagePath: string[] = ['/'];
        if (this.activeProduct.product?.id) {
            startPagePath.push(this.activeProduct.product.id);
        }
        logger.debug('TO_STARTPAGE ->', startPagePath.slice(1).join('/'));
        return this.router.navigate(startPagePath);
    }
}
