import { CrsDocumentHitDTO, OdinDocumentBookInfo } from '@idr/shared/model';

export const createBookInfoFromCrsDocumentHit = (documentHit: CrsDocumentHitDTO): OdinDocumentBookInfo | undefined => {
    const crsBookInfo = documentHit.hit[0].bookInfo;
    if (!crsBookInfo) {
        return undefined;
    }

    const documentRootTitle: string = documentHit.docShortTitle;
    const documentSubTitle = documentHit.hit[0].doctitle;

    const title = crsBookInfo.edition
        ? `${crsBookInfo.title}, ${crsBookInfo.edition}, ${documentRootTitle}`
        : `${crsBookInfo.title}, ${documentRootTitle}`;
    const subTitle = documentRootTitle !== documentSubTitle ? documentSubTitle : undefined;

    return {
        title,
        subTitle,
    };
};
