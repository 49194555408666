import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GtmIdDirective } from '@idr/ui/tracking';
import { BookmarkDialogService } from '../../../../core/dialog/bookmark-dialog.service';
import { PopupWidthClass, HideForAnonymousDirective, PopupDirective } from '@idr/ui/shared';
import { HistoryComponent } from '../history/history.component';
import { InfoMenuLoaderComponent } from '../info-menu-loader.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatIconModule,
        MatTooltipModule,

        HideForAnonymousDirective,
        HistoryComponent,
        InfoMenuLoaderComponent,
        PopupDirective,
        GtmIdDirective,
    ],
    selector: 'idr-header-menu',
    standalone: true,
    styleUrls: ['./header-menu.component.scss'],
    templateUrl: './header-menu.component.html',
})
export class HeaderMenuComponent {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public readonly PopupWidthClass: typeof PopupWidthClass = PopupWidthClass;

    constructor(private readonly bookmarkDialogs: BookmarkDialogService) {}

    public async showFoldersAndNotes(): Promise<void> {
        await this.bookmarkDialogs.openBookmarksOverviewDialog();
    }
}
