export const BetaTestingPopupType = {
    Survey: 1,
    ThankYou: 2,
};
export type BetaTestingPopupType = (typeof BetaTestingPopupType)[keyof typeof BetaTestingPopupType];

export const BETA_TESTING_POPUP_DELAY = 1000;

export const SURVEY_POPUP_DISPLAY_AFTER_X_DAYS = 2;

export const VISIT_TIMES_BEFORE_SHOWING_SURVEY = 2;
