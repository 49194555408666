// FYI: the order here matters... it is even covered with unit-tests
// => the version dialog has an expected order...
export const ApiName = {
    CRS: 'crs-api',
    PRODUCT_CONFIG: 'product-config-api',
    MY_DESK_CONFIG: 'myDesk-config-api',
    OUTPUT_MANAGER: 'output-manager-api',
    AUTHOR_INFORMATION: 'author-information-api',
    BOOKMARKS: 'bookmarks-api',
    CONTENTHUB: 'contenthub-api',
    SUGGESTIONS: 'suggestions-api',
    AUTH_COOKIE: 'auth-cookie-api',
    EMAIL: 'email-api',
    USER_SETTINGS: 'user-settings-api',
};

export type ApiName = (typeof ApiName)[keyof typeof ApiName];

export const VERSION_INFO_ENDPOINT = 'versioninfo.json';
