import { hash, ValueObject } from 'immutable';
import { ProductId } from '../product';

export class SearchLink implements ValueObject {
    readonly trackBy: string;

    constructor(
        public readonly productId: ProductId,
        public readonly query: string,
        public readonly inCluster?: string,
        public readonly searchCategory?: string,
    ) {
        this.trackBy = `SearchLink:${this.productId}:${this.query}:${this.inCluster}:${this.searchCategory}`;
    }

    public static fromStorage(from: any): SearchLink {
        return new SearchLink(from.productId, from.query, from.inCluster, from.searchCategory);
    }

    public equals(other: SearchLink): boolean {
        return (
            this.productId === other?.productId &&
            this.query === other?.query &&
            this.inCluster === other?.inCluster &&
            this.searchCategory === other?.searchCategory
        );
    }

    public hashCode(): number {
        return hash(this.trackBy);
    }
}
