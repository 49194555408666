import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GtmIdDirective } from '@idr/ui/tracking';
import { RxIf } from '@rx-angular/template/if';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UsersnapApiService } from '../../services/usersnap-api.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RxIf, GtmIdDirective],
    selector: 'idr-usersnap',
    standalone: true,
    styleUrls: ['./usersnap.component.scss'],
    template:
        '<button *rxIf="showButton$" data-cy="usersnap" class="primary big" (click)="openUsersnapMenu()" [hgGtmId]="[\'contactButton\', \'open\', \'contact\']">Kontakt</button>',
})
export class UsersnapComponent {
    public readonly showButton$: Observable<boolean>;

    constructor(
        private readonly usersnapApiService: UsersnapApiService,
        private readonly router: Router,
    ) {
        usersnapApiService.init();
        this.showButton$ = combineLatest([this.usersnapApiService.isReady$, this.router.events]).pipe(
            filter(([, event]) => event instanceof NavigationEnd),
            map(([isReady, event]) => {
                const navigationEvent = event as NavigationEnd;
                const urlPaths = navigationEvent.url.split('/');
                return isReady && !urlPaths.includes('config');
            }),
        );
    }

    public openUsersnapMenu(): void {
        this.usersnapApiService.openFeedbackMenu();
    }
}
