export const BauplanItemType = {
    DOCUMENT_LINK: 'document',
    BAUPLAN_LINK: 'column',
    EXTERNAL_LINK: 'external',
    LEXICON_LINK: 'lexicon',
    FILTER_LINK: 'filter',
    APPLICATION_LINK: 'application',
    ADDISON_LINK: 'addison',
};

export type BauplanItemType = (typeof BauplanItemType)[keyof typeof BauplanItemType];
