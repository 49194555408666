import { ApiName } from '../api';
import { ProductId } from '../product/product-id';
import { CrsClusterBy, CrsCommonParams, CrsCustomRange, CrsHitField, CrsSortBy } from './crs-params';
import {
    getCrsCustomRangeParamIfSet,
    getCrsHitFieldsParam,
    getUrParamIfFalse,
    getUrParamIfTrue,
} from './crs-uri-building';
import { getEncodedQueryForCrs } from './get-encoded-query-for-crs';

/**
 * @see https://gitlab.haufedev.systems/aurora/crs/aurora.crs.Products.ContentretrievalREST/-/blob/master/src/Products/ContentretrievalREST/browser/v2/doc/hitlists.md
 *
 * not implemented (that means the defaults will be used by CRS for all requests):
 *  - strict_order (default: ? **but** will be ignored by CRS when hitorder is set to 'relevanz-')
 *  - quick_search_only (default: false) (not used in javaee backend as well)
 *  - cluster_by_doc (default: true) (we don't need to set it to false
 *  - show_empty_clusters (default: false) (we don't need to set it to true)
 *  - date_from
 *  - date_to
 *  - search_all_products (default: false) (currently the feature "Treffer in anderen Produkten" isn't implemented)
 *
 *  - quick_jump (default: true) (used in javaee backend but I decided against setting it.
 *                                We are fine with the default. Important for us is `quick_search` that needs to be set to `false` in
 *                                case CRS detected a `quick_jump` query but nothing was found based on that...)
 *                                (a detected `quick_jump` query will trigger a different search inside CRS.
 *                                To enforce a normal "full-text" search we need to set `quick_search` to `false` only.
 *                                => redoing the search based on that will give us results for "corrupted" `quick_jump` queries as well)
 *  - histogram_by
 *
 * (undocumented)
 *  - timeout
 *  - docfield
 *  - sub_hits_flag (default: false)
 */
export class CrsSearchParams implements CrsCommonParams {
    private constructor(
        public readonly productId: ProductId,
        _query: string,
        public readonly hitPath?: string,
        public readonly clustering: CrsClusterBy = CrsClusterBy.NOT_CLUSTERED,
        public readonly docRange?: CrsCustomRange,
        public readonly quickSearch = true,
        public readonly sorting: CrsSortBy = CrsSortBy.RELEVANCE_DESCENDING,
        public readonly hitRange?: CrsCustomRange,
        public readonly hitContextLength = 360,
        public readonly encodedQuery = getEncodedQueryForCrs(_query, false),
        public readonly encodedQuerySlashReplacement = getEncodedQueryForCrs(_query, true),
    ) {}

    public static searchParamsFor(
        productId: ProductId,
        query: string,
        hitPath?: string,
        page = 1,
        sorting?: CrsSortBy,
        showHitEnvironment?: boolean,
    ): CrsSearchParams {
        let clusterBy: CrsClusterBy = CrsClusterBy.NOT_CLUSTERED;
        if (hitPath) {
            const isRequestForSubCluster: boolean = hitPath.split('|').length > 2;
            clusterBy = isRequestForSubCluster ? CrsClusterBy.CLUSTER_BY_TWO_LEVELS : CrsClusterBy.CLUSTER_BY_ONE_LEVEL;
        }
        const docRange: CrsCustomRange = { startIndex: (page - 1) * 15, endIndex: page * 15 };
        let hitContextLength = 360;
        if (showHitEnvironment === false) {
            hitContextLength = 0;
        }
        const hitRange: CrsCustomRange = { startIndex: 0, endIndex: 1 };
        return new CrsSearchParams(
            productId,
            query,
            hitPath,
            clusterBy,
            docRange,
            true,
            sorting,
            hitRange,
            hitContextLength,
        );
    }

    public static searchCategoriesFor(productId: ProductId, query: string, hitPath?: string): CrsSearchParams {
        const clusterBy: CrsClusterBy = hitPath
            ? CrsClusterBy.CLUSTER_BY_TWO_LEVELS
            : CrsClusterBy.CLUSTER_BY_ONE_LEVEL;
        return new CrsSearchParams(
            productId,
            query,
            hitPath,
            clusterBy,
            undefined,
            false,
            CrsSortBy.DEFAULT,
            undefined,
            -1,
        );
    }

    public get asSearchUri(): string {
        const hitContext: string = this.hitContextLength > 0 ? `/hitcontext/${this.hitContextLength}` : '';
        // hardcoded in javaee backend as well...
        // de.haufe.aurora.domain.hitlister.ejb.HitListerServiceBean#createHitlistRequestParams
        const hitPath: string = !!this.hitPath && this.hitPath.length > 0 ? `/hitpath/${this.hitPath}|*` : '';
        // de.haufe.aurora.dataaccess.crs.hitlist.provider.URIBuilder#buildBaseUri
        return (
            `${ApiName.CRS}/hitlists/product/${this.productId}` + //
            `/cluster_by/${this.clustering}` + //
            `/hitorder/${this.sorting}` + //
            // getUrParamIfTrue('strict_order', ...) + //
            getCrsCustomRangeParamIfSet('hitrange', this.hitRange) + //
            `/searchterm/${this.encodedQuerySlashReplacement}` + //
            getUrParamIfFalse('quick_search', this.quickSearch) + //
            // getUrParamIfTrue('quick_search_only', ...) + //
            // `/date_from/${...}` + // format: DD.MM.YYYY or DD.MM.YY
            // `/date_to/${...}` + // format: DD.MM.YYYY or DD.MM.YY
            // getUrParamIfTrue('show_empty_clusters', ...) + //
            // getUrParamIfTrue('search_all_products', ...) + //
            //      this will trigger CRS to add the `hasSubHits` flag to the result...
            //      => used for requesting categories (and being aware of whether or not the category has sub-categories)
            getUrParamIfTrue('sub_hits_flag', this.clustering === CrsClusterBy.CLUSTER_BY_ONE_LEVEL) + //
            // getUrParamIfFalse('cluster_by_doc', ...) + //
            getCrsCustomRangeParamIfSet('docrange', this.docRange) + //
            hitPath + //
            // hardcoded in javaee backend as well...
            // de.haufe.aurora.domain.hitlister.ejb.HitListerServiceBean#createHitlistRequestParams
            // we have a slightly different logic here... we always set it since we need it for the 2 cases we'll request a search result for
            // (1) is the hit count indicator of the clusters in the hitlist
            // (2) is the paging for the hits...
            '/set_numdocs/1' + //
            // hardcoded in javaee backend as well...
            // de.haufe.aurora.domain.hitlister.ejb.HitListerServiceBean#createHitlistRequestParams
            // de.haufe.aurora.domain.hitlister.ejb.HitListerServiceBean#DEFAULT_HITFIELDS
            getCrsHitFieldsParam([CrsHitField.LEGAL_QUESTION, CrsHitField.PROVIDER_ID, CrsHitField.VA_INFO]) + //
            hitContext
        );
    }

    public get asCorrectionUri(): string {
        // hardcoded in javaee backend as well...
        // de.haufe.aurora.domain.hitlister.ejb.HitListerServiceBean#createHitlistRequestParams
        // de.haufe.aurora.dataaccess.crs.hitlist.provider.URIBuilder#buildBaseUri
        return (
            `${ApiName.CRS}/similarSearchTerms/product/${this.productId}` + //
            `/searchterm/${this.encodedQuery}` + //
            getUrParamIfFalse('quick_search', this.quickSearch) + //
            // getUrParamIfTrue('quick_search_only', ...) + //
            // `/date_from/${...}` + // format: DD.MM.YYYY or DD.MM.YY
            // `/date_to/${...}` + // format: DD.MM.YYYY or DD.MM.YY
            // getUrParamIfTrue('search_all_products', ...) + //
            // `/numsearchterms/${...}` (it is possible to request multiple corrections (default: 1)
            // `/docid/${...}`
            ''
        );
    }
}
