import { Inject, Injectable } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { LocalStorageKey, LocalStorageService } from '../local-storage';
import { LocationService } from '../location';
import { SettingsService } from '../settings/settings.service';
import { AUTH_SCOPE } from './auth-scope';
import { LoginUrl } from './login-url';
import { REALM } from './realm';
import { Realm } from './realms';
import { THIRD_PARTY, ThirdParty } from './third-party';
import { ThirdPartyToken } from './third-party-token';

@Injectable({ providedIn: 'root' })
export class AuthService {
    readonly #logPrefix = '[AuthService]';

    #redirecting = false;

    constructor(
        private readonly location: LocationService,
        private readonly settings: SettingsService,
        private readonly localStorage: LocalStorageService,
        @Inject(REALM) private readonly realm: Realm,
        @Inject(THIRD_PARTY) private readonly thirdParty: ThirdParty,
        @Inject(AUTH_SCOPE) private readonly authScope: string,
        private readonly locationService: LocationService,
    ) {
        this.#overwriteSessionIfTokenIsPresent();
    }

    logout(): void {
        // We also remove the auth realm from local storage when the user logs out so that he/she can use again the default realm
        // if visits the application without a realm.
        this.localStorage.remove(LocalStorageKey.REALM);
        this.location.redirect(`${this.settings.bffHost}/sp/logout`);
    }

    redirectToLoginPage(): void {
        if (this.#redirecting) {
            return;
        }

        const { token, name } = this.thirdParty;
        const loginUrl = this.createLoginUrl(this.realm, token, name, this.authScope).url;

        logger.debug(this.#logPrefix, 'redirectToLoginPage -> Redirecting user to login page', loginUrl);
        this.#redirecting = true;
        this.location.redirect(loginUrl);
    }

    createLoginUrl(
        realm: Realm,
        thirdPartyToken: string,
        thirdPartyTokenName: ThirdPartyToken,
        requestedScope: string,
    ): LoginUrl {
        return new LoginUrl(this.settings.bffHost, this.location.href, realm, {
            thirdPartyToken,
            thirdPartyTokenName,
            requestedScope,
        });
    }

    /**
     * There are cases where we need to overwrite the session if the third party token is present in the url.
     * This prevents the "inavlid token" error when session expires
     * See the tickets for more information:
     * NAUA-7601 - NAUA-7596 - NAUA-7603
     */
    #overwriteSessionIfTokenIsPresent(): void {
        if (
            [Realm.OFFLINE, Realm.PARTNER].includes(this.realm) &&
            this.locationService.searchParams.has(ThirdPartyToken.PROFILE)
        ) {
            this.redirectToLoginPage();
        }
    }
}
