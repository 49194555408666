import { ActivatedRoute } from '@angular/router';

const findIn = <T>(route: ActivatedRoute, key: string, mapName: string): T | undefined => {
    let value: T;
    while (!value && route) {
        value = route.snapshot?.[mapName]?.[key];
        route = route.firstChild;
    }
    return value;
};

/**
 * Will lookup given `key` in the parameters of given `route` using its snapshot.
 *
 * @returns undefined if not found
 */
export const findInParams = <T>(route: ActivatedRoute, key: string): T | undefined => {
    return findIn<T>(route, key, 'params');
};

/**
 * Will lookup given `key` in the data of given `route` using its snapshot.
 *
 * @returns undefined if not found
 */
export const findInData = <T>(route: ActivatedRoute, key: string): T | undefined => {
    return findIn<T>(route, key, 'data');
};
