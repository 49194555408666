import { OverlayRef } from '@angular/cdk/overlay';
import { Directive } from '@angular/core';
import { Message, MessageService, OnlineAccessService } from '@idr/ui/shared';
import { rxEffects } from '@rx-angular/state/effects';

/**
 * Directive to show a message when the user is offline.
 *
 * @see OfflineMessage
 * @see OnlineAccessService
 */
@Directive({
    selector: '[idrOfflineMessage]',
    standalone: true,
})
export class OfflineMessageDirective {
    private overlayRef: OverlayRef;

    constructor(onlineAccessService: OnlineAccessService, messageService: MessageService) {
        rxEffects(({ register }) =>
            register(onlineAccessService.isOnline$, isOnline => {
                if (isOnline) {
                    // We intentionally don't call the messageService.closeDialog as there is case
                    // that it could actually close another dialog.
                    // (i.e. the offline message is shown, the user closes that, another message is shown, the user goes online ->
                    // the last message should not be closed)
                    this.overlayRef?.dispose();
                    this.overlayRef = undefined;
                    return;
                }

                // Display a message to check the connection
                const message: Message = {
                    message: 'Sie sind offline. Bitte überprüfen Sie Ihre Internet-Verbindung.',
                };
                this.overlayRef = messageService.post(message);
            }),
        );
    }
}
