import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { GtmIdDirective } from '@idr/ui/tracking';
import { RxFor } from '@rx-angular/template/for';
import { Observable } from 'rxjs';
import { NavigationItem } from '../../model/navigation-item';
import { NavigationItemService } from '../../services/navigation-item.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, MatIconModule, MatTooltipModule, RxFor, GtmIdDirective],
    providers: [NavigationItemService],
    selector: 'idr-navigation-items',
    standalone: true,
    styleUrls: ['./navigation-items.component.scss'],
    templateUrl: './navigation-items.component.html',
})
export class NavigationItemsComponent {
    @HostBinding('attr.role') public readonly role: string = 'navigation';

    /**
     * opened => the title will be shown + the tooltip is disabled.
     * closed => only icon is shown + tooltip is activated
     */
    @Input() public opened: boolean;

    public readonly navigationItems$: Observable<NavigationItem[]>;

    constructor(sideBarService: NavigationItemService) {
        this.navigationItems$ = sideBarService.navigationItems$;
    }

    public trackBy(index: number, navigationItem: NavigationItem): string {
        return navigationItem.trackBy();
    }
}
