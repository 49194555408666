import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FileType, GroupConfiguration, GroupId, ProductGroupConfiguration } from '@idr/model/config';
import { DebugSettings, ProductId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { AUTHENTICATED_USER_UPN_HEADER } from '@idr/ui/consts';
import { CachingHttpInterceptor, DEBUG_SETTINGS, MY_DESK_CONFIG_API_URL } from '@idr/ui/shared';
import {
    BehaviorSubject,
    catchError,
    firstValueFrom,
    last,
    map,
    Observable,
    of,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs';

const setHeaders = (debug: DebugSettings): HttpHeaders =>
    CachingHttpInterceptor.optOut(
        new HttpHeaders(
            // we only need specific headers for local development
            debug.local_config_api && debug.local_user_email
                ? { [AUTHENTICATED_USER_UPN_HEADER]: debug.local_user_email }
                : {},
        ),
    );

@Injectable({ providedIn: 'root' })
export class ConfigApiService {
    readonly #apiUrl: string;

    readonly #headers: HttpHeaders;

    readonly #cache: Map<GroupId, ProductGroupConfiguration> = new Map();

    readonly #logPrefix = '[ConfigApiService]';

    readonly #cachedProductGroups$ = new BehaviorSubject<ProductGroupConfiguration[]>(this.#cachedProductGroups);

    readonly #requestAgain = new BehaviorSubject<void>(void 0);

    readonly files = {
        add: async (groupId: GroupId, file: File, type: FileType, products?: ProductId[]) => {
            logger.debug(this.#logPrefix, 'addFile ->', groupId, file, products);

            const formData: FormData = new FormData();
            formData.append('file', file);
            formData.append('filename', file.name);
            if (products) {
                formData.append('products', JSON.stringify(products));
            }

            const req$ = this.http
                .post<ProductGroupConfiguration>(`${this.#apiUrl}/productGroup/${groupId}/${type}`, formData, {
                    headers: this.#headers,
                    observe: 'events',
                    reportProgress: true,
                })
                .pipe(
                    // here we could potentially implement progress feedback (for uploading the file)
                    tap(event => logger.debug(this.#logPrefix, 'addFile.event ->', event)),
                    // Now upload & API processing is done
                    // The last value in our source is the actual http response of the API when the call is done
                    last(),
                    // Backend won't respond with no body if the call was successful.
                    // Therefor we can safely cast it.
                    map(data => (data as HttpResponse<ProductGroupConfiguration>).body as ProductGroupConfiguration),
                );
            const patched = await firstValueFrom(req$);
            this.#updateInCache(patched);
            logger.debug(this.#logPrefix, 'addFile -> SUCCESS', groupId, patched);
            return patched;
        },

        /**
         * Deletes a file related/used in configuration (e.g. a partner logo). This will also update the group configuration.
         *
         * You need to update accordingly by yourself OR you request the configuration again.
         * @param id the id of the logo
         * @param type the {@link FileType} (used to determine the endpoint)
         */
        delete: async (id: string, type: FileType) => {
            logger.debug(this.#logPrefix, 'deleteFile ->', id, type);
            const req$ = this.http.delete<ProductGroupConfiguration>(`${this.#apiUrl}/${type}/${id}`, {
                headers: this.#headers,
            });
            await firstValueFrom(req$);
            this.#requestAgain.next();
            logger.debug(this.#logPrefix, 'deleteFile -> SUCCESS', id, type);
        },
    };

    /**
     * A !!long living!! observable that updates everytime group related configuration got changed.
     *
     * The following operations will cause an update:
     * @see delete
     * @see patch
     * @see publish
     * @see files.add
     * @see files.delete
     */
    readonly productGroups$: Observable<ProductGroupConfiguration[]>;

    constructor(
        private readonly http: HttpClient,
        @Inject(MY_DESK_CONFIG_API_URL) apiUrl: string,
        @Inject(DEBUG_SETTINGS) debug: DebugSettings,
    ) {
        this.#apiUrl = apiUrl;
        this.#headers = setHeaders(debug);
        this.productGroups$ = this.#requestAgain.pipe(
            switchMap(() =>
                http.get<ProductGroupConfiguration[]>(`${this.#apiUrl}/productGroup`, { headers: this.#headers }),
            ),
            tap(result => {
                logger.debug(this.#logPrefix, 'productGroups$ ->', result);
                // Since we got now a complete new state from backend, we need to clear the cache completely.
                // Otherwise we might keep a group that got maybe deleted...
                this.#cache.clear();
                result.forEach(group => this.#cache.set(group.groupId, group));
                this.#updateCachedProductGroups();
            }),
            catchError(err => {
                logger.warn(this.#logPrefix, "productGroups$ -> couldn't get response from backend", err);
                return of([]);
            }),
            switchMap(() => this.#cachedProductGroups$),
            shareReplay(1),
        );
    }

    get #cachedProductGroups(): ProductGroupConfiguration[] {
        return Array.from(this.#cache.values());
    }

    #updateCachedProductGroups() {
        this.#cachedProductGroups$.next(this.#cachedProductGroups);
    }

    /**
     * Updates internal {@link #cache} and emits its state.
     * That will trigger {@link productGroups$} as a result.
     * @param group
     * @private
     */
    #updateInCache(group: ProductGroupConfiguration) {
        this.#cache.set(group.groupId, group);
        this.#updateCachedProductGroups();
    }

    /**
     * Deletes related entry for given {@link groupId}.
     *
     * Be aware only drafts are allowed to be deleted.
     * API will deny with 404 if there is no related draft in database.
     *
     * @param groupId
     */
    async delete(groupId: GroupId): Promise<void> {
        logger.debug(this.#logPrefix, 'delete ->', groupId);
        const req$ = this.http.delete(`${this.#apiUrl}/productGroup/${groupId}`, { headers: this.#headers });
        await firstValueFrom(req$);
        this.#requestAgain.next();
        logger.debug(this.#logPrefix, 'delete -> SUCCESS', groupId);
    }

    /**
     * Gets a complete {@link ProductGroupConfiguration} based on passed {@link groupId}.
     * You'll get <code>undefined</code> if related group wasn't found.
     * It is either an unknown <code>groupId<code> or configuration for this group isn't stored in our backend yet.
     *
     * @param groupId
     */
    async get(groupId: GroupId): Promise<ProductGroupConfiguration | undefined> {
        logger.debug(this.#logPrefix, 'get ->', groupId);
        const cached = this.#cache.get(groupId);
        if (cached) {
            logger.debug(this.#logPrefix, 'get -> SUCCESS (cached)', groupId, cached);
            return cached;
        }

        const knownGroups = await firstValueFrom(this.productGroups$);
        const foundIt = knownGroups.find(group => group.groupId === groupId);
        if (foundIt) {
            logger.debug(this.#logPrefix, 'get -> SUCCESS', groupId, foundIt);
            return foundIt;
        }

        logger.warn(this.#logPrefix, "get -> FAILED; requested group isn't part of known groups in backend", groupId);
        return undefined;
    }

    async patch(groupId: GroupId, config: Partial<GroupConfiguration>): Promise<ProductGroupConfiguration> {
        logger.debug(this.#logPrefix, 'patch ->', groupId, config);
        const req$ = this.http.patch<ProductGroupConfiguration>(`${this.#apiUrl}/productGroup/${groupId}`, config, {
            headers: this.#headers,
        });
        const patched = await firstValueFrom(req$);
        this.#updateInCache(patched);
        logger.debug(this.#logPrefix, 'patch -> SUCCESS', groupId, patched);
        return patched;
    }

    async publish(groupId: GroupId): Promise<ProductGroupConfiguration> {
        logger.debug(this.#logPrefix, 'publish ->', groupId);
        const req$ = this.http.put<ProductGroupConfiguration>(
            `${this.#apiUrl}/productGroup/${groupId}/publish`,
            {},
            { headers: this.#headers },
        );
        const published = await firstValueFrom(req$);
        this.#updateInCache(published);
        logger.debug(this.#logPrefix, 'publish -> SUCCESS', groupId, published);
        return published;
    }
}
