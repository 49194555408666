import { NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Output,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { isStartPage } from '@idr/shared/utils';
import { ProductLogoComponent } from '@idr/ui/header';
import { isClickInside, WingPanelButtonComponent } from '@idr/ui/shared';
import { RxLet } from '@rx-angular/template/let';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { NavigationItemsComponent } from './navigation-items/navigation-items.component';

export const TOOLTIP_MESSAGE_CHANGE_DELAY = 25;

/**
 * This component combines the {@link ProductLogoComponent} with the navigation.
 *
 * It can be opened (default state) and closed.
 *
 * @see opened
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, MatTooltipModule, RxLet, ProductLogoComponent, NavigationItemsComponent, WingPanelButtonComponent],
    selector: 'idr-side-bar',
    standalone: true,
    styleUrls: ['./side-bar.component.scss'],
    templateUrl: './side-bar.component.html',
})
export class SideBarComponent {
    /**
     * Whether or not the sideBar is "opened".
     * We have 2 states: "closed" & "opened".
     *
     * The state can be changed with the "toggle" button or from outside with {@link open()} and/or {@link close()}.
     *
     * @see open
     * @see close
     * @see onToggleWingPanelButton
     */
    @HostBinding('class.opened') public opened = true;

    @HostBinding('class.done') public animationDone = true;

    /**
     * Emits the "closed" & "opened" state whenever the user interacts with the "toggleButton".
     *
     * You'll get "false" when the sideBar is closed (from opened -> closed).
     * You'll get "true" when the sideBar is opened (from closed -> opened).
     */
    @Output() public readonly toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

    private readonly _wingPanelTooltip$: BehaviorSubject<string> = new BehaviorSubject('Navigation einklappen');

    constructor(
        private readonly router: Router,
        private readonly elementRef: ElementRef,
    ) {}

    @HostListener('document:click', ['$event'])
    public handleOutsideClick(event: Event): void {
        const clickInsideSidebar: boolean = isClickInside(this.elementRef, event);
        if (!this.opened || clickInsideSidebar || this.ignoreClickOutside) {
            return;
        }

        this.close();
    }

    @HostListener('transitionend')
    public onTransitionEnd(): void {
        this.animationDone = true;
    }

    @HostListener('transitionstart')
    public onTransitionStart(): void {
        this.animationDone = false;
    }

    /**
     * Connected to our template and called whenever the user clicks on the "toggle" button.
     *
     * @param toggled `false` when it got closed | `true` when it got opened {@link WingPanelButtonComponent.toggled}
     */
    public onToggleWingPanelButton(toggled: boolean): void {
        if (this.opened === toggled) {
            return;
        }

        this.animationDone = false;
        this.opened = toggled;
        this.toggled.emit(this.opened);
        // the content of the tooltips should be updated after it got hidden...
        // sadly I can't subscribe to the hidden state of materials tooltip (they have something but that is only available internally)
        // also I can't use `onTransitionEnd` because that one isn't triggered if the page gets reloaded...
        timer(TOOLTIP_MESSAGE_CHANGE_DELAY).subscribe(() => this.updateWingPanelButtonTooltipContent());
    }

    public open(): void {
        this.onToggleWingPanelButton(true);
    }

    public close(): void {
        this.onToggleWingPanelButton(false);
    }

    public get openedAndAnimationDone(): boolean {
        return this.opened && this.animationDone;
    }

    public get wingPanelTooltip$(): Observable<string> {
        return this._wingPanelTooltip$.asObservable();
    }

    private updateWingPanelButtonTooltipContent(): void {
        this._wingPanelTooltip$.next(this.opened ? 'Navigation einklappen' : 'Navigation ausklappen');
    }

    private get ignoreClickOutside(): boolean {
        return isStartPage(this.router.routerState.snapshot.url);
    }
}
