import { Injectable } from '@angular/core';
import {
    AlphabeticalIndex,
    BauplanIndex,
    ContentType,
    HotConfig,
    Links,
    NewsConfig,
    Product,
    StartPageConfiguration,
} from '@idr/shared/model';
import { ActiveProduct } from '@idr/ui/shared';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';

export type StartPageConfigType = AlphabeticalIndex | BauplanIndex | Links | HotConfig | NewsConfig | undefined;

/**
 * Helper function to get config from start-page data.
 */
const findContent = <T extends StartPageConfigType>(startPage: StartPageConfiguration, contentType: ContentType): T => {
    for (const column of startPage.columns) {
        for (const framePortlet of column.framePortlets) {
            for (const boxSection of framePortlet.boxSections) {
                if (boxSection.content.type === contentType) {
                    return boxSection.content as T;
                }
            }
        }
    }
    return undefined;
};

@Injectable({ providedIn: 'root' })
export class ActiveStartPage {
    public readonly activeStartPage$ = this.activeProduct.product$.pipe(
        filter(product => product !== undefined),
        map((product: Product) => product.startPage),
        shareReplay(1),
    );

    constructor(private readonly activeProduct: ActiveProduct) {}

    public getConfig$<T extends StartPageConfigType>(contentType: ContentType): Observable<T> {
        return this.activeStartPage$.pipe(
            filter(startPage => !!startPage),
            // NOTE: We have to use take(1) because we depend on activeProduct.product$ which is a long living observable
            take(1),
            map(startPage => findContent<T>(startPage, contentType)),
            shareReplay(1),
        );
    }
}
