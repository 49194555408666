@if (suggestions().length > 0 && focusDelayed$ | async) {
    <ul
        (document:keyup.enter)="selectSuggestion(selectedSearchTerm)"
        (document:keyup.arrowUp)="onArrowUp()"
        (document:keyup.arrowDown)="onArrowDown()">
        @for (suggestion of suggestions(); track suggestion.searchTerm) {
            <li
                class="has-hover"
                (click)="selectSuggestion(suggestion.searchTerm)"
                [class.selected]="suggestion.selected">
                <span class="highlight">{{ suggestion.prefix }}</span
                >{{ suggestion.suffix }}
            </li>
        }
    </ul>
}
