/* eslint-disable no-console */
export type Logger = Pick<Console, 'debug' | 'error' | 'info' | 'log' | 'warn'>;

export const logger: Logger = {
    debug: (message: string, ...args: unknown[]) => console.debug(message, ...args),
    error: (message: string, ...args: unknown[]) => console.error(message, ...args),
    info: (message: string, ...args: unknown[]) => console.info(message, ...args),
    log: (message: string, ...args: unknown[]) => console.log(message, ...args),
    warn: (message: string, ...args: unknown[]) => console.warn(message, ...args),
};
