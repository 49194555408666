import { Inject, Injectable } from '@angular/core';
import { Event, NavigationEnd } from '@angular/router';
import {
    DocumentId,
    ECONDA_PATH_PREFIX,
    EcondaEvent,
    EcondaPath,
    EcondaSearchHit,
    EcondaSearchTrackingData,
    EcondaTarget,
    EcondaTargetArray,
    EcondaTargetGroup,
    EcondaTargetName,
    Product,
} from '@idr/shared/model';
import {
    containsDocument,
    containsTopicPage,
    isColumnPage,
    isDocumentPage,
    isErrorPage,
    isFilterPage,
    isImprintPage,
    isLexiconPage,
    isNewAndChangedPage,
    isSearchPage,
    isStartPage,
    logger,
    startsWithProductId,
} from '@idr/shared/utils';
import { HotDocument } from '@idr/ui/content-hub';
import { RoutedDocument } from '@idr/ui/document';
import { NPS_ID, REALM_PARAM_NAME } from '@idr/ui/shared';
import { rxState } from '@rx-angular/state';
import { rxEffects } from '@rx-angular/state/effects';
import { EMPTY, merge, Observable, of, OperatorFunction, switchMap } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { IDeskTopicPage } from '../../topic-page/model/topic-page';
import { findInData, findInParams } from '../../utils/route/activated-route-helpers';
import { TrackingDependencies } from './tracking-dependencies';
import { createDocumentContentUrl, createTopicPageContentUrl } from './tracking-helpers';

type UrlCheck = (url: string) => boolean;

const URL_BLACKLIST: UrlCheck[] = [isSearchPage, isColumnPage, isLexiconPage, isFilterPage];

interface ContentPaths {
    readonly content: string;
    /** @see EcondaEvent.hipath */
    readonly hipath?: string;
}

interface EcondaEventData {
    readonly content: string;
    readonly params?: {
        /** @see EcondaEvent.hipath */
        readonly hipath?: string;
        /** @see EcondaEvent.search */
        readonly search?: EcondaSearchTrackingData;
        /** @see EcondaPageChangeEventDto.target */
        readonly target?: EcondaTargetArray;
    };
}

interface TrackingState {
    readonly data: EcondaEventData;
    readonly userId: string;
}

/**
 * For debugging, you can add `?emosdebug=yxcvbnm` to the url.
 * @deprecated We should use GTM tracking service. At some point the econda tracking will be removed.
 */
@Injectable({ providedIn: 'root' })
export class EcondaTrackingService {
    readonly pageChange$: Observable<EcondaEvent>;
    readonly #logPrefix = '[EcondaTrackingService]';
    readonly #state = rxState<TrackingState>();

    constructor(
        private readonly deps: TrackingDependencies,
        @Inject(NPS_ID) private readonly npsId: string,
    ) {
        this.pageChange$ = this.#state.select().pipe(
            filter(({ data, userId }) => !!data && !!userId),
            this.#mapStateToEcondaEvent,
            // to ensure that we don't track the same event twice in a row
            distinctUntilChanged((e1, e2) => e1.equals(e2)),
            shareReplay(1),
        );

        const userId$ = deps.profile.isAnonymous$.pipe(
            switchMap(isAnonymous => (isAnonymous ? of('anonymous') : deps.profile.userId$)),
        );

        this.#state.connect('data', this.#routeToEcondaEventData$);

        this.#state.connect('userId', userId$);

        rxEffects(({ register }) => {
            register(this.pageChange$, event => {
                logger.debug(this.#logPrefix, `pageChange$ ->`, event);
                this.deps.econda.send(event.asDto());
            });
        });
    }

    trackHotDocument(document: HotDocument) {
        logger.debug(`[TrackingService] trackHotDocument ->`, document);
        const event = document.isPaidContent ? EcondaTargetName.PaidContent : EcondaTargetName.IncludedContent;
        this.trackTarget(new EcondaTarget(EcondaTargetGroup.DocumentHot, event));
    }

    /**
     * Tracks a "page change" for given {@param path}.
     *
     * This is helpful if you want to track a "pageChange" on your own.
     *
     * It is needed for complex pages like column view & search where needed data isn't available to {@link EcondaTrackingService}.
     */
    trackPageChange(path: EcondaPath, params?: { readonly hits?: EcondaSearchHit[] }): void {
        logger.debug(this.#logPrefix, ` trackPageChange ->`, path);
        const paths = this.#constructContentPaths(path);
        this.#state.set('data', () => ({
            content: paths.content,
            params: {
                hipath: paths.hipath,
                ...(params?.hits ? { search: { hits: params.hits } } : {}),
            },
        }));
    }

    trackSearch(path: EcondaPath, search: EcondaSearchTrackingData): void {
        logger.debug(this.#logPrefix, ` trackSearch ->`, path, search);
        const paths = this.#constructContentPaths(path);
        this.#state.set('data', () => ({
            content: paths.content,
            params: {
                hipath: paths.hipath,
                search,
            },
        }));
    }

    /**
     * Tracks a "target". When adding new tracking for new features you should prefer targets over markers.
     * This is because targets are more powerful when it comes to reporting later on.
     *
     * @see EcondaTarget
     * @see https://support.econda.de/display/INEN/Targets
     */
    trackTarget(target: EcondaTarget): void {
        if (!target?.isConvertible()) {
            logger.warn(this.#logPrefix, ' trackTarget -> target undefined or not convertible ', target);
            return;
        }

        const state = this.#state.get();
        if (!state.data || !state.userId) {
            logger.warn(this.#logPrefix, `trackTarget -> can't track since there was no pageChange yet`, target);
            return;
        }

        logger.debug(this.#logPrefix, ` trackTarget ->`, target);
        this.#state.set('data', old => ({
            ...old.data,
            params: {
                ...old.data.params,
                target: target.convertForEcondaEvent(),
            },
        }));
    }

    get #documentId(): DocumentId {
        return findInParams<DocumentId>(this.deps.route, 'docId');
    }

    get #routedDocument(): RoutedDocument {
        return findInData<RoutedDocument>(this.deps.route, 'document');
    }

    get #mapRouteToEcondaEventData(): OperatorFunction<string, EcondaEventData> {
        return routeUrl$ =>
            routeUrl$.pipe(
                filter(url => {
                    if (startsWithProductId(url)) {
                        // blacklisted urls are handled differently
                        // see trackPageChangeFor
                        for (const isBlacklisted of URL_BLACKLIST) {
                            if (isBlacklisted(url)) {
                                logger.warn(
                                    this.#logPrefix,
                                    'mapRouteToEcondaEventData -> got NavigationEnd but url is blacklisted',
                                    url,
                                );
                                return false;
                            }
                        }
                        return true;
                    }
                    return isErrorPage(url);
                }),
                map(() => {
                    const paths = this.#constructContentPaths();
                    return {
                        content: paths.content,
                        params: { hipath: paths.hipath },
                    };
                }),
                catchError(err => {
                    logger.warn(this.#logPrefix, ' mapRouteToEcondaEventData -> failed to convert route change', err);
                    return EMPTY;
                }),
            );
    }

    get #mapStateToEcondaEvent(): OperatorFunction<TrackingState, EcondaEvent> {
        return source$ =>
            source$.pipe(
                map(
                    state =>
                        new EcondaEvent(state.data.content, this.#product, this.npsId, state.userId, state.data.params),
                ),
            );
    }

    get #product(): Product {
        return findInData(this.deps.route, 'product');
    }

    /**
     * Converts {@link NavigationEnd} events to {@link EcondaEvent}. Will be used to track page changes.
     *
     * @see trackPageChange
     */
    get #routeToEcondaEventData$(): Observable<EcondaEventData> {
        return merge(
            // If there is a reload on startpage the very first NavigationEnd isn't triggered.
            // BUT! this is only the case for the startpage.
            isStartPage(this.deps.router.url) ? of(this.deps.router.url) : EMPTY,
            this.deps.router.events.pipe(
                filter((event: Event) => event instanceof NavigationEnd),
                map<NavigationEnd, string>(event => event.url),
            ),
        ).pipe(
            tap(url => logger.debug(this.#logPrefix, ' routeToEcondaEventData$ -> got url', url)),
            this.#mapRouteToEcondaEventData,
        );
    }

    get #topicPage(): IDeskTopicPage {
        return findInData<IDeskTopicPage>(this.deps.route, 'topicPage');
    }

    /**
     * Creates a new `EcondaEvent` prepared already with the data needed to be set for every event.
     */
    #constructContentPaths(path?: EcondaPath): ContentPaths {
        let hipath: string = undefined;
        let url = this.deps.router.routerState.snapshot.url;

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const _containsDocument = containsDocument(url);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const _containsTopicPage = containsTopicPage(url);

        if (isStartPage(url)) {
            // We have to remove the auth realm query param from the url
            // because it is not needed for the econda tracking.
            url = url.replace(new RegExp(`\\?${REALM_PARAM_NAME}=.*$`), '');
            url = `${url}/Startseite`;
        } else if (isImprintPage(url)) {
            url = url.replace(/\/document.*$/, '/Impressum');
        } else if (isDocumentPage(url)) {
            url = url.replace(/\/document.*$/, '/Dokument');
        } else if (isColumnPage(url)) {
            url = url.replace(/column\/.*$/, path);
        } else if (isFilterPage(url)) {
            url = url.replace(/filter\/.*$/, path);
        } else if (isLexiconPage(url)) {
            url = url.replace(/lexicon\/.*$/, path);
        } else if (isNewAndChangedPage(url)) {
            url = url.replace(/new-and-changed.*$/, 'Neue und geänderte Dokumente');
        } else if (isSearchPage(url)) {
            url = url.split('?')[0].replace(/search.*/, path);
        } else if (isErrorPage(url)) {
            url = '/Fehlerseite';
        }

        if (_containsTopicPage) {
            const topicPage = this.#topicPage;
            if (topicPage) {
                url = createTopicPageContentUrl(url, topicPage);
            }
        }

        // modifying document specific `content` values to match requirements
        // also we need to add `hipath` attribute for document page loads
        if (_containsDocument) {
            const docId = this.#documentId;
            const docMeta = this.#routedDocument.meta;
            if (docId && docMeta) {
                url = createDocumentContentUrl(url, docId, docMeta);

                // `hipath` is a combination of rootDocId and subDocId
                // rootDocId shall be tracked on its own if just rootDocId was loaded
                hipath = `[${docMeta.root.id}] ${docMeta.root.title}`;
                if (docMeta.root.id !== docId) {
                    hipath += `/[${docId}] ${docMeta.title}`;
                }
            }
        }

        const content = `${ECONDA_PATH_PREFIX}/${url.slice(1)}`;

        return { content, hipath };
    }
}
