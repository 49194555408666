import { ECONDA_NO_TRACKING_KEY, EcondaEvent } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { LocalStorageKey, LocationService, SettingsService } from '@idr/ui/shared';
import { ConsentTrackingService } from '../consent/consent-tracking.service';
import { TrackingConsent } from '../consent/tracking-consent.model';
import { EcondaService } from './econda.service';

export const addEconda =
    (
        document: Document,
        settings: SettingsService,
        location: LocationService,
        econdaService: EcondaService,
        consentService: ConsentTrackingService,
    ): // it must return a function since we use this as an APP_INITIALIZER
    (() => void) =>
    () => {
        const logPrefix = '[EcondaTracking addEconda]';
        let econdaAdded = false;
        const window: Window = document.defaultView;

        if (location.searchParams.has(ECONDA_NO_TRACKING_KEY)) {
            logger.debug(logPrefix, `Will skip adding Econda because of "${ECONDA_NO_TRACKING_KEY}" query parameters.`);
            return;
        }

        const addEcondaScript = () => {
            const clientId: string = settings.econda ? settings.econda.client_id : undefined;
            logger.debug(logPrefix, ' (addEcondaScript) adding script with clientId ->', clientId);

            // this is only lazy because of Cypress e2e tests ...
            // in Cypress, we'll stub this object and block also the script in order to be able to assert the tracking
            // in "normal" app mode this object isn't present at this very moment since the related script is async & not yet loaded...
            // (the emos3, once loaded, will pick up what's already pushed in `stored` and overwrite window['emos3'] with its own impl)
            if (!window['emos3']) {
                window['emos3'] = {
                    stored: [],
                    send(event: EcondaEvent): void {
                        this.stored.push(event);
                    },
                };
            }
            if (clientId) {
                window['emos3'].CLIENT_KEY = clientId;
            }
            const firstScript: HTMLScriptElement = document.getElementsByTagName('script')[0];
            const econda: HTMLScriptElement = document.createElement('script');
            econda.async = true;
            econda.src = '/assets/emos3.c57.0.js';
            econda.type = 'text/javascript';
            firstScript.parentNode.insertBefore(econda, firstScript);
            econdaAdded = true;
            econdaService.econdaIsAdded();
        };

        const updateEcondaToConsent = (consent: TrackingConsent) => {
            let mode = 1; // disabled
            if (consent.statisticsEnabled) {
                mode = 2;
            }
            if (consent.marketingEnabled) {
                mode = 3;
            }

            logger.debug(logPrefix, ' (updateEcondaToConsent) set mode in storage ->', mode);
            // We intentionally NOT use the LocalStorageService to set the value since we do not want to have the idr:: prefix in the key.
            // That local storage value is consumed by econda as well, so we have to avoid adding a prefix.
            localStorage.setItem(LocalStorageKey.ECONDA_PRIVACY_MODE, mode + '');

            if (mode === 1 || econdaAdded) {
                return;
            }

            // econda isn't added yet... so we need to add it (we are allowed to do so)
            addEcondaScript();
        };

        consentService.consent$.subscribe(consent => {
            logger.debug(logPrefix, 'consent set ->', consent);
            updateEcondaToConsent(consent);
        });

        // we do suppress UC_UI_SUPPRESS_CMP_DISPLAY in tests and therefore the script wont be placed into DOM
        // because script was never loaded and econda.send would not be called and this fails e2e test
        // that context is set via: Cypress.on('window:before:load')
        if (window['emos3']?.context === 'test') {
            addEcondaScript();
        }
    };
