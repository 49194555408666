<ul role="tablist">
    @for (tab of tabs; let tabIndex = $index; track tab.title) {
        <li
            role="tab"
            tabindex="0"
            #liElem
            [attr.aria-selected]="selectedIndex === tabIndex"
            [attr.aria-disabled]="!!tab.disabled"
            [matTooltip]="tab.tooltip ?? tab.title"
            #tooltip="matTooltip"
            hgElementOverflowsElement
            [hgGtmId]="[gtmArea(), 'open', gtmDescription(tab.title)]"
            (isOverflowing)="tooltip.disabled = !tab.tooltip && !$event"
            (click)="onClickSelectedIndex(tabIndex)"
            (keydown.enter)="onClickSelectedIndex(tabIndex)">
            @if (tab.loading) {
                <hg-loading-dots />
            } @else {
                {{ tab.title }}
            }
        </li>
    }
</ul>
<div
    role="presentation"
    #indicator></div>
