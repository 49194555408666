import { BRAND_IDS, BrandId } from '@idr/shared/model';

/**
 * Returns the svg name of the corresponding brand logo.
 * If given brand isn't known to us, we fallback to svg name for {@link BrandId.Haufe}.
 * @param brandId
 */
export const getBrandIcon = (brandId: BrandId): string => {
    if (BRAND_IDS.includes(brandId)) {
        return `companyLogo_${brandId}`;
    }
    return `companyLogo_${BrandId.Haufe}`;
};
