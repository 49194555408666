import { NodeDTO } from '../crs/content-hierarchy';
import { DeeplinkPathId } from '../deeplinks';
import { createDocumentClassificationFromDto, DocumentId } from '../document';
import { ProductId } from '../product';
import { BauplanId } from './bauplan-id';
import { BauplanItemType } from './bauplan-item-type';
import { BauplanViewType } from './bauplan-view-type';

export class BauplanItem {
    public readonly title: string;
    public readonly type: string | undefined;
    public readonly linkType: BauplanItemType;
    public readonly id: BauplanId | DocumentId | string;
    public readonly href: string | undefined;
    public readonly domId: string;
    /**
     * The level of the node in the bauplan tree.
     * For documents the level is undefined. It only applies for nodes in the bauplan tree.
     */
    public readonly level: number | undefined;
    public children: BauplanItem[] = [];

    private constructor(node: NodeDTO, productId: ProductId, bauplanViewType: BauplanViewType) {
        if (node.document) {
            this.title = node.document.title;
            this.type = createDocumentClassificationFromDto(node.document.docclass).description;
            this.linkType = BauplanItemType.DOCUMENT_LINK;
            this.id = node.document.docid;
            this.href = `/${productId}/document/${node.document.docid}`;
        } else if (node.link) {
            this.title = node.link.title;
            if (node.link.application) {
                this.type = 'Applikation';
                if (node.link.application.appid === 'addison') {
                    this.linkType = BauplanItemType.ADDISON_LINK;
                    this.href = undefined;
                } else {
                    this.linkType = BauplanItemType.APPLICATION_LINK;
                    this.href = decodeURIComponent(node.link.application.appid + ' ' + node.link.application.cmdline);
                }
                this.id = `APP_${this.href?.toString()}`;
            } else {
                this.type = 'Externer Link';
                this.linkType = BauplanItemType.EXTERNAL_LINK;
                this.href = decodeURIComponent(node.link.uri as string);
                this.id = `EL_${this.href.toString()}`;
            }
        } else {
            this.title = node.title as string;
            switch (bauplanViewType) {
                case BauplanViewType.Lexicon:
                    this.linkType = BauplanItemType.LEXICON_LINK;
                    break;
                case BauplanViewType.Filter:
                    this.linkType = BauplanItemType.FILTER_LINK;
                    break;
                default:
                    this.linkType = BauplanItemType.BAUPLAN_LINK;
                    break;
            }
            this.id = node.id as string;
            this.href = `/${productId}/${DeeplinkPathId.Column}/${node.id}`;
        }

        this.domId = BauplanItem.getBauplanDomId(this.id);

        // The path has a format like this: "PI12345|1|4|1". From the path we can extract the level of the node depending on how many | it has.
        const pathParts = node?.path?.split('|');
        this.level = pathParts ? pathParts?.length - 2 : undefined; // to avoid NaN when pathParts is undefined
    }

    public static fromNodeDto(
        node: NodeDTO,
        productId: ProductId,
        bauplanViewType: BauplanViewType,
    ): BauplanItem | undefined {
        if (!node || !productId) {
            return undefined;
        }
        return new BauplanItem(node, productId, bauplanViewType);
    }

    public static getBauplanDomId(bauplanId: BauplanId | DocumentId | string): string {
        return `bauplan_${bauplanId}`;
    }
}
