import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiName,
    CrsDocumentDTO,
    CrsDocumentWrapperDTO,
    CrsHitlistDTO,
    cutAnchor,
    DocumentId,
    DocumentQuery,
} from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ActiveProduct, DocumentSearchResult, MessageService } from '@idr/ui/shared';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { CrsExceptionTypes } from '../../models/crs/crs-exception-types';
import {
    createDocumentSearchResultFromCrsError,
    createDocumentSearchResultFromFlatDocDto,
    createDocumentSearchResultFromHitListDto,
    createNotFoundDocumentSearchResult,
} from '../../utils/create-document-search-result';
import { CrsBaseService } from './crs-base.service';

/**
 * @deprecated Will be replaced by Odin
 */
@Injectable({ providedIn: 'root' })
export class CrsDocumentSearchService extends CrsBaseService {
    private readonly logPrefix = '[CrsDocumentSearchService]';

    constructor(
        private readonly activeProduct: ActiveProduct,
        messageService: MessageService,
        httpClient: HttpClient,
    ) {
        super(messageService, httpClient);
    }

    getDocumentSearchResults$(dto: CrsDocumentDTO, query: string | undefined): Observable<DocumentSearchResult> {
        const docQuery: DocumentQuery = new DocumentQuery(query);
        if (docQuery.empty) {
            return of(createNotFoundDocumentSearchResult(docQuery.value));
        }

        const searchResult$ = dto.isMultipart
            ? this.#getMatchingParagraphs$(dto.rootid, docQuery)
            : this.#getMatchingParagraphsForFlatDocument$(dto.docid, docQuery.value);

        return searchResult$.pipe(
            catchError(error => of(createDocumentSearchResultFromCrsError(docQuery.value, error.message))),
            tap(result => logger.debug(this.logPrefix, 'getDocumentSearchResults$', result)),
        );
    }

    /**
     * Returns {@link DocumentSearchResult} that contains all documentIds inside given `rootId` that are related to given `query`.
     */
    #getMatchingParagraphs$(rootId: DocumentId, query: DocumentQuery): Observable<DocumentSearchResult> {
        // (!) call it with `'cluster_by_doc/false'` & `'hitrange/0-${Number.MAX_VALUE}'`
        // https://gitlab.haufedev.systems/aurora/backend/aurora.core/-/blob/master/aua-dl-impl/src/main/java/de/haufe/aurora/domain/documentprovider/ejb/DocumentProviderServiceBean.java#L274
        // example search for "das" in "Einkommensteuergesetz"
        // => /hitlists/product/PI10413/docid/HI43504/searchterm/das/cluster_by_doc/false/hitrange/0-166

        // https://gitlab.haufedev.systems/aurora/crs/aurora.crs.Products.ContentretrievalREST/-/blob/master/src/Products/ContentretrievalREST/browser/v2/doc/hitlists.md
        // `cluster_by_doc` needs to be `false` to get a flat list of document-ids
        // `hitrange/-` is a shortcut for "just give me all hits"...
        //      => we leave out start index, so CRS sets `0`
        //      => we leave out end index, so CRS sets it to the end of the list
        // We went through the implementation in iDesk2 and figured out which query params are needed and transferred them here.
        // We didn't include the params that had the default values.
        const apiUrl = `${ApiName.CRS}/hitlists/product/${this.activeProduct.id}/docid/${rootId}${query.asQueryParam}/cluster_by_doc/false/hitrange/-/set_numdocs/false/hitorder/system/quick_search/false`;

        return this.makeCrsGetRequest<CrsHitlistDTO>(apiUrl, {
            throwError: true,
            suppressErrorMessagePopupForExceptionType: CrsExceptionTypes.OSRParser,
        }).pipe(map((dto: CrsHitlistDTO) => createDocumentSearchResultFromHitListDto(query.value, dto)));
    }

    #getMatchingParagraphsForFlatDocument$(documentId: DocumentId, query: string): Observable<DocumentSearchResult> {
        let apiUrl = `${ApiName.CRS}/documents/product/${this.activeProduct.id}/ID/${cutAnchor(documentId)}`;
        if (query) {
            apiUrl = `${apiUrl}${new DocumentQuery(query).asQueryParam}`;
        }
        // For flat documents we cannot request the matching paragraphs since we only have the whole document.
        // We can instead parse the html ourselves and find out which chapters have the search hits.
        // Since the dto might be from a chapter of the flat document we have to get the dto of the full flat document that contains all the subchapters.
        return this.makeCrsGetRequest<CrsDocumentWrapperDTO>(apiUrl, {
            ignoreException: false,
            throwError: true,
            suppressErrorMessagePopupForExceptionType: CrsExceptionTypes.OSRParser,
        }).pipe(
            map((crsWrapperDto: CrsDocumentWrapperDTO) => crsWrapperDto.document?.[0]),
            tap((dto: CrsDocumentDTO) => logger.debug(this.logPrefix, 'getMatchingParagraphsForFlatDocument$ ->', dto)),
            map((dto: CrsDocumentDTO) => createDocumentSearchResultFromFlatDocDto(query, dto)),
        );
    }
}
