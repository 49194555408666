/* eslint-disable @typescript-eslint/naming-convention */
import { ContentHubDocumentDTO, HotMetaDTO } from '@idr/shared/model';

export const CONTENT_HUB_HOT_DOCUMENT_DTO_MOCK: ContentHubDocumentDTO<HotMetaDTO> = {
    'ch:haufe-document': {
        'xmlns:ch': 'https://contenthub.haufe-lexware.com/haufe-document',
        'ch:nativeContent': '',
        'chb:baselineContent': {
            xmlns: 'https://www.w3.org/1999/xhtml',
            'xmlns:chb': 'https://contenthub.haufe-lexware.com/baseline-format-schema',
        },
        'chb:preview': {
            xmlns: 'https://www.w3.org/1999/xhtml',
            'xmlns:chb': 'https://contenthub.haufe-lexware.com/baseline-format-schema',
            content:
                '<h2>SIE FRAGEN, DR. ELZER ANTWORTET</h2> \n<h4><strong>Das WEG-Recht in der Praxis: Im Dialog mit Dr. Oliver Elzer</strong></h4>',
        },
        'ch:meta': {
            'hot:type': {
                'xmlns:hot': 'https://contenthub.haufe.io/producers/hot',
                content: 'online-seminar',
            },
            'hot:at_first_glance': {
                'xmlns:hot': 'https://contenthub.haufe.io/producers/hot',
                content:
                    '<h4><strong>Intro Dr. Elzer – 30 Min.</strong></h4> \n<ul> \n <li>Kurzüberblick zu den gesetzlichen Grundlagen der Jahresabrechnung</li> \n <li>Die Bestimmung der Nachschüsse</li> \n <li>Die Anpassung der Vorschüsse</li> \n <li>Die Beziehung von Jahresabrechnung und Vermögensbericht</li> \n <li>Die Problematik des Drittnutzers</li> \n</ul> \n<h4><strong>Ganze 60 Minuten sind für Ihre Fragen reserviert!</strong></h4> \n<a href="https://onlinetraining.haufe.de/somelink">An OnlineTraining link</a>',
            },
            'hot:duration': {
                'xmlns:hot': 'https://contenthub.haufe.io/producers/hot',
                content: 90,
            },
            'hot:target_group': [
                {
                    'xmlns:hot': 'https://contenthub.haufe.io/producers/hot',
                    content: 'Immobilienverwalter',
                },
                {
                    'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                    content: 'Wohnungseigentumsverwalter',
                },
            ],
            'hot:sold_out': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: false,
            },
            'ch:appDocId': '07d3778b-2ab4-48d1-aa1c-c20482967526',
            'hot:author_request_url': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: 'https://otp.haufe-ep.de/api/v2/authors/5044',
            },
            'hot:price': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: 116.62,
            },
            'ch:title': [
                {
                    name: 'short_title',
                    content: 'WEG-Recht-Reihe Dr. Elzer: Jahresabrechnung',
                },
                {
                    name: 'default',
                    content:
                        'WEG-Recht in der Praxis mit Dr. Elzer – WEG-Verwaltung: Die häufigsten Fragen/Problemfälle zur Jahresabrechnung',
                },
            ],
            'hot:published': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: true,
            },
            'hot:access_url': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: 'https://onlinetraining.haufe.de/bestellung/new/5077',
            },
            'hot:author_image_url': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: 'https://onlinetraining.haufe.de/uploads/tx_otpinstructor/Oliver_Elzer_1628812918.jpg',
            },
            'hot:recommended': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: false,
            },
            'hot:missing_mobile_optimising': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: false,
            },
            'hot:start_date_time': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: '2022-03-24T15:00:00+01:00',
            },
            'hot:link_www': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content:
                    'https://onlinetraining.haufe.de/produkt/immobilien/weg-recht-in-der-praxis-mit-dr-elzer-weg-verwaltung-die-haeufigsten-fragenproblemfaelle-zur-jahresabrechnung?checkSession=1',
            },
            'ch:creator': ['Oliver Elzer', 'Michael Jung'],
            'ch:canonicalUrl':
                'https://onlinetraining-api.haufe.de/elearning/v1/products/07d3778b-2ab4-48d1-aa1c-c20482967526',
            'ch:application': 'hot',
            'ch:contentHubId': 'contenthub://hot/07d3778b-2ab4-48d1-aa1c-c20482967526',
            'ch:chronologicalSortDate': '2022-03-24T15:00:00+01:00',
            'hot:category_title': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: 'WEG-Verwaltung',
            },
            'hot:category_id': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: '0c9886e8-5356-4e58-921a-c7731fbcf67a',
            },
            'ch:documentType': 'online-seminar',
            'hot:net_price': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: 69,
            },
            'hot:author': {
                forename: 'Andreas',
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                surname: 'Pitz',
                title: 'Prof. Dr.',
            },
            'hot:haufelexware_product': [
                {
                    ecom_service_id: 17,
                    'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                    service_id: 5084,
                    title: 'SGB Office Professional',
                },
            ],
            'hot:parent_category_id': {
                'xmlns:hot': 'http://contenthub.haufe.io/producers/hot',
                content: '9ebc7d84-89ad-4d09-86ed-305dc582e491',
            },
        },
    },
};
