/**
 *
 * Get a timestamp for a date that is n days in the future / past
 *
 * @param offsetDays - number of days in the future / past
 * @param startDate - the date to start from (now if not provided)
 * @returns number - the timestamp of the date
 */
export const getTimeStampOffsetByDays = (offsetDays: number, startDate: Date = new Date()): number => {
    return startDate.setDate(startDate.getDate() + offsetDays);
};

/**
 *
 * Get a timestamp for a date that is n weeks in the future / past
 *
 * @param offsetWeeks - number of weeks in the future / past
 * @param startDate - the date to start from (now if not provided)
 * @returns number - the timestamp of the date
 */
export const getTimestampOffsetByWeeks = (offsetWeeks: number, startDate?: Date): number => {
    return getTimeStampOffsetByDays(offsetWeeks * 7, startDate);
};

/**
 *
 * Get short ISO-date from a Date object
 *
 * @param date - date object (now if not provided)
 * @returns string - short ISO-date
 */
export const getISODateShort = (date: Date = new Date()): string => {
    return date.toISOString().split('T')[0];
};
