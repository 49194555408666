<!-- We just don't want to render anything as long as we don't have the configuration yet
     This is because we want to avoid flickering between the 2 possible state (legacy & new configuration) -->
@if (requiresLegacyConfig() !== undefined) {
    @if (requiresLegacyConfig()) {
        @if (brand(); as brand) {
            <hg-company-logo
                [brand]="brand"
                [logo]="productLogo()"
                role="presentation"
                [attr.aria-label]="'Firmenlogo von ' + brand.label" />
        }
    } @else {
        @if (brandId(); as brandId) {
            <idr-company-logo
                [brandId]="brandId"
                [partnerLogo]="partnerLogo()" />
        }
    }
}

<h1 [attr.aria-label]="'Ihr Produkt heißt ' + productName()">
    <span #renderedProductName>{{ productName() }}</span>
    <ng-container *rxIf="productNameSize$; let productNameSize">
        <svg
            role="presentation"
            [attr.viewBox]="'0 0 ' + productNameSize.width + ' ' + productNameSize.height"
            [style.max-width.px]="productNameSize.width">
            <text
                x="0"
                y="18">
                {{ productName() }}
            </text>
        </svg>
    </ng-container>
</h1>
