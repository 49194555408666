import { hash, ValueObject } from 'immutable';
import { CrsDocClassDTO, CrsLinkDTO } from '../crs';
import { OdinDocumentBookInfo, OdinDocumentClassification } from '../odin';
import { ProductId } from '../product/product-id';
import { createDocumentClassificationFromDto } from './create-document-classification-from-dto';
import { DocumentId } from './document-id';
import { DocumentProvider } from './document-provider';

export class DocumentLink implements ValueObject {
    public readonly trackBy: string;

    constructor(
        public readonly productId: ProductId,
        public readonly documentId: DocumentId,
        public readonly title: string,
        public readonly classification: OdinDocumentClassification,
        public readonly bookInfo?: OdinDocumentBookInfo,
        public readonly provider?: DocumentProvider,
    ) {
        this.trackBy = `${productId}:${documentId}`;
    }

    public static fromJson(json: CrsLinkDTO, isBook = false): DocumentLink | undefined {
        if (!json?.resource) {
            return undefined;
        }

        return new DocumentLink(
            json.resource.productid,
            json.resource.docid,
            json.title as string,
            createDocumentClassificationFromDto(json.resource.docclass as CrsDocClassDTO, isBook),
        );
    }

    public static fromStorage(from: any): DocumentLink {
        return new DocumentLink(
            from.productId,
            from.documentId,
            from.title,
            {
                description: from.classification.description,
                subType: from.classification.category,
                type: from.classification.type,
            },
            from.bookInfo,
            from.provider,
        );
    }

    public equals(other: DocumentLink): boolean {
        return this.productId === other?.productId && this.documentId === other?.documentId;
    }

    public hashCode(): number {
        return hash(`DocumentLink:${this.productId}:${this.documentId}`);
    }
}
