import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { cutLeadingSlash } from '@idr/shared/utils';
import { ApiService } from '../../services';

export const RenderingType = {
    Background: 'background',
    Image: 'image',
};

export type RenderingType = (typeof RenderingType)[keyof typeof RenderingType];

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'hg-image',
    standalone: true,
    styleUrls: ['./image.component.scss'],
    template: '',
})
export class ImageComponent {
    readonly #nativeElement: HTMLElement;

    constructor(
        elementRef: ElementRef,
        private readonly renderer: Renderer2,
        private readonly apiService: ApiService,
    ) {
        this.#nativeElement = elementRef.nativeElement;
    }

    /**
     * Renders a round image (100px x 100px) inside this element based on the passed url.
     */
    @Input()
    set bgImgSource(imageUrl: SafeUrl) {
        if ((imageUrl ?? '').toString().length === 0) {
            return;
        }
        this.#updateStyles(imageUrl, RenderingType.Background);
    }

    /**
     * Renders given url as img tag inside this element.
     */
    @Input()
    set imgSource(imageUrl: SafeUrl) {
        if ((imageUrl ?? '').toString().length === 0) {
            return;
        }
        this.#updateStyles(imageUrl, RenderingType.Image);
    }

    #updateStyles(origUrl: SafeUrl, type: RenderingType): void {
        this.#reset();
        const url = this.#getImageUrl(origUrl.toString());

        switch (type) {
            case RenderingType.Background:
                this.renderer.setAttribute(this.#nativeElement, 'role', 'presentation');
                this.renderer.setStyle(this.#nativeElement, 'backgroundImage', `url('${url}')`);
                break;
            case RenderingType.Image:
                // eslint-disable-next-line no-case-declarations
                const imgElement: HTMLElement = this.renderer.createElement('img');
                this.renderer.setAttribute(imgElement, 'src', url);
                this.renderer.appendChild(this.#nativeElement, imgElement);
                break;
        }
    }

    #getImageUrl(imageUrl: string): string {
        // We only need to authenticate (i.e. use static content API) if the image url is relative and not absolute
        if (imageUrl.match(/https?:\/\//)) {
            return imageUrl;
        }

        return `${this.apiService.staticContentBaseUrl}/${cutLeadingSlash(imageUrl)}`;
    }

    // Not sure if this is even still needed.
    // In the initial impl. the url of the image changed with every token update. Now it stays the same, so styles and attributes also should never change.
    #reset(): void {
        this.renderer.removeAttribute(this.#nativeElement, 'role');
        this.renderer.removeStyle(this.#nativeElement, 'backgroundImage');
        /*
         @ts-expect-error can be ignored because it is safe to iterate a HTMLCollection like this in JS
        */
        for (const child of this.#nativeElement.children) {
            this.renderer.removeChild(this.#nativeElement, child);
        }
    }
}
