import { OverlayRef } from '@angular/cdk/overlay';
import { Compiler, ComponentRef, Injectable, Injector, NgModuleFactory } from '@angular/core';
import { CENTERED, DialogService } from '@idr/ui/shared';
import { AppStateService } from '../app-state/app-state.service';
import { DocumentMeta, IDeskDocument } from '@idr/ui/document';

@Injectable({
    providedIn: 'root',
})
export class BookmarkDialogService {
    // This serves the purpose of a cache to avoid having to reload the foldersAndNotesModule
    private foldersAndNotesModule: any;

    constructor(
        private readonly compiler: Compiler,
        private readonly injector: Injector,
        private readonly dialogService: DialogService,
        private readonly appState: AppStateService,
    ) {}

    /**
     * @see DialogService#closeAll
     */
    public closeAll(): void {
        this.dialogService.closeAll();
    }

    public async openBookmarksOverviewDialog(): Promise<void> {
        const overlayRef: OverlayRef = this.dialogService.openDialog({ horizontally: true, vertically: false });
        const folderAndNotesModule = await this.getFoldersAndNotesModule();
        overlayRef.attach(folderAndNotesModule.getComponentPortalForFoldersAndNotesDialog());
    }

    public async openAddBookmarkDialog(
        document: Pick<IDeskDocument, 'id' | 'title'>,
        meta: DocumentMeta,
        selectRootDocument = true,
    ): Promise<void> {
        const overlayRef: OverlayRef = this.dialogService.openDialog(CENTERED, () =>
            this.appState.addingNewBookmarkCanceled(),
        );
        const folderAndNotesModule = await this.getFoldersAndNotesModule();
        const componentRef: ComponentRef<any> = await overlayRef.attach(
            folderAndNotesModule.getComponentPortalForEditBookmarkDialog(),
        );
        componentRef.instance.document = { document, meta, selectRootDocument };
    }

    private async getFoldersAndNotesModule(): Promise<any> {
        if (!this.foldersAndNotesModule) {
            const { FoldersAndNotesModule } = await import('../../folders-and-notes/folders-and-notes.module');
            const moduleFactory: NgModuleFactory<any> = await this.compiler.compileModuleAsync(FoldersAndNotesModule);
            this.foldersAndNotesModule = moduleFactory.create(this.injector).instance;
        }
        return this.foldersAndNotesModule;
    }
}
