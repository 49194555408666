import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentLink, DocumentProvider } from '@idr/shared/model';
import { hrefFromPathArray } from '@idr/shared/utils';
import { GtmArea, GtmIdDirective } from '@idr/ui/tracking';
import { ProviderIconComponent } from '../provider-icon/provider-icon.component';
import { getDocumentRoute } from './get-document-route';

export interface DocumentLinkComponentModel {
    readonly href: string;
    readonly provider: DocumentProvider | undefined;
    readonly routerLink: string[];
    readonly title: string;
    readonly type: string | undefined;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '[class.typeAboveTitle]': 'typeAboveTitle()',
    },
    imports: [ProviderIconComponent, GtmIdDirective],
    selector: 'hg-document-link',
    standalone: true,
    styleUrls: ['./document-link.component.scss'],
    templateUrl: './document-link.component.html',
})
export class DocumentLinkComponent {
    activate = output<Event>();

    activated = input(false);

    /**
     * This is used to determine where the document link that is clicked was placed.
     */
    gtmArea = input.required<GtmArea>();

    link = input.required<DocumentLink>();

    model = computed<DocumentLinkComponentModel>(() => {
        const link = this.link();
        const routerLink = ['/', link.productId, ...getDocumentRoute(link), link.documentId];
        return {
            href: hrefFromPathArray(routerLink),
            provider: link.provider,
            routerLink,
            title: link.bookInfo?.title ?? link.title,
            type: link.classification.description,
        };
    });

    /**
     * If you set this to `false`, clicking on the link will not open the document in full-view mode...
     * => you'll have to care by yourself for the navigation by using the `(activated)` output
     */
    routeToDocumentPage = input(true); // meant to be set once

    /**
     * `true`               => type is placed above title
     * `false` (default)    => type is placed below title
     */
    typeAboveTitle = input(false);

    constructor(private readonly router: Router) {}

    async activateLink(e: Event): Promise<void> {
        // We have to prevent the default event because we have used a href attribute to make right-click with "open in new tab" work.
        e.preventDefault();
        this.activate.emit(e);
        if (this.routeToDocumentPage()) {
            await this.router.navigate(this.model().routerLink);
        }
    }
}
