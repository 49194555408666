import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../api';
import { AuthService } from '../auth/auth.service';

const OPT_IN_AUTH = 'idr-opt-in-auth';

/**
 * This {@link HttpInterceptor} will add authentication for our backend requests.
 * It incorporates {@link AuthService} for getting the `access_token` that will be added to each individual request.
 *
 * If the call fails because of an expired `access_token`, the call will be repeated *once*
 * (therefore a new `access_token` is requested using {@link AuthService}).
 */
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    readonly #logPrefix = '[AuthHttpInterceptor]';

    constructor(
        private readonly authService: AuthService,
        private readonly apiService: ApiService,
    ) {}

    /**
     * This is a special header that can be used to opt-in for sending the auth cookie with the request.
     * This is useful for requests that are not going to our APIs, but we still want to send the auth cookie.
     * Such an example is the request to the profile endpoint. Since profile endpoint is not registered in unknown of our APIs
     */
    static get optInAuthHeaders(): HttpHeaders {
        return new HttpHeaders().set(OPT_IN_AUTH, 'true');
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // The auth cookie is not sent with all requests. We have to explicitly declare that we want to send it.
        // We basically need to send it with all requests that go to our APIs.
        if (request.headers.get(OPT_IN_AUTH) === 'true' || this.apiService.findApiNameForUrl(request.url)) {
            logger.debug(this.#logPrefix, `intercept -> should attach auth cookie to request`, request.url);
            return next
                .handle(request.clone({ withCredentials: true }))
                .pipe(catchError(error => this.#handleError(request, error)));
        }

        logger.debug(this.#logPrefix, `intercept -> no auth cookie is required`, request.url);
        return next.handle(request);
    }

    #handleError(request: HttpRequest<unknown>, error: HttpErrorResponse): Observable<HttpEvent<unknown>> {
        // The only case that will not be handled here is the static content requests.
        // Those are basically GET requests that happen by the browser itself and not by Angular.
        // The reason is that when you have img src attribute set, the request is not made by Angular but by browser itself.
        // Thus, Angular will not have a way to identify if the request fails with 401 or 403.
        if ([401, 403].includes(error.status)) {
            logger.debug(
                this.#logPrefix,
                `#handleError -> got ${error.status} for ${request.url}. Will redirecting to login page.`,
            );
            this.authService.redirectToLoginPage();
            return EMPTY;
        }

        logger.warn(this.#logPrefix, `#handleError -> got unexpected error for ${request.url}`, error);
        return throwError(() => error);
    }
}
