import { inject, Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { convertToFeature, DebugSettings, EcondaSettings, Feature, SettingsDTO, UserCentrics } from '@idr/shared/model';
import { cutSlash, logger } from '@idr/shared/utils';
import { List as ImmutableList } from 'immutable';
import { Environment, ENVIRONMENT } from '../environment';
import { APP_SETTINGS } from './app-settings.token';

export const DEBUG_SETTINGS: InjectionToken<DebugSettings> = new InjectionToken('DebugSettings', {
    providedIn: 'root',
    factory: () => inject(SettingsService).debug ?? {},
});

/**
 * This service provides access to the `Settings`.
 *
 * Settings data is loaded even before the application is loaded. (see main.ts)
 * settings.json` is loaded and parses the result into `Settings`.
 *
 * All parts of the settings are accessible through getters.
 */
@Injectable({ providedIn: 'root' })
export class SettingsService {
    readonly features: ImmutableList<Feature>;

    readonly #logPrefix = '[SettingsService]';

    constructor(
        @Inject(APP_SETTINGS) private readonly settings: SettingsDTO,
        @Optional() @Inject(ENVIRONMENT) private readonly environment?: Environment,
    ) {
        logger.debug(this.#logPrefix, 'loadSettings ->', this.settings);
        this.features = ImmutableList(settings.features ? settings.features.map(dto => convertToFeature(dto)) : []);
    }

    /**
     * Returns the API base url WITHOUT the trailing slash.
     */
    get bffHost(): string {
        return cutSlash(this.settings.bffHost);
    }

    /**
     * This is the endpoint that is used to connect to ContentHub.
     * Bff provides 2 different endpoints for ContentHub and ContentHub integration respectively.
     */
    get bffContentHubEndpoint(): string {
        return this.settings.bffContentHubEndpoint;
    }

    /**
     * The event name that should be triggered when the feedback button is clicked.
     * Read more here: {@link SettingsDTO.usersnapActivationEventName}
     */
    get usersnapActivationEventName(): string {
        return this.settings.usersnapActivationEventName;
    }

    get debug(): DebugSettings | undefined {
        if (this.settings.debug !== undefined && !this.environment?.local) {
            logger.warn(
                this.#logPrefix,
                "loadSettings. Won't use given debug settings since app isn't running in `local` mode",
            );
            return undefined;
        }
        return this.settings.debug;
    }

    get econda(): EcondaSettings | undefined {
        return this.settings.econda;
    }

    get userCentrics(): UserCentrics | undefined {
        return this.settings.userCentrics;
    }
}
