import { inject, InjectionToken } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { LocationService } from '../location';
import { REALM } from './realm';
import { Realm } from './realms';
import { ThirdPartyToken } from './third-party-token';

export interface ThirdParty {
    readonly token: string;
    readonly name: ThirdPartyToken;
}

/**
 * This manages the third party token (either access token or profile, depending on the auth flow) in the state of the app.
 * The third party token is passed to the auth endpoint, and it contains the authentication information for the third party
 * with which we can identify the user in our system and perform the login.
 */
export const THIRD_PARTY: InjectionToken<ThirdParty> = new InjectionToken('ThirdParty', {
    providedIn: 'root',
    factory: () => {
        const realm = inject(REALM);
        const location = inject(LocationService);

        const createThirdParty = (name: ThirdPartyToken) => {
            logger.debug(`[THIRD_PARTY] using ${name} for retrieving token`);
            return { name, token: location.searchParams.get(name) };
        };

        switch (realm) {
            case Realm.DATEV:
                return createThirdParty(ThirdPartyToken.ACCESS_TOKEN);
            case Realm.PARTNER:
            case Realm.OFFLINE:
                return createThirdParty(ThirdPartyToken.PROFILE);
        }

        return { name: undefined, token: undefined };
    },
});
