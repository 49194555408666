import { OdinDateString } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';

export const convertCrsDateToYYYYMMDD = (date: string | undefined): OdinDateString | undefined => {
    if (!date) {
        return undefined;
    }

    // There is a case where the date is malformed, and we should not convert it.
    // This is for the document HI43690
    // In that case the docstate date has a value of "1-.-0.1996" 😳
    // Since we don't want to throw an error and break the app in cases like that, we fall back to undefined for those cases.
    if (!/^\d{2}\.\d{2}\.\d{4}$/.test(date)) {
        logger.error(`[convertCrsDateToYYYYMMDD] - Invalid date format: ${date}. Expected format: 'dd.MM.yyyy'`);
        return undefined;
    }

    const [day, month, year] = date.split('.');
    return `${year}-${month}-${day}` as OdinDateString;
};
