import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    selector: 'hg-loading-dots',
    standalone: true,
    styleUrls: ['./loading-dots.component.scss'],
    template: `<span>&bull;</span><span>&bull;</span><span>&bull;</span>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingDotsComponent {
    @HostBinding('attr.aria-hidden') readonly ariaHidden = true;
}
