import { Injectable } from '@angular/core';
import { TargetDTO, TargetPathDTO } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ActiveProduct } from '@idr/ui/shared';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LegacyProductConfigurationService {
    readonly #logPrefix = '[LegacyProductConfigurationService]';

    constructor(private readonly activeProduct: ActiveProduct) {}

    /**
     * Returns the TargetDTO depending on the active product.
     * Observable will update on product change.
     * Be aware that getTarget$ returns a long living Observable.
     * Use takeUntilDestroyed() or first() operator!
     *
     * @param path the path to the target
     */
    public getTarget$(path: TargetPathDTO): Observable<TargetDTO | undefined> {
        logger.debug(this.#logPrefix, ` getTarget$ ->`, path);
        return this.activeProduct.product$.pipe(
            map(activeProduct => activeProduct.getConfigurationTarget(path)),
            catchError(error => {
                logger.error(this.#logPrefix, ` getTarget$ -> '${path}' failed with`, error);
                return of(undefined);
            }),
        );
    }
}
