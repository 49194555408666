import { computed, effect, Injectable, signal, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
    GroupConfiguration,
    GroupId,
    HasName,
    IS_DRAFT,
    IS_NOT_CONFIGURED,
    ModifiedMeta,
    ProductGroupConfiguration,
} from '@idr/model/config';
import { logger } from '@idr/shared/utils';
import { RxState } from '@rx-angular/state';
import { rxEffects } from '@rx-angular/state/effects';
import { List as ImmutableList, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { firstValueFrom, map, Observable, OperatorFunction } from 'rxjs';
import { ConfigApiService } from '../services/config-api.service';
import { FileRelatedConfig } from './file-related-config';

type InternalIndex = ImmutableMap<GroupId, ProductGroupConfiguration>;

export type TableData = HasName &
    Partial<ModifiedMeta> & {
        readonly groupId: GroupId;
        readonly isDraft: boolean;
        readonly notConfigured: boolean;
    };

export interface ConfigState {
    readonly rawData: ImmutableList<ProductGroupConfiguration>;
    readonly tableData: ImmutableList<TableData>;
}

type InternalConfigState = ConfigState & {
    /**
     * The index is meant for internal use
     */
    readonly index: InternalIndex;
    /**
     * Contains all ids for groups that got changed within current client session.
     * It's updated after either successful PATCH or reset.
     *
     * @see ConfigModel.patch
     * @see ConfigModel.reset
     */
    readonly changed: ImmutableSet<GroupId>;
};

const compareByName = <T extends HasName>(a: T, b: T) => a.name.localeCompare(b.name);

const toArraySortedByName = <T extends HasName>(list: ImmutableList<T>) => list.toArray().sort(compareByName);

@Injectable({ providedIn: 'root' })
export class ConfigModel {
    readonly #logPrefix = '[ConfigModel]';

    readonly #syncing = signal(true);

    readonly #state: RxState<InternalConfigState> = new RxState();

    /**
     * Delegates value from {@link InternalConfigState.changed}.
     */
    readonly changed$: Observable<ImmutableSet<GroupId>>;

    readonly files: FileRelatedConfig;

    readonly productGroups: Signal<ProductGroupConfiguration[]>;

    readonly productGroups$: Observable<ProductGroupConfiguration[]>;

    readonly syncing: Signal<boolean> = this.#syncing.asReadonly();

    readonly tableData: Signal<TableData[]>;

    readonly tableData$: Observable<TableData[]>;

    /**
     * Gets filled step by step for every FIRST only PATCH of a group.
     *
     * It'll contain the state of that group BEFORE the PATCH.
     * We can use that later for our reset functionality.
     *
     * @private
     */
    #indexStateBeforeFirstPatch: InternalIndex = ImmutableMap();

    constructor(private readonly api: ConfigApiService) {
        // there are no changes initially by definition
        this.#state.set({ changed: ImmutableSet() });

        // this ensures initial state based on data from backend
        rxEffects(({ register }) => register(api.productGroups$, groups => this.#update(groups)));

        const rawData$ = this.#state.select('rawData');
        this.#state.connect('tableData', rawData$.pipe(this.#mapRawDataToTableData));

        const index$ = this.#state.select('index');
        this.#state.connect('rawData', index$.pipe(this.#mapIndexToRawData));

        this.changed$ = this.#state.select('changed');

        this.files = new FileRelatedConfig(api, {
            // When deleting files the configuration is changed.
            // We should ensure that our UI has a consistent state after the deletion as well.
            // The DELETE endpoint doesn't return an updated state (would be unconventional).
            // So, what can we do? We just request the state again.
            afterDelete: async () => {
                logger.debug(this.#logPrefix, 'files.afterDelete');
                this.#syncing.set(true);
                this.#update(await firstValueFrom(this.api.productGroups$));
                // FIXME We don't support the reset feature yet for file uploads
                // this.#state.set({ changed: this.#state.get('changed').add(groupId) });
            },

            // We don't need to do anything before a file deletion.
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            beforeDelete: () => {},

            // After a file upload we should update our internal state as well to ensure consistency.
            afterUpload: (groupId, patched) => {
                logger.debug(this.#logPrefix, 'files.afterUpload ->', groupId, patched);
                this.#state.set({
                    index: this.#index.set(groupId, patched),
                    // FIXME We don't support the reset feature yet for file uploads
                    // changed: this.#state.get('changed').add(groupId),
                });
            },

            // This is for our "reset" functionality becoming available after very first change of the user's session
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeUpload: groupId => {
                // FIXME We don't support the reset feature yet for file uploads
                // logger.debug(this.#logPrefix, 'files.beforeUpload ->', groupId);
                // this.#registerPatch(groupId);
            },
        });

        this.productGroups = computed(() => toArraySortedByName(this.#state.signal('rawData')() ?? ImmutableList()));
        this.productGroups$ = toObservable(this.productGroups);
        this.tableData = computed(() => toArraySortedByName(this.#state.signal('tableData')() ?? ImmutableList()));
        this.tableData$ = toObservable(this.tableData);

        effect(() => {
            logger.debug(this.#logPrefix, 'productGroups ->', this.productGroups());
            logger.debug(this.#logPrefix, 'tableData ->', this.tableData());
        });
    }

    get #index(): InternalIndex {
        return this.#state.get('index');
    }

    get #mapIndexToRawData(): OperatorFunction<InternalIndex, ImmutableList<ProductGroupConfiguration>> {
        return map(index => index.toList());
    }

    get #mapRawDataToTableData(): OperatorFunction<ImmutableList<ProductGroupConfiguration>, ImmutableList<TableData>> {
        return map(rawData =>
            rawData.map(raw => ({
                groupId: raw.groupId,
                name: raw.name,
                isDraft: raw.state === IS_DRAFT,
                notConfigured: raw.state === IS_NOT_CONFIGURED,
                // createdBy: raw.configuration.meta.createdBy,
                // createdOn: raw.configuration.meta.createdOn,
                modifiedBy: raw.meta.modifiedBy,
                modifiedOn: raw.meta.modifiedOn,
                // publishedBy: raw.configuration.meta.publishedBy,
                // publishedOn: raw.configuration.meta.publishedOn,
            })),
        );
    }

    /**
     * Is doing the patch with {@link ConfigApiService.patch} and updating our internal {@link InternalConfigState}.
     */
    async #patch(groupId: GroupId, config: Partial<GroupConfiguration>, triggeredByReset = false): Promise<boolean> {
        logger.debug(this.#logPrefix, '#patch ->', groupId, config);
        try {
            this.#registerPatch(groupId);
            const patched = await this.api.patch(groupId, config);

            const changed = this.#state.get('changed');
            this.#state.set({
                index: this.#index.set(groupId, patched),
                changed: triggeredByReset ? changed.delete(groupId) : changed.add(groupId),
            });

            // When we reset the config; we don't need to store it anymore.
            // It'll be updated again as soon as the user changes something again.
            if (triggeredByReset) {
                this.#indexStateBeforeFirstPatch = this.#indexStateBeforeFirstPatch.delete(groupId);
            }

            return true;
        } catch (err) {
            logger.error(this.#logPrefix, '#patch ->', groupId, err);
            logger.warn(this.#logPrefix, "#patch -> Couldn't patch successfully");
        }

        return false;
    }

    #registerPatch(groupId: GroupId): void {
        // First we need to check whether we should store the current state internally for potential reset.
        const stateBeforePatch = this.#index.get(groupId);
        if (stateBeforePatch && !this.#indexStateBeforeFirstPatch.has(groupId)) {
            logger.debug(
                this.#logPrefix,
                'registerPatch -> storing state before first PATCH in this session',
                groupId,
                stateBeforePatch,
            );
            this.#indexStateBeforeFirstPatch = this.#indexStateBeforeFirstPatch.set(groupId, stateBeforePatch);
        }
    }

    /**
     * Updates the internal state with given {@link groups}.
     * Also, it resets {@link syncing}.
     *
     * @param groups
     * @private
     */
    #update(groups: ProductGroupConfiguration[]) {
        logger.debug(this.#logPrefix, '#update ->', groups);
        let index: InternalIndex = ImmutableMap();
        groups.forEach(group => (index = index.set(group.groupId, group)));
        this.#state.set({ index, rawData: ImmutableList.of(...groups) });
        this.#syncing.set(false);
    }

    /**
     * Gets an observable of {@link ProductGroupConfiguration} for given {@link groupId}.
     * You might get `undefined` if given {@link groupId} is unknown.
     *
     * @param groupId
     */
    get$(groupId: GroupId): Observable<ProductGroupConfiguration | undefined> {
        logger.debug(this.#logPrefix, 'get$ ->', groupId);
        return this.#state.select('index').pipe(map(index => index.get(groupId)));
    }

    /**
     * Patches given {@link config} in given {@link groupId}.
     * Also, it updates the internal state which leads to emitting e.g. {@link productGroups}.
     *
     * @param groupId
     * @param config
     *
     * @return `true` if successful, `false` if any error happened
     */
    async patch(groupId: GroupId, config: Partial<GroupConfiguration>): Promise<boolean> {
        return this.#patch(groupId, config);
    }

    /**
     * Deletes entry for given {@link groupId}.
     * Deleting is only possible for draft entries.
     * After it got deleted, the state will be updated with the published entry.
     *
     * This leads to an update in {@link productGroups} as well.
     * @param groupId
     */
    async delete(groupId: GroupId): Promise<boolean> {
        logger.debug(this.#logPrefix, 'delete ->', groupId);
        try {
            await this.api.delete(groupId);
            const productGroupsAfterDelete = await firstValueFrom(this.api.productGroups$);
            // One can only delete a draft.
            // When a draft got deleted, we should replace it in our state with the published one.
            // The user should be able to see the published state of the configuration now.
            const publishedEntry = productGroupsAfterDelete.find(candidate => candidate.groupId === groupId);
            if (!publishedEntry) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('Somehow we encountered an inconsistent API state');
            }
            this.#state.set({ index: this.#index.set(groupId, publishedEntry) });
            return true;
        } catch (err) {
            logger.error(this.#logPrefix, 'delete ->', groupId, err);
            logger.warn(this.#logPrefix, "delete -> Couldn't delete successfully");
        }
        return false;
    }

    async publish(groupId: GroupId): Promise<boolean> {
        logger.debug(this.#logPrefix, 'publish ->', groupId);
        try {
            const published = await this.api.publish(groupId);
            this.#state.set({ index: this.#index.set(groupId, published) });
            return true;
        } catch (err) {
            logger.error(this.#logPrefix, 'publish ->', groupId, err);
            logger.warn(this.#logPrefix, "publish -> Couldn't publish successfully");
        }
        return false;
    }

    async reset(groupId: GroupId): Promise<boolean> {
        logger.debug(this.#logPrefix, 'reset ->', groupId);

        const formerConfiguration = this.#indexStateBeforeFirstPatch.get(groupId)?.configuration;
        if (!formerConfiguration) {
            logger.warn(this.#logPrefix, "reset -> We don't know former state & can't call patch accordingly", groupId);
            return false;
        }

        return this.#patch(groupId, formerConfiguration, true);
    }
}
