import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[hgAutoFocus]',
    standalone: true,
})
export class AutoFocusDirective implements AfterContentInit {
    constructor(private readonly el: ElementRef) {}

    ngAfterContentInit(): void {
        this.el.nativeElement.focus();
    }
}
