import { logger } from '@idr/shared/utils';
import { DocumentId } from '../document/document-id';

const DOCUMENT_ID_WITH_SUB_CHAPTER_NUMBER = /[Hh][Ii](\d+)\.(\d+)/;

export class CombinedSubChapterId {
    private constructor(
        public readonly rootId: DocumentId,
        public readonly nthSubChapter: number,
    ) {}

    public static isConvertible(documentId: DocumentId): boolean {
        return DOCUMENT_ID_WITH_SUB_CHAPTER_NUMBER.test(documentId);
    }

    public static convert(documentId: DocumentId): CombinedSubChapterId | undefined {
        const match = DOCUMENT_ID_WITH_SUB_CHAPTER_NUMBER.exec(documentId);
        logger.debug(`[CombinedSubChapterId] convert`, documentId, `->`, match);
        if (match?.length !== 3) {
            return undefined;
        }

        const converted: CombinedSubChapterId = new CombinedSubChapterId(
            `HI${match[1]}`,
            Number.parseInt(match[2], 10),
        );
        logger.debug(`[CombinedSubChapterId] convert`, documentId, `->`, converted);
        return converted;
    }
}
