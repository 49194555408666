<section>
    <h1>Brand Logo</h1>
    <form>
        <p>Logodatei</p>
        <mat-radio-group [formControl]="brandCtrl">
            @for (brand of brandIds; track brand) {
                <mat-radio-button [value]="brand">
                    <span>{{ getLabelFromBrand(brand) }}</span></mat-radio-button
                >
            }
        </mat-radio-group>
        <mat-icon [svgIcon]="selectedBrandImage()" />
    </form>
</section>

<mc-edit-partner-logo />
