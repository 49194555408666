import { ApiName } from '@idr/shared/model';

export const FRONTEND_VERSION_NAME = 'Oberfläche';

// exported only for e2e tests to verify that all software versions are listed in the dialog
export const MicroServiceVersionNames = {
    [ApiName.CRS]: 'Suche',
    [ApiName.AUTHOR_INFORMATION]: 'Autor-Information',
    [ApiName.BOOKMARKS]: 'Bookmarks',
    [ApiName.CONTENTHUB]: 'Content-Hub',
    [ApiName.OUTPUT_MANAGER]: 'Output-Manager',
    [ApiName.PRODUCT_CONFIG]: 'Produktkonfiguration',
    [ApiName.MY_DESK_CONFIG]: 'myDesk Produktkonfiguration',
    [ApiName.SUGGESTIONS]: 'Suggestions',
    [ApiName.AUTH_COOKIE]: 'Cookie-Authentifizierung',
    [ApiName.EMAIL]: 'E-Mail',
    [ApiName.USER_SETTINGS]: 'Benutzereinstellungen',
};
