import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Inject, signal, Signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IS_DRAFT } from '@idr/model/config';
import { DebugSettings } from '@idr/shared/model';
import { DEBUG_SETTINGS } from '@idr/ui/shared';
import { EditContextComponent } from '../../components/edit-context/edit-context.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { HeaderComponent } from '../../components/header/header.component';
import { NavigationComponent } from '../../components/navigation/navigation.component';
import {
    ACTIVATED_PRODUCT_GROUP,
    ActivatedProductGroupProvider,
    ActivatedProductGroupSignal,
} from '../activated-product-group';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [JsonPipe, RouterOutlet, EditContextComponent, FooterComponent, HeaderComponent, NavigationComponent],
    providers: [ActivatedProductGroupProvider],
    selector: 'mc-edit',
    standalone: true,
    styleUrl: './edit.component.scss',
    template: ` <mc-header>
            <mc-edit-context [active]="activeProductGroup()" />
        </mc-header>
        <mc-navigation />
        <div>
            @if (debug()) {
                <aside>
                    <pre>{{ activeProductGroup() | json }}</pre>
                </aside>
            }
            <router-outlet />
        </div>
        @if (showFooter()) {
            <mc-footer />
        }`,
})
export class EditComponent {
    readonly activeProductGroup: ActivatedProductGroupSignal;
    readonly debug: Signal<boolean>;
    readonly showFooter: Signal<boolean>;

    constructor(
        @Inject(ACTIVATED_PRODUCT_GROUP) activeProductGroup: ActivatedProductGroupSignal,
        @Inject(DEBUG_SETTINGS) debug: DebugSettings,
    ) {
        this.activeProductGroup = activeProductGroup;
        this.debug = signal<boolean>(!!debug.config_debug).asReadonly();
        // the footer is only relevant for draft state
        this.showFooter = computed(() => activeProductGroup()?.state === IS_DRAFT);
    }
}
