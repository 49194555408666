import { inject, InjectionToken } from '@angular/core';
import { ApiName } from '@idr/shared/model';
import { ApiService, DEBUG_SETTINGS } from '../services';

export const MY_DESK_CONFIG_API_URL: InjectionToken<string> = new InjectionToken('CONFIG_API_URL', {
    providedIn: 'root',
    factory: () => inject(DEBUG_SETTINGS).local_config_api ?? ApiName.MY_DESK_CONFIG,
});

export const MY_DESK_CONFIG_IMAGE_URL: InjectionToken<string> = new InjectionToken('MY_DESK_CONFIG_IMAGE_URL', {
    providedIn: 'root',
    factory: () =>
        inject(DEBUG_SETTINGS).local_config_api ?? inject(ApiService).getApiEndpointUrl(ApiName.MY_DESK_CONFIG),
});
