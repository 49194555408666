import { HttpInterceptorFn } from '@angular/common/http';
import { CallThroughInterceptor, HmgUserIdHeaderHttpInterceptor, LoggingInterceptor } from '@idr/ui/shared';
import { environment } from '../../environments/environment';

/**
 * Important: The order of the interceptors is crucial!
 * Please make sure to add new interceptors at the correct position.
 */
export const HTTP_INTERCEPTORS: HttpInterceptorFn[] = [
    // IMPORTANT: Keep the HmgUserIdHeaderHttpInterceptorService above the MicroServiceHttpInterceptorService
    // It depends on the url prefix that MicroServiceHttpInterceptorService is striping off.
    HmgUserIdHeaderHttpInterceptor,

    // This adds more logging to our http requests... I added this when I tried to find a bug in e2e tests
    environment.production ? CallThroughInterceptor : LoggingInterceptor,
];
