import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EcondaEventDto, EcondaPageChangeEventDto, EcondaScript, EcondaTargetEventDto } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ReplaySubject } from 'rxjs';
import { delayWhen, tap } from 'rxjs/operators';

/**
 * The "adapter" for econda calls.
 */
@Injectable({ providedIn: 'root' })
export class EcondaService {
    readonly #logPrefix = '[EcondaService]';
    /**
     * Emits when the econda script is added to the page.
     */
    readonly #econdaIsAdded$ = new ReplaySubject<void>(1);
    /**
     * The queue that keeps the events to be sent to econda until the econda script is ready to send them.
     */
    readonly #sendQueue$ = new ReplaySubject<EcondaEventDto>();

    constructor(
        public readonly ngZone: NgZone,
        @Inject(DOCUMENT) public readonly document: Document,
    ) {
        this.#sendQueue$
            .pipe(
                // Making sure that econda script is added before sending the event
                // There were issues where we tried to send events before the script was initialized -> missing the initial page load.
                delayWhen(() => this.#econdaIsAdded$),
                tap(event => this.#econda.send(event)),
                takeUntilDestroyed(),
            )
            .subscribe();
    }

    /**
     * @returns the object of the injected tracking library (emos3.c57.0.js) used for accessing econda API
     */
    get #econda(): EcondaScript {
        const window: Window = this.document.defaultView;
        let econda: EcondaScript = window['emos3'];
        // we ensure that econda is callable if something went wrong with the emos3.c57.0.js script...
        if (!econda) {
            econda = {
                send: (event: EcondaEventDto) => {
                    logger.warn(this.#logPrefix, 'emos3.send() is not implemented, cannot send event.');
                    logger.debug(this.#logPrefix, 'simulated emos3.send got ->', event);
                },
            } as EcondaScript;
            window['emos3'] = econda;
        }

        logger.debug(this.#logPrefix, 'econda ->', econda);
        return econda;
    }

    econdaIsAdded(): void {
        this.#econdaIsAdded$.next();
    }

    send(event: EcondaEventDto) {
        if (!event) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            const logData = [
                (event as EcondaPageChangeEventDto).content,
                (event as EcondaPageChangeEventDto).search,
                (event as EcondaPageChangeEventDto).sfilter,
                (event as EcondaPageChangeEventDto).hipath,
                (event as EcondaTargetEventDto).Target,
                event,
            ];
            logger.debug(this.#logPrefix, 'send ->', ...logData);
            this.#sendQueue$.next(event);
        });
    }
}
