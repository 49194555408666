import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ApiName } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { HMG_USER_ID_HEADER } from '@idr/ui/consts';
import { switchMap } from 'rxjs';
import { ApiService } from '../api';
import { ProfileService } from '../profile/profile.service';

export const APIS_NEEDING_HMG_USER_ID_HEADER = [ApiName.BOOKMARKS, ApiName.USER_SETTINGS];

export const HmgUserIdHeaderHttpInterceptor: HttpInterceptorFn = (request, next) => {
    const apiName: ApiName | undefined = inject(ApiService).findApiNameForUrl(request.url);
    if (!apiName) {
        return next(request);
    }

    // Bookmarks and User Settings APIs also need this header. They don't really use that
    // since they extract the user id from the token that we provide, but they were part of the backend monolith
    // when they were designed and this is a leftover requirement that we didn't drop yet because of iDesk2.
    if (!APIS_NEEDING_HMG_USER_ID_HEADER.includes(apiName)) {
        return next(request);
    }

    logger.debug('[HmgUserIdHeaderHttpInterceptor] -> will add', HMG_USER_ID_HEADER, 'header to url', request.url);
    return inject(ProfileService).userId$.pipe(
        switchMap(userId => {
            const modifiedRequest = request.clone({
                headers: request.headers.set(HMG_USER_ID_HEADER, userId),
            });
            return next(modifiedRequest);
        }),
    );
};
