import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureId } from '@idr/shared/model';
import { rxEffects } from '@rx-angular/state/effects';
import { Set as ImmutableSet } from 'immutable';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { SettingsService } from '../settings';

/**
 * Pretty simple helper service that filters query parameters on activated route for parameter `feature-on` & `feature-off`.
 * It provides an observable where you can easily check if your feature is enabled ({@link hasFeature$}).
 *
 * You can enable feature toggle based on a query parameter like:
 * <code>?feature-on=foo</code> where `foo` will be the value you can check with {@link hasFeature$}.
 * It is even possible to enable multiple features at once: <code>?feature-on=foo,bar</code> where `foo` and `bar` are enabled afterward.
 * Call <code>?feature-off=foo</code> to disable `foo` or <code>?feature-off=foo,bar</code> to disable `foo` and `bar` again.
 *
 * It also takes into account what is set in the settings.json file, but ignores the start/end dates of those enabled in `settings.json`.
 */
@Injectable({ providedIn: 'root' })
export class FeatureService {
    private readonly features$: BehaviorSubject<ImmutableSet<FeatureId>> = new BehaviorSubject(ImmutableSet());

    constructor(route: ActivatedRoute, settings: SettingsService) {
        const enabledFeatures$ = route.queryParams.pipe(
            filter(params => params['feature-on']),
            map(params => params['feature-on']),
        );

        const disabledFeatures$ = route.queryParams.pipe(
            filter(params => params['feature-off']),
            map(params => params['feature-off']),
        );

        settings.features.forEach(feature => (this.features = this.features.add(feature.id)));

        rxEffects(({ register }) => {
            register(enabledFeatures$, enabledFeatures => {
                let features = this.features;
                enabledFeatures.split(',').forEach((enabled: string) => {
                    features = features.add(enabled.trim());
                });
                this.features = features;
            });

            register(disabledFeatures$, disabledFeatures => {
                let features = this.features;
                disabledFeatures.split(',').forEach((disabled: string) => {
                    features = features.delete(disabled.trim());
                });
                this.features = features;
            });
        });
    }

    private get features(): ImmutableSet<FeatureId> {
        return this.features$.value;
    }

    private set features(features: ImmutableSet<FeatureId>) {
        this.features$.next(features);
    }

    public hasFeature$(feature: FeatureId): Observable<boolean> {
        return this.features$.pipe(
            map(features => features.has(feature)),
            distinctUntilChanged(),
        );
    }
}
