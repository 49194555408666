<a
    *rxFor="let item of navigationItems$; trackBy: trackBy"
    [routerLink]="item.routerLink"
    [attr.aria-label]="item.description"
    [hgGtmId]="['navigation', 'open', 'topLevelItem']"
    [class.is-active]="item.active"
    [class.debug]="item.debug"
    [matTooltip]="item.label"
    [matTooltipDisabled]="opened"
    matTooltipClass="for-sidebar"
    matTooltipPosition="right">
    <span [class.opened]="opened">{{ item.label }}</span>
    <mat-icon [svgIcon]="item.icon" />
</a>
