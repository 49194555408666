export const GtmEvents = {
    PageChange: 'PageChange' as const,
    LexiconFilterPageChange: 'LexiconFilterPageChange' as const,
    UserId: 'UserId' as const,
    UserSettingsChanged: 'UserSettingsChanged' as const,
    OutputmanagerEvent: 'OutputmanagerEvent' as const,
    SearchEvent: 'SearchEvent' as const,
    FormSubmitEvent: 'FilterTableEvent' as const,
};

export type GtmEvent = (typeof GtmEvents)[keyof typeof GtmEvents];

export interface GtmEventBase {
    readonly event: GtmEvent;
}
