export const AUTHENTICATED_USER_UPN_HEADER = 'X-AUTHENTICATED-USERDATA-UPN';

export const CORRELATION_ID_HEADER = 'X-CORRELATION-ID';

export const CLIENT_SESSION_ID_HEADER = 'X-CLIENT-SESSION-ID';

export const HMG_USER_ID_HEADER = 'X-HMGUSERID';

export const HELP_PDF_WITH_SEARCH_PAGE = '/assets/Handbuch-iDesk-online.pdf#page=16';

export const HISTORY_MAX_SIZE = 20;

export const LEXINFORM_CALLER_PARAM_NAME = 'caller';

export const USERSNAP_API_KEY = 'df68546c-edc9-4062-9390-65f7a50c3c3b';
