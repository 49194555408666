<hg-tab-list
    [tabs]="tabs"
    (selected)="onTabSelected($event)"
    gtmArea="history" />

@if (showConfirmDelete()) {
    <hg-confirm-banner
        gtmArea="history"
        message="Möchten Sie den Verlauf wirklich löschen?"
        (confirmed)="clearHistory($event)" />
}

@switch (activeTab()) {
    @case (0) {
        <!-- documents -->
        <section>
            @for (link of documentHistoryLinks(); track link.trackBy) {
                <hg-document-link
                    gtmArea="docHistory"
                    [link]="link"
                    (activate)="close()" />
            }
            @if (documentHistoryLinks().length === 0) {
                <p>Keine Einträge</p>
            }
        </section>
    }
    @case (1) {
        <!-- search -->
        <section>
            @for (link of searchHistoryLinks(); track link.trackBy) {
                <hg-search-link
                    [link]="link"
                    (activate)="close()" />
            }
            @if (searchHistoryLinks().length === 0) {
                <p>Keine Einträge</p>
            }
        </section>
    }
}

<button
    (click)="onDeleteClicked()"
    [disabled]="empty()"
    [hgGtmId]="[activeTab() === 0 ? 'docHistory' : 'searchHistory', 'delete', 'history']">
    Verlauf löschen
</button>
