import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { logger } from '@idr/shared/utils';

@Directive({ selector: '[hgResizeObserver]', standalone: true })
export class ResizeObserverDirective implements OnDestroy {
    @Output() readonly resized: EventEmitter<DOMRectReadOnly> = new EventEmitter();

    readonly #resizeObserver: ResizeObserver;

    constructor(private readonly el: ElementRef) {
        this.#resizeObserver = new ResizeObserver(entries => {
            const dimension = entries[0].contentRect;
            logger.debug('[ResizeObserverDirective] emitting', dimension, 'for', el.nativeElement);
            this.resized.emit(dimension);
        });

        this.#resizeObserver.observe(el.nativeElement);
        logger.debug('[ResizeObserverDirective] observing', el.nativeElement);
    }

    ngOnDestroy(): void {
        this.#resizeObserver.unobserve(this.el.nativeElement);
    }
}
