@if (message()?.header) {
    <div [innerHTML]="message()?.message"></div>
} @else {
    <p>{{ message()?.message }}</p>
}
@if (message()?.errorId; as errorId) {
    <p class="technical">
        Fehlercode: <span>#{{ errorId }}</span>
    </p>
}
@if (message()?.technicalDescription; as technicalDescription) {
    <p class="technical">
        {{ technicalDescription }}
    </p>
}
