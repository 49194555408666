<idr-search-input
    #searchInput
    elementIdPrefix="header-search"
    gtmArea="search"
    [trackingContext]="econdaTargetGroup"
    [ignoreEnterKey]="suggestions.hasSelection"
    (submitCalled)="onSubmitCalled($event)">
    <idr-suggestions
        #suggestions
        [trackingContext]="econdaTargetGroup"
        [focused]="searchInput.focusChange | push"
        [query]="searchInput.query"
        (suggestionSelected)="searchInput.submit($event)" />
</idr-search-input>
