import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

/**
 * The purpose of this directive is to improve the "perceived performance".
 *
 * For example when the user clicks on a search hit the docId query param is changed
 * then the list is re-rendered and only then (after a visible delay) the element is highlighted. (not good)
 *
 * With this directive the element is immediately highlighted.
 * It highlights on click and also on model change.
 * When an element is highlighted it removes the highlight class from all other siblings.
 */
@Directive({
    selector: '[hgHighlightOnClick]',
    standalone: true,
})
export class HighlightOnClickDirective {
    constructor(
        private readonly el: ElementRef,
        private readonly renderer: Renderer2,
    ) {}

    @Input()
    set active(active: boolean) {
        if (active) {
            this.onClick();
        }
    }

    @HostListener('click')
    onClick(): void {
        this.#clearOldSelections();
        this.renderer.addClass(this.el.nativeElement, 'activated');
    }

    #clearOldSelections(): void {
        this.el.nativeElement.parentNode.querySelectorAll('.activated').forEach((sibling: Element) => {
            sibling.classList.remove('activated');
        });
    }
}
