import { ComponentType, GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, Provider } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

export interface Centered {
    readonly horizontally: boolean;
    readonly vertically: boolean;
}

export const CENTERED: Centered = { horizontally: true, vertically: true };

@Injectable({ providedIn: 'root' })
export class DialogService {
    /**
     * Can for example be used to hide the footnotes when other dialogs are opened
     */
    readonly dialogUpdated$: Observable<void>;

    readonly #dialogUpdated$ = new Subject<void>();

    #onCancel?: () => void | undefined;
    #overlayRef?: OverlayRef;

    constructor(
        private readonly injector: Injector,
        private readonly overlay: Overlay,
    ) {
        this.dialogUpdated$ = this.#dialogUpdated$.asObservable();
    }

    closeAll(): void {
        logger.debug('[DialogService] -> closeAll');
        if (this.#onCancel) {
            this.#onCancel();
        }
        this.#dialogUpdated$.next();
        this.#overlayRef?.dispose();
    }

    /**
     * @param onCancel The callback function that will be called when the dialog is closed.
     * IMPORTANT!: We don't await for this function to finish so that the UX is not hurt by a slow callback.
     */
    openDialog(centered: Centered = CENTERED, onCancel?: () => void): OverlayRef {
        logger.debug('[DialogService] -> openDialog');

        this.#dialogUpdated$.next();
        let positionStrategy: GlobalPositionStrategy = this.overlay.position().global();
        if (centered.horizontally) {
            positionStrategy = positionStrategy.centerHorizontally();
        }
        if (centered.vertically) {
            positionStrategy = positionStrategy.centerVertically();
        }

        this.#overlayRef = this.overlay.create(
            new OverlayConfig({
                disposeOnNavigation: true,
                hasBackdrop: true,
                positionStrategy,
            }),
        );

        this.#onCancel = onCancel;

        this.#overlayRef
            .backdropClick()
            .pipe(
                // we use take(1) instead of first() because the backdropClick() might never emit.
                // https://stackoverflow.com/questions/42345969/take1-vs-first
                take(1),
                tap(() => this.closeAll()),
            )
            .subscribe();

        return this.#overlayRef;
    }

    /**
     * @param onCancel The callback function that will be called when the dialog is closed.
     * IMPORTANT!: We don't await for this function to finish so that the UX is not hurt by a slow callback.
     */
    openWithComponent<Type extends ComponentType<unknown>>(
        component: Type,
        providers: Provider[] = [],
        centered: Centered = CENTERED,
        onCancel?: () => Promise<void> | void,
    ): OverlayRef {
        const dialogInjector: Injector = Injector.create({
            parent: this.injector,
            providers,
        });
        const overlayRef: OverlayRef = this.openDialog(centered, onCancel);
        overlayRef.attach(new ComponentPortal(component, null, dialogInjector));
        return overlayRef;
    }
}
