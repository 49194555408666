<a
    [routerLink]="routerLink()"
    [hgGtmId]="['searchHistory', 'open', 'search']"
    [queryParams]="{ 'new-search': true, 'search-category': link().searchCategory }"
    (click)="activate.emit($event)">
    <p
        class="title"
        [innerHTML]="link().query"></p>
    <section>
        @if (link().searchCategory) {
            <p class="type">
                {{ link().searchCategory }}
            </p>
        }
        <p class="product-name">
            {{ link().productId | hgProductName | push }}
        </p>
    </section>
</a>
