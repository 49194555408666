import { signal } from '@angular/core';
import { DocumentId } from '@idr/shared/model';
import { List as ImmutableList } from 'immutable';

/**
 * @returns the DOM id of a `tocEntry`; you can use this to get the related element in DOM
 */
const getTocEntryDOMId = (docId: DocumentId): string => `toc${docId}`;

export class TocEntry {
    readonly #isActive = signal(false);
    readonly isActive = this.#isActive.asReadonly();

    readonly #isExpanded = signal(false);
    readonly isExpanded = this.#isExpanded.asReadonly();

    readonly domId: string;
    readonly isSubLevel: boolean = this.level > 1;

    #children: ImmutableList<TocEntry> | undefined;

    constructor(
        readonly documentId: DocumentId,
        readonly docSize: number,
        readonly title: string,
        /**
         * the level this entry belongs to
         */
        readonly level = 1,
        readonly parent?: TocEntry,
    ) {
        this.domId = getTocEntryDOMId(documentId);
    }

    set children(children: ImmutableList<TocEntry>) {
        if (this.#children) {
            throw Error('Unexpected call to set children. Children are immutable once set.');
        }
        this.#children = children;
    }

    get children(): ImmutableList<TocEntry> {
        return this.#children ?? ImmutableList.of();
    }

    /**
     * Expands the tocEntry. Also expands the parent if the clicked element is a child.
     *
     * @param activate false when a parent tocEntry should be expanded without activating it.
     */
    expand(activate = true): void {
        this.#isActive.set(activate);
        this.#isExpanded.set(true);
        if (this.parent) {
            this.parent.expand(false); // is opened but not activated
        }
    }

    collapse(): void {
        this.#isExpanded.set(false);
    }

    activate(): void {
        this.#isActive.set(true);
    }

    deactivate(): void {
        this.#isActive.set(false);
    }

    get hasChildren(): boolean {
        return this.children.size > 0;
    }
}
