import { Directionality } from '@angular/cdk/bidi';
import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    DestroyRef,
    ElementRef,
    Inject,
    input,
    OnInit,
    Optional,
    Signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { GtmArea, GtmIdDirective, GtmValues, PORTLET_TITLE_TOKEN } from '@idr/ui/tracking';
import { timer } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { WINDOW } from '../../services';

export type Alignment = 'flex-start' | 'center' | 'flex-end';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgStyle, NgTemplateOutlet, MatIconModule, GtmIdDirective],
    providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
    selector: 'hg-stepper',
    standalone: true,
    styleUrls: ['./stepper.component.scss'],
    templateUrl: './stepper.component.html',
})
export class StepperComponent extends CdkStepper implements OnInit {
    readonly arrowButtonGtmValues: Signal<GtmValues>;

    gtmArea = input.required<GtmArea>();

    navMargin = input<string>('0 0 0 0');
    navAlign = input<Alignment>('flex-start');

    readonly #storage: Storage;

    /**
     * will be set lazily; in the end right after this component got rendered, it should be set
     * @private
     */
    #storageKey?: string;

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        private readonly elementRef: ElementRef<HTMLElement>,
        @Inject(WINDOW) private readonly window: Window,
        private readonly destroyRef: DestroyRef,
        @Optional() dir: Directionality,
        @Optional() @Inject(PORTLET_TITLE_TOKEN) private readonly portletTitle: Signal<string>,
    ) {
        super(dir, changeDetectorRef, elementRef);
        this.#storage = this.window.sessionStorage;

        this.arrowButtonGtmValues = computed(() => ({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ...(portletTitle ? { startpageSection_title: this.portletTitle(), startpageLink_title: 'arrow' } : {}),
        }));

        this.orientation = 'vertical';
    }

    ngOnInit() {
        // setTimeout is needed, otherwise it's not possible to restore the state.
        // I tried to do the same in afterViewInit and in afterContentInit without success.
        // FIXME isn't there a better way to do this?
        timer(0)
            .pipe(
                // I added unsubscribe here because without it the code has the potential to break e2e
                // => in a very fast execution:
                //    `StepperComponent` could be initialised and removed from DOM immediately at almost the same time
                //     This leaves code in timer in a stale state. It'll fail because it depends on DOM structure.
                takeUntilDestroyed(this.destroyRef),
                map(() => {
                    this.#storageKey = this.#initStorageKey();
                    const selectedIndex: number = JSON.parse(this.#storage.getItem(this.#storageKey) as string) ?? 0;
                    return this.steps.get(selectedIndex);
                }),
                // it can happen (when product configuration changed or user switched product) that we get undefined
                // reason is the persisted index doesn't match to loaded structure
                filter(Boolean),
                tap(selected => (this.selected = selected)),
            )
            .subscribe();
    }

    getStepButtonGtmValues(step: number): GtmValues {
        return {
            step,
            number_of_steps: this.steps.length,
            ...(this.portletTitle
                ? // eslint-disable-next-line @typescript-eslint/naming-convention
                  { startpageSection_title: this.portletTitle(), startpageLink_title: 'sliderStep' }
                : {}),
        };
    }

    onClick(step: CdkStep): void {
        this.selected = step;
        this.#saveActiveStepToSessionStorage();
    }

    override next(): void {
        let newSelectedIndex = this.selectedIndex + 1;
        if (this.#numberOfSteps === newSelectedIndex) {
            newSelectedIndex = 0;
        }
        this.selected = this.steps.get(newSelectedIndex);
        this.#saveActiveStepToSessionStorage();
    }

    prev(): void {
        let newSelectedIndex = this.selectedIndex - 1;
        if (newSelectedIndex < 0) {
            newSelectedIndex = this.#numberOfSteps - 1;
        }
        this.selected = this.steps.get(newSelectedIndex);
        this.#saveActiveStepToSessionStorage();
    }

    get #numberOfSteps(): number {
        return this.steps.toArray().length;
    }

    #initStorageKey(): string {
        const path = (child: HTMLElement): string => {
            if (child.parentElement?.tagName?.toLowerCase() === 'body') {
                return child.tagName;
            }
            const siblings = child.parentElement?.children as HTMLCollection;
            const index = [...siblings].findIndex(element => element === child);

            return path(child.parentElement as HTMLElement) + '/' + child.tagName + index;
        };

        return path(this.elementRef.nativeElement);
    }

    #saveActiveStepToSessionStorage() {
        this.#storage.setItem(this.#storageKey as string, this.selectedIndex.toString());
    }
}
