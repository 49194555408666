import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink],
    selector: 'mc-header',
    standalone: true,
    styleUrl: './header.component.scss',
    template: `<h1><a [routerLink]="['/', 'config']">myDesk Konfiguration</a></h1>
        <ng-content />`,
})
export class HeaderComponent {}
