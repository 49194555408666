import { DocumentId } from '@idr/shared/model';
import { List as ImmutableList } from 'immutable';
import { TocEntry } from './toc-entry';

const findTocEntryRecursive = (docId: DocumentId, entries: ImmutableList<TocEntry>): TocEntry | undefined => {
    for (const entry of entries) {
        if (entry.documentId === docId) {
            return entry;
        }
        const childResult = findTocEntryRecursive(docId, entry.children);
        if (childResult !== undefined) {
            return childResult;
        }
    }
    return undefined;
};

const resetRecursive = (entries: ImmutableList<TocEntry>): void => {
    entries.forEach(entry => {
        entry.deactivate();
        entry.collapse();
        resetRecursive(entry.children);
    });
};

export class Toc {
    constructor(
        readonly entries: ImmutableList<TocEntry>,
        /**
         * The hidden flag is used when we have TOC with entries, but we don't want to display the TOC at all.
         * Such a case is when we have flat documents that have a TOC just as a helper to render their chapters
         * but the TOC itself doesn't contain any useful information.
         */
        readonly hidden = false,
    ) {}

    reset(): void {
        resetRecursive(this.entries);
    }

    expand(docId: DocumentId): void {
        findTocEntryRecursive(docId, this.entries)?.expand();
    }
}
