import { BrandId } from '@idr/shared/model';
import { GroupConfiguration, LoginConfiguration } from './product-group-configuration';

export const DEFAULT_CONFIGURATION: GroupConfiguration = {
    brandId: BrandId.Haufe,
    partnerLogos: [],
};

export const DEFAULT_LOGIN_CONFIGURATION: LoginConfiguration = {
    brandId: DEFAULT_CONFIGURATION.brandId,
};
