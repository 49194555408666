import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProductGroupConfiguration } from '@idr/model/config';
import { ProductId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { WINDOW } from '@idr/ui/shared';

@Injectable({ providedIn: 'root' })
export class ConfigNavigator {
    readonly #logPrefix = '[ConfigNavigator]';

    constructor(
        private readonly router: Router,
        @Inject(WINDOW) private readonly window: Window,
    ) {}

    goToGroup(group: ProductGroupConfiguration) {
        logger.debug(this.#logPrefix, 'goToGroup ->', group);
        this.router.navigate(['/', 'config', group.groupId]);
    }

    goToProductDraft(productId: ProductId) {
        logger.debug(this.#logPrefix, 'goToProductDraft ->', productId);
        this.window.open(`/${productId}?draft=true`, '_blank');
    }

    goToRootConfig() {
        logger.debug(this.#logPrefix, 'goToRootConfig');
        this.router.navigate(['/', 'config']);
    }
}
