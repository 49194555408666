import { ChangeDetectionStrategy, Component, Inject, input, output, signal } from '@angular/core';
import { FileType, MAX_SIZE_PARTNER_LOGO_IN_MEGABYTES, UploadedFile } from '@idr/model/config';
import { ProductId } from '@idr/shared/model';
import { UploadFileComponent } from '@idr/ui/shared';
import { ConfigModel } from '../../model/config-model';
import { PartnerLogoProductOptionsState } from '../../model/partner-logo-product-options-state';
import { ACTIVATED_PRODUCT_GROUP, ActivatedProductGroupSignal } from '../../routing/activated-product-group';
import { ProductSelectComponent } from '../product-select/product-select.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ProductSelectComponent, UploadFileComponent],
    selector: 'mc-add-partner-logo',
    standalone: true,
    styleUrl: './add-partner-logo.component.scss',
    template: `
        <mc-product-select
            #select
            (change)="partnerLogoProductOptions.set($event.product.id, $event.product.selected)"
            [options]="partnerLogoProductOptions.values()" />
        <section>
            <p>Logodatei</p>
            <div>
                <hg-upload-file
                    accept=".jpg,.jpeg,.png,.svg,.webp"
                    [maxSize]="MAX_SIZE"
                    (picked)="onFilePicked($event, select.selectedProducts)"
                    [error]="error()" />
            </div>
        </section>
    `,
})
export class AddPartnerLogoComponent {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    readonly MAX_SIZE = MAX_SIZE_PARTNER_LOGO_IN_MEGABYTES;

    readonly error = signal<string | undefined>(undefined);

    readonly logo = input<UploadedFile | undefined>(undefined);

    /**
     * Emits after a file got uploaded successfully.
     * It doesn't emit if any error happened while uploading it.
     */
    readonly used = output<void>();

    constructor(
        @Inject(ACTIVATED_PRODUCT_GROUP) private readonly active: ActivatedProductGroupSignal,
        private readonly model: ConfigModel,
        public readonly partnerLogoProductOptions: PartnerLogoProductOptionsState,
    ) {}

    async onFilePicked(picked: File, products: ProductId[]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const groupId = this.active()!.groupId;

        // We have a non-multiple input for file upload, so we can safely cast to `File` type
        const success = await this.model.files.upload(groupId, picked, FileType.PartnerLogo, products);
        if (success) {
            this.used.emit();
            return; // success; we are done
        }

        this.error.set('Fehler beim Hochladen. Überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.');
    }
}
