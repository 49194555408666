import { CrsDocumentDTO, CrsOutlineDTO, CrsOutlineEntryDTO, DocumentId } from '@idr/shared/model';
import { Toc } from '@idr/ui/document';
import { createToc } from './create-toc';

export interface FlatDocumentPart {
    readonly id: DocumentId;
    readonly content: string;
}

export const multipartDtosFromFlatDto = (flatDocIds: DocumentId[], dto: CrsDocumentDTO): CrsDocumentDTO[] => {
    const parts = getFlatDocumentParts(dto);
    return parts
        .filter(part => flatDocIds.includes(part.id))
        .map(part => ({
            ...dto,
            docid: part.id,
            text: part.content,
            // We don't change the isMultipart flag because the flat document is not originally a multipart document and we want other code to be able to recognize that.
        }));
};

/**
 * Splits the flat document into parts. The parts contain only the HTML content of each part.
 */
export const getFlatDocumentParts = (dto: CrsDocumentDTO): FlatDocumentPart[] => {
    if (!dto.text) {
        return [];
    }

    // For every section of the document there is an "<a name="..." class="sectionId">" tag before it.
    // The value inside the "name" attribute is the id of the section that is also used as an anchor.
    // https://regex101.com/r/0g1I9l/1
    const sectionsRegExp = /<a name="([^"]*)" class="sectionId">[^</a>]*<\/a>/g;

    // The parts consist of the text between the "<a name="..." class="sectionId">" tags and the sectionIds that are given in the name attribute.
    // parts[n] contains the section text and parts[n+1] contains the sectionId.
    const sections: string[] = dto.text.split(sectionsRegExp);
    const parts: FlatDocumentPart[] = [
        {
            id: dto.docid,
            content: sections[0],
        },
    ];

    // We use a step of 2 because the matches return the sectionContent and the sectionId in pairs.
    // We start at 1 because the first part is the text before the first "<a name="..." class="sectionId">" tag.
    for (let i = 1; i < sections.length - 1; i += 2) {
        const sectionId = sections[i];
        const sectionContent = sections[i + 1];
        // The way that .split works is that it returns the parts between the matches but not the matches themselves.
        // Thus, we have to manually add the "<a name="..." class="sectionId">" tag back to the textContent.
        const textContent = `<a name="${sectionId}" class="sectionId"> </a>${sectionContent}`;
        parts.push({
            id: `${dto.docid}-${sectionId}`,
            content: textContent,
        });
    }
    return parts;
};

export const createTocForFlatDocumentWithoutToc = (parts: FlatDocumentPart[], rootDocTitle: string): Toc => {
    const createEntries = (_parts: FlatDocumentPart[]): CrsOutlineEntryDTO[] =>
        // We should remove the first item from the parts as it's the root document that is always not included in the TOC.
        _parts.slice(1).map(part => ({
            docid: part.id,
            // The title and shorttitle are left empty because the toc in this case is not displayed anyway.
            // Therefore, we don't really care about filling them.
            title: '',
            shorttitle: '',
            isRessort: false,
        }));

    const outline: CrsOutlineDTO = {
        docid: parts[0].id,
        shorttitle: rootDocTitle,
        title: rootDocTitle,
        // Unfortunately we don't have a way to calculate the size of the document but that shouldn't cause issues
        // since flat documents are usually short and we already have them available in the first request anyway.
        docsize: 0,
        entry: createEntries(parts),
    };

    return createToc(outline, true);
};
