<dialog open>
    <header
        cdkDrag
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragHandle
        cdkTrapFocus
        cdkTrapFocusAutoCapture>
        {{ data.headline }}
        <button (click)="close()">
            <mat-icon svgIcon="close" />
        </button>
    </header>
    <main>
        <ng-container *ngTemplateOutlet="data.message;" />
    </main>
    <footer>
        <button
            class="secondary"
            (click)="close()">
            Abbrechen
        </button>
        <button
            #submit
            type="submit"
            [mat-dialog-close]="true"
            [class.primary]="!data.dangerousSubmit"
            [class.warning]="data.dangerousSubmit">
            {{ data.submitLabel }}
        </button>
    </footer>
</dialog>
