import { inject, InjectionToken } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { LocationService } from '../location';
import { REQUESTED_SCOPE_PARAM_NAME } from './auth-params';
import { REALM } from './realm';
import { Realm } from './realms';

/**
 * The requested scope is a string that contains the product ids that the user has requested access to.
 * It's a space separated list of product ids, and it is used in the internal auth flow only.
 *
 * @example 'PI78 PI42765'
 */
export const AUTH_SCOPE: InjectionToken<string> = new InjectionToken('AuthScope', {
    providedIn: 'root',
    factory: () => {
        const realm = inject(REALM);
        if (realm !== Realm.HAUFE_INTERNAL_IDESK) {
            return undefined;
        }

        const location = inject(LocationService);
        const requestedScope = location.searchParams.get(REQUESTED_SCOPE_PARAM_NAME);

        logger.debug('[AUTH_SCOPE] -> found in query params', requestedScope);
        return requestedScope;
    },
});
