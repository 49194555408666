import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ProductConfiguration } from '@idr/model/config';
import { ApiName, DebugSettings, ProductId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { DEBUG_SETTINGS, findKeyInObject } from '@idr/ui/shared';
import { Map as ImmutableMap } from 'immutable';
import { catchError, EMPTY, map, of, shareReplay, tap } from 'rxjs';

export type ProductConfigurationIndex = ImmutableMap<ProductId, ProductConfiguration> | undefined;

/**
 * An service that returns an index for all product configurations for all products the user has licensed.
 * Or it returns the draft configuration of a certain product.
 *
 * Be aware this is the configuration inside our new myDesk product configuration system.
 * It has nothing to do with the old legacy iDesk product configuration delivered via iConfig, Productionline
 * & a legacy Java µ-service in our infrastructure.
 *
 * You get `undefined` as initial value since we need to request backend first & need to wait for its result.
 */

@Injectable({ providedIn: 'root' })
export class ProductConfigurationService {
    readonly allConfigurations: Signal<ProductConfigurationIndex>;
    readonly apiUrl: string;

    readonly #logPrefix = '[ProductConfigurationService]';

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router,
        // we need debug in case we run locally and have also our myDesk product config api running & want to use it
        @Inject(DEBUG_SETTINGS) debug: DebugSettings,
    ) {
        this.apiUrl = debug.local_config_api ?? ApiName.MY_DESK_CONFIG;
        this.allConfigurations = toSignal(
            this.http.get<ProductConfiguration[]>(`${this.apiUrl}/product`).pipe(
                tap(result => logger.debug(this.#logPrefix, 'allConfigurations got ->', result)),
                // when we have an error from API for some reason we must not stop
                // if we wouldn't resolve with at least an empty list the whole app breaks and will be unusable
                catchError(error => {
                    logger.error(this.#logPrefix, 'allConfigurations got ->', error);
                    return of<ProductConfiguration[]>([]);
                }),
                map(result => ImmutableMap(result.map(config => [config.productId, config]))),
                shareReplay(1),
            ),
        );
    }

    getDraft(productId: ProductId) {
        logger.debug(this.#logPrefix, 'getDraft ->', productId);
        return this.http.get<ProductConfiguration>(`${this.apiUrl}/product/draft/${productId}`).pipe(
            tap(result => logger.debug(this.#logPrefix, 'getDraft ->', productId, result)),
            // when we have an error from API for some reason we must not stop
            // if we wouldn't resolve with at least an empty list the whole app breaks and will be unusable
            catchError(error => {
                logger.error('PRODUCT_CONFIGURATION_DRAFT got ->', error);
                const errorStatus = findKeyInObject(error, 'statusCode');
                // we need an error-page for no-navigation here
                if (errorStatus === 404) this.router.navigate(['/error/no-draft-error']);
                return EMPTY;
            }),
        );
    }
}
