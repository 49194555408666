import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { getHeaderParamAsBool } from './http-utils';

export const CACHE_IT_HTTP_HEADER_KEY = 'cache_it';

/**
 * This should be used to interact with the cache.
 *
 * @return a unique representation of given request based on the url and serialised parameters
 */
const keyify = (request: HttpRequest<unknown>): string => {
    return request.url + (request.params.keys().length > 0 ? `?${request.params.toString()}` : '');
};

/**
 * @see https://angular.io/guide/http#caching
 */
@Injectable()
export class CachingHttpInterceptor implements HttpInterceptor {
    constructor(private readonly cache: CacheService<HttpResponse<unknown>>) {}

    static optOut(headers = new HttpHeaders()): HttpHeaders {
        return headers.set(CACHE_IT_HTTP_HEADER_KEY, 'false');
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // Requests that are not GET or OPTIONS should not be cached.
        // It doesn't make sense to cache POST, PUT, DELETE, etc. since they are "mutating" requests.
        if (request.method !== 'GET' && request.method !== 'OPTIONS') {
            return next.handle(request);
        }

        if (!getHeaderParamAsBool(CACHE_IT_HTTP_HEADER_KEY, request.headers)) {
            return next.handle(request);
        }

        const keyified: string = keyify(request);
        const cachedResponse = this.cache.get(keyified);

        if (cachedResponse !== undefined) {
            logger.debug(`[CachingHttpInterceptor] will return cached response for:\n${keyified}`);
            return of(cachedResponse);
        }

        return this.#sendRequest(request, next, this.cache);
    }

    /**
     * Get server response observable by sending request to `next()`.
     * Will add the response to the cache on the way out.
     */
    #sendRequest(
        request: HttpRequest<unknown>,
        next: HttpHandler,
        cache: CacheService<HttpResponse<unknown>>,
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    const keyified: string = keyify(request);
                    cache.set(keyified, event);
                    logger.debug(`[CachingHttpInterceptor] cached response for:\n${keyified}`);
                }
            }),
        );
    }
}
