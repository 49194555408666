import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SettingsService } from '@idr/ui/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { addUserCentrics } from './add-usercentrics';
import { TrackingConsent } from './tracking-consent.model';

@Injectable({ providedIn: 'root' })
export class ConsentTrackingService {
    readonly #logPrefix = '[ConsentTrackingService]';
    readonly #consent$ = new ReplaySubject<TrackingConsent>(1);

    consent$: Observable<TrackingConsent>;

    constructor(
        @Inject(DOCUMENT) readonly document: Document,
        private readonly settings: SettingsService,
    ) {
        this.#initUserCentrics();
        this.consent$ = this.#consent$.pipe(shareReplay(1), takeUntilDestroyed());
    }

    #initUserCentrics() {
        this.#attatchWindowEventListeners(this.document.defaultView);
        addUserCentrics(this.document);
    }

    #attatchWindowEventListeners(windowProxy: WindowProxy & typeof globalThis) {
        windowProxy.addEventListener('UC_UI_INITIALIZED', () => {
            this.#consent$.next(this.#getConsent());
        });

        windowProxy.addEventListener(
            'UC_UI_VIEW_CHANGED',
            (event: Event & { readonly detail?: { readonly view: 'NONE' | string } }) => {
                // we ignore all UI events until the cookie "banner" was submitted
                if (event.detail && event.detail.view !== 'NONE') {
                    return;
                }
                this.#consent$.next(this.#getConsent());
            },
        );
    }

    #getConsent(): TrackingConsent {
        const consentData = this.document.defaultView['UC_UI'].getServicesBaseInfo();
        let statisticsEnabled = false;
        let marketingEnabled = false;
        for (const service of consentData) {
            switch (service.id) {
                case this.settings.userCentrics?.statisticsId:
                    statisticsEnabled = service.consent.status;
                    break;
                case this.settings.userCentrics?.marketingId:
                    marketingEnabled = service.consent.status;
                    break;
            }
        }
        return { marketingEnabled, statisticsEnabled };
    }
}
