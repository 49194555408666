import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiName,
    ContentHierarchyDTO,
    CrsExceptionDTO,
    CrsHitlistDTO,
    CrsSearchParams,
    CrsSortBy,
    NodeDTO,
    Product,
} from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { CrsBaseService, CrsExceptionTypes } from '@idr/ui/crs-facade';
import { ActiveProduct, ErrorId, MessageService } from '@idr/ui/shared';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { SearchHitPreviewStateService } from './search-hit-preview-state.service';

/**
 * The category that contains all documents from all categories.
 */
const DEFAULT_ALL_CATEGORIES_CATEGORY = { path: '', title: 'Alle Inhalte' };

@Injectable({
    providedIn: 'root',
})
export class CrsSearchService extends CrsBaseService {
    readonly #logPrefix = '[CrsSearchService]';

    constructor(
        private readonly activeProduct: ActiveProduct,
        private readonly searchHitPreviewState: SearchHitPreviewStateService,
        private readonly messageService: MessageService,
        httpClient: HttpClient,
    ) {
        super(messageService, httpClient);
    }

    /**
     * @see https://gitlab.haufedev.systems/aurora/crs/aurora.crs.Products.ContentretrievalREST/-/blob/master/src/Products/ContentretrievalREST/browser/v2/doc/similarSearchTerms.md
     * @see "SearchSpecification" in https://gitlab.haufedev.systems/aurora/backend/aurora.core/-/blob/master/aua-dl-impl/src/main/java/de/haufe/aurora/domain/hitlister/ejb/CRSHitlistDAOServiceBean.java
     */
    getCorrectedQuery$(query: string): Observable<CrsHitlistDTO | undefined> {
        const product: Product = this.activeProduct.product;
        const params: CrsSearchParams = CrsSearchParams.searchParamsFor(product.id, query);
        return this.makeCrsGetRequest<CrsHitlistDTO | CrsExceptionDTO>(params.asCorrectionUri).pipe(
            tap(() => logger.debug(this.#logPrefix, `getSearchCorrection$(${query})`)),
            map(value => value as CrsHitlistDTO), // otherwise TypeScript complains. It's save to do because we filter out CrsExceptionDTO
            catchError((err: Error) => {
                logger.error(
                    this.#logPrefix,
                    `getCorrectedQuery$(${JSON.stringify(params)}); got error from backend`,
                    err,
                );
                return of(undefined);
            }),
        );
    }

    /**
     * @see https://gitlab.haufedev.systems/aurora/crs/aurora.crs.Products.ContentretrievalREST/-/blob/master/src/Products/ContentretrievalREST/browser/v2/doc/hitlists.md
     */
    getSearchCategories$(query: string, hitPath?: string): Observable<CrsHitlistDTO | undefined> {
        const product: Product = this.activeProduct.product;
        const params: CrsSearchParams = CrsSearchParams.searchCategoriesFor(product.id, query, hitPath);
        return this.makeCrsGetRequest<CrsHitlistDTO | CrsExceptionDTO>(params.asSearchUri).pipe(
            tap(() => logger.debug(this.#logPrefix, `getSearchCategories$(${query}, ${hitPath})`)),
            map(value => value as CrsHitlistDTO), // otherwise TypeScript complains. It's safe to do because we filter out CrsExceptionDTO
            catchError((err: Error) => {
                logger.error(
                    this.#logPrefix,
                    `getSearchCategories$(${JSON.stringify(params)}); got error from backend`,
                    err,
                );
                this.messageService.postError(ErrorId.CRS_ERROR, {
                    message: 'Die Suche konnte nicht durchgeführt werden.',
                    type: 'Suche Fehler',
                });
                return of(undefined);
            }),
        );
    }

    getSearchableCategories$(): Observable<NodeDTO[]> {
        const product: Product = this.activeProduct.product;
        const url = `${ApiName.CRS}/auroraContentHierarchy/product/${product.id}/path/${product.id}/depth/1/visible_only/0/searchable_only/1`;
        return this.makeCrsGetRequest<ContentHierarchyDTO | CrsExceptionDTO>(url).pipe(
            map((contentHierarchyDTO: ContentHierarchyDTO) => {
                // NAUA-5887: filter out invalid elements.
                return contentHierarchyDTO.node[0].subnodes.node.filter((node: NodeDTO) => {
                    if (!node.path) {
                        logger.warn(this.#logPrefix, `getSearchableCategories$(); filtered out invalid node`, node);
                    }
                    return !!node.path;
                });
            }),
            map((nodes: NodeDTO[]) => {
                return [DEFAULT_ALL_CATEGORIES_CATEGORY, ...nodes];
            }),
            catchError((err: Error) => {
                logger.error(this.#logPrefix, `getSearchableCategories$(); got error from backend`, err);
                return of([]);
            }),
        );
    }

    /**
     * @param withoutHitEnv if true, the hit environment will not be included in the search result -> faster responses. (default: false).
     * @see https://gitlab.haufedev.systems/aurora/crs/aurora.crs.Products.ContentretrievalREST/-/blob/master/src/Products/ContentretrievalREST/browser/v2/doc/hitlists.md
     * @see "SearchSpecification" in https://gitlab.haufedev.systems/aurora/backend/aurora.core/-/blob/master/aua-dl-impl/src/main/java/de/haufe/aurora/domain/hitlister/ejb/CRSHitlistDAOServiceBean.java
     */
    getSearch$(
        query: string,
        hitPath?: string,
        page = 1,
        sorting?: CrsSortBy,
        withoutHitEnv = false,
    ): Observable<CrsHitlistDTO | CrsExceptionDTO | undefined> {
        const showHitEnvironment$ = withoutHitEnv
            ? of(false)
            : this.searchHitPreviewState.showHitEnvironment$.pipe(take(1));

        return showHitEnvironment$.pipe(
            map(showHitEnvironment =>
                CrsSearchParams.searchParamsFor(
                    this.activeProduct.product.id,
                    query,
                    hitPath,
                    page,
                    sorting,
                    showHitEnvironment,
                ),
            ),
            switchMap((params: CrsSearchParams) =>
                this.makeCrsGetRequest<CrsHitlistDTO | CrsExceptionDTO>(params.asSearchUri, {
                    // we ignore the quick jump failure exception because we want to show the no-results page.
                    ignoreExceptionType: CrsExceptionTypes.QuickjumpFailed,
                }).pipe(
                    tap(() => logger.debug(this.#logPrefix, `getSearch$(${query}, ${hitPath}, ${page})`)),
                    catchError((err: Error) => {
                        logger.error(
                            this.#logPrefix,
                            `getSearch$(${JSON.stringify(params)}); got error from backend`,
                            err,
                        );
                        this.messageService.postError(ErrorId.CRS_ERROR, {
                            message: 'Die Suche konnte nicht durchgeführt werden.',
                            type: 'Suche Fehler',
                        });
                        return of(undefined);
                    }),
                ),
            ),
        );
    }
}
