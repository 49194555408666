import { OverlayModule } from '@angular/cdk/overlay';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

export const MODULES = [
    OverlayModule,
    GoogleTagManagerModule.forRoot({
        id: 'GTM-MG5MXWMS',
        gtm_resource_path: 'https://wwi.new.products.haufe.de/wwi.js',
    }),
];
