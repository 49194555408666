import { Injectable } from '@angular/core';
import { BauplanId, Breadcrumb, DocumentId } from '@idr/shared/model';
import { ActiveProduct } from '@idr/ui/shared';
import { Observable } from 'rxjs';
import { CrsBauplanService } from '../crs/service/crs-bauplan.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
    constructor(
        private readonly crsService: CrsBauplanService,
        private readonly activeProduct: ActiveProduct,
    ) {}

    public getBreadcrumbForBauplanId$(
        bauplanId: BauplanId,
        productId = this.activeProduct.id,
    ): Observable<Breadcrumb[]> {
        return this.crsService.getBreadcrumbForBauplanId$(bauplanId, productId);
    }

    public getBreadcrumbForDocumentId$(
        documentId: DocumentId,
        productId = this.activeProduct.id,
    ): Observable<Breadcrumb[]> {
        return this.crsService.getBreadcrumbForDocumentId$(documentId, productId);
    }
}
