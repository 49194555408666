<span>{{ message() }}</span>
<button
    [hgGtmId]="[gtmArea(), 'confirm', 'no']"
    (click)="confirmed.emit(false)">
    Nein
</button>
<button
    [hgGtmId]="[gtmArea(), 'confirm', 'yes']"
    hgSetFocus
    (click)="confirmed.emit(true)">
    Ja
</button>
