/**
 * Valid queries (in PI25844 | HSO Excellence):
 *  - "estg 3" ("Direktsprung", <§> + <law-abbreviation>)
 *  - "estg 3 rz 3" ("Direktsprung", <§> + <law-abbreviation> + "rz" (for "Randziffer") + <rz-paragraph>)
 *  - "VI R 13/16" ("Aktenzeichen einer Entscheidung")
 *  - "11.11.2011" ("Datum")
 *  - "BStBl II 2007, 459" ("Fundstelle")
 */
export function getEncodedQueryForCrs(query: string, replaceSlash: boolean) {
    // document-query.ts:
    // CRS does encode 2 times... we need to do that as well so we don't get into trouble with special characters, like '/', '%', ...
    // crs-search-params.ts:
    // this is needed since CRS will double decode the requested API url...
    // => if you have a "/" inside your query (e.g. "VI R 13/16") without double uri-encoding it,
    //    you'll break the uri deconstructing of CRS ... => CRS will then run into a parser exception
    if (replaceSlash) {
        // whole document is highlighted if we do not replace "/" in these cases (not for VI R 13/16)
        // https://regex101.com/r/BhHizW/2
        const slashNoTrailingNumbersRegex = /(\/)([^0-9/]+)/g;
        const queryWithoutSlashNoTrailingNumbers = query.replace(slashNoTrailingNumbersRegex, '$2');
        //  https://gitlab.haufedev.systems/aurora/backend/aurora.core/-/blob/master/aua-al-impl/src/main/java/de/haufe/aurora/dataaccess/crs/provider/CRSURIBuilder.java
        return encodeURIComponent(encodeURIComponent(queryWithoutSlashNoTrailingNumbers));
    }
    // for in document search query shown in search-field
    return encodeURIComponent(encodeURIComponent(query));
}
