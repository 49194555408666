<a
    [href]="model().href"
    [class.activated]="activated()"
    (keydown.enter)="activateLink($event)"
    (click)="activateLink($event)"
    [hgGtmId]="[gtmArea(), 'open', 'document']">
    @if (!!model().provider) {
        <hg-provider-icon [provider]="model().provider!" />
    }
    <div>
        <p
            class="title"
            [innerHTML]="model().title"></p>
        <p class="type">
            @if (model().type !== "Unbekannt") {
                {{ model().type }}
            }
            <ng-content select="[slot=type]" />
        </p>
    </div>
    <ng-content select="[slot=content]" />
</a>
