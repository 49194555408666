import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule],
    selector: 'hg-full-view-button',
    standalone: true,
    styleUrls: ['./full-view-button.component.scss'],
    template: ` <button id="open-document-full-view">
        <span>Vollansicht öffnen</span>
        <mat-icon svgIcon="fullview" />
    </button>`,
})
export class FullViewButtonComponent {}
