import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ProductId, SearchLink } from '@idr/shared/model';
import { GtmIdDirective } from '@idr/ui/tracking';
import { RxPush } from '@rx-angular/template/push';
import { ProductNamePipe } from '../../pipes';

type SearchRouterLink = [string, ProductId, 'search', string];

/**
 * This component was inspired by {@link DocumentLinkComponent}.
 *
 * It has less functionality since YAGNI :) I only needed a styled link that'll open the search.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, RxPush, ProductNamePipe, GtmIdDirective],
    selector: 'hg-search-link',
    standalone: true,
    styleUrls: ['./search-link.component.scss'],
    templateUrl: './search-link.component.html',
})
export class SearchLinkComponent {
    link = input.required<SearchLink>();

    // We need the activate event emitter to be able to close the history dialog.
    // Otherwise, when we click on the search term it doesn't close the history dialog.
    // For some reason attaching click listener (i.e. <hg-search-link (click)="close()">) in the history.component doesn't work.
    activate = output<Event>();

    routerLink = computed<SearchRouterLink | [...SearchRouterLink, { readonly path: string }]>(() => {
        const link = this.link();
        const routerLink: SearchRouterLink = ['/', link.productId, 'search', link.query];

        // We push the inCluster value conditionally to avoid having path=undefined in the url
        if (link.inCluster) {
            return [...routerLink, { path: link.inCluster }];
        }

        return routerLink;
    });
}
