import { ChangeDetectionStrategy, Component, computed, Inject, input, Signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { UploadedFile } from '@idr/model/config';
import { logger } from '@idr/shared/utils';
import { MY_DESK_CONFIG_IMAGE_URL } from '@idr/ui/shared';
import { ConfigModel } from '../../model/config-model';
import { PartnerLogoProductOptionsState } from '../../model/partner-logo-product-options-state';
import { ACTIVATED_PRODUCT_GROUP, ActivatedProductGroupSignal } from '../../routing/activated-product-group';
import { Change, ProductSelectComponent } from '../product-select/product-select.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIcon, ProductSelectComponent],
    selector: 'mc-uploaded-partner-logo',
    standalone: true,
    styleUrl: './uploaded-partner-logo.component.scss',
    template: `
        <mc-product-select
            [options]="partnerLogoProductOptions.values()"
            (change)="updateLogo(logo().id, $event)"
            [selected]="logo().products" />
        <section>
            <p>Logodatei</p>
            <div>
                <mat-icon svgIcon="thumbnail" />
                <p>{{ logo().filename }} - {{ sizeInKb() }} kb</p>
                <button (click)="delete(this.logo())">
                    <mat-icon svgIcon="close" />
                </button>
            </div>
            <img [src]="logoUrl()" />
        </section>
    `,
})
export class UploadedPartnerLogoComponent {
    readonly #logPrefix = '[UploadedPartnerLogoComponent]';

    readonly logo = input.required<UploadedFile>();

    readonly logoUrl: Signal<string>;

    readonly sizeInKb = computed(() => Math.floor(this.logo().size / 1024));

    constructor(
        @Inject(ACTIVATED_PRODUCT_GROUP) private readonly active: ActivatedProductGroupSignal,
        @Inject(MY_DESK_CONFIG_IMAGE_URL) imageDomain: string,
        public readonly model: ConfigModel,
        public readonly partnerLogoProductOptions: PartnerLogoProductOptionsState,
    ) {
        this.logoUrl = computed(() => `${imageDomain}${this.logo().url}`);
    }

    async updateLogo(id: string, change: Change): Promise<void> {
        logger.debug(this.#logPrefix, 'updateLogo ->', id, change);
        this.partnerLogoProductOptions.set(change.product.id, change.product.selected);

        // We have an active group AND we also have partnerLogo related configuration, so we can safely cast
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const active = this.active()!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const partnerLogos = active.configuration.partnerLogos!.map(logo => ({
            ...logo,
            ...(logo.id === id ? { products: change.selectedProducts } : {}),
        }));
        await this.model.patch(active.groupId, { partnerLogos });
    }

    async delete(file: UploadedFile) {
        logger.debug(this.#logPrefix, 'delete ->', file);
        file.products?.forEach(product => this.partnerLogoProductOptions.set(product, false));
        await this.model.files.delete(file.id, 'partnerLogo');
    }
}
