import { deepmerge } from 'deepmerge-ts';
import { TourPage } from '../product-tour';

/**
 * This class represents the user settings that are stored in the user settings API.
 * The settings stored in the user settings API contain more than just the settings for reloaded.
 * The reloaded specific settings are stored in the `reloaded` property.
 * Those settings are represented by the {@link ReloadedUserSettings} class.
 */
export class UserSettings {
    private constructor(public readonly values: UserSettingsDTO) {}

    public static fromDTO(dto: UserSettingsDTO) {
        return new UserSettings(dto);
    }

    public updateValues(updatedSettings: Partial<ReloadedUserSettingsDTO>): UserSettings {
        return new UserSettings(deepmerge(this.values, { reloaded: updatedSettings }));
    }
}

// The structure is pretty open -> You can basically save whatever key-value pair you want there
// Here are the ones that we found when we used the auroratest01 user.
export interface UserSettingsDTO {
    // There are more settings coming from iDesk2, but we don't need them.
    // We decided to keep all the relevant to this application settings in reloaded to avoid conflicts.
    readonly reloaded?: ReloadedUserSettingsDTO;

    readonly [key: string]: any; // for other iDesk2 related settings
}

type ProductTour = { readonly [key in TourPage]: boolean };

export interface ReloadedUserSettingsDTO {
    readonly document?: {
        readonly fontSize?: number;
        readonly documentHeaderOpen?: boolean;
        readonly tableOfContentsOpen?: boolean;
        readonly relatedDocumentsOpen?: boolean;
    };
    readonly search?: {
        readonly showSearchHitPreview?: boolean;
        /**
         * We show the search feedback component every 24 hours.
         * This setting holds the next timestamp when the search feedback component should be shown.
         */
        readonly showSearchFeedbackAgainAt?: number;
    };
    readonly onboarding?: {
        readonly thankYouPopupShown?: boolean;
        readonly surveyPopupShown?: boolean;
        readonly surveyPopupScheduledFor?: number;
    };
    readonly productTourFinished?: ProductTour;
    readonly news?: {
        readonly readNews?: string[]; // FIXME: This is not a string array
    };
    readonly history?: {
        readonly documents?: string[]; // FIXME: This is not a string array
        readonly search?: string[]; // FIXME: This is not a string array
    };
    readonly visitCount?: number;
    readonly firstVisitDate?: number;
    readonly nps?: {
        readonly nextSurveyAfterDate?: number;
    };
}
