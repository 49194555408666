import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { GroupId } from '@idr/model/config';
import { logger } from '@idr/shared/utils';
import { ErrorMessageComponent } from '../components/error-message/error-message.component';
import { SuccessMessageComponent } from '../components/success-message/success-message.component';
import { ConfigModel } from '../model/config-model';
import { ConfigNavigator } from '../routing/config-navigator';

const GENERIC_ERROR_MESSAGE = 'Das hat nicht funktioniert. Versuche es noch einmal.';

const TOAST_CONFIG: MatSnackBarConfig<string> = {
    duration: 4000,
    horizontalPosition: 'center',
    panelClass: 'config-toast',
    verticalPosition: 'bottom',
};

@Injectable({ providedIn: 'root' })
export class ConfigActions {
    readonly #logPrefix = '[ConfigActions]';

    constructor(
        private readonly model: ConfigModel,
        private readonly navigator: ConfigNavigator,
        private readonly snackBar: MatSnackBar,
    ) {}

    #showError(): void {
        this.snackBar.openFromComponent(ErrorMessageComponent, {
            ...TOAST_CONFIG,
            data: GENERIC_ERROR_MESSAGE,
        });
    }

    async #execute(
        groupId: GroupId,
        operation: keyof Pick<ConfigModel, 'delete' | 'publish' | 'reset'>,
        successMessage: string,
        navigateToRoot = true,
    ): Promise<void> {
        if (!groupId) {
            logger.warn(this.#logPrefix, operation, '->', groupId, 'Falsy input; Will ignore call.');
            return;
        }

        logger.debug(this.#logPrefix, operation, '->', groupId);
        const success = await this.model[operation](groupId);

        if (success) {
            this.snackBar.openFromComponent(SuccessMessageComponent, {
                ...TOAST_CONFIG,
                data: successMessage,
            });

            if (!navigateToRoot) {
                return;
            }

            return this.navigator.goToRootConfig();
        }

        this.#showError();
    }

    async delete(groupId: GroupId): Promise<void> {
        await this.#execute(groupId, 'delete', 'Entwurf gelöscht');
    }

    async publish(groupId: GroupId): Promise<void> {
        await this.#execute(groupId, 'publish', 'Änderungen sind jetzt live');
    }

    async reset(groupId: GroupId): Promise<void> {
        await this.#execute(groupId, 'reset', 'Änderungen zurückgesetzt', false);
    }
}
