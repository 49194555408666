import { ChangeDetectionStrategy, Component, effect, Inject, Signal, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { BRAND_IDS, BrandId, getLabelFromBrand } from '@idr/shared/model';
import { getBrandIcon } from '@idr/ui/shared';
import { rxEffects } from '@rx-angular/state/effects';
import { ConfigModel } from '../../model/config-model';
import { ACTIVATED_PRODUCT_GROUP, ActivatedProductGroup } from '../../routing/activated-product-group';
import { EditPartnerLogoComponent } from '../edit-partner-logo/edit-partner-logo.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, MatIcon, MatRadioModule, EditPartnerLogoComponent],
    selector: 'mc-edit-logo',
    standalone: true,
    styleUrl: './edit-logo.component.scss',
    templateUrl: './edit-logo.component.html',
})
export class EditLogoComponent {
    readonly brandCtrl: FormControl<BrandId> = new FormControl();

    readonly brandIds: BrandId[] = BRAND_IDS;

    readonly getLabelFromBrand = getLabelFromBrand;

    readonly selectedBrandImage = signal<string>(`companyLogo_${this.brandIds[0]}`);

    constructor(@Inject(ACTIVATED_PRODUCT_GROUP) active: Signal<ActivatedProductGroup>, model: ConfigModel) {
        effect(
            () => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const brandId = active().configuration.brandId!;
                this.brandCtrl.setValue(brandId, { emitEvent: false });
                this.selectedBrandImage.set(getBrandIcon(brandId));
            },
            { allowSignalWrites: true },
        );

        rxEffects(({ register }) =>
            register(this.brandCtrl.valueChanges, brandId => {
                this.selectedBrandImage.set(getBrandIcon(brandId));
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return model.patch(active()!.groupId, { brandId });
            }),
        );
    }
}
