import { DocumentId } from '@idr/shared/model';
import { hash, List as ImmutableList, ValueObject } from 'immutable';

/**
 * DocumentSearchResult is used for in-doc-search.
 * When a query is entered DocumentSearchResult is loaded.
 * It contains a list of all ids representing the chapters that contain a search hit.
 *
 * DocumentSearchResult can have following "states"
 *
 * (1) empty => no search results found: result.isEmpty === true & result.isLoading === false
 * (2) with search results: result.isEmpty === false & result.isLoading === false,
 * (3) intermediate state: server request is still in progress: result.isEmpty === true & result.isLoading === true
 */
export class DocumentSearchResult implements ValueObject {
    public readonly noResultsFound: boolean;
    public readonly resultsFound: boolean;

    private readonly _hashCode: number;

    constructor(
        /**
         * BE CAREFUL with this.
         * In a flat document this list IS NOT consistent with our rendering.
         * (if there is any result) You'll only see the id of the whole document in this list.
         * Our faked chapter ids aren't mentioned here at all.
         *
         * This case is specifically handled in `DocumentSearchModel`.
         */
        public readonly docIds: ImmutableList<DocumentId>,
        public readonly query: string,
        public readonly message?: string,
    ) {
        this._hashCode = hash({ id: docIds.get(0), query });
        this.noResultsFound = docIds.isEmpty();
        this.resultsFound = !this.noResultsFound;
    }

    public get firstDocId(): DocumentId | undefined {
        return this.docIds.first();
    }

    public isFirstDocId(docId: string): boolean {
        return this.findIndexForDocId(docId) === 0;
    }

    public isLastDocId(docId: string): boolean {
        return this.findIndexForDocId(docId) === this.docIds.size - 1;
    }

    public equals(other: DocumentSearchResult): boolean {
        return (
            this.firstDocId === other?.firstDocId &&
            this.docIds.equals(other?.docIds) &&
            this.message === other?.message &&
            this.query === other?.query
        );
    }

    public hashCode(): number {
        return this._hashCode;
    }

    private findIndexForDocId(docId: DocumentId): number {
        return this.docIds.findIndex(chapter => chapter === docId);
    }
}
