import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { logger } from '@idr/shared/utils';
import { DragAndDropFilesDirective } from '../../directives';

const kbToMb = (kb: number): number => kb / (1024 * 1024);

/**
 * This component has a file-input & a draggable area where the user can drag files into.
 *
 * Those files are then emitted by {@link picked}, so you can process them (e.g. send them to backend).
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.error]': 'showError()', // eslint-disable-line @typescript-eslint/naming-convention
    },
    imports: [MatIcon, DragAndDropFilesDirective],
    selector: 'hg-upload-file',
    standalone: true,
    styleUrls: ['./upload-file.component.scss'],
    templateUrl: './upload-file.component.html',
})
export class UploadFileComponent {
    readonly #logPrefix = '[UploadFileComponent]';

    readonly #error = signal<string | undefined>(undefined);

    /**
     * The file types you want to target/accept with this upload.
     * Set it is comma separated list of file extensions as one string.
     *
     * E.g. '.jpg,.png,.svg,.webp'
     *
     * @see HTMLInputElement.accept
     */
    readonly accept = input.required<string>();

    /**
     * Use this if you got a backend error and want to show it to user.
     * Pick an understandable user message as this gets shown to the user.
     */
    readonly error = input<string | undefined>(undefined);

    /**
     * The current error message shown to user.
     * It is a combination of set state via input {@link error} or the internal error logic (e.g. wrong file format, too big).
     */
    readonly errorMessage = computed(() => this.error() || this.#error());

    /**
     * A regexp based on {@link accept}.
     * Used for internal file validation.
     */
    readonly extensionRegExp = computed(() => {
        let fileEndings = '';
        for (const ext of this.accept().split(',')) {
            fileEndings += `(\\.${ext.slice(1)})`;
        }
        return new RegExp(`^.*[${fileEndings}]$`);
    });

    /**
     * A visual representation of {@link accept} being shown as help to user.
     */
    readonly formats = computed(() => this.accept().replace('.', '').replaceAll(',.', ', '));

    /**
     * The label shown on the "upload" button.
     */
    readonly label = input<string>('Hochladen');

    /**
     * The max file size in MB allowed to be uploaded.
     */
    readonly maxSize = input.required<number>();

    /**
     * Emits the file(s) that got "uploaded" by user.
     *
     * You need to process this of course & sent it to backend for "real" upload.
     */
    readonly picked = output<File>();

    /**
     * `true` when this component is in error state; `false` otherwise
     */
    readonly showError = computed(() => this.errorMessage() !== undefined);

    onFilePicked(files: FileList) {
        logger.debug(this.#logPrefix, 'onFilePicked ->', files);
        const picked = files.item(0);
        if (!picked) {
            return;
        }

        if (!this.extensionRegExp().test(picked.name)) {
            this.#error.set(`Dateityp nicht unterstützt. Erlaubte Formate sind: ${this.formats()}.`);
            return;
        }

        const fileSizeInMb = kbToMb(picked.size);
        if (fileSizeInMb > this.maxSize()) {
            this.#error.set(`Datei ist zu groß. Die maximale erlaubte Dateigröße ist ${this.maxSize()} MB.`);
            return;
        }

        this.picked.emit(picked);
    }
}
