<h1>Welche Produktfamilie möchtest du konfigurieren?</h1>

<div>
    @if (model.syncing()) {
        <hg-loading-wheel />
    } @else {
        <table
            mat-table
            [dataSource]="dataSource"
            aria-describedby="product-group-list"
            class="full-width-table">
            <ng-container matColumnDef="name">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Produktfamilie
                </th>
                <td
                    mat-cell
                    *matCellDef="let element">
                    {{ element.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="isDraft">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Offener Entwurf
                </th>
                <td
                    mat-cell
                    *matCellDef="let element">
                    @if (element.isDraft) {
                        Ja
                        <mat-icon svgIcon="construction" />
                    } @else {
                        Nein
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="modifiedOn">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Zuletzt bearbeitet
                </th>
                <td
                    mat-cell
                    *matCellDef="let element">
                    @if (element.modifiedOn) {
                        {{ element.modifiedOn | date: "medium" }}
                    } @else {
                        -
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="modifiedBy">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Von
                </th>
                <td
                    mat-cell
                    *matCellDef="let element">
                    {{ element.modifiedBy ?? "-" }}
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="columns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: columns"
                [class.not-configured]="row.notConfigured"
                (click)="navigator.goToGroup(row)"
                [routerLink]="[row.id, 'brand-logo']"></tr>

            <tr *matNoDataRow>
                <td
                    colspan="4"
                    class="error">
                    Keine Daten verfügbar
                </td>
            </tr>
        </table>
    }
</div>
