import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { Brand, ProductLogo } from '@idr/shared/model';
import { ImageTransformPipe } from '../../pipes';

/**
 * @deprecated Shall be renamed to `HaufeLogoComponent` as soon as we got rid of the old legacy logo related product configuration
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ImageTransformPipe],
    selector: 'hg-company-logo',
    standalone: true,
    styleUrls: ['./company-logo.component.scss'],
    templateUrl: './company-logo.component.html',
})
export class CompanyLogoComponent {
    @HostBinding('attr.role') readonly role = 'presentation';

    brand = input.required<Brand>();

    /**
     * @deprecated this should be a component that just always renders the Haufe logo (used in our error pages)
     *             It is still there because of support for old/legacy iDesk configuration
     *
     * @see AuthErrorComponent
     * @see ErrorComponent
     * @see LicenseErrorComponent
     */
    logo = input<ProductLogo>();
}
