import { FileType, GroupId, ProductGroupConfiguration } from '@idr/model/config';
import { ProductId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ConfigApiService } from '../services/config-api.service';

/**
 * It is part of {@link ConfigModel} and extends it with config file related api & state management.
 *
 * @see delete
 * @see upload
 */
export class FileRelatedConfig {
    readonly #logPrefix = '[FileRelatedConfig]';

    constructor(
        private readonly api: ConfigApiService,
        private readonly hooks: {
            /**
             * Called after upload is executed.
             * @param groupId the related group id for which the file got uploaded
             * @param patched the state after the upload, we got this from backend
             */
            afterUpload: (groupId: GroupId, patched: ProductGroupConfiguration) => void | Promise<void>;
            /**
             * Called before upload is executed.
             * @param groupId the related group id for which the file upload got triggered
             */
            beforeUpload: (groupId: GroupId) => void | Promise<void>;
            /**
             * Called after delete of a file took place in backend.
             * Now, you should update any dependent state.
             * @param id the id of the file that got deleted
             */
            afterDelete: (id: string) => void | Promise<void>;
            /**
             * Called before deleting a file
             * @param id the id of the file being deleted
             */
            beforeDelete: (id: string) => void | Promise<void>;
        },
    ) {}

    /**
     * Deletes a file under given {@link id}.
     * The {@link type} is important as well since we have different endpoints for different files.
     *
     * @param id
     * @param type
     *
     * @return `true` when successful, `false` in case any error happened
     */
    async delete(id: string, type: FileType): Promise<boolean> {
        logger.debug(this.#logPrefix, 'delete ->', id);
        try {
            await this.hooks.beforeDelete(id);
            await this.api.files.delete(id, type);
            await this.hooks.afterDelete(id);
            return true;
        } catch (err) {
            logger.error(this.#logPrefix, 'delete ->', id, err);
            logger.warn(this.#logPrefix, "delete -> Couldn't delete file successfully");
        }
        return false;
    }

    /**
     * Uploads given {@link file} for given {@link groupId}.
     * The {@link type} is important as well since we have different endpoints for different files.
     *
     * E.g. `partnerLogo` has different size/dimension validation compared to e.g. background image for login page.
     *
     * Optionally, {@link products} can be set as well, to target specific products in given {@link groupId}.
     *
     * @param groupId
     * @param file
     * @param type
     * @param products
     *
     * @return `true` when successful, `false` in case any error happened
     */
    async upload(groupId: GroupId, file: File, type: FileType, products?: ProductId[]): Promise<boolean> {
        logger.debug(this.#logPrefix, 'upload ->', groupId, file, products);
        try {
            await this.hooks.beforeUpload(groupId);
            const patched = await this.api.files.add(groupId, file, type, products);
            await this.hooks.afterUpload(groupId, patched);
            return true;
        } catch (err) {
            logger.error(this.#logPrefix, 'upload ->', groupId, err);
            logger.warn(this.#logPrefix, "upload -> Couldn't upload file successfully");
        }

        return false;
    }
}
