import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ACTIVATED_PRODUCT_GROUP, ActivatedProductGroupSignal } from '../../routing/activated-product-group';

//FIXME: add unit tests

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [],
    selector: 'mc-edit-search-filter',
    standalone: true,
    templateUrl: './edit-search-filter.component.html',
})
export class EditSearchFilterComponent {
    constructor(@Inject(ACTIVATED_PRODUCT_GROUP) public readonly active: ActivatedProductGroupSignal) {}
}
