import { ContentHubDocumentDTO, EntryDTO, NewsMetaDTO, OdinDocumentClassification } from '@idr/shared/model';
import { arrayFromVar } from '@idr/shared/utils';
import { Realm } from '@idr/ui/shared';
import { ContentHubAuthor } from './content-hub-author';
import { ContentHubDocument } from './content-hub-document';

export class NewsDocument extends ContentHubDocument {
    /**
     * The links to the authors of the news document in the format "contenthub://portals/content/<id-number>"
     */
    readonly newsAuthorsLinks?: string[];
    /**
     * The authors of the news document as retrieved from contenthub using {@link newsAuthorsLinks}
     */
    readonly newsAuthors?: ContentHubAuthor[];
    readonly image?: string;
    readonly trackingPath?: string;
    readonly overline?: string;

    private constructor(
        documentDTO: ContentHubDocumentDTO<NewsMetaDTO>,
        realm: Realm,
        entryDTO?: EntryDTO<ContentHubDocumentDTO<NewsMetaDTO>>,
    ) {
        const meta: NewsMetaDTO = documentDTO['ch:haufe-document']['ch:meta'];
        const classification: OdinDocumentClassification = {
            type: 'NEWS',
            description: 'News',
            subType: 'News',
        };
        super(documentDTO, entryDTO, classification, realm, {
            preview: documentDTO['ch:preview']?.content,
        });

        this.newsAuthorsLinks = arrayFromVar(meta['pr:author'])?.map(author => author.content as string);
        this.image = meta['pr:image']?.content;
        this.trackingPath = meta['pr:trackingPath']?.content;
        this.overline = meta['pr:overline']?.content;
    }

    static fromEntryDTO(entryDTO: EntryDTO<ContentHubDocumentDTO<NewsMetaDTO>>, realm: Realm) {
        return new NewsDocument(entryDTO.content, realm, entryDTO);
    }

    static fromDocumentDTO(documentDTO: ContentHubDocumentDTO<NewsMetaDTO>, realm: Realm): NewsDocument {
        return new NewsDocument(documentDTO, realm);
    }
}
