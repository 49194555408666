import { SettingsDTO } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { Environment } from '@idr/ui/shared';

const logPrefix = '[get-settings.ts]';

export const getSettings = async (environment: Environment): Promise<SettingsDTO> => {
    const settingsFile = `${environment.local ? '/assets' : ''}/settings.json`;
    try {
        return (await fetch(settingsFile)).json();
    } catch (error) {
        logger.error(logPrefix, `couldn't load '${settingsFile}' (${error.status}) ${error.name} ${error.message}`);
        if (environment.local) {
            logger.error(logPrefix, 'Do you have `/apps/ui/src/assets/settings.json` configured properly?', error);
        } else if (!environment.production) {
            logger.error(logPrefix, 'Did you really run `ng serve --env=local`?', error);
        }
        throw error;
    }
};
