/**
 * Collection of our different errors. The user will see the error id in a dialog (when one of these errors occur).
 */
export const ErrorId = {
    /**
     * Means that the initial document request failed
     *  (requesting metadata and redirecting to document page was successful).
     * As a result the user see an empty document page.
     */
    DOC_REQUEST_FAILED: 'doc-req-error',
    /**
     * Means that no imprint is configured for the current selected product.
     * As a result the user see an empty document page.
     */
    NO_IMPRINT_CONFIGURED: 'no-imprint',
    /**
     * Means that routing failed. This can happen when configured resolvers fail to load expected data and therefor
     * throw an error. This can happen when search or document metadata is not available.
     *
     * As a result the user sees the last state of the app (before routing was initiated) or the error page if this
     * error occurred already on the app load (like reloading the search page when backend is down).
     */
    NAVIGATION_ERROR: 'nav-error',
    /**
     * self-explanatory... used for error handling in `CrsService`.
     */
    CRS_ERROR: 'crs',
    /**
     * self-explanatory... used for error handling in `OutputManagerService`.
     */
    OUTPUT_MANAGER_SERVICE_ERROR: 'output-manager',
    /**
     * Somehow somewhere a bad request got executed. Is the code correctly wired together?
     */
    BAD_REQUEST: 'bad-request',
};
