import { is, Stack } from 'immutable';
import { logger } from '@idr/shared/utils';
import { HasValues } from './has-values';

/**
 * A stack that will always put the latest added value in first position.
 *
 * @see add
 */
export class ReverseStackWithMaxSize<T> implements HasValues<T> {
    private constructor(
        /**
         * How many items are hold by this stack at maximum.
         *
         * Equal or greater than 1
         *
         * @default 1
         */
        public readonly maxSize: number,
        private readonly stack: Stack<T>,
    ) {}

    public static create<Type>(maxSize: number, values: Type[] = []): ReverseStackWithMaxSize<Type> {
        const overflowingElementsCount = values.length - maxSize;
        logger.debug('[ReverseStackWithMaxSize] create ->', maxSize, values);
        return new ReverseStackWithMaxSize<Type>(
            maxSize <= 0 ? 1 : maxSize,
            Stack.of(...values).skipLast(overflowingElementsCount > 0 ? overflowingElementsCount : 0),
        );
    }

    /**
     * Gives you the current size of this stack.
     */
    public get size(): number {
        return this.values.length;
    }

    /**
     * Gives you all current values (sorted by the time each value was added) in this stack.
     */
    public get values(): T[] {
        return this.stack.toArray();
    }

    /**
     * Will add given {@param value} in first position.
     *
     * If given {@param value} is already part of this stack it gets reordered to the first position, but it won't be added again.
     * That means this stack also works like a set where one and the same element can't be added twice.
     *
     * If due to adding given {@param value} this stack would break initially set {@see maxSize} this stack will remove the current last value.
     *
     * Equality is determined with the help of {@see is}.
     * That means if you use this stack with complex objects, you need to implement {@see import('immutable').ValueObject}.
     */
    public add(value: T): ReverseStackWithMaxSize<T> {
        let stack: Stack<T> = this.stack;

        if (stack.includes(value)) {
            stack = stack.filter(v => !is(v, value));
        }

        if (stack.size === this.maxSize) {
            stack = stack.butLast();
        }

        return new ReverseStackWithMaxSize<T>(this.maxSize, stack.unshift(value));
    }

    /**
     * Obviously clears this stack. You'll have an empty stack afterwards.
     */
    public clear(): ReverseStackWithMaxSize<T> {
        return new ReverseStackWithMaxSize<T>(this.maxSize, this.stack.clear());
    }
}
