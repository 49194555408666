/**
 * Will return an Array from a value. If given value is undefined it will return undefined and not [undefined]
 *
 * @param value
 * @return an Array that contains only the given value or the Array itself (if given value is already an array). It returns undefined if the given value is undefined
 */
export const arrayFromVar = <T>(value: T | T[] | undefined): T[] | undefined =>
    Array.isArray(value) ? value : value ? [value] : undefined;

/**
 * Returns the First Value of an Array or the value itself if a single value was passed
 *
 * @param value
 * @return the first value if the given value is an array, otherwise it returns the given value
 */
export const getFirstValueFrom = <T>(value: T | T[]): T => (Array.isArray(value) ? value[0] : value);
