import { Observable } from 'rxjs';
import { delayWhen } from 'rxjs/operators';

/**
 * Delays the emits of given source until $signal has emitted atleast once.
 * $singal best to be an hot observable
 * @param signal$
 */
export const queueUntil =
    <T>(signal$: Observable<unknown>) =>
    (source$: Observable<T>) => {
        return source$.pipe(delayWhen(() => signal$));
    };
