import { Pipe, PipeTransform } from '@angular/core';
import { ProductId } from '@idr/shared/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductService } from '../services';

@Pipe({ name: 'hgProductName', standalone: true })
export class ProductNamePipe implements PipeTransform {
    constructor(private readonly productService: ProductService) {}

    transform(productId: ProductId): Observable<string> {
        return this.productService.products$.pipe(
            map(products => products.find(product => product.id === productId)?.name),
            map(name => (name === undefined ? `Unbekanntes Produkt (${productId})` : name)),
        );
    }
}
