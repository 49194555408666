type AnyObject = Record<string, unknown>;

export const findKeyInObject = (obj: AnyObject, keyToFind: string): unknown => {
    let result: unknown = null;

    const recursiveSearch = (currentObj: AnyObject): void => {
        // Use `Object.hasOwn()` to check if the object has the specified property
        if (Object.hasOwn(currentObj, keyToFind)) {
            result = currentObj[keyToFind];
            return;
        }

        // If the current level is an object, search its properties recursively
        for (const key in currentObj) {
            const value = currentObj[key];
            if (typeof value === 'object' && value !== null) {
                recursiveSearch(value as AnyObject);
            }
        }
    };

    recursiveSearch(obj);
    return result;
};
