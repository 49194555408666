import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GtmIdDirective } from '@idr/ui/tracking';
import { RxLet } from '@rx-angular/template/let';
import { Observable } from 'rxjs';
import { FontSizeService } from '../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatTooltipModule, RxLet, GtmIdDirective],
    selector: 'hg-font-size',
    standalone: true,
    styles: ':host { display: flex }',
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: ` <ng-container *rxLet="fontSize$; let fontSize">
        <button
            class="with-hitbox"
            [hgGtmId]="['documentMenu', 'decrease', 'fontSize']"
            [hgGtmValues]="{ font_size: fontSize - 1 }"
            [disabled]="fontSize <= 1"
            (click)="onClickFontDecrease()"
            matTooltip="Schriftgröße verringern">
            <mat-icon svgIcon="A-" />
        </button>
        <button
            class="with-hitbox"
            [hgGtmId]="['documentMenu', 'increase', 'fontSize']"
            [hgGtmValues]="{ font_size: fontSize + 1 }"
            [disabled]="fontSize >= 5"
            (click)="onClickFontIncrease()"
            matTooltip="Schriftgröße erhöhen">
            <mat-icon svgIcon="A+" />
        </button>
    </ng-container>`,
})
export class FontSizeComponent {
    readonly fontSize$: Observable<number>;

    constructor(private readonly service: FontSizeService) {
        this.fontSize$ = service.fontSize$;
    }

    onClickFontIncrease(): void {
        this.service.increaseFontSize();
    }

    onClickFontDecrease(): void {
        this.service.decreaseFontSize();
    }
}
