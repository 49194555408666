import { ChangeDetectionStrategy, Component, computed, effect, Inject, Signal, signal } from '@angular/core';
import { UploadedFile } from '@idr/model/config';
import { PartnerLogoProductOptionsState } from '../../model/partner-logo-product-options-state';
import { ACTIVATED_PRODUCT_GROUP, ActivatedProductGroup } from '../../routing/activated-product-group';
import { AddPartnerLogoComponent } from '../add-partner-logo/add-partner-logo.component';
import { UploadedPartnerLogoComponent } from '../uploaded-partner-logo/uploaded-partner-logo.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AddPartnerLogoComponent, UploadedPartnerLogoComponent],
    providers: [PartnerLogoProductOptionsState],
    selector: 'mc-edit-partner-logo',
    standalone: true,
    styleUrl: './edit-partner-logo.component.scss',
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `<h1>Partner Logo</h1>
        @for (logo of partnerLogos(); track logo.id) {
            <mc-uploaded-partner-logo [logo]="logo" />
        }
        @if (!showUploadFile() && showAddAnotherLogo()) {
            <button
                class="primary"
                data-cy="addAnotherLogo"
                (click)="addAnotherLogo()"
                [disabled]="disableAddAnotherLogo()">
                Partnerlogo hinzufügen
            </button>
        }
        @if (showUploadFile()) {
            <mc-add-partner-logo (used)="hideAddPartnerLogo()" />
        }`,
})
export class EditPartnerLogoComponent {
    readonly #showAddAnotherLogo = signal<boolean>(false);

    readonly #showUploadFile = signal<boolean>(false);

    readonly disableAddAnotherLogo: Signal<boolean>;

    readonly partnerLogos: Signal<UploadedFile[]>;

    readonly showAddAnotherLogo = this.#showAddAnotherLogo.asReadonly();

    readonly showUploadFile = this.#showUploadFile.asReadonly();

    constructor(@Inject(ACTIVATED_PRODUCT_GROUP) active: Signal<ActivatedProductGroup>) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.partnerLogos = computed(() => active().configuration.partnerLogos!);

        this.disableAddAnotherLogo = computed(() => this.partnerLogos().length >= active().products.length);

        effect(
            () => {
                if (this.partnerLogos().length === 0) {
                    this.#showAddAnotherLogo.set(false);
                    this.#showUploadFile.set(true);
                    return;
                }

                this.#showAddAnotherLogo.set(true);
            },
            { allowSignalWrites: true },
        );
    }

    addAnotherLogo() {
        this.#showAddAnotherLogo.set(false);
        this.#showUploadFile.set(true);
    }

    hideAddPartnerLogo() {
        this.#showUploadFile.set(false);
    }
}
