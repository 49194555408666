import { Injectable } from '@angular/core';
import { SearchLink } from '@idr/shared/model';
import { HISTORY_MAX_SIZE } from '@idr/ui/consts';
import { LocalStorageKey, LocalStorageService } from '@idr/ui/shared';
import { History } from './history';

@Injectable({ providedIn: 'root' })
export class SearchHistory extends History<SearchLink> {
    constructor(localStorage: LocalStorageService) {
        super(LocalStorageKey.HISTORY_SEARCH, localStorage, SearchLink.fromStorage, HISTORY_MAX_SIZE);
    }
}
