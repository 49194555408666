import {
    ALL_HOT_TYPES,
    ContentHubContentLayout,
    DocumentLink,
    isTopicPageDocument,
    RoutePaths,
} from '@idr/shared/model';

export const getDocumentRoute = (link: DocumentLink): string[] => {
    if (ALL_HOT_TYPES.includes(link.classification.type)) {
        return [RoutePaths.Hot, ContentHubContentLayout.full];
    }

    if (link.classification.type === 'NEWS') {
        return [RoutePaths.News, ContentHubContentLayout.full];
    }

    if (isTopicPageDocument(link.classification.type, link.documentId)) {
        return [RoutePaths.TopicPage];
    }

    return [RoutePaths.Document];
};
