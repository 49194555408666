import { Clipboard } from '@angular/cdk/clipboard';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    input,
    signal,
    Signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Subscription, timer } from 'rxjs';

export const SHOW_COPIED_DURATION = 2000;

/**
 * A copy button.
 *
 * You can set {@link label} as well as {@link copiedLabel}.
 * The user will see the default state of the button.
 *
 * When clicking the button the {@link value} will be copied into the client's clipboard.
 * Also, alongside the button will switch its state for a short amount of time to give feedback to user
 * that copying took place.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule],
    selector: 'hg-copy-button',
    standalone: true,
    styleUrls: ['./copy-button.component.scss'],
    template: `
        <button
            #button
            data-cy="copy"
            [class]="copied() ? 'success' : 'secondary'"
            (click)="copy()">
            {{ renderLabel() }}
            @if (copied()) {
                <mat-icon svgIcon="submit" />
            }
        </button>
    `,
})
export class CopyButtonComponent {
    readonly #copied: WritableSignal<boolean> = signal(false);

    @ViewChild('button') button?: ElementRef<HTMLButtonElement>;

    readonly copied: Signal<boolean> = this.#copied.asReadonly();

    /**
     * Optionally you can set the label shown for the "value-got-copied" state of this button.
     *
     * If not set, 'Kopiert' will be shown as default.
     */
    copiedLabel = input<string>();

    label = input.required<string>();

    /**
     * The value that will be copied into {@link Clipboard}.
     */
    value = input.required<string>();

    readonly renderLabel = computed(() => {
        if (this.copied()) {
            return this.copiedLabel() ?? 'Kopiert';
        }
        return this.label();
    });

    #timer?: Subscription;

    constructor(private readonly clipboard: Clipboard) {}

    copy(): void {
        this.button?.nativeElement?.blur();

        this.clipboard.copy(this.value());
        this.#copied.set(true);

        this.#timer?.unsubscribe();
        this.#timer = timer(SHOW_COPIED_DURATION).subscribe(() => this.#copied.set(false));
    }
}
