import { ChangeDetectionStrategy, Component, computed, Input, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DocumentLink, SearchLink } from '@idr/shared/model';
import { GtmIdDirective } from '@idr/ui/tracking';
import {
    PopupDirective,
    ConfirmBannerComponent,
    DocumentLinkComponent,
    SearchLinkComponent,
    Tab,
    SelectedIndex,
    TabListComponent,
} from '@idr/ui/shared';
import { DocumentHistory } from '../../../../core/history/document-history';
import { SearchHistory } from '../../../../core/history/search-history';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ConfirmBannerComponent, DocumentLinkComponent, SearchLinkComponent, TabListComponent, GtmIdDirective],
    selector: 'idr-history',
    standalone: true,
    styleUrls: ['./history.component.scss'],
    templateUrl: './history.component.html',
})
export class HistoryComponent {
    @Input() popup: PopupDirective;

    /**
     * The index of the current activated tab
     *
     * @default 0
     */
    readonly activeTab: Signal<SelectedIndex>;

    readonly #activeTab: WritableSignal<SelectedIndex> = signal(0);

    readonly documentHistoryLinks: Signal<DocumentLink[]>;

    /**
     * `true` when current active tab doesn't hold any entries and `false` otherwise
     */
    readonly empty: Signal<boolean>;

    readonly searchHistoryLinks: Signal<SearchLink[]>;

    /**
     * `true` when "delete-history" button was clicked
     * `false` every time a different tab is selected
     * `false` after history was cleared ({@see clearHistory})
     *
     *  @default no value
     */
    readonly showConfirmDelete: Signal<boolean>;

    readonly #showConfirmDelete: WritableSignal<boolean> = signal(false);

    readonly tabs: Tab[] = [{ title: 'Zuletzt geöffnet' }, { title: 'Zuletzt gesucht' }];

    constructor(
        readonly documentHistory: DocumentHistory,
        readonly searchHistory: SearchHistory,
    ) {
        this.activeTab = this.#activeTab.asReadonly();

        const documentHistoryLinks = toSignal(documentHistory.state$);
        this.documentHistoryLinks = computed(() => documentHistoryLinks().values);

        const searchHistoryLinks = toSignal(searchHistory.state$);
        this.searchHistoryLinks = computed(() => searchHistoryLinks().values);

        this.showConfirmDelete = this.#showConfirmDelete.asReadonly();

        const links = computed(() => {
            if (this.activeTab() === 0) {
                return this.documentHistoryLinks();
            }
            return this.searchHistoryLinks();
        });

        this.empty = computed(() => links().length === 0);
    }

    close(): void {
        this.popup.close();
    }

    /**
     * Clears history when {@param confirmed} equals true (otherwise this call will be ignored) depending on active tab.
     * For example: if "lastDocuments" is active only {@link DocumentHistory} will be cleared
     *
     * @see DocumentHistory.clear()
     * @see SearchHistory.clear()
     */
    clearHistory(confirmed: boolean): void {
        this.#showConfirmDelete.set(false);
        if (!confirmed) {
            return;
        }
        switch (this.#activeTab()) {
            case 0:
                this.documentHistory.clear();
                break;
            case 1:
                this.searchHistory.clear();
                break;
        }
    }

    /**
     * Switches active tab based on given {@param activeTab}.
     * Should be connected to `<hg-tab-list>`.
     */
    onTabSelected(activeTab: SelectedIndex) {
        this.#activeTab.set(activeTab);
        this.#showConfirmDelete.set(false);
    }

    onDeleteClicked() {
        this.#showConfirmDelete.set(true);
    }
}
