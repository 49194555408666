import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { isProductGroupIdFormat, isProductIdFormat, Product, ProductGroupId, ProductId } from '@idr/shared/model';
import { logger, urlAsParts, urlWithoutQueryParams } from '@idr/shared/utils';
import { NavigationService, RoutePaths } from '@idr/ui/navigation';
import { ActiveProduct, ProductService } from '@idr/ui/shared';
import { List as ImmutableList } from 'immutable';
import { EMPTY, from, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ProductGroupService } from './product-group.service';
import { ProductRedirectionService } from './product-redirection.service';
import { RouteSpecificProductResolver } from './route-specific-product-resolver';

@Injectable({ providedIn: 'root' })
export class ProductResolver {
    readonly #logPrefix = '[ProductResolver]';

    constructor(
        private readonly activeProduct: ActiveProduct,
        private readonly productService: ProductService,
        private readonly productGroupService: ProductGroupService,
        private readonly navigationService: NavigationService,
        private readonly productRedirectionService: ProductRedirectionService,
        private readonly routeSpecificProductResolver: RouteSpecificProductResolver,
    ) {}

    /**
     * Returns an observable of either:
     *      - the corresponding product from the user's products when the current route contains a product-id
     *      - never if no product can be found
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Product> {
        const requestedProductId: string = route.params.productId;
        const queryParams: Params = route.queryParams;
        // We are removing the query params from the urlPathParts because if they are left there angular will interpret them
        // as part of the route params and not as query params.
        // We keep the query params in a separate object anyway to pass them as navigation extras.
        const urlPathParts: string[] = urlAsParts(urlWithoutQueryParams(state.url));

        logger.debug(
            this.#logPrefix,
            'resolve ->',
            'requested product id',
            requestedProductId,
            'urlPathParts',
            urlPathParts,
            'queryParams',
            queryParams,
        );

        return this.resolveProduct$(requestedProductId, urlPathParts, queryParams).pipe(
            catchError(err => this.handleError(err)),
        );
    }

    private handleError(err: Error): Observable<never> {
        logger.error(this.#logPrefix, 'handleError -> Unexpected error while resolving product', err);
        // we got no product data at all from backend
        // in case our app currently gets redirected to API portal in order to retrieve new session
        logger.warn(this.#logPrefix, 'handleError -> Will redirect to error page');
        return from(this.navigationService.navigateToIfNoRedirectIsPending(['/', RoutePaths.Error])).pipe(
            switchMap(() => EMPTY),
        );
    }

    private resolveProduct$(
        requestedProductId: string,
        urlPathParts: string[],
        queryParams: Params,
    ): Observable<Product> {
        if (!requestedProductId) {
            // When no product id is given we try to load the default product.
            return this.#resolveProductId$(requestedProductId, urlPathParts, queryParams);
        }

        if (isProductIdFormat(requestedProductId)) {
            return this.#resolveProductId$(requestedProductId as ProductId, urlPathParts, queryParams);
        }

        if (isProductGroupIdFormat(requestedProductId)) {
            return this.#resolveProductGroup$(requestedProductId as ProductGroupId, urlPathParts, queryParams);
        }

        const errorMessage = 'Requested product id is neither a product id nor a product group id';
        logger.warn(this.#logPrefix, 'resolve ->', errorMessage, requestedProductId);
        return throwError(() => new Error(errorMessage));
    }

    /**
     * Resolves given product group. It'll identify a product that is part of given group and redirect to it.
     *
     * Optimal behavior:
     *  (1) (if possible, meaning content is available) stay in current opened product or use last visited one
     *  (2) if (1) is not possible use any other licensed product that has requested content
     *  (3) if (2) is not possible -> error
     *
     * We ignore the "has requested content" part since this is very hard to achieve.
     * Also, this wasn't implemented in iDesk2 and as far as I know it wasn't a problem.
     *
     * @param requestedProductGroup
     * @param urlPathParts
     * @param queryParams
     * @private
     */
    #resolveProductGroup$(
        requestedProductGroup: ProductGroupId,
        urlPathParts: string[],
        queryParams: Params,
    ): Observable<Product> {
        logger.debug(this.#logPrefix, '#resolveProductGroup$ ->', requestedProductGroup, urlPathParts, queryParams);

        // getLicensedProductsForGroupId$ might throw an error if the user has no licensed products for the requested group
        // In that case we want to redirect to the correct error page
        return this.productGroupService.getLicensedProductsForGroupId$(requestedProductGroup).pipe(
            switchMap(licensedProducts => {
                if (licensedProducts.size === 0) {
                    logger.warn(
                        this.#logPrefix,
                        '#resolveProductGroup$ -> No product owned in requested group. Will fallback to error',
                    );
                    this.navigationService.navigateToIfNoRedirectIsPending([
                        '/',
                        RoutePaths.Error,
                        RoutePaths.NoProductInProductGroupError,
                    ]);
                    return EMPTY;
                }

                const candidates = this.#moveLastActiveOneInFirstPosition(licensedProducts);
                return this.routeSpecificProductResolver.resolve(candidates, urlPathParts);
            }),
            tap(product => this.productRedirectionService.activateProduct(product, urlPathParts, queryParams)),
            catchError(err => {
                logger.warn(
                    this.#logPrefix,
                    `#resolveProductGroup$ -> Couldn't resolve product group "${requestedProductGroup}".`,
                    err,
                );
                this.navigationService.navigateToIfNoRedirectIsPending([
                    '/',
                    RoutePaths.Error,
                    RoutePaths.UnknownProductGroupError,
                ]);
                return EMPTY;
            }),
        );
    }

    #resolveProductId$(
        requestedProductId: ProductId | string, // it's an empty string when it's not defined in the route
        routePathParts: string[],
        queryParams: Params,
    ): Observable<Product> {
        return this.#licensedProducts$.pipe(
            switchMap(licensedProducts => {
                const requestedProduct: Product = licensedProducts.find(product => product.id === requestedProductId);

                // If we cannot find a matching product we navigate to the default product (the first one in the licensed products list)
                if (!requestedProduct) {
                    logger.warn(
                        this.#logPrefix,
                        '#resolveProductId$ -> Routed product',
                        requestedProductId,
                        'does not exist, cannot be accessed or the user is not allowed to access it.',
                    );
                    this.productRedirectionService.redirectToLastActiveOrDefaultProduct(
                        licensedProducts,
                        routePathParts,
                        queryParams,
                    );
                    return EMPTY;
                }

                logger.debug(
                    this.#logPrefix,
                    '#resolveProductId$ -> Found requested product. Will resolve.',
                    requestedProduct,
                );
                this.activeProduct.product = requestedProduct;
                return of(requestedProduct);
            }),
        );
    }

    get #licensedProducts$(): Observable<ImmutableList<Product>> {
        return this.productService.products$.pipe(
            take(1),
            switchMap(licensedProducts => {
                if (licensedProducts.length === 0) {
                    logger.warn(this.#logPrefix, '#licensedProducts$ -> No products available. Will fallback to error');
                    return throwError(() => new Error('No products available'));
                }

                logger.debug(
                    this.#logPrefix,
                    '#licensedProducts$ -> licensed products are: ',
                    licensedProducts?.map(p => p.id).join(','),
                );
                return of(ImmutableList.of(...licensedProducts));
            }),
        );
    }

    #moveLastActiveOneInFirstPosition(candidates: ImmutableList<Product>): ImmutableList<Product> {
        const lastActiveProductId = this.productRedirectionService.lastActiveProductId;
        const lastActiveProductIndex = candidates.findIndex(product => product.id === lastActiveProductId);
        if (lastActiveProductIndex > 0) {
            logger.debug(
                this.#logPrefix,
                '#moveLastActiveOneInFirstPosition -> considering last opened product',
                lastActiveProductId,
            );
            const lastActiveProduct = candidates.get(lastActiveProductIndex);
            return candidates.remove(lastActiveProductIndex).insert(0, lastActiveProduct);
        }

        return candidates;
    }
}
