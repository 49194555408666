import { CrsRelatedDocumentsClusterDTO } from './crs-related-documents';

export const TranslatedTitle = {
    news: 'News',
    topissues: 'Top-Themen',
    favorites: 'Besonders relevant',
    others: 'Sonstige',
    KOM_CL: 'Kommentare',
    GES_CL: 'Gesetze',
    VO_RL_CL: 'Verordnungen & Richtlinien',
    VV_CL: 'Verwaltungsvereinbarungen',
    BE_CL: 'Besprechungsergebnisse',
    RS_CL: 'Rundschreiben',
    SONST_CL: 'Sonstige Rechtsquellen',
    WI_SCHR_CL: 'Wichtige Schreiben',
    W_KOM_CL: 'Weitere Kommentare',
    KOM_UV_CL: 'Kommentierungen',
    NO_KE_CL: 'Normenkette',
    VA_CL: 'Kommentierte Verwaltungsanweisung',
    NAE_CL: 'Nicht-Anwendungserlasse',
    UER_CL: 'Übergangsregelungen',
    ANAE_CL: 'Aufhebung von Nichtanwendungserlassen',
    MUSTER_PASSIV_CL: 'Einspruchsmuster',
    ENTSCH_PASSIV_CL: 'Wird zitiert von',
    VA_PASSIV_CL: 'Wird zitiert von',
    UR_CL: 'Kommentierte Entscheidung',
    ERL_CL: '(Nicht-)Anwendungserlasse',
};

export type TranslatedTitleId = keyof typeof TranslatedTitle;

export type TranslatedTitle = (typeof TranslatedTitle)[keyof typeof TranslatedTitle];

export const convert = (cluster: CrsRelatedDocumentsClusterDTO): string | TranslatedTitle | undefined => {
    if (cluster.i18nKey) {
        const titleKey = cluster.i18nKey.replace('cluster.', '') as TranslatedTitleId;
        return TranslatedTitle[titleKey];
    }

    return cluster.name;
};
