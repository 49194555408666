import { Routes } from '@angular/router';
import { DeeplinkPathId } from '@idr/shared/model';
import { ColRedirectCanActivateGuard } from './column/col-redirect-can-activate.guard';

export const DEEPLINK_ROUTES: Routes = [
    {
        path: `${DeeplinkPathId.Column}/:li`,
        canActivate: [ColRedirectCanActivateGuard],
        children: [],
    },
];
