import { inject } from '@angular/core';
import { ResolveFn, Route } from '@angular/router';
import { ProductGroupConfiguration } from '@idr/model/config';
import { logger } from '@idr/shared/utils';
import { EditLogoComponent } from '../components/edit-logo/edit-logo.component';
import { EditSearchFilterComponent } from '../components/edit-search-filter/edit-search-filter.component';
import { GroupsAvailableGuard } from '../guards/groups-available-guard';
import { GroupExistsGuard } from '../guards/is-group-allowed-guard';
import { IsUserAllowedGuard } from '../guards/is-user-allowed-guard';
import { ConfigApiService } from '../services/config-api.service';
import { EditComponent } from './edit/edit.component';
import { StartComponent } from './start/start.component';

export type ResolveType = ProductGroupConfiguration | undefined;

export const productGroup: ResolveFn<ResolveType> = async route => {
    const logPrefix = '[productGroup]';
    const groupId = route.params?.['groupId'];
    const resolved = await inject(ConfigApiService).get(groupId);
    logger.debug(logPrefix, 'resolved', groupId, 'from route ->', resolved);
    // the undefined case is covered by our `GroupExistsGuard`
    // => `GroupExistsGuard` will navigate to an error page if group doesn't exist
    return resolved;
};

export const ROUTING: Route[] = [
    {
        path: '',
        component: StartComponent,
        canActivate: [IsUserAllowedGuard],
        resolve: { productGroup: () => undefined },
        title: 'myDesk Konfiguration - Produktfamilie wählen',
    },

    // when user picked a product group we start the edit mode...
    {
        path: ':groupId',
        component: EditComponent,
        canActivate: [GroupsAvailableGuard, GroupExistsGuard],
        resolve: { productGroup },

        children: [
            { path: '', pathMatch: 'prefix', redirectTo: 'logo' },
            {
                path: `logo`,
                component: EditLogoComponent,
                title: 'myDesk Konfiguration - Logo editieren',
            },
            {
                path: `filter`,
                component: EditSearchFilterComponent,
                title: 'myDesk Konfiguration - Suchfilter editieren',
            },
        ],
    },
];
