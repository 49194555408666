export const RoutePaths = {
    Blank: 'blank',
    Config: 'config',
    Column: 'column',
    Document: 'document',
    DevDocumentation: 'docu',
    Error: 'error',
    Filter: 'filter',
    Hot: 'hot',
    Imprint: 'imprint',
    Lexicon: 'lexicon',
    LicenseError: 'license-error',
    AuthError: 'auth-error',
    NoDraftError: 'no-draft-error',
    News: 'news',
    NewAndChanged: 'new-and-changed',
    NoProductInProductGroupError: 'no-product-in-group',
    Search: 'search',
    TopicPage: 'topicPage',
    UnknownProductGroupError: 'unknown-group',
};

export type RoutePaths = (typeof RoutePaths)[keyof typeof RoutePaths];
