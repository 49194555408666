import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    input,
    NgZone,
    output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { logger } from '@idr/shared/utils';
import { GtmArea, GtmIdDirective } from '@idr/ui/tracking';
import { ElementOverflowsDirective } from '../../directives';
import { LoadingDotsComponent } from '../loading-dots/loading-dots.component';
import { Tab } from './tab';

export type SelectedIndex = number;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatTooltipModule, ElementOverflowsDirective, LoadingDotsComponent, GtmIdDirective],
    selector: 'hg-tab-list',
    standalone: true,
    styleUrls: ['./tab-list.component.scss'],
    templateUrl: './tab-list.component.html',
})
export class TabListComponent implements AfterViewInit {
    @ViewChildren('liElem') readonly tabRefs?: QueryList<ElementRef>;

    @ViewChild('indicator') readonly indicator?: ElementRef;

    selected = output<number>();

    gtmArea = input.required<GtmArea>();

    #tabs?: Tab[];
    #selectedIndex?: SelectedIndex;

    constructor(private readonly ngZone: NgZone) {}

    /**
     * Gets index of current selected tab based on previously set {@see tabs}.
     *
     * @default undefined
     */
    get selectedIndex(): SelectedIndex | undefined {
        return this.#selectedIndex;
    }

    /**
     * Changes current selected tab based on index of the tab-to-be-activated in previously set {@see tabs}.
     * Will be ignored if related tab is currently disabled.
     *
     * @default undefined
     */
    @Input()
    set selectedIndex(selectedIndex: SelectedIndex) {
        logger.debug('[TabList] selectedIndex ->', selectedIndex);
        this.#selectedIndex = selectedIndex;
        this.#updateIndicatorSize();
    }

    get tabs(): Tab[] | undefined {
        return this.#tabs;
    }

    /**
     * Sets the tabs for this component. Initially there are no tabs and this component is empty.
     * So you need to at least once set this with a not empty {@link Tab}.
     *
     * @default empty
     */
    @Input()
    set tabs(tabs: Tab[]) {
        this.#tabs = tabs;
        if (tabs?.length >= 1 && this.#selectedIndex === undefined) {
            this.selectedIndex = 0;
        }

        /*
         * There is a case where the initially selected tab is still loading.
         * After that the tabs are updated and we need to call updateIndicatorSize again.
         * setTimeout is needed so that the UI is updated.
         */
        setTimeout(() => {
            this.#updateIndicatorSize();
        });
    }

    ngAfterViewInit(): void {
        this.#updateIndicatorSize();
    }

    onClickSelectedIndex(selectedIndex: number): void {
        if (
            this.#tabs &&
            (this.#tabs[selectedIndex].disabled ||
                this.#tabs[selectedIndex].loading ||
                selectedIndex >= this.#tabs.length ||
                this.#selectedIndex === selectedIndex)
        ) {
            return;
        }
        this.selectedIndex = selectedIndex;
        this.selected.emit(selectedIndex);
    }

    #updateIndicatorSize(): void {
        logger.debug('[TabList] updateIndicatorSize ->', this.selectedIndex, this.tabRefs);
        this.ngZone.runOutsideAngular(() => {
            const tabElementRefs: ElementRef[] = this.tabRefs === undefined ? [] : this.tabRefs.toArray();
            // Somehow I ran into my unit tests into this (Cannot read property 'nativeElement' of undefined) :(
            // I couldn't fix it reliable in the unit test so I added this condition...
            // it shouldn't happen in production since we unsubscribe on destroy...
            if (tabElementRefs.length === 0 || this.selectedIndex === undefined || !this.indicator) {
                return;
            }

            const relatedTab: HTMLElement = tabElementRefs[this.selectedIndex].nativeElement;
            logger.debug(
                '[TabList] updateIndicatorSize ->',
                this.selectedIndex,
                tabElementRefs,
                relatedTab.offsetWidth,
            );
            // FIXME this doesn't work... ?_?
            // this.renderer.setStyle(this.indicator.nativeElement, 'width', relatedTab.offsetWidth);
            this.indicator.nativeElement.style.width = `${relatedTab.offsetWidth}px`;
            this.indicator.nativeElement.style.marginLeft = `${relatedTab.offsetLeft - (relatedTab.parentElement?.offsetLeft ?? 0)}px`;
        });
    }

    gtmDescription(tabTitle: string): string {
        if (this.gtmArea() === 'history') {
            if (tabTitle === 'Zuletzt geöffnet') {
                return 'docHistory';
            }
            return 'searchHistory';
        }
        return 'tab';
    }
}
