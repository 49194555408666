import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { GtmArea, GtmIdDirective } from '@idr/ui/tracking';
import { SetFocusDirective } from '../../directives';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SetFocusDirective, GtmIdDirective],
    selector: 'hg-confirm-banner',
    standalone: true,
    styleUrls: ['./confirm-banner.component.scss'],
    templateUrl: './confirm-banner.component.html',
})
export class ConfirmBannerComponent {
    gtmArea = input.required<GtmArea>();

    message = input.required<string>();

    confirmed = output<boolean>();
}
