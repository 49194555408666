export const NavigationIcon = {
    A_Z: 'a-z',
    TAG: 'tag',
    BANK_CIRCLE: 'bank-circle',
    BIBLIOGRAPHY: 'bibliography',
    BOOK: 'book',
    BOOKING_RATES: 'booking-rates',
    BOOK_BANK: 'book-bank',
    BOOK_GEOLOCATION: 'book-geolocation',
    BOOK_HR: 'book-HR',
    BOOK_STAR: 'book-star',
    BOOK_TREND: 'book-trend',
    BOOK_TV: 'book-tv',
    CHARGE: 'charge',
    CHECKED_LIST: 'checked-list',
    COMMENT: 'comment',
    CONTROLLING: 'controlling',
    DOCUMENT_GAVEL: 'document-gavel',
    DOCUMENT_MICROPHONE: 'document-microphone',
    DOUBLE_COMMENT: 'double-comment',
    DOUBLE_COMMENT_PLUS: 'double-comment+',
    EURO_FLAG: 'euro-flag',
    FACTORY_A_Z: 'factory-a-z',
    FALLBACK: 'generic',
    FALLOUT_AVATAR: 'fallout-avatar',
    GEAR_CIRCLE: 'gear-circle',
    INFO_CLOCK: 'info-clock',
    JUDGEMENT_DATABASE: 'judgement-database',
    LETTER: 'letter',
    LETTER_CHECKED: 'letter-checked',
    LETTER_PARAGRAPH: 'letter-paragraph',
    LETTER_PERCENT: 'letter-percent',
    LETTER_STAR: 'letter-star',
    LIBRA: 'libra',
    LIBRARY: 'library',
    LIGHTBULB: 'lightbulb',
    LIGHTBULB_EURO: 'lightbulb-euro',
    LIGHTBULB_CHECKED: 'lightbulb-checked',
    LIGHTBULB_PERCENT: 'lightbulb-percent',
    LIGHTBULB_STAR: 'lightbulb-star',
    MAGAZINE: 'magazine',
    MAGNIFIER_EURO: 'magnifier-euro',
    NEWSLETTER: 'newsletter',
    PARAGRAPHS: 'paragraphs',
    SCREEN_LIBRA: 'screen-libra',
    SECURITY: 'security',
    START: 'start',
    TOOLS: 'tools',
    TRAINING: 'training',
};

export type NavigationIcon = (typeof NavigationIcon)[keyof typeof NavigationIcon];

export const findIcon = (label: string): NavigationIcon => {
    // #1 try exact match (but with lower case it is a tiny little bit "generic")
    switch (label.toLocaleLowerCase()) {
        case 'lexikon':
            return NavigationIcon.A_Z;
        case 'rechtsquellen':
            return NavigationIcon.PARAGRAPHS;
        case 'verwaltungsvorschriften':
        case 'fachbeiträge':
            return NavigationIcon.LETTER;
        case 'digitale kanzlei':
            return NavigationIcon.SCREEN_LIBRA;
        case 'beratung plus':
            return NavigationIcon.DOUBLE_COMMENT_PLUS;
        case 'digitale prozesse':
        case 'operationalisierung':
        case 'kanzlei im wandel':
            return NavigationIcon.GEAR_CIRCLE;
        case 'öffentlicher dienst':
            return NavigationIcon.BANK_CIRCLE;
        case 'praxis-beispiele':
            return NavigationIcon.CHECKED_LIST;
        case 'smart solutions':
            return NavigationIcon.LIGHTBULB;
        case 'finanzmanagement nach ländern':
            return NavigationIcon.EURO_FLAG;
        case 'rechtsprechungs-report':
        case 'rechts-report':
            return NavigationIcon.DOCUMENT_GAVEL;
        case 'tagungsunterlagen':
            return NavigationIcon.DOCUMENT_MICROPHONE;
        case 'gmbh-lexikon':
            return NavigationIcon.FACTORY_A_Z;
        case 'länderanhänge':
            return NavigationIcon.BOOK_GEOLOCATION;
        case 'veranlagungshandbücher':
            return NavigationIcon.BOOK_TREND;
        case 'handbuch tv-l':
            return NavigationIcon.BOOK_TV;
        case 'handbuch tvöd':
            return NavigationIcon.BOOK_BANK;
        case 'übersichtsseiten':
            return NavigationIcon.TAG;
    }

    // #2 try more generic approach
    if (/lexikon/i.test(label)) {
        return NavigationIcon.A_Z;
    }

    // Themenseiten
    if (/themenseite/i.test(label)) {
        return NavigationIcon.TAG;
    }

    // Kommentare
    if (/kommentar/i.test(label)) {
        return NavigationIcon.COMMENT;
    }

    // Fachmagazine
    if (/fachmagazin/i.test(label)) {
        return NavigationIcon.BOOK_STAR;
    }

    // Fachmagazine
    if (/weiterbildung/i.test(label)) {
        return NavigationIcon.TRAINING;
    }

    // Personalmagazin
    if (/magazin/i.test(label)) {
        return NavigationIcon.MAGAZINE;
    }

    // E-Training | Onlinetraining
    if (/training/i.test(label)) {
        return NavigationIcon.TRAINING;
    }

    // Arbeitshilfen
    if (/arbeitshilfe/i.test(label)) {
        return NavigationIcon.TOOLS;
    }

    // Gehaltsanalyse
    if (/gehalt/i.test(label) || /ökonomie/i.test(label)) {
        return NavigationIcon.MAGNIFIER_EURO;
    }

    // Beratung
    if (/beratung/i.test(label)) {
        return NavigationIcon.DOUBLE_COMMENT;
    }

    // Controlling
    if (/controlling/i.test(label)) {
        return NavigationIcon.CONTROLLING;
    }

    // Aktuelle Information & Umsatzsteuer aktuell
    if (/aktuell/i.test(label)) {
        return NavigationIcon.INFO_CLOCK;
    }

    // Gesetze, Vorschriften & Rechtsprechung
    if (/gesetz/i.test(label)) {
        return NavigationIcon.LETTER_PARAGRAPH;
    }

    // Tarifverträge
    if (/tarif/i.test(label)) {
        return NavigationIcon.CHARGE;
    }

    // Bibliothek
    if (/bibliothek/i.test(label)) {
        return NavigationIcon.LIBRARY;
    }

    // Fachgruppen
    if (/(fachgruppe|betriebe spezial)/i.test(label) || /soziales/i.test(label)) {
        return NavigationIcon.FALLOUT_AVATAR;
    }

    // Steuerverfahren
    if (/steuerverfahren/i.test(label)) {
        return NavigationIcon.LETTER_PERCENT;
    }

    // Compliance Management
    if (/compliance/i.test(label)) {
        return NavigationIcon.SECURITY;
    }

    // Fachwissen & Umsatzsteuer Know-how
    if (/fachwissen/i.test(label) || /know-how/i.test(label) || /ökologie/i.test(label)) {
        return NavigationIcon.LIGHTBULB_STAR;
    }

    // Standards
    if (/standards/i.test(label)) {
        return NavigationIcon.LETTER_CHECKED;
    }

    // Basiswissen
    if (/basiswissen/i.test(label)) {
        return NavigationIcon.LIGHTBULB_CHECKED;
    }

    // Honorartipps
    if (/honorartipps/i.test(label)) {
        return NavigationIcon.LIGHTBULB_EURO;
    }

    // Entscheidungsdatenbank
    if (/datenbank/i.test(label) || /digitale organisation/i.test(label)) {
        return NavigationIcon.JUDGEMENT_DATABASE;
    }

    // Buchungssätze
    if (/buchungssätze/i.test(label)) {
        return NavigationIcon.BOOKING_RATES;
    }

    // Sachgebiete
    if (/sachgebiete/i.test(label) || /digiTales/i.test(label)) {
        return NavigationIcon.LETTER_STAR;
    }

    // Steuerratgeber
    if (/steuerratgeber/i.test(label)) {
        return NavigationIcon.LETTER_PERCENT;
    }

    // Bibliographie
    if (/bibliographie/i.test(label)) {
        return NavigationIcon.BIBLIOGRAPHY;
    }

    // Newsletter
    if (/newsletter/i.test(label)) {
        return NavigationIcon.NEWSLETTER;
    }

    // #3 try even more generic approach.

    // Rechtsgrundlagen | Rechtsprechung | Entscheidungen
    if (/rechts[\w]+/i.test(label) || /entscheidung/i.test(label)) {
        return NavigationIcon.LIBRA;
    }

    // Themen A-Z | Inhalte von A-Z
    if (/a-z/i.test(label)) {
        return NavigationIcon.A_Z;
    }

    // Handbuch | Handbücher
    if (/handb[uü]ch/i.test(label) || /bvl spezial/i.test(label)) {
        return NavigationIcon.BOOK;
    }

    // HR-NewsService
    if (/[-]?HR[-]?/.test(label)) {
        return NavigationIcon.BOOK_HR;
    }

    // Steuerwissen
    if (/Steuer/.test(label)) {
        return NavigationIcon.LIGHTBULB_PERCENT;
    }

    return NavigationIcon.FALLBACK;
};
