import { ProductId } from '../product';

/**
 * A string that follows RegExp: PG\s+
 *
 * @example PGHFO, PGHAUFE
 */
export type ProductGroupId = `PG${string}`;

export interface ProductGroup {
    readonly id: ProductGroupId;
    readonly productIds: ProductId[];
}

/**
 * Returns true if the given string is a valid product group id.
 * Useful, in order to distinguish between product ids and product groups.
 */
export const isProductGroupIdFormat = (requestedProductGroupId: string): boolean => {
    return /^PG[A-Z]+$/.test(requestedProductGroupId);
};
