import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'hg-error-call-support',
    standalone: true,
    styleUrls: ['./error-call-support.component.scss'],
    template: `<p>{{ context() }}, bitte versuchen Sie es zu einem späteren Zeitpunkt nochmal.</p>
        <small>
            Sollte das Problem weiterhin bestehen setzen Sie sich mit unserem
            <a
                href="https://www.haufe.de/kontakt"
                target="_blank">
                Technischen Support
            </a>
            in Verbindung.
        </small>`,
})
export class ErrorCallSupportComponent {
    context = input.required<string>();
}
