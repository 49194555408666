import { ProductId } from '../product';
import { ProductGroup, ProductGroupId } from './product-group';

const HFO_PRODUCT_GROUP: ProductId[] = ['PI42765', 'PI20354', 'PI11525', 'PI337', 'PI914'];

const HOED_PRODUCT_GROUP: ProductId[] = ['PI22306', 'PI13994', 'PI15539', 'PI15555', 'PI7633', 'PI477', 'PI7684'];

const HPO_PRODUCT_GROUP: ProductId[] = ['PI42323', 'PI22608', 'PI10413', 'PI78'];

const HPU_PRODUCT_GROUP: ProductId[] = ['PI43990', 'PI1996'];

const HSGB_PRODUCT_GROUP: ProductId[] = ['PI434', 'PI16306'];

const HSO_PRODUCT_GROUP: ProductId[] = [
    'PI25844',
    'PI16039',
    'PI28452',
    'PI11940',
    'PI5592',
    'PI38083',
    'PI27',
    'PI8990',
    'PI15512',
];

export const PRODUCT_GROUPS: ProductGroup[] = [
    { id: 'PGHFO', productIds: HFO_PRODUCT_GROUP },
    { id: 'PGHOED', productIds: HOED_PRODUCT_GROUP },
    { id: 'PGHPO', productIds: HPO_PRODUCT_GROUP },
    { id: 'PGHPU', productIds: HPU_PRODUCT_GROUP },
    { id: 'PGHSGB', productIds: HSGB_PRODUCT_GROUP },
    { id: 'PGHSO', productIds: HSO_PRODUCT_GROUP },
];

export const DEFAULT_PRODUCT_GROUP: ProductGroupId = 'PGHAUFE';

export const PRODUCT_GROUP_IDS = [DEFAULT_PRODUCT_GROUP, ...PRODUCT_GROUPS.map(group => group.id)];
