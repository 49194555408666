import { BrandLabelDTO } from '../brand';

export const ConfigCluster = {
    SEARCH: 'suche',
    STARTPAGE: 'mainpage',
    NAVIGATION: 'navigation',
    USER_MENU: 'userMenu',
    HELP_MENU: 'helpMenu',
    RELATED_DOCUMENTS: 'relatedDocuments',
};

export type ConfigCluster = (typeof ConfigCluster)[keyof typeof ConfigCluster];

export type TargetPathDTO = ConfigCluster | string;

export interface ProductConfigurationDTO {
    readonly units: TargetDTO[];
    readonly _links: ProductConfigurationLinkDTO[];
}

export interface TargetDTO {
    readonly targetPath: TargetPathDTO;
    readonly position?: number;
    readonly source?: string;
    readonly children?: TargetDTO[];
    readonly properties?: PropertiesDTO;
}

export interface FramePortletDTO {
    readonly title?: string;
    readonly visible?: 'ALWAYS' | 'IF_NEEDED' | 'NEVER' | /*in different context also this is possible*/ 'true';
}

export type ContentPropertyViewDTOType = 'ALPHAINDEX' | 'COL';

export type ShowMoreLinkDTOVisibilityType = 'ALWAYS' | 'NEVER' | 'IF_NEEDED';

export interface ShowMoreLinkPropertiesDTO {
    readonly title?: string;
    readonly visible?: ShowMoreLinkDTOVisibilityType;
}

export type LinkTypeDTOType = 'BPL_ID' | 'URL' | 'DOC_ID';

export interface LinkPropertiesDTO {
    readonly title?: string;
    readonly type?: LinkTypeDTOType | string;
}

export interface HeroLinkPropertiesDTO {
    readonly title?: string;
    readonly description?: string;
    readonly image?: string;
}

export interface BauplanIndexPropertiesDTO {
    readonly maxItems?: string;
    readonly view?: ContentPropertyViewDTOType;
}

export interface ProductOverviewLinkPropertiesDTO {
    readonly title?: string;
    readonly numDaysBack?: string;
    readonly initialPage?: string;
}

export interface NotYetCategorisedPropertiesDTO {
    readonly id?: string;

    readonly text?: string;
    readonly caption?: string;

    readonly enabled?: string;
    readonly clusterName?: string;
    readonly seminarsName?: string;
    readonly videosName?: string;
    readonly trainingsName?: string;
    readonly nrHits?: string;
    readonly percentWidth?: string;
    readonly showIcon?: string;
    readonly showImage?: string;
    readonly showName?: string;
    readonly logo?: string;
    readonly fallbackLogo?: string;
    readonly width?: string;
    readonly height?: string;
    readonly email?: string;
    readonly showRelatedDocuments?: string;
    readonly showColumnVerfahrensstatus?: string;
    readonly mobil?: string;
    readonly hilfedatei?: string;
    readonly festnetz?: string;
    readonly doctype?: string;
    readonly category?: string;
    readonly facetKey?: string;
    readonly selectionType?: string;
    readonly expandFirstCluster?: string;
    readonly locale?: 'de' | 'fr';
    readonly shortName?: string;
    readonly showHistory?: string;
    readonly productLogo?: string;
    readonly updateInfo?: string;
    readonly survey?: string;
    readonly brand?: BrandLabelDTO;
    readonly shopUrl?: string;
    readonly l4Project?: string;
    readonly releaseInfo?: string;
    readonly favicon?: string;
    readonly cosmosBar?: string;
    readonly itemLayout?: string;
    readonly showSpeakerImage?: string;
    readonly maxETrainings?: string;
    readonly showETrainings?: string;
    readonly showAdditionalContents?: string;
    readonly maxAdditionalContents?: string;
}

export interface PropertiesDTO
    extends FramePortletDTO,
        LinkPropertiesDTO,
        HeroLinkPropertiesDTO,
        ProductOverviewLinkPropertiesDTO,
        NotYetCategorisedPropertiesDTO,
        BauplanIndexPropertiesDTO {}

export interface ProductConfigurationLinkDTO {
    readonly rel: string;
    readonly href: string;
}
