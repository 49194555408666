import { Pipe, PipeTransform } from '@angular/core';
import { cutLeadingSlash } from '@idr/shared/utils';
import { ApiService } from '../../services';

@Pipe({ name: 'hgImageTransform', standalone: true })
export class ImageTransformPipe implements PipeTransform {
    constructor(private readonly apiService: ApiService) {}

    transform(src: string): string {
        if (!src) {
            return '';
        }
        return `${this.apiService.staticContentBaseUrl}/${cutLeadingSlash(src)}`;
    }
}
