import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { rxEffects } from '@rx-angular/state/effects';
import { ProfileService } from '../services';

/**
 * This directive will hide the element that is attached to for anonymous users.
 */
@Directive({ selector: '[hgHideForAnonymous]', standalone: true })
export class HideForAnonymousDirective {
    constructor(profileService: ProfileService, viewContainerRef: ViewContainerRef, templateRef: TemplateRef<unknown>) {
        logger.debug('[HideForAnonymousDirective] -> init');
        rxEffects(({ register }) =>
            register(profileService.isAnonymous$, isAnonymous => {
                if (isAnonymous) {
                    viewContainerRef.clear();
                } else {
                    viewContainerRef.createEmbeddedView(templateRef);
                }
            }),
        );
    }
}
