import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '@idr/ui/shared';
import { EcondaService } from './econda.service';

@Injectable({ providedIn: 'root' })
export class TrackingDependencies {
    constructor(
        public readonly router: Router,
        public readonly route: ActivatedRoute,
        public readonly econda: EcondaService,
        public readonly profile: ProfileService,
    ) {}
}
