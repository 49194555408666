import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';
import { logger } from '@idr/shared/utils';
import { filter, map, Observable, shareReplay, startWith, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouterEvents {
    readonly #logPrefix = '[RouterEvents]';

    readonly navigationEnd$: Observable<NavigationEnd>;

    /**
     * Returns all the route parameters irrespective of the route outlet.
     * It basically combines all the parameters from all parent / child routes.
     * This is really useful when you need to access a parent parameter from a child route.
     *
     * **CAUTION**: If a child route has a parameter with the same name it will be overwritten by the parent value.
     * Thus, it's important to make sure that we have unique parameter names.
     */
    readonly routeParams$: Observable<Params>;

    #currentUrl: string;
    #previousUrl = '';

    constructor(private readonly router: Router) {
        this.#currentUrl = router.url;

        this.navigationEnd$ = router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(event => event as NavigationEnd), // Just to make typescript happy
            tap(() => {
                logger.debug(this.#logPrefix, 'navigationEnd$ ->', this.#currentUrl);
                this.#previousUrl = this.#currentUrl;
                this.#currentUrl = router.url;
            }),
            // Why using startWith? -> The problem is that when the app loads the first time we don't have an NavigationEnd event
            // because we are subscribing to the router events too late.
            // We can manually emit an event with the current url to overcome this limitation.
            // Otherwise, the routeParams$ observable will never emit the initial params that are set.
            startWith(new NavigationEnd(0, this.router.url, this.router.url)),
            shareReplay(1),
        );

        this.routeParams$ = this.navigationEnd$.pipe(map(() => this.snapshotParams));
    }

    get hasPreviousUrl(): boolean {
        return this.#previousUrl !== '';
    }

    /**
     * Returns all the route parameters irrespective of the route outlet.
     * It basically combines all the parameters from all parent / child routes.
     * This is really useful when you need to access a parent parameter from a child route.
     * This method comes from the official Angular params documentation as a way to get all the parameters for all routes.
     *
     * @see ActivatedRouteSnapshot.params
     *
     * **CAUTION**: If a child route has a parameter with the same name it will be overwritten by the parent value.
     * Thus, it's important to make sure that we have unique parameter names.
     */
    get snapshotParams(): Params {
        let params: Params = {};
        const stack: ActivatedRouteSnapshot[] = [this.router.routerState.snapshot.root];
        while (stack.length > 0) {
            const route = stack.pop() as ActivatedRouteSnapshot;
            params = { ...params, ...route.params };
            stack.push(...route.children);
        }
        return params;
    }
}
