import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable, StaticProvider } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { INJECT_MESSAGE_BOX_MESSAGE, MessageBoxComponent } from '../../components/message-box/message-box.component';
import { DialogService } from '../dialog';
import { Message } from './model';

interface CustomErrorTyped {
    readonly message: string;
    readonly type: string;
}

/**
 * Simple service that can be used to sent messages throughout the app.
 */
@Injectable({ providedIn: 'root' })
export class MessageService {
    /**
     * Holds current reference to overlay that was created due to posting a message.
     *
     * @see post
     */
    #overlayRef?: OverlayRef;

    /**
     * Subscribe to this observable to get informed as soon as new messages were posted.
     *
     * Messages get posted with #postError and #post.
     */
    readonly #message$ = new BehaviorSubject<Message | undefined>(undefined);

    get message$(): Observable<Message | undefined> {
        return this.#message$.asObservable();
    }

    constructor(private readonly dialogService: DialogService) {}

    post(message: Message): OverlayRef | undefined {
        logger.debug(`[MessageService] #post(); will post ${JSON.stringify(message)} onto stream`);
        this.#message$.next(message);
        const providers: StaticProvider[] = [{ provide: INJECT_MESSAGE_BOX_MESSAGE, useValue: message }];
        // Close any previously open message to avoid duplicate messages.
        this.closeDialog();
        this.#overlayRef = this.dialogService.openWithComponent(MessageBoxComponent, providers);
        // We return the overlayRef so that the caller can access the instance of the messagebox if needed.
        // We use that for the offline-message.directive logic.
        return this.#overlayRef;
    }

    postError(
        id: string,
        error?: Error | CustomErrorTyped,
        description = 'Das hätte nicht passieren dürfen. Bitte versuchen Sie es noch einmal.',
        header?: string,
    ): void {
        let technicalDescription: string | undefined;
        let customHeader: string | undefined;
        if (error && (error as CustomErrorTyped).type) {
            technicalDescription = `${(error as CustomErrorTyped).type}: ${error.message}`;
        } else if (error && error.message) {
            technicalDescription = error.message;
        } else if (header) {
            customHeader = header;
        }

        const message: Message = { message: description, errorId: id, technicalDescription, header: customHeader };
        this.post(message);
    }

    closeDialog(): void {
        this.#overlayRef?.dispose();
        this.#overlayRef = undefined;
    }
}
