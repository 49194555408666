import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { USERSNAP_API_KEY } from '@idr/ui/consts';
import { REALM, SettingsService } from '@idr/ui/shared';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { UsersnapApi } from '../components/usersnap/usersnap-api';

/**
 * Just to avoid typescript complains about "onUsersnapLoad" not existing in "window"
 */
declare global {
    interface Window {
        // eslint-disable-next-line functional/prefer-readonly-type
        onUsersnapLoad: (api) => void;
    }
}

/**
 * A basic logic for loading usersnap script to angular is taken from https://github.com/usersnap/public/tree/master/angular/src/app
 * The loading of usersnap, and it's basic functionality is tested with e2e tests. Thus, we don't have any unit tests for this service.
 */
@Injectable({ providedIn: 'root' })
export class UsersnapApiService {
    public readonly isReady$: Observable<boolean>;

    // This is required to show the feedback button ONLY when the api is initialized.
    // This will prevent timing issues that can occur by clicking the feedback button before the api is initialized.
    // This scenario is highly unlikely to occur in real app usage, but it's better to be safe than sorry since that happens in e2e tests.
    private readonly _isReady$: Subject<boolean> = new ReplaySubject(1);

    private usersnapApi: UsersnapApi;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(REALM) private readonly realm: string,
        private readonly settingsService: SettingsService,
    ) {
        this.isReady$ = this._isReady$.asObservable();
    }

    private get usersnapScriptTag(): HTMLScriptElement {
        const script = document.createElement('script');
        script.id = 'usersnapScript'; // this is used for e2e tests
        script.defer = false;
        script.type = 'text/javascript';
        // This script will call the onUsersnapLoad method from the "window" instance that we registered in init(),
        // thus making the usersnapAPI available to our class.
        script.src = `https://widget.usersnap.com/global/load/${USERSNAP_API_KEY}?onload=onUsersnapLoad`;
        return script;
    }

    public init(): void {
        // We avoid loading the usersnap API again
        if (this.usersnapApi) {
            return;
        }
        logger.debug('[UsersnapApiService] init');
        // We are attaching a method that we register as a callback after the usersnap script is loaded in the "window" instance.
        // This has to be injected before adding the script tag that loads usersnap.
        // IMPORTANT: { useSystemFonts: true } deactivates loading of google fonts.
        // https://help.usersnap.com/docs/api-for-usersnap-project
        this.document.defaultView.window.onUsersnapLoad = (api: UsersnapApi) => {
            logger.debug('[UsersnapApiService] init.onUsersnapLoad ->', api);
            api.init({
                useSystemFonts: true,
                // In Usersnap we can pass any key-value pair under the user object.
                // Based on those key-values we can target the audience of a widget.
                // https://help.usersnap.com/docs/targeting#targeting-segments
                user: {
                    authRealm: this.realm,
                },
            });
            this.usersnapApi = api;
            this._isReady$.next(true);
            this._isReady$.complete();
        };
        this.document.body.append(this.usersnapScriptTag);
    }

    public openFeedbackMenu(): void {
        this.sendEvent(this.settingsService.usersnapActivationEventName);
    }

    private sendEvent(event: string): void {
        logger.debug('[UsersnapApiService] sendEvent ->', event);
        // logEvent might be a confusing name -> it actually dispatches an event that triggers an action that we have defined.
        this.usersnapApi?.logEvent(event);
    }
}
