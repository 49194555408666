<idr-product-logo
    *ngIf="openedAndAnimationDone"
    role="presentation" />
<idr-navigation-items [opened]="openedAndAnimationDone" />
<hg-wing-panel-button
    *rxLet="wingPanelTooltip$; let wingPanelButtonTooltipContent"
    gtmArea="navigation"
    [attr.aria-label]="wingPanelButtonTooltipContent"
    [matTooltip]="wingPanelButtonTooltipContent"
    matTooltipPosition="right"
    [position]="'left'"
    [opened]="opened"
    (toggled)="onToggleWingPanelButton($event)" />
