import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { logger } from '@idr/shared/utils';
import { catchError, finalize, tap } from 'rxjs/operators';

/**
 * Just copy & pasted https://angular.io/guide/http#using-interceptors-for-logging for the sake of debugging...
 *
 * (only active in development mode)
 *
 * @see CoreModule
 */
export const LoggingInterceptor: HttpInterceptorFn = (request, next) => {
    logger.debug(`[LoggingInterceptor] intercept START -> ${request.method} "${request.urlWithParams}"`);
    const started: number = Date.now();
    let requestStatus: string;

    // extend server response observable with logging
    return next(request).pipe(
        tap(event => (requestStatus = event instanceof HttpResponse ? 'Succeeded' : '')),
        catchError(error => {
            logger.error('[LoggingInterceptor] intercepted request failed ERROR ->', error);
            requestStatus = 'Failed';
            throw error;
        }),
        // Log when response observable either completes or errors
        finalize(() => {
            const elapsed: number = Date.now() - started;
            const msg = `[LoggingInterceptor] intercept END -> ${request.method} "${request.urlWithParams}": ${requestStatus} in ${elapsed}ms.`;
            logger.debug(msg);
        }),
    );
};
