import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { getGtmId, GtmAction, GtmArea } from './models';
import { GtmValues } from './models/gtm-values';

@Directive({
    selector: '[hgGtmId]',
    standalone: true,
})
export class GtmIdDirective implements OnChanges {
    @Input({ required: true }) hgGtmId: [area: GtmArea, action: GtmAction, description: string] | undefined;
    @Input() hgGtmValues: GtmValues | undefined | string;

    constructor(private readonly el: ElementRef<HTMLElement>) {
        this.render();
    }

    ngOnChanges(): void {
        this.render();
    }

    private render() {
        this.setGtmValueAttributes();
        if (this.hgGtmId) {
            this.el.nativeElement.setAttribute('data-id', getGtmId(...this.hgGtmId));
        }
    }

    private setGtmValueAttributes() {
        if (!this.hgGtmValues) {
            return;
        }

        Object.entries(this.hgGtmValues).forEach(([key, value]) => {
            this.el.nativeElement.setAttribute(`data-${key}`, value);
        });
    }
}
