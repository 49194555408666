import { BauplanId } from '../bauplan';
import { CrsContentHierarchyPathsDTO, CrsHierarchyPathDTO } from '../crs';

export class Breadcrumb {
    /**
     * @param linkIndex for example: "LI10396953"
     * @param title for example: "Fachbeiträge"
     */
    constructor(
        public readonly linkIndex: BauplanId,
        public readonly title: string,
    ) {}

    /**
     * @returns Breadcrumb[] or [] if no hierarchyPath available
     */
    public static fromJson(contentHierarchyPaths: CrsContentHierarchyPathsDTO): Breadcrumb[] {
        if (!contentHierarchyPaths.hierarchyPath) {
            return [];
        }
        const hierarchyPath: CrsHierarchyPathDTO = contentHierarchyPaths.hierarchyPath[0];
        return hierarchyPath.node.map(node => new Breadcrumb(node.id, node.title));
    }
}
