import { ChangeDetectionStrategy, Component, computed, Inject, Signal, TemplateRef } from '@angular/core';
import { MatOption } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
// eslint-disable-next-line barrel-files/avoid-importing-barrel-files
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { GroupId, HasName } from '@idr/model/config';
import { HasProductId } from '@idr/shared/model';
import { ConfirmDialog, ConfirmDialogOptions } from '@idr/ui/shared';
import { firstValueFrom } from 'rxjs';
import {
    ACTIVATED_PRODUCT_GROUP,
    ActivatedProductGroup,
    ActivatedProductGroupSignal,
} from '../../routing/activated-product-group';
import { ConfigNavigator } from '../../routing/config-navigator';
import { ConfigActions } from '../../services/config-actions';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'mc-footer',
    standalone: true,
    imports: [MatSelect, MatTooltip, MatOption],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
})
export class FooterComponent {
    readonly products: Signal<(HasProductId & HasName)[]>;

    readonly active: Signal<ActivatedProductGroup>;

    constructor(
        private readonly dialog: MatDialog,
        @Inject(ACTIVATED_PRODUCT_GROUP) active: ActivatedProductGroupSignal,
        public readonly navigator: ConfigNavigator,
        private readonly actions: ConfigActions,
    ) {
        // We cast away the `undefined` case here, since it won't happen for us
        // `FooterComponent` is only shown for draft groups => in that case the active group won't be undefined.
        this.active = active as Signal<ActivatedProductGroup>;
        this.products = computed(() => this.active().products);
    }

    get #groupId(): GroupId {
        return this.active().groupId;
    }

    async #executeAfterConfirm(
        operation: keyof Pick<ConfigActions, 'delete' | 'publish' | 'reset'>,
        confirmDialogOptions: ConfirmDialogOptions,
    ): Promise<void> {
        const dialogRef = this.dialog.open(ConfirmDialog, { data: confirmDialogOptions });
        const submit = await firstValueFrom(dialogRef.afterClosed());
        if (!submit) {
            return;
        }

        await this.actions[operation](this.#groupId);
    }

    async delete(message: TemplateRef<unknown>): Promise<void> {
        await this.#executeAfterConfirm('delete', {
            dangerousSubmit: true,
            headline: 'Löschen',
            submitLabel: 'Löschen',
            message,
        });
    }

    async publish(message: TemplateRef<unknown>): Promise<void> {
        await this.#executeAfterConfirm('publish', {
            headline: 'Live schalten',
            submitLabel: 'Live schalten',
            message,
        });
    }

    async reset(message: TemplateRef<unknown>): Promise<void> {
        await this.#executeAfterConfirm('reset', {
            dangerousSubmit: true,
            headline: 'Zurücksetzen',
            submitLabel: 'Zurücksetzen',
            message,
        });
    }
}
