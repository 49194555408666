import { Inject, Injectable } from '@angular/core';
import { logger } from '@idr/shared/utils';
import {
    ContentHubAuthor,
    ContentHubQueryOptions,
    ContentHubSearchSortBy,
    NewsDocument,
    NewsDocumentsWrapper,
} from '@idr/ui/content-hub';
import { NavigationService } from '@idr/ui/navigation';
import { ActiveProduct, REALM, Realm, RouterEvents } from '@idr/ui/shared';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ContentHubContentService } from '../../content-hub/services/content-hub-content.service';
import { ContentHubService } from '../../core/contenthub/content-hub.service';
import { DocumentHistory } from '../../core/history/document-history';
import { ReadNewsService } from './read-news.service';

export const NEWS_DOCUMENTS_LIMIT = 20;

@Injectable({ providedIn: 'root' })
export class NewsService extends ContentHubContentService<NewsDocument> {
    public readonly isLoadingMore$: Observable<boolean>;
    public readonly newsDocument$: Observable<NewsDocument>;
    // This is only used in the list view
    public readonly newsDocumentsWrapper$: Observable<NewsDocumentsWrapper>;
    // offset and limit are used to lazy load more documents if needed (used for news)
    private readonly _isLazyLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private readonly _offset$: BehaviorSubject<number> = new BehaviorSubject(1);
    // This serves as a stack for the loaded documents to provide the lazy loading functionality
    private loadedNewsDocumentsWrapper: NewsDocumentsWrapper;
    private readonly logPrefix = '[NewsService]';

    constructor(
        navigationService: NavigationService,
        routerEvents: RouterEvents,
        readNewsService: ReadNewsService,
        activeProduct: ActiveProduct,
        documentHistory: DocumentHistory,
        @Inject(REALM) realm: Realm,
        private readonly contentHubService: ContentHubService,
    ) {
        super(
            navigationService,
            routerEvents,
            contentHubService,
            NewsDocument.fromDocumentDTO,
            activeProduct,
            documentHistory,
            realm,
        );

        this.isLoadingMore$ = this._isLazyLoading$.asObservable();

        this.newsDocument$ = this.contentHubDocument$.pipe(
            switchMap(newsDocument => this.insertAuthors$(newsDocument)),
            switchMap(newsDocument => this.insertImage$(newsDocument)),
            tap(newsDocument => readNewsService.markNewsItemAsRead(newsDocument)),
            shareReplay(1),
        );

        this.newsDocumentsWrapper$ = this._offset$.pipe(
            // We have to wait until the previous request has finished before we can load more documents
            // That could be triggered if the user scrolls down to the bottom of the page multiple times while the previous request is still loading
            filter(() => this._isLazyLoading$.value === false),
            tap(() => this._isLazyLoading$.next(true)),
            // We have to sort the news by date descending
            map(offset => ({ sortBy: ContentHubSearchSortBy.SORT_DATE_DESC, offset, limit: NEWS_DOCUMENTS_LIMIT })),
            switchMap((options: ContentHubQueryOptions) => contentHubService.requestNewsDocuments$(options)),
            map(newsDocumentsWrapper => this.mergeNewsDocumentsWrapper(newsDocumentsWrapper)),
            tap(newsDocumentsWrapper => (this.loadedNewsDocumentsWrapper = newsDocumentsWrapper)),
            tap(() => this._isLazyLoading$.next(false)),
            catchError(error => {
                this._isLazyLoading$.next(false);
                logger.error(this.logPrefix, 'newsDocumentsWrapper$ -> Error loading documents', error);
                return of({ error });
            }),
            shareReplay(1),
        );
    }

    public loadMoreDocuments(): void {
        if (this._offset$.value >= this.loadedNewsDocumentsWrapper?.documents?.totalCount) {
            // There are no more docs to load
            return;
        }

        this._offset$.next(this._offset$.value + NEWS_DOCUMENTS_LIMIT);
    }

    private insertAuthors$(newsDocument: NewsDocument): Observable<NewsDocument> {
        if (!newsDocument?.newsAuthorsLinks) {
            return of(newsDocument);
        }

        const newsAuthors$: Observable<ContentHubAuthor>[] = newsDocument.newsAuthorsLinks.map(authorLink =>
            this.contentHubService.requestContentHubAuthors$(authorLink),
        );

        return forkJoin(newsAuthors$).pipe(
            map((newsAuthors: ContentHubAuthor[]) => {
                return {
                    ...newsDocument,
                    newsAuthors,
                } as NewsDocument;
            }),
            catchError(error => {
                logger.error('[NewsPageService] -> insertAuthors$ Error loading authors', error);
                // If there is an error fetching the authors we just return the newsDocument without them to avoid breaking the app.
                return of(newsDocument);
            }),
        );
    }

    /**
     * 1. we request the image of the news
     * 2. baselineContent consist of to section <div class="chb-teaser"> and <div class="chb-text">
     * 3. we insert the image between chb-teaser and chb-text (inside p for styling)
     *    or if the sections are not available append the image to the end.
     */
    private insertImage$(newsDocument: NewsDocument): Observable<NewsDocument> {
        if (!newsDocument?.image) {
            return of(newsDocument);
        }
        return this.contentHubService.requestContentHubImage$(newsDocument.image).pipe(
            map(newsImage => {
                const baselineContent: string = newsDocument.baselineContent;
                if (!baselineContent) {
                    return newsDocument;
                }

                const imgTag = `<p><img src="${newsImage.url}" alt="${newsImage.altText}"></p>`;
                const textOpeningTag = '<div class="chb-text">';

                // Image should be inserted before div with chb-text class if it is available
                const imageShouldBeEmbedded: boolean = baselineContent.includes(textOpeningTag);
                if (imageShouldBeEmbedded) {
                    return {
                        ...newsDocument,
                        baselineContent: baselineContent.replace(textOpeningTag, `${imgTag}${textOpeningTag}`),
                    } as NewsDocument;
                }

                // There is no such div tag => append the image to the end
                return {
                    ...newsDocument,
                    baselineContent: `${baselineContent}${imgTag}`,
                } as NewsDocument;
            }),
        );
    }

    private mergeNewsDocumentsWrapper(newsDocumentsWrapper: NewsDocumentsWrapper): NewsDocumentsWrapper {
        // For the first load we don't have any previous docs to merge with
        if (!this.loadedNewsDocumentsWrapper) {
            return newsDocumentsWrapper;
        }

        return {
            error: newsDocumentsWrapper.error,
            documents: {
                ...this.loadedNewsDocumentsWrapper.documents,
                entries: [
                    ...this.loadedNewsDocumentsWrapper.documents.entries,
                    ...newsDocumentsWrapper.documents.entries,
                ],
            },
        };
    }
}
