import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { logger } from '@idr/shared/utils';
import { filter, map, Observable, take, tap } from 'rxjs';
import { ConfigModel } from '../model/config-model';

export const GroupExistsGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
    const router = inject(Router);
    const model = inject(ConfigModel);
    const groupId = route.params['groupId'];

    return model.productGroups$.pipe(
        filter(groups => groups.length > 0),
        take(1),
        map(groups => groups.some(group => group.groupId === groupId)),
        tap(groupExists => {
            if (!groupExists) {
                logger.warn("[GroupExistsGuard] -> routed group doesn't exist:", groupId);
                router.navigate(['/error']);
            }
        }),
    );
};
