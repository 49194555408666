import { Injectable } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { catchError, firstValueFrom, map, Observable, of, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { UserSettingsService } from '../user-settings/user-settings.service';

export const DEFAULT_FONT_SIZE = 3;

export const MAX_FONT_SIZE = 5;

export const MIN_FONT_SIZE = 1;

export type FontSize = 1 | 2 | 3 | 4 | 5;

@Injectable({ providedIn: 'root' })
export class FontSizeService {
    public readonly fontSize$: Observable<FontSize>;

    private readonly _fontSize$: ReplaySubject<FontSize> = new ReplaySubject(1);

    private readonly logPrefix = '[FontSizeService]';

    constructor(private readonly userSettings: UserSettingsService) {
        this.fontSize$ = this._fontSize$.pipe(
            tap(fontSize => logger.debug(this.logPrefix, 'fontSize$ ->', fontSize)),
            distinctUntilChanged(),
            shareReplay(1),
        );

        this.userSettings.settings$
            .pipe(
                take(1),
                map(settings => {
                    const fontSize = settings?.document?.fontSize;
                    if (!fontSize) {
                        return DEFAULT_FONT_SIZE;
                    }
                    if (fontSize < MIN_FONT_SIZE || fontSize > MAX_FONT_SIZE) {
                        logger.warn(
                            this.logPrefix,
                            '-> fontSize from settings is out of range. Falling back to default.',
                        );
                        return DEFAULT_FONT_SIZE;
                    }
                    return fontSize as FontSize;
                }),
                catchError(error => {
                    logger.error(
                        this.logPrefix,
                        '-> failed to get state from API, falling back to default value',
                        error,
                    );
                    return of(DEFAULT_FONT_SIZE);
                }),
                tap(fontSize => {
                    logger.debug(this.logPrefix, '-> initState', fontSize);
                    this._fontSize$.next(fontSize as FontSize);
                }),
            )
            .subscribe();
    }

    public async increaseFontSize(): Promise<void> {
        return firstValueFrom(
            this.fontSize$.pipe(
                take(1), // Taking 1 from the fontSize$ to avoid side effects since we are updating it within this method.
                map(fontSize => fontSize + 1),
                switchMap(updatedFontSize =>
                    updatedFontSize > MAX_FONT_SIZE ? of(void 0) : this.updateFontSize$(updatedFontSize as FontSize),
                ),
            ),
        );
    }

    public async decreaseFontSize(): Promise<void> {
        return firstValueFrom(
            this.fontSize$.pipe(
                take(1), // Taking 1 from the fontSize$ to avoid side effects since we are updating it within this method.
                map(fontSize => fontSize - 1),
                switchMap(updatedFontSize =>
                    updatedFontSize < MIN_FONT_SIZE ? of(void 0) : this.updateFontSize$(updatedFontSize as FontSize),
                ),
            ),
        );
    }

    private updateFontSize$(fontSize: FontSize): Observable<void> {
        this._fontSize$.next(fontSize);
        return this.userSettings.updateSettings$({ document: { fontSize: fontSize } }).pipe(
            take(1),
            map(() => void 0),
            // No need to catch error because the user settings service already does that.
        );
    }
}
