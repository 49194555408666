import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ElementWithTitle {
    readonly title: string;
}

export const duplicateTitleValidator =
    (elements$: Observable<ElementWithTitle[]>): AsyncValidatorFn =>
    (control: AbstractControl) =>
        ifTitleIsExist(control.value, elements$);

const ifTitleIsExist = (
    newName: string,
    elements$: Observable<ElementWithTitle[]>,
): Observable<ValidationErrors | null> => {
    return elements$.pipe(
        map(elements => {
            const duplicateName = elements.some(element => element.title === newName);
            if (duplicateName) {
                return { duplicateNameError: true };
            }
            return null;
        }),
    );
};
