import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    ElementRef,
    HostListener,
    Inject,
    Signal,
    ViewChild,
} from '@angular/core';
import { IS_NOT_CONFIGURED, ProductConfiguration, UploadedFile } from '@idr/model/config';
import { Brand, BrandId, ProductLogo } from '@idr/shared/model';
import { CURRENT_PRODUCT_CONFIGURATION_TOKEN } from '@idr/ui/services';
import {
    ActiveProduct,
    CommonNavigationService,
    CompanyLogoComponent as LegacyCompanyLogoComponent,
} from '@idr/ui/shared';
import { getGtmId } from '@idr/ui/tracking';
import { RxIf } from '@rx-angular/template/if';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CompanyLogoComponent } from '../company-logo/company-logo.component';

/**
 * Combines the company logo with the product name to the "productLogo".
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'data-id': getGtmId('logo', 'open', 'startPage'),
    },
    imports: [RxIf, LegacyCompanyLogoComponent, CompanyLogoComponent],
    selector: 'idr-product-logo',
    standalone: true,
    styleUrls: ['./product-logo.component.scss'],
    templateUrl: './product-logo.component.html',
})
export class ProductLogoComponent {
    /**
     * Used for reducing size of the product name if needed (for too long product names).
     * (see template ... sizing is done based on a svg...)
     */
    @ViewChild('renderedProductName') renderedProductName?: ElementRef;

    readonly brandId: Signal<BrandId | undefined>;

    readonly partnerLogo: Signal<UploadedFile | undefined>;

    readonly productNameSize$: Observable<{ height: number; width: number }>;

    readonly productName: Signal<string | undefined>;

    /**
     * @deprecated will be removed when we can rely fully on the new myDesk configuration
     */
    readonly brand: Signal<Brand | undefined>;

    /**
     * @deprecated will be removed when we can rely fully on the new myDesk configuration
     */
    readonly productLogo: Signal<ProductLogo | undefined>;

    /**
     * @deprecated will be removed when we can rely fully on the new myDesk configuration
     */
    readonly requiresLegacyConfig: Signal<boolean | undefined>;

    constructor(
        @Inject(CURRENT_PRODUCT_CONFIGURATION_TOKEN) productConfiguration: Signal<ProductConfiguration | undefined>,
        activeProduct: ActiveProduct,
        cdr: ChangeDetectorRef,
        private readonly commonNavigation: CommonNavigationService,
    ) {
        this.productName = computed(() => activeProduct.productSignal()?.name);

        this.productNameSize$ = activeProduct.product$.pipe(
            // FIXME this might not be the case anymore now since the template is signal based...
            // change detection is needed ... otherwise the productname isn't rendered yet and we would end up with corrupt sizes
            tap(() => cdr.detectChanges()),
            map(() => {
                return {
                    height: this.renderedProductName?.nativeElement?.offsetHeight,
                    width: this.renderedProductName?.nativeElement?.offsetWidth,
                };
            }),
        );

        this.brandId = computed(() => productConfiguration()?.group?.brandId);

        this.partnerLogo = computed(() => {
            const candidates: UploadedFile[] = productConfiguration()?.group?.partnerLogos ?? [];
            for (const candidate of candidates) {
                // at this point of time we have an active product, so we can safely cast to it
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if (candidate.products?.includes(activeProduct.id!)) {
                    return candidate;
                }
            }
            return undefined;
        });

        this.brand = computed(() => activeProduct.productSignal()?.brand);
        this.productLogo = computed(() => activeProduct.productSignal()?.productLogo);
        this.requiresLegacyConfig = computed(() => {
            const config = productConfiguration();
            return config === undefined ? undefined : config.state === IS_NOT_CONFIGURED;
        });
    }

    @HostListener('click')
    goToStartPage(): void {
        this.commonNavigation.toStartpage();
    }
}
