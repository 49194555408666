<hg-loading-wheel *rxIf="loading$" />
@if (inDraftMode()) {
    <mc-draft-banner />
}
<div
    idrTabKey
    idrBranding
    idrMessage
    idrOfflineMessage
    idrEnvironment
    idrVisitCount
    idrUserFeedback
    idrNps
    idrDebugGtmIds
    [attr.aria-busy]="busy$ | push">
    <router-outlet />
</div>

<idr-usersnap />
