import { inject, Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { logger } from '@idr/shared/utils';
import { REALM, Realm, urlWithRealmQueryParam } from '../services';

const logPrefix = '[WithRealmInHtmlPipe]';

@Pipe({
    name: 'hgWithRealmInHtml',
    standalone: true,
})
/**
 * Parses the given html string and adds the realm to the href attribute of the anchor tags.
 */
export class WithRealmInHtmlPipe implements PipeTransform {
    readonly #realm: Realm = inject(REALM);

    transform(value: SafeHtml): SafeHtml {
        const parser = new DOMParser();
        const doc = parser.parseFromString(value.toString(), 'text/html');
        const anchorTags = doc.getElementsByTagName('a');
        for (const anchorTag of anchorTags) {
            const href = anchorTag.getAttribute('href');
            if (href) {
                const transformed = urlWithRealmQueryParam(href, this.#realm);
                anchorTag.setAttribute('href', transformed);
                logger.debug(logPrefix, '-> found in search params', href, '->', transformed);
            }
        }
        return doc.body.innerHTML as SafeHtml;
    }
}
