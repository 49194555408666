import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

export const CACHE_INSTANCE = new InjectionToken<Cache<unknown>>('CACHE_INSTANCE');

export interface Cache<T> {
    // eslint-disable-next-line functional/prefer-readonly-type
    [key: string]: [Date | undefined, T];
}

const isExpired = (expiresIn: Date | undefined): boolean => {
    // expiresIn may be undefined because if the value was set with no `timeToLive` we store undefined for this value
    /** @see set */
    if (!expiresIn) {
        return false;
    }
    const now: Date = new Date();
    return expiresIn.getTime() < now.getTime();
};

/**
 * This is a simple straight forward cache based on a map.
 * Besides key and value it also stores date value that is used for internal purging logic.
 *
 * @see https://nrempel.com/guides/angular-httpclient-httpinterceptor-cache-requests/
 *
 * @see get
 * @see set
 */
@Injectable({ providedIn: 'root' })
export class CacheService<T> {
    /** it stores for each key a tuple with the "expire" date and the value */
    readonly #cache: Cache<T>;

    constructor(@Optional() @Inject(CACHE_INSTANCE) cache: Cache<T>) {
        this.#cache = cache ?? {};
    }

    /**
     * @return your value for given key if something related was stored in the cache
     *         you get `undefined` if nothing for given key was found
     */
    get(key: string): T | undefined {
        const tuple = this.#cache[key];
        if (tuple === undefined) {
            return undefined;
        }

        const expiresIn = tuple[0];
        const value: T = tuple[1];

        if (isExpired(expiresIn)) {
            delete this.#cache[key];
            return undefined;
        }

        return value;
    }

    /**
     * Will dive into the cache and look for expired values.
     * Any found expired value will be removed from the cache.
     */
    purge(): void {
        for (const key of Object.keys(this.#cache)) {
            const expiresIn = this.#cache[key][0];
            if (isExpired(expiresIn)) {
                delete this.#cache[key];
            }
        }
    }

    /**
     * Adds/updates given value in relation to given key.
     *
     * @param timeToLiveInSeconds (optional) if not set, a default will be used
     *        set it to `null` if you don't want your given value to expire
     */
    set(key: string, value: T, timeToLiveInSeconds: number = 60 /*minutes*/ * 60 /*seconds*/): void {
        if (timeToLiveInSeconds === null) {
            this.#cache[key] = [undefined, value];
        } else {
            const expires: Date = new Date();
            expires.setSeconds(expires.getSeconds() + timeToLiveInSeconds);
            this.#cache[key] = [expires, value];
        }
    }
}
