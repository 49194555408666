import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'hg-label',
    standalone: true,
    styleUrls: ['./label.component.scss'],
    template: '<ng-content/>',
})
export class LabelComponent {}
