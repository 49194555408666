import {
    ContentHubContentLayout,
    ContentHubDocumentDTO,
    DocumentLink,
    HotConfig,
    MetaDTO,
    NewsConfig,
} from '@idr/shared/model';
import { ContentHubDocument } from '@idr/ui/content-hub';
import { NavigationService } from '@idr/ui/navigation';
import { ActiveProduct, Realm, RouterEvents } from '@idr/ui/shared';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ContentHubService } from '../../core/contenthub/content-hub.service';
import { DocumentHistory } from '../../core/history/document-history';

type ContentHubDocTransformer<ContentHubDocumentType> = (
    contentHubDocument: ContentHubDocumentDTO<Partial<MetaDTO>>,
    realm: Realm,
    config?: HotConfig | NewsConfig,
) => ContentHubDocumentType;

export abstract class ContentHubContentService<ContentHubDocumentType extends ContentHubDocument> {
    public readonly isFullView$: Observable<boolean>;
    public readonly layout$: Observable<ContentHubContentLayout>;
    protected readonly contentHubDocument$: Observable<ContentHubDocumentType>;

    protected constructor(
        navigationService: NavigationService,
        routerEvents: RouterEvents,
        contentHubService: ContentHubService,
        contentHubDocTransformer: ContentHubDocTransformer<ContentHubDocumentType>,
        activeProduct: ActiveProduct,
        documentHistory: DocumentHistory,
        realm: Realm,
        config$: Observable<HotConfig | NewsConfig> = of(undefined),
    ) {
        this.layout$ = routerEvents.routeParams$.pipe(
            map(params => params.layout),
            distinctUntilChanged(),
            // making sure that the given layout param is valid, otherwise we use the page layout
            map(layout =>
                Object.keys(ContentHubContentLayout).includes(layout) ? layout : ContentHubContentLayout.page,
            ),
            shareReplay(1),
        );

        this.isFullView$ = this.layout$.pipe(map(layout => layout === ContentHubContentLayout.full));
        const contentHubId$ = navigationService.contentHubId$.pipe(filter(NavigationService.isValidContentHubId));

        this.contentHubDocument$ = combineLatest([contentHubId$, config$]).pipe(
            switchMap(([contentHubId, config]) =>
                contentHubService.requestContentHubDocument$(contentHubId).pipe(
                    // This transformer function will return either a HotDocument or a NewsDocument
                    map(dto => contentHubDocTransformer(dto, realm, config)),
                ),
            ),
            tap(contentHubDocument => {
                documentHistory.add(
                    new DocumentLink(
                        activeProduct.product.id,
                        contentHubDocument.contentHubId,
                        contentHubDocument.title,
                        contentHubDocument.classification,
                    ),
                );
            }),
            // We should avoid adding shareReplay(1) here, because the following scenario has issues:
            // 1. User searches for a term
            // 2. User selects news category from search results -> the first news document loads
            // 3. User selects another news search hit -> the contentHubId is updated on the url
            // 4. Preview tries to render again and uses the contentHubDocument$ but because of the "cached" value, if we have shareReplay(1) here,
            //    it will temporarily render the old news document, and then it will re-render with the new contentHubId when the pipe runs through.
        );
    }
}
