import { ContentHubDocumentDTO, NewsMetaDTO } from '@idr/shared/model';
import { getFirstValueFrom } from '@idr/shared/utils';

export class ContentHubImage {
    private constructor(
        public readonly altText: string,
        public readonly url: string,
    ) {}

    public static fromDTO(dto: ContentHubDocumentDTO<NewsMetaDTO>): ContentHubImage {
        const meta = dto['ch:haufe-document']['ch:meta'];
        return new ContentHubImage(
            meta['pr:altText']?.content as string,
            getFirstValueFrom(meta['pr:externalLink'])?.content as string,
        );
    }
}
