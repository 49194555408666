/**
 * list of 'mail-enabled' domains in the haufe space
 * provided by: Keller, Alexander<alexander.keller@haufe-lexware.com> (TL Infrastructure)
 */

export const INTERNAL_EMAIL_DOMAINS = [
    'hlsc.de',
    'haufemg.com',
    'checkliste.de',
    'controlling-expo.de',
    'wundw.com',
    'wirtschaftundweiterbildung.de',
    'weiterbildungsmesse.de',
    'profirma-premium.de',
    'profirma.de',
    'personalmagazin.com',
    'wundw-premium.de',
    'haufefachmedia.de',
    'wuw-magazin.de',
    'elearning-expo.de',
    'elearning-expo.ch',
    'elearning-expo.at',
    'consultant-magazin.de',
    'call-center-expo.de',
    'callcenter-expo.de',
    'business-software-expo.de',
    'acquisa-premium.de',
    'acquisa-crm-messe.de',
    'acquisa-crm-expo.de',
    'acquisa-crmexpo.de',
    'acquisa-crm-expo.com',
    'acquisa-crm-expo.ch',
    'acquisa-crm-expo.at',
    'haufe-fachmedia.de',
    'erp-expo.de',
    'haufe-facebook-team.de',
    'immobilienwirtschaft.de',
    'lohn-gehalts-office.de',
    'fundraising-direkt.de',
    'tvoed-office.de',
    'haufe-recht.de',
    'steuer-office.de',
    'steueroffice.de',
    'sozialversicherungs-office.de',
    'sozialversicherungsoffice.de',
    'sgb-office.de',
    'Redmark.de',
    'rechnungswesen-office.de',
    'personal-office.de',
    'personal-magazin.de',
    'msofficepro.de',
    'microsoft-officepro.de',
    'lexware-akademie.de',
    'lexpool.de',
    'immobilien-office.de',
    'wirtschaftspraxis.de',
    'haufe-berlin.de',
    'Haufe-Akademie.de',
    'taschenguide.de',
    'gmbh-office.de',
    'excelpro.de',
    'controlling-office.de',
    'wrs.de',
    'lexware.hlsc.de',
    'diewohnungswirtschaft.de',
    'bookman.lexware.de',
    'haufemediengruppe.de',
    'lexlive.de',
    'verkaufsleiter-schule.de',
    'aussendienst-information.de',
    'dervertriebsspezialist.de',
    'dvs-gmbh.de',
    'dvvk.de',
    'verkaufsleiterschule.de',
    'input-institut.de',
    'haufe-lexware-service.de',
    'haufe-lexware.de',
    'BusinessShop24.de',
    'onlineschulungen.lexware.de',
    'haufe-aktuell.de',
    'haufegroup.com',
    'vermieterplus.de',
    'vdv-verlag.de',
    'vpw-verlag.de',
    'vds-verlag.de',
    'vermieternetz.de',
    'gmbhnetz.de',
    'personalplus.de',
    'steuerkanzlei.de',
    'vpw.de',
    'vcw-verlag.de',
    'vcw.de',
    'immo-expo.de',
    'personalmagazin.de',
    'steuer-consultant.de',
    'BusinessSofortKontakt.de',
    'consultant.de',
    'taxman.de',
    'softuse.de',
    'soft-use.de',
    'buchfuehrung-office.de',
    'arbeitssicherheit-office.de',
    'arbeitsschutz-office.de',
    'lexstart.de',
    'lexware.de',
    'grp.haufemg.com',
    'haufe.de',
    'on-hl.com',
    'haufegruppe.mail.onmicrosoft.com',
    'Haufe-Akademie.com',
    'talent-management-gipfel.de',
    'hammonia.de',
    'lexware-vor-ort.de',
    'haufe-gruppe.de',
    'haufe-group.com',
    'meine-firma-und-ich.de',
    'haufe.com',
    'haufe-hammonia.de',
    'personal-quarterly.de',
    'personalquarterly.de',
    'lexware.com',
    'quickimmobilie.de',
    'quicksteuer.lexware.de',
    'cc.haufe-lexware.com',
    'pr.dvvk.de',
    'pr.haufe-akademie.de',
    'pr.haufe-lexware.com',
    'haufe-lexware.com',
    'exchangedelegation.haufe-lexware.com',
    'lexware-pay.de',
    'haufe-newtimes.de',
    'steuern.de',
    'lexware-verein.net',
    'schaeffer-poeschel.de',
    'neosales.de',
    'vereinskult.de',
    'haufe-kongress.de',
    'semigator.de',
    'haufe-gruppe.com',
    'talent-management-gipfel.com',
    'mediasales.haufe-lexware.com',
    'axera.de',
    'snow.haufe-lexware.com',
    'archivierung.lexware.de',
    'karriereboost.de',
    'sociapply.de',
    'haufe-lexware.io',
    'steuerklassen.net',
    'adressplus.lexware.de',
    'lexbizz.de',
    'haufegroup.mail.onmicrosoft.com',
    'zevj.haufe.com',
    'dokumentenportal.online',
    'quicksteuer.de',
    'erp.com.de',
    'lohnabrechnung-software.com',
    'jira.haufe.com',
    'xn--lexffice-p4a.de',
    'lsl.de',
    'lslboston.com',
    'corporatebookstore.de',
    'sf.haufe-akademie.de',
    'advent.lexware.com',
    'hr-proplus.de',
    'haufe-advisory.com',
    'av.haufe.com',
    'test.haufe.com',
    'schaeffer-poeschel.haufe.de',
    'b2b-media-alliance.com',
    'b2b-media-alliance.de',
    'pega.haufe-lexware.com',
    'lexrocket.de',
    'career.haufegroup.com',
    'careers-test.haufegroup.com',
    'haufe.io',
    'service.haufe.de',
    'service.lexware.de',
    'hgops.de',
    'haufe-lexware.org',
    'doo.haufe-lexware.com',
    'haufe-advisory.net',
    'zeugnismanager.haufe.de',
    'haufe.group',
    'smartsteuer.de',
    'vlppscontrol01.haufe-ep.de',
    'haufe-ep.de',
    'lexfree.de',
    'advolux.haufe.de',
    'service.schaeffer-poeschel.de',
    'mediasales.haufe.de',
    'umfrage.haufe.de',
    'haufe-x360.de',
    'haufe-greenestate.de',
    'softwarevergleich.de',
    'inside.haufe.group',
    'work-in-progress.film',
    'skills.haufe-lexware.com',
    'mybookplus.de',
    'neues-lernen.org',
    'lexoffice-endorser.de',
    'axera.de',
    'SFchatter.haufe-lexware.com',
    'lexoffice-coaches.de',
    'breakfree.lexware.de',
    'erechnung.haufe-lexware.com',
    'intalento.de',
    'hre.haufe.de',
    'rudolf.ai',
];
