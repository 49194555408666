import {
    createDocumentClassificationFromDto,
    CrsDocumentDTO,
    DocumentId,
    DocumentProvider,
    OdinDocumentState,
    ProductId,
} from '@idr/shared/model';
import { CommentedDocumentLink, DocumentMeta, IDeskDocument, Toc } from '@idr/ui/document';
import { createBookInfoFromCrsDocument } from './create-book-info';

export const createDocumentMeta = (
    productId: ProductId,
    documentDto: CrsDocumentDTO,
    root: IDeskDocument,
    toc: Toc,
    id: DocumentId,
    /**
     * This is only used when the document meta has a document state defined.
     */
    state: OdinDocumentState | undefined,
): DocumentMeta => ({
    authorIds: documentDto.authorid,
    bookInfo: createBookInfoFromCrsDocument(documentDto),
    citation: documentDto.citation,
    classification: createDocumentClassificationFromDto(documentDto.docclass, documentDto.bookInfo !== undefined),
    commentedDocumentLink: getCommentedDocumentLink(documentDto),
    id,
    title: documentDto.title,
    productId,
    provider: DocumentProvider.fromJson(documentDto.providerid),
    root,
    toc,
    state,
    updateInfo: documentDto.updateInfo,
});

const getCommentedDocumentLink = (documentDto: CrsDocumentDTO): CommentedDocumentLink | undefined => {
    if (documentDto.normCommentContent?.commentedDocument) {
        const dto = documentDto.normCommentContent.commentedDocument;
        return {
            href: `/${dto.productid}/document/${dto.docid}`,
            label: 'Kommentierten Gesetzesstand anzeigen',
        };
    }
    if (documentDto.commentContent?.commentedDocument) {
        const dto = documentDto.commentContent.commentedDocument;
        return {
            href: `/${dto.productid}/document/${dto.docid}`,
            label:
                documentDto.docclass.category === 'VERWALTUNGSANWEISUNG'
                    ? 'Kommentierte VA anzeigen'
                    : 'Kommentiertes Urteil anzeigen',
        };
    }
    return undefined;
};
