import { ScrollDispatcher } from '@angular/cdk/overlay';
import { NgStyle } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    input,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { GtmArea, GtmIdDirective } from '@idr/ui/tracking';
import { rxEffects } from '@rx-angular/state/effects';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isClickInside } from '../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgStyle, MatTooltipModule, GtmIdDirective],
    selector: 'hg-tooltip-on-click',
    standalone: true,
    styleUrls: ['./tooltip-on-click.component.scss'],
    template: ` <button
            class="with-hitbox"
            (click)="toggleTooltip()"
            [hgGtmId]="[gtmArea(), 'open', 'tooltip']">
            <ng-content />
        </button>
        <mat-tooltip-component
            #tooltip
            [ngStyle]="{ display: tooltipIsVisible ? '' : 'none' }" />`,
})
export class TooltipOnClickComponent implements AfterViewInit, OnDestroy {
    @ViewChild('tooltip') tooltip?: MatTooltip;

    message = input.required<string>();
    gtmArea = input.required<GtmArea>();

    tooltipIsVisible = false;

    readonly #toggleTooltip$: Subject<boolean> = new Subject();

    #scrollSubscription?: Subscription;

    constructor(
        private readonly changeDetectionRef: ChangeDetectorRef,
        private readonly elementRef: ElementRef,
        private readonly scrollDispatcher: ScrollDispatcher,
    ) {
        rxEffects(({ register }) =>
            register(this.#toggleTooltip$.pipe(debounceTime(50)), visible => {
                if (visible) {
                    this.#hideTooltip();
                    return;
                }
                this.#showTooltip();
            }),
        );
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event): void {
        const clickedInside: boolean = isClickInside(this.elementRef, event);
        if (!clickedInside) {
            this.#hideTooltip();
        }
    }

    ngAfterViewInit(): void {
        // There are no message and position Input properties for the MatTooltipComponent to bind to them inside the template
        // so we have to directly assign the values to the properties of the MatTooltip instance.
        (this.tooltip as MatTooltip).message = this.message();
        (this.tooltip as MatTooltip).position = 'left';
    }

    ngOnDestroy(): void {
        this.#deregisterHideOnScroll();
    }

    toggleTooltip(): void {
        if (!this.tooltipIsVisible) {
            this.#showTooltip();
        } else {
            this.#hideTooltip();
        }
    }

    #deregisterHideOnScroll(): void {
        this.#scrollSubscription?.unsubscribe();
    }

    #hideTooltip(): void {
        this.tooltipIsVisible = false;
        this.changeDetectionRef.detectChanges();
        this.#deregisterHideOnScroll();
    }

    #registerHideOnScroll(): void {
        this.#scrollSubscription = this.scrollDispatcher.scrolled().subscribe(() => this.#hideTooltip());
    }

    #showTooltip(): void {
        this.tooltipIsVisible = true;
        this.changeDetectionRef.detectChanges();
        this.#registerHideOnScroll();
    }
}
