import { EnvironmentInjector } from '@angular/core';
import { CrsDocumentDTO } from '@idr/shared/model';
import { createIDeskDocument, IDeskDebugDocumentLinks, IDeskDocument } from '@idr/ui/document';

export const createIdeskDocumentFromMultipartDto = (
    injector: EnvironmentInjector,
    dto: CrsDocumentDTO,
    index: number | undefined,
    params?: {
        readonly query?: string;
        readonly title?: string;
    },
): IDeskDocument => {
    const debugLinks: IDeskDebugDocumentLinks = {
        self: dto.docid,
        root: dto.rootid,
        next: dto.sucDocid,
        prev: dto.preDocid,
    };

    return createIDeskDocument(injector, {
        authorIds: dto.authorid,
        citation: dto.citation,
        content: dto.text,
        id: dto.docid,
        index,
        debugLinks,
        longTitle: dto.longTitle,
        query: params?.query,
        docSize: dto.text?.length,
        // this was added for flat documents ... DON'T remove it unless you know what you are doing!!!
        title: params?.title ?? dto.title,
        // norm related stuff (specific to norm documents)
        selectedVersionTitle: dto.normContent?.fassungTitle,
        versionHistory: dto.normContent?.section?.[0]?.fassungLink,
        versionInfo: dto.normContent?.versionInfo,
    });
};
