import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Renderer2 } from '@angular/core';
import { FeatureId } from '@idr/shared/model';
import { FeatureService } from '@idr/ui/shared';
import { rxEffects } from '@rx-angular/state/effects';

/**
 * This directive enables the user to debug the GTM IDs.
 * It's based on the feature flag `debugGtmIds`.
 * In order to enable this feature you have to visit the application with the query parameter `feature-on=debugGtmIds` OR you enable it via `settings.json`.
 */
@Directive({
    selector: '[idrDebugGtmIds]',
    standalone: true,
})
export class DebugGtmIdsDirective {
    constructor(
        @Inject(DOCUMENT) document: Document,
        private readonly renderer: Renderer2,
        featureService: FeatureService,
    ) {
        rxEffects(({ register }) =>
            register(featureService.hasFeature$(FeatureId.DebugGtmIds), hasFeature => {
                if (hasFeature) {
                    this.renderer.addClass(document.body, 'debug-gtm-ids');
                    return;
                }
                this.renderer.removeClass(document.body, 'debug-gtm-ids');
            }),
        );
    }
}
