import { Injectable } from '@angular/core';
import {
    DocumentId,
    EcondaPath,
    EcondaSearchHit,
    EcondaSearchTrackingData,
    EcondaTarget,
    EcondaTargetGroup,
} from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ActiveProduct } from '@idr/ui/shared';
import { rxState } from '@rx-angular/state';
import { rxEffects } from '@rx-angular/state/effects';
import { hash } from 'immutable';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { SearchCategoryGroup } from '../../search-page/model/search-category-group';
import { CrsSearchHit } from '../../search-page/model/search-hit';
import { EmptySearchResult, HitlistSearchResult } from '../../search-page/model/search-result';
import { EcondaTrackingService } from './econda-tracking.service';

const isSameSearch = (data1: Data, data2: Data): boolean =>
    data1.hitsHash === data2.hitsHash &&
    data1.preview === data2.preview &&
    data1.newSearch?.query === data2.newSearch?.query;

type Data = {
    readonly hitsHash?: number;
    readonly path?: EcondaPath;
    readonly preview?: DocumentId;
} & EcondaSearchTrackingData;

/**
 * This service collects data from search and triggers related events in our tracking.
 *
 * Since we have in our app different places where the data is available this service contains some "waiting" logic.
 * We can only track if we have all the data present.
 *
 * @deprecated this service is obsolete if we remove econda tracking completely from our source
 */
@Injectable({ providedIn: 'root' })
export class SearchTrackingService {
    /**
     * `true` when a new search was triggered but not yet tracked
     * `true` initially and after all data for a new search was at least once present and tracked
     *
     * This is used to decide whether we need to track just a pageChange or a pageChange together with a search.
     *
     * @see EcondaTrackingService.trackPageChange
     * @see EcondaTrackingService.trackSearch
     */
    #callTrackSearch = true;

    /**
     * Relates to {@link Data.hitsHash}.
     *
     * Gets set after a tracking a search (or "only" pageChange) in combination with hits.
     */
    #lastTrackedHitsHash: number;

    /**
     * ID of cluster that was set in "pre-selection" for search (select in header beside the search input field).
     *
     * @see preselectedCluster
     */
    #preselectedCluster: string;

    readonly #state = rxState<Data>();

    constructor(
        private readonly activeProduct: ActiveProduct,
        private readonly econdaTrackingService: EcondaTrackingService,
    ) {
        econdaTrackingService.pageChange$
            .pipe(
                // every page change that isn't related to a search should reset `trackSearch`
                // so that we once we get a searchResult again we again queue a search related pageChange ....
                filter(event => !/PI\d+\/Suche\//.test(event.content)),
                tap(() => {
                    this.#callTrackSearch = true;
                    this.#lastTrackedHitsHash = undefined;
                }),
            )
            .subscribe();

        const trackIt$ = this.#state.select().pipe(
            distinctUntilChanged((previous, current) => isSameSearch(previous, current)),
            filter(state => {
                const isEmptySearch = state?.newSearch?.hitCount === 0;
                const isHitlistSearch = !!state.preview && state?.hits?.length > 0;
                return state.path !== undefined && (isEmptySearch || isHitlistSearch);
            }),
        );

        // everytime we track we need to partially reset internally the state regarding the preview
        // this is for preventing tracking inconsistent state, like e.g. former preview for different selected cluster
        this.#state.connect(trackIt$, () => {
            logger.debug('[SearchTrackingService] clear');
            return { preview: undefined };
        });

        rxEffects(({ register }) => register(trackIt$, () => this.#trackState()));
    }

    /**
     * Reserves given {@param title} for next incoming search as "preselected" cluster...
     * This service will automatically add information about the preselection with {@link EcondaSearchTrackingData.newSearch.preselectedCluster}.
     */
    set preselectedCluster(title: string) {
        const target: EcondaTarget = new EcondaTarget(EcondaTargetGroup.Header, `Suche/Vorauswahl/${title}`);
        this.econdaTrackingService.trackTarget(target);
        this.#callTrackSearch = true;
        this.#preselectedCluster = title;
    }

    set emptySearch(searchResult: EmptySearchResult) {
        logger.debug('[SearchTrackingService] emptySearch ->', searchResult);
        const query = searchResult.searchTerm;
        this.#updateState({
            path: 'Suche/Keine Treffer',
            newSearch: {
                hitCount: 0,
                query,
                ...(this.#preselectedCluster ? { preselectedCluster: this.#preselectedCluster } : {}),
            },
        });
    }

    set searchResult([categoryGroup, searchResult]: [SearchCategoryGroup, HitlistSearchResult]) {
        // contains the cluster related path of our current search
        let cluster: string;

        // when hitPath is undefined "Alle Dokumente" was requested
        if (searchResult.hitPath === undefined) {
            cluster = 'Alle Dokumente';
        } else {
            let parent: string;
            for (const category of categoryGroup.categories) {
                // this is a special case where we store the "parent" category for a selected child category that still needs to be found
                if (searchResult.hitPath.startsWith(category.hitPath) && category.hitPath !== searchResult.hitPath) {
                    parent = category.title;
                }

                if (category.hitPath === searchResult.hitPath) {
                    cluster = parent ? `${parent}/${category.title}` : category.title;
                }
            }
        }

        // if we don't have valid data we stop here
        if (cluster === undefined) {
            logger.warn(
                "[SearchTrackingService] searchResult -> found no matching category. This shouldn't happen.",
                categoryGroup.categories,
                searchResult,
            );
            return;
        }

        const query = searchResult.searchTerm;
        const hits: EcondaSearchHit[] = [];
        let hitsId = `${query}:${cluster}`;
        searchResult.items.forEach((hit, index) => {
            hitsId += `:${hit.documentId}`;
            hits.push([
                hit.documentId,
                this.activeProduct.product.id,
                query,
                cluster,
                index + 1,
                hit instanceof CrsSearchHit ? hit.classification?.description : cluster,
            ]);
        });

        const hitsHash = hash(hitsId);
        let data: Data = { hits, hitsHash };

        // we need to add also `newSearch` to data if it is a new search (`callTrackSearch` will be `true` in that case)
        if (this.#callTrackSearch) {
            // We can't trust the `totalDocCount` of current selected because that only equals the number of hits in that specific category
            // and NOT the "total" hitCount of the search in general.
            // BUT, luckily we can safely take the number from the 'Alle Dokumente' category
            const hitCount = categoryGroup.allDocumentsCategory.totalDocCount;
            data = {
                ...data,
                newSearch: {
                    hitCount,
                    query,
                    ...(this.#preselectedCluster ? { preselectedCluster: this.#preselectedCluster } : {}),
                },
            };
        }

        const path = `Suche/${cluster}`;
        logger.debug('[SearchTrackingService] searchResult -> Updating state with', path, data);
        this.#updateState({ path, ...data });
    }

    set preview(preview: DocumentId) {
        // Same value again ... let's ignore this to not trigger our `track$` pipe here
        // This is actually bad, but we need to keep it.
        // The code & its flow is built in a way where we need to protect here the internal state from being set again
        // for one and the same document.
        if (this.#state.get('preview') === preview) {
            return;
        }

        logger.debug('[SearchTrackingService] preview ->', preview);
        this.#updateState({ preview });
    }

    #updateState(state: Data): void {
        this.#state.set({ ...this.#state.get(), ...state });
    }

    #trackState(): void {
        const state = this.#state.get();

        if (this.#callTrackSearch) {
            logger.debug('[SearchTrackingService] trackState -> Tracking search for', state);
            this.econdaTrackingService.trackSearch(state.path, {
                ...(state.hits ? { hits: state.hits } : {}),
                ...(state.newSearch ? { newSearch: state.newSearch } : {}),
            });
            // we tracked the search now
            // => follow-up page changes on same search shall only track the page change
            this.#callTrackSearch = false;
            this.#lastTrackedHitsHash = state.hitsHash;
            return;
        }

        logger.debug('[SearchTrackingService] trackState -> Tracking pageChange for', state);
        const trackHits = state.hits && state.hitsHash !== this.#lastTrackedHitsHash;
        if (trackHits) {
            this.econdaTrackingService.trackPageChange(state.path, { hits: state.hits });
            this.#lastTrackedHitsHash = state.hitsHash;
        } else {
            this.econdaTrackingService.trackPageChange(state.path);
        }
    }
}
