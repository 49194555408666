import { Directive, HostBinding, Inject } from '@angular/core';
import { Environment, ENVIRONMENT } from '@idr/ui/shared';

/**
 * Adds `local` or `production` as css class to attached element (depends on which environment is active).
 *
 * @see #environment.ts
 */
@Directive({
    selector: '[idrEnvironment]',
    standalone: true,
})
export class EnvironmentDirective {
    @HostBinding('class.local') public local: boolean;

    @HostBinding('class.production') public production: boolean;

    constructor(@Inject(ENVIRONMENT) environment: Environment) {
        this.local = environment.local;
        this.production = environment.production;
    }
}
