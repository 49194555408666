import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'hgCleanContentHubSearch',
    standalone: true,
})
export class CleanContentHubSearchPipe implements PipeTransform {
    public transform(content: string): SafeHtml {
        return content.replaceAll('chb-search-highlight', '');
    }
}
