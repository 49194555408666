import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { DomService } from '../services';

/**
 * This directive scrolls a DOM element into view if the element is
 * marked with this directive and parameter [active] set to true.
 *
 * No scrolling happens if the model changes (change of [active] from false to true)
 * after the user clicked on this DOM element.
 */
@Directive({
    selector: '[hgScrollIntoView]',
    standalone: true,
})
export class ScrollIntoViewDirective implements OnChanges {
    @Input() active = false;

    /**
     * When userClick === false && this.active === true the element will be scrolled into view
     * That way when the user clicks on an element and it becomes active no scrolling should take place.
     */
    #userClick = false;

    constructor(
        private readonly el: ElementRef,
        private readonly domService: DomService,
    ) {}

    @HostListener('click')
    onClick(): void {
        this.#userClick = true;
    }

    ngOnChanges(): void {
        if (!this.#userClick && this.active) {
            // without timeout the elements below this element are not yet
            // rendered so that the height of the container is not correct
            // and element is not scrolled into view correctly.
            // OutsideZone because we don't need change detection here => better performance
            this.domService.setTimeoutOutsideAngular(() => {
                this.domService.ensureScrolledIntoView(this.el.nativeElement, 'center', 'auto');
            });
        }
        this.#userClick = false;
    }
}
