import { HttpClient } from '@angular/common/http';
import { CrsExceptionDTO } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { CONSUME_GENERIC_ERROR_HANDLER, ErrorId, MessageService } from '@idr/ui/shared';
import {
    catchError,
    filter,
    Observable,
    ObservableInput,
    ObservedValueOf,
    of,
    OperatorFunction,
    throwError,
} from 'rxjs';
import { CrsExceptionType } from '../../models/crs/crs-exception-types';

export interface FilterCrsExceptionOptions {
    /**
     * If true, the exception will be ignored (filtered out from the emitted values using the "filter" rxjs operator).
     * Furthermore no error message will be posted to the message service since the exception is ignored.
     * Note: If this is set to true, the "throwError" option will be ignored.
     */
    readonly ignoreException?: boolean;
    /**
     * This ignores only the exception with the given type.
     * If you want to ignore all exceptions, use the "ignoreException" option.
     */
    readonly ignoreExceptionType?: CrsExceptionType;
    /**
     * If true, an Error will be thrown when a CRS exception is found.
     */
    readonly throwError?: boolean;
    /**
     * Suppresses the error message popup that would be shown to the user when a specific CRS exception is found.
     * That is helpful when the error message is handled by some component (e.g. the in-doc search input component when you search with a problematic query (e.g. queries containing special symbols like +))
     */
    readonly suppressErrorMessagePopupForExceptionType?: CrsExceptionType;
}

const logPrefix = `[CrsBaseService]`;

export abstract class CrsBaseService {
    protected constructor(
        private readonly message: MessageService,
        private readonly http: HttpClient,
    ) {}

    /**
     * This is just a wrapper around the http get request that is used to communicate with the CRS.
     * It provides a way to handle the CRS exceptions that are returned in the body of the response when the status code is not 200.
     * @returns The response of the request if no exception was found, otherwise the exception object that CRS returns in the body of non 200 responses.
     */
    protected makeCrsGetRequest<CrsResponseType>(
        url: string,
        filterCrsExceptionOptions: FilterCrsExceptionOptions = {},
    ): Observable<CrsResponseType> {
        return this.http
            .get<CrsResponseType>(url, {
                headers: {
                    // This header is added so that the generic error http interceptor does not consume the error.
                    // That interceptor would consume the error and the filterCRSException operator below wouldn't have the context to catch the error and handle it properly.
                    [CONSUME_GENERIC_ERROR_HANDLER]: 'false',
                },
            })
            .pipe(
                /**
                 * Previously, CRS would return 200 for all requests, even if there was an error.
                 * In the body of the response, there would be a JSON object with a field `exception` that provided more info about it.
                 * Now, CRS returns proper error status codes. Since we don't want to touch the whole application for it (CRS will be deprecated soon anyway),
                 * we need to catch these status codes and return the exception as before.
                 */
                this.#catchCrsExceptionError(),
                filter(response => this.#filterCrsException(url, response, filterCrsExceptionOptions)),
            );
    }

    /**
     * Catches the CRS Exception errors and returns the response body.
     * It's implemented that way so that we don't have to touch the whole application to handle the non 200 status codes from CRS.
     * Previously it always returned 200, even if there was an error, and the error was in the body of the response.
     * Since CRS will be removed soon in favor of the new search API, it doesn't make sense to change that in the respective places but rather imitate the old behavior.
     * @returns A CrsExceptionDTO object if it exists, otherwise the error object.
     */
    #catchCrsExceptionError<T, O extends ObservableInput<any>>(): OperatorFunction<T, T | ObservedValueOf<O>> {
        return source =>
            source.pipe(
                catchError(error => {
                    if (error?.status !== 200 && error?.error?.exception) {
                        logger.debug(
                            logPrefix,
                            'catchCrsExceptionError: -> caught exception from body',
                            error.error.exception,
                        );
                        return of(error.error);
                    }
                    logger.debug(logPrefix, 'catchCrsExceptionError: -> throwing error', error);
                    return throwError(() => error);
                }),
            );
    }

    #filterCrsException(
        url: string,
        crsResponse: CrsExceptionDTO | unknown,
        options: FilterCrsExceptionOptions,
    ): boolean {
        const crsException = (crsResponse as CrsExceptionDTO).exception;
        const fnLogPrefix = 'filterCrsException:';

        if (crsException) {
            logger.warn(
                logPrefix,
                fnLogPrefix,
                'exception is found',
                crsException,
                'options given:',
                options,
                'request url',
                url,
            );
        }

        if (options.ignoreExceptionType && crsException?.type === options.ignoreExceptionType) {
            logger.debug(logPrefix, fnLogPrefix, 'ignoring exception type', options.ignoreExceptionType);
            return true;
        }

        if (options.ignoreException || !crsException) {
            logger.debug(logPrefix, fnLogPrefix, 'ignoring exception');
            return true;
        }

        logger.error(logPrefix, fnLogPrefix, crsException);

        if (options.suppressErrorMessagePopupForExceptionType !== crsException.type) {
            logger.debug(logPrefix, fnLogPrefix, 'posting error message to message service');
            this.message.postError(ErrorId.CRS_ERROR, crsException);
        } else {
            logger.debug(
                logPrefix,
                fnLogPrefix,
                'error message popup was suppressed for the exception type',
                crsException.type,
            );
        }

        if (options.throwError) {
            logger.debug(logPrefix, fnLogPrefix, 'throwing error');
            throw new Error(`[${crsException.type}] ${crsException.message}`);
        }

        logger.debug(logPrefix, fnLogPrefix, 'filtered out exception');
        return false;
    }
}
