export interface CrsUpdatedDocsUpdateInfoDTO {
    readonly status: CrsUpdatedDocsStatus;
    readonly message: string;
}

export const CrsUpdatedDocsStatus = {
    ADDED: 'ADDED',
    MODIFIED: 'MODIFIED',
};

export type CrsUpdatedDocsStatus = (typeof CrsUpdatedDocsStatus)[keyof typeof CrsUpdatedDocsStatus];
