import { DOCUMENT } from '@angular/common';
import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { DocumentId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { hash } from 'immutable';
import { IDeskDocument, OptionalDocumentAttributes } from '../models';

type CreateIDeskDocumentProperties = MandatoryCreateIDeskDocumentProperties & OptionalCreateIDeskDocumentProperties;

type MandatoryCreateIDeskDocumentProperties = Pick<
    IDeskDocument,
    'content' | 'debugLinks' | 'id' | 'index' | 'longTitle' | 'docSize' | 'title'
>;

type OptionalCreateIDeskDocumentProperties = Partial<OptionalDocumentAttributes>;

export const createIDeskDocument = (
    injector: EnvironmentInjector,
    props: CreateIDeskDocumentProperties,
    trackBy?: string,
): IDeskDocument => {
    const authorIds = props.authorIds ?? [];
    const citation = props.citation ?? '';
    const query = props.query ?? '';
    trackBy = trackBy ?? `${props.id}-${query}`;
    const hashCode = hash(trackBy);

    const indexIsSet = props.index ? props.index >= 0 : false;
    if (!indexIsSet) {
        logger.warn("[createIDeskDocument] index isn't set for", props);
    }

    const created: IDeskDocument = {
        ...props,
        authorIds,
        citation,
        isDummy: false,
        query,
        trackBy,
        getElement: () => getElement(injector, props.id),
        hashCode: () => hashCode,
        equals: (other: IDeskDocument): boolean => trackBy === other.trackBy,
    };

    return created;
};

const getElement = (injector: EnvironmentInjector, id: DocumentId): HTMLElement | undefined =>
    runInInjectionContext(injector, () => {
        const document = inject(DOCUMENT).getElementById(id);
        if (document) {
            return document;
        }

        logger.warn("[IDeskDocument.getElement] couldn't find element for:", id);
        return undefined;
    });
