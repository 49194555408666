export type IsConfigured = 1;

export type IsDraft = 0;

export type IsNotConfigured = -1;

export type IsPublished = 2;

export const IS_DRAFT: IsDraft = 0;

export const IS_CONFIGURED: IsConfigured = 1;

export const IS_NOT_CONFIGURED: IsNotConfigured = -1;

export const IS_PUBLISHED: IsPublished = 2;
