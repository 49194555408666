import { ChangeDetectionStrategy, Component, input, Signal } from '@angular/core';
import { Message } from '../../services/message/model/message';

@Component({
    selector: 'hg-message-content',
    standalone: true,
    templateUrl: './message-content.component.html',
    styleUrl: './message-content.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageContentComponent {
    message: Signal<Message | undefined> = input<Message>();
}
