import { logger } from '@idr/shared/utils';
import { LocalStorageKey, LocalStorageService } from '@idr/ui/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { HasValues } from './has-values';
import { ReverseStackWithMaxSize } from './reverse-stack-with-max-size';

export abstract class History<T> {
    public readonly state$: Observable<HasValues<T>>;

    private readonly _state$: BehaviorSubject<ReverseStackWithMaxSize<T>> = new BehaviorSubject(undefined);

    protected constructor(
        private readonly storageKey: LocalStorageKey,
        private readonly localStorage: LocalStorageService,
        storageConverter: (fromStorage: unknown) => T,
        maxSize: number,
    ) {
        const inStorage: string = this.localStorage.get(this.storageKey);
        let initialState: T[] = [];
        if (inStorage !== undefined) {
            initialState = JSON.parse(inStorage).map((fromStorage: unknown) => storageConverter(fromStorage));
        }
        this._state$ = new BehaviorSubject(ReverseStackWithMaxSize.create<T>(maxSize, initialState));
        this.state$ = this._state$.asObservable();
    }

    public add(entry: T): void {
        const current: ReverseStackWithMaxSize<T> = this._state$.value;
        const next: ReverseStackWithMaxSize<T> = current.add(entry);
        this.localStorage.set(this.storageKey, JSON.stringify(next.values));
        logger.debug(`[History] (${this.storageKey}) add ->`, entry, 'next', next.values);
        this._state$.next(next);
    }

    public clear(): void {
        logger.debug(`[History] (${this.storageKey}) clear`);
        const current: ReverseStackWithMaxSize<T> = this._state$.value;
        const next: ReverseStackWithMaxSize<T> = current.clear();
        this.localStorage.remove(this.storageKey);
        this._state$.next(next);
    }
}
