import { ChangeDetectionStrategy, Component, computed, HostBinding, Inject, input, Signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UploadedFile } from '@idr/model/config';
import { BrandId, getLabelFromBrand } from '@idr/shared/model';
import { getBrandIcon, MY_DESK_CONFIG_IMAGE_URL } from '@idr/ui/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    host: { '[class]': 'brandId()' },
    imports: [MatIconModule],
    selector: 'idr-company-logo',
    standalone: true,
    styleUrl: './company-logo.component.scss',
    template: ` <mat-icon [svgIcon]="logo()" /><span>{{ label() }}</span>
        @if (partnerLogo()) {
            <img [src]="partnerLogoUrl()" />
        }`,
})
export class CompanyLogoComponent {
    @HostBinding('attr.role') readonly role = 'presentation';

    readonly brandId = input.required<BrandId>();

    // FIXME test visibility with an e2e
    readonly label = computed(() => `Firmenlogo von ${getLabelFromBrand(this.brandId())}`);

    readonly logo = computed(() => getBrandIcon(this.brandId()));

    readonly partnerLogo = input<UploadedFile>();

    readonly partnerLogoUrl: Signal<string | undefined>;

    constructor(@Inject(MY_DESK_CONFIG_IMAGE_URL) imageDomain: string) {
        this.partnerLogoUrl = computed(() => {
            const logo = this.partnerLogo();
            if (!logo) {
                return undefined;
            }

            return `${imageDomain}${logo.url}`;
        });
    }
}
