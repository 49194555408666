import { Injectable } from '@angular/core';
import { DocumentLink } from '@idr/shared/model';
import { HISTORY_MAX_SIZE } from '@idr/ui/consts';
import { LocalStorageKey, LocalStorageService } from '@idr/ui/shared';
import { History } from './history';

@Injectable({ providedIn: 'root' })
export class DocumentHistory extends History<DocumentLink> {
    constructor(localStorage: LocalStorageService) {
        super(LocalStorageKey.HISTORY_DOCUMENTS, localStorage, DocumentLink.fromStorage, HISTORY_MAX_SIZE);
    }
}
