import { InjectionToken } from '@angular/core';
import { DocumentId } from '@idr/shared/model';

export interface TextSelection {
    readonly chapterId: DocumentId;
    /**
     * In FF the selection disappears when the menu opens.
     * By passing the range we can restore the selection...stupid hack, but it works
     */
    readonly range: Range;
    readonly text: string;
    readonly textHtml: string;
}

export const INJECT_FEEDBACK: InjectionToken<TextSelection> = new InjectionToken('INJECT_FEEDBACK');
