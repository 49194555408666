import { A11yModule } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, HostListener, Inject, InjectionToken } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NPS_CONFIG } from '@idr/shared/model';
import { getTimeStampOffsetByDays, logger } from '@idr/shared/utils';
import { DialogService, UserSettingsService, SafePipe } from '@idr/ui/shared';
import { tap } from 'rxjs/operators';

export const INJECT_SURVEY_IFRAME_SRC: InjectionToken<string> = new InjectionToken<string>('INJECT_SURVEY_IFRAME_SRC');

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [A11yModule, MatIconModule, SafePipe],
    standalone: true,
    selector: 'idr-nps-dialog',
    styleUrls: ['./nps-dialog.component.scss'],
    templateUrl: './nps-dialog.component.html',
})
export class NpsDialogComponent {
    constructor(
        @Inject(INJECT_SURVEY_IFRAME_SRC) public readonly surveyIframeSrc: string,
        private readonly userSettingsService: UserSettingsService,
        private readonly dialogService: DialogService,
    ) {
        logger.debug('[NpsDialogComponent] init with', { surveyIframeSrc });
    }

    @HostListener('document:keydown.escape')
    public onEscape(): void {
        this.cancelDialog();
    }

    public cancelDialog(): void {
        logger.debug('[NpsDialogComponent] cancelDialog');
        const nextViewDateMs = getTimeStampOffsetByDays(NPS_CONFIG.nextCancelOffsetInDays);
        this.scheduleNextSurvey(nextViewDateMs);
    }

    public finishDialog(): void {
        logger.debug('[NpsDialogComponent] finishDialog');
        const nextViewDateMs = getTimeStampOffsetByDays(NPS_CONFIG.nextFinishOffsetInDays);
        this.scheduleNextSurvey(nextViewDateMs);
    }

    private scheduleNextSurvey(dateInMs): void {
        const settings = {
            nps: {
                nextSurveyAfterDate: dateInMs,
            },
        };
        this.userSettingsService
            .updateSettings$(settings)
            .pipe(tap(() => this.dialogService.closeAll()))
            .subscribe();
    }
}
