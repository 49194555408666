import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, OnInit, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { GtmArea } from '@idr/ui/tracking';
import { PanelPosition } from './position';

/**
 * Widget for
 * https://haufegroup.invisionapp.com/d/main#/console/17213512/389310292/inspect
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule],
    selector: 'hg-wing-panel-button',
    standalone: true,
    styleUrls: ['./wing-panel-button.component.scss'],
    template: '<mat-icon svgIcon="minimize"/>',
})
export class WingPanelButtonComponent implements OnInit {
    @HostBinding('class.opened') classOpened = true;

    @HostBinding('class.left') classLeft = true;

    @HostBinding('class.right') classRight = false;

    @HostBinding('attr.tabindex') readonly tabIndex: string = '0';

    @Input({ required: true }) gtmArea?: GtmArea;

    @HostBinding('attr.data-id') gtmId?: string;

    /**
     * Emits the "closed" & "opened" state whenever the user clicks the button.
     *
     * You'll get "false" when the button entered "closed" state (from opened -> closed).
     * You'll get "true" when the button entered "opened" state (from closed -> opened).
     */
    toggled = output<boolean>();

    @Input()
    set position(position: PanelPosition) {
        switch (position) {
            case 'left':
                this.classLeft = true;
                this.classRight = false;
                break;
            case 'right':
                this.classLeft = false;
                this.classRight = true;
                break;
        }
    }

    /**
     * Used to restore view state after reload.
     * Without it the component will always have it's initial state (opened) on reload. (NAUA-4856)
     */
    @Input()
    set opened(opened: boolean) {
        this.classOpened = opened;
    }

    @HostListener('click')
    onClick(): void {
        this.classOpened = !this.classOpened;
        this.toggled.emit(this.classOpened);
        this.updateGtmId();
    }

    @HostListener('keydown.enter')
    onEnter(): void {
        this.onClick();
    }

    ngOnInit(): void {
        this.updateGtmId();
    }

    private updateGtmId() {
        this.gtmId = this.classOpened ? `${this.gtmArea}-collapse-panel` : `${this.gtmArea}-expand-panel`;
    }
}
