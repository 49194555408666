import {
    ContentHubDocumentDTO,
    EntryDTO,
    HotConfig,
    HotMetaDTO,
    HotType,
    HotTypeLabel,
    OdinDocumentClassification,
} from '@idr/shared/model';
import { arrayFromVar } from '@idr/shared/utils';
import { Realm, urlWithRealmQueryParam } from '@idr/ui/shared';
import { ContentHubDocument } from './content-hub-document';

export class HotDocument extends ContentHubDocument {
    readonly isPaidContent: boolean;
    readonly type: HotType;

    readonly authorImageUrls?: string[];
    readonly duration?: number;
    readonly price?: number;
    readonly netPrice?: number;
    readonly startDateTime?: Date;
    readonly targetGroups?: string[];
    readonly url?: string;

    constructor(
        documentDTO: ContentHubDocumentDTO<HotMetaDTO>,
        realm: Realm,
        hotConfig: HotConfig,
        entryDTO?: EntryDTO<ContentHubDocumentDTO<HotMetaDTO>>,
    ) {
        const meta: HotMetaDTO = documentDTO['ch:haufe-document']['ch:meta'];
        const type = meta['hot:type'].content as string;
        const classification: OdinDocumentClassification = {
            type,
            description: HotTypeLabel.get(type) ?? '',
            subType: HotTypeLabel.get(type),
        };
        super(documentDTO, entryDTO, classification, realm, {
            baselineContent: documentDTO['ch:haufe-document']['ch:meta']['hot:at_first_glance']?.content,
        });

        const isIncludedContent = (arrayFromVar(meta['ch:packageId'] ?? []) as string[]).includes(
            `SI${hotConfig?.service}`,
        );

        this.type = type;
        this.duration = meta['hot:duration']?.content;
        this.targetGroups = arrayFromVar(meta['hot:target_group'])?.map(value => value.content as string);
        this.price = meta['hot:price']?.content;
        this.netPrice = meta['hot:net_price']?.content;
        this.isPaidContent = !!(!isIncludedContent && this.price && this.price > 0);
        this.authorImageUrls = arrayFromVar(meta['hot:author_image_url'])?.map(author => author?.content as string);
        this.startDateTime = meta['hot:start_date_time']
            ? new Date(meta['hot:start_date_time'].content as string)
            : undefined;
        // There is also a hot:accessUrl in the meta, but that leads to a "new order" page adding the current HOT to the cart.
        // What we want to use instead is the hot:link_www that is the link to the details page of the HOT.
        // Then the user can see the details and decide if he wants to order it.
        this.url = meta['hot:link_www']
            ? urlWithRealmQueryParam(meta['hot:link_www'].content as string, realm)
            : undefined;
    }

    static fromDocumentDTO(
        documentDTO: ContentHubDocumentDTO<HotMetaDTO>,
        realm: Realm,
        hotConfig: HotConfig,
    ): HotDocument {
        return new HotDocument(documentDTO, realm, hotConfig);
    }

    static fromEntryDTO(entryDTO: EntryDTO<ContentHubDocumentDTO<HotMetaDTO>>, realm: Realm, hotConfig: HotConfig) {
        return new HotDocument(entryDTO.content, realm, hotConfig, entryDTO);
    }
}
