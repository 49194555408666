import { BauplanId } from '../bauplan/bauplan-id';
import { DocumentId } from '../document/document-id';
import { ProductId } from '../product/product-id';
import { CrsUpdatedDocsUpdateInfoDTO } from './crs-updated-docs-update-info';
import { DocCategoryId, DocTypeId } from './document-classification-locale-de';

export type CrsDocumentProviderIdDTO = 'OVS' | 'SP' | 'STF' | 'HAUFE' | string;

interface CrsHasDocumentId {
    readonly docid: DocumentId;
}

interface CrsHasName {
    readonly name: string;
}

interface CrsHasProductId {
    readonly productid: ProductId;
}

export interface CrsOutlinesWrapperDTO {
    readonly product: ({
        readonly productname: string;
        readonly outline: CrsOutlineDTO[];
    } & CrsHasProductId)[];
}

export interface CrsOutlineDTO extends CrsHasDocumentId {
    readonly shorttitle: string;
    readonly title: string;
    readonly docsize: number;
    readonly entry: CrsOutlineEntryDTO[];
}

export interface CrsOutlineEntryDTO extends CrsHasDocumentId {
    readonly isRessort: boolean;
    readonly shorttitle: string;
    readonly title: string;
    readonly entry?: CrsOutlineEntryDTO[];
    readonly paragraphNo?: string[];
    readonly anchor?: string;
    readonly docsize?: number; // it's optional as well (for flat documents) (noticed this after manual testing in PI25844 > HI14098642)
}

export interface CrsDocumentWrapperDTO extends CrsHasProductId {
    readonly productname: string;
    readonly document: CrsDocumentDTO[];
}

export interface CrsFootnotesWrapperDTO extends CrsHasDocumentId, CrsHasProductId {
    readonly footnote: CrsFootnoteDTO[];
}

export interface CrsFootnoteDTO {
    readonly index: number;
    readonly text: string;
}

export interface CrsBookInfoDTO {
    readonly edition: string;
    readonly authorid: number[];
    readonly editor: string[];
    readonly title: string;
}

export interface CrsDocumentDTO extends CrsHasDocumentId {
    readonly rootid: DocumentId;
    readonly title: string;
    readonly text?: string; // undefined in topic pages
    readonly docclass: CrsDocClassDTO;
    readonly isMultipart: boolean;
    readonly longTitle?: string; // undefined in topic pages
    readonly isSearchable: boolean;
    readonly authorid?: number[];
    readonly bookInfo?: CrsBookInfoDTO;
    readonly citation?: string;
    readonly updateInfo?: CrsUpdatedDocsUpdateInfoDTO;
    readonly commentedContent?: any; // FIXME unclear which type this
    /**
     * Set if this is a comment for a specific other document.
     * The other document is most likely a so called "Verwaltungsanweisung" OR "Entscheidung".
     *
     * If the document is a norm comment, then instead {@link normCommentContent} is set.
     */
    readonly commentContent?: {
        readonly commentedDocument?: CrsHasDocumentId & CrsHasName & CrsHasProductId;
    };
    /**
     * Set only for norm comments.
     * There are other comment types, but they use {@link commentedContent} instead.
     */
    readonly normCommentContent?: {
        readonly altCommentLink: CrsCommentLinkDTO[];
        readonly commentedDocument: CrsHasDocumentId & CrsHasName & CrsHasProductId;
        readonly currentFassung: CrsHasDocumentId & CrsHasName & CrsHasProductId;
        readonly hasArchives: boolean;
        readonly showArchives: boolean;
    };
    /**
     * FIXME Documentation when is this set? ... I found LI13707393 in PI10413 where this field was also present in response from CRS
     *       This FIXME applies also to {@link highlightInfo} & {@link toolContent}
     */
    readonly description?: string;
    readonly docdate?: string;
    readonly docstate?: CrsDocStateDTO;
    readonly highlightInfo?: string;
    readonly normContent?: CrsNormContentDTO;
    readonly preDocid?: string;
    readonly providerid?: CrsDocumentProviderIdDTO;
    readonly ressortid?: string;
    readonly sucDocid?: DocumentId;
    readonly toolContent?: unknown;
    readonly topicPageContent?: CrsTopicPageContentDTO;
    readonly imagepath?: string;
}

export interface CrsVersionInfoDTO {
    readonly isCurrentVersion: boolean;
    readonly versionLink: CrsLinkDTO[];
    readonly versionTitle: string;
}

export interface CrsNormContentDTO {
    /** see {@link CRS_TestData_PI25844.ESTG_ROOT} */
    readonly isSynopsis?: boolean;
    readonly section?: CrsNormContentSectionDTO[];
    readonly showArchives?: boolean;
    readonly fassungTitle?: string; // exists only if we request a different version than "aktuel fassung"
    readonly isCurrentVersion?: boolean;
    readonly versionInfo?: CrsVersionInfoDTO;
}

export interface CrsNormContentSectionDTO {
    readonly anchor?: string;
    readonly commentLink?: CrsCommentLinkDTO[];
    readonly fassungLink?: CrsVersionLinkDTO[];
    readonly isRelatedDocsAnchor?: boolean;
}

export interface CrsCommentLinkDTO {
    readonly resource: CrsCommentLinkResourceDTO;
    readonly title: string;
}

export interface CrsCommentLinkResourceDTO extends CrsHasDocumentId, CrsHasName, CrsHasProductId {
    readonly editors: string;
    readonly providerid: CrsDocumentProviderIdDTO;
}

export interface CrsVersionLinkDTO {
    readonly resource: CrsVersionLinkResourceDTO;
    readonly title?: string; // title is undefined for version "Aktuelle Fassung"
}

export interface CrsVersionLinkResourceDTO extends CrsHasName, CrsHasProductId {
    readonly normkey: string; // in the form 'aktuell|1|EStG'
}

export interface CrsDocClassDTO {
    readonly type: DocTypeId;
    readonly category?: DocCategoryId;
    readonly subcategory?: string;
    // when requesting Bauplan entries this might be set if the entry is a "Buch" ... :(
    readonly displayname?: string;
}

export interface CrsDocStateDTO {
    readonly date?: string;
    readonly link?: CrsLinkDTO;
}

export interface CrsLinkDTO {
    readonly resource: CrsResourceDTO;
    readonly title?: string;
}

export interface CrsResourceDTO extends CrsHasDocumentId, CrsHasName, CrsHasProductId {
    readonly docclass?: CrsDocClassDTO;
    readonly tool?: {
        readonly filepath?: string;
    };
    readonly updateInfo?: CrsUpdatedDocsUpdateInfoDTO;
}

export interface CrsContentHierarchyPathsDTO extends CrsHasProductId {
    readonly hierarchyPath: CrsHierarchyPathDTO[];
    readonly productname: string;
}

export interface CrsHierarchyPathDTO {
    readonly node: CrsNodeDTO[];
}

export interface CrsNodeDTO {
    readonly path: string;
    readonly id: BauplanId;
    readonly title: string;
}

// extracted from de.haufe.aurora.dataaccess.crs.exportoptions.api.ExportAreaName
export const CrsExportAreaNameTypes = [
    'current_page',
    'complete_list',
    'documents_current_page',
    'documents_all',
    'current_page_updatelist',
    'complete_list_updatelist',
    'documents_current_page_updatelist',
    'documents_all_updatelist',
    'complete',
    'current',
    'section',
    'marked',
    'leitsatz',
    'paragraphs',
    'table_of_contents',
] as const;
export type CrsExportAreaNameTypeDTO = (typeof CrsExportAreaNameTypes)[number];

// extracted from de.haufe.aurora.dataaccess.crs.exportoptions.api.ExportOptionName
export const CrsExportOptions = [
    'haufeindex',
    'stand',
    'gesetzesstand',
    'author',
    'citation',
    'footnotes',
    'use_header',
    'use_footer',
    'tableofcontents',
    'with_examples',
    'with_tips',
    'about_norm',
    'about_labor_agreement',
    'about_document',
    'details',
    'highlight',
    'searchTerm',
    'with_archive',
    'hitsDoc',
    'hitsViewDetailed',
    'hitsViewDetailedWithContext',
] as const;

export type CrsExportOptionNameTypeDTO = (typeof CrsExportOptions)[number];
export interface CrsExportOptionDTO {
    readonly name: CrsExportOptionNameTypeDTO;
    readonly enabled: boolean;
}

export interface CrsExportOptionsDTO {
    readonly exportArea: CrsExportAreaNameTypeDTO[];
    readonly exportOption: CrsExportOptionDTO[];
}

export interface CrsTopicPageContentDTO {
    readonly articleLink: CrsArticleLinkDTO;
    readonly acts?: string;
    readonly linkCategory: CrsLinkCategoryDTO[];
    readonly keyword: KeywordDTO[];
    readonly imagePath?: string;
}

export interface CrsArticleLinkDTO {
    readonly visible: boolean;
    readonly resource: CrsResourceDTO;
}

export interface CrsLinkCategoryDTO {
    readonly title: string;
    readonly link: CrsLinkDTO[];
}

export interface KeywordDTO {
    readonly name: string;
    readonly weight: number;
    readonly link: CrsLinkDTO[];
}
