import { CrsCustomRange, CrsHitField } from './crs-params';

export const getUrParamIf = (param: string, value: boolean, condition: boolean): string => {
    return condition === value ? `/${param}/${value ? '1' : '0'}` : '';
};

export const getUrParamIfFalse = (param: string, value: boolean): string => {
    return getUrParamIf(param, value, false);
};

export const getUrParamIfTrue = (param: string, value: boolean): string => {
    return getUrParamIf(param, value, true);
};

export const getCrsCustomRangeParamIfSet = (param: string, range?: CrsCustomRange): string => {
    if (!range) {
        return '';
    }

    return `/${param}/${range.startIndex}-${range.endIndex}`;
};

export const getCrsHitFieldsParam = (hitFields: CrsHitField[]): string => {
    if (!hitFields) {
        return '';
    }
    return hitFields.map(field => `/docfield/${field}`).join('');
};
