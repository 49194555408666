import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface LetContext<T> {
    // eslint-disable-next-line functional/prefer-readonly-type
    ngLet: T;
}

//  https://medium.com/@AustinMatherne/angular-let-directive-a168d4248138
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ngLet]', standalone: true })
export class LetDirective<T> {
    readonly #context: LetContext<T> = { ngLet: null as T };

    constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<T>>) {
        _viewContainer.createEmbeddedView(_templateRef, this.#context);
    }

    @Input()
    set ngLet(value: T) {
        this.#context.ngLet = value;
    }
}
