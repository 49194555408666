import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ActiveProduct, CopyButtonComponent, WINDOW } from '@idr/ui/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CopyButtonComponent],
    selector: 'mc-draft-banner',
    standalone: true,
    styleUrl: './draft-banner.component.scss',
    template: `<h1>Vorschau</h1>
        <hg-copy-button
            copiedLabel="Link kopiert"
            label="Review Link kopieren"
            [value]="previewLink" />`,
})
export class DraftBannerComponent {
    readonly previewLink: string;

    constructor(@Inject(WINDOW) window: Window, activeProduct: ActiveProduct) {
        const location = window.location;
        const productId = activeProduct.id;
        this.previewLink = `${location.protocol}//${location.host}/${productId}?draft=true&auth_realm=haufe-internal-idesk&requested_scope=${productId}`;
    }
}
