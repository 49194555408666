import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule],
    selector: 'mc-success-message',
    template: ` <mat-icon svgIcon="check" />
        {{ message }}
        <button (click)="ref.dismiss()">
            <mat-icon svgIcon="close" />
        </button>`,
    standalone: true,
    styleUrl: './success-message.component.scss',
})
export class SuccessMessageComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public readonly message: string,
        public readonly ref: MatSnackBarRef<SuccessMessageComponent>,
    ) {}
}
