import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { logger } from '@idr/shared/utils';
import { fromEvent } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { DrawerId } from '../../model';
import { DomService, LocalStorageKey, LocalStorageService } from '../../services';
import { getDrawerLocalStorageKey } from '../drawer/drawer.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'hg-document-with-drawers',
    standalone: true,
    styleUrls: ['./document-with-drawers.component.scss'],
    template: '<ng-content/>',
})
export class DocumentWithDrawersComponent implements AfterViewInit {
    // these class host binding are used to change layout based on closed/open state of the drawers...
    @HostBinding('class.t-closed') tocClosed: boolean;
    @HostBinding('class.r-closed') relatedClosed: boolean;

    readonly #contentLowerBreakpoint: number;

    readonly #logPrefix = '[DocumentWithDrawersComponent]';

    constructor(
        private readonly domService: DomService,
        private readonly localStorageService: LocalStorageService,
    ) {
        this.#contentLowerBreakpoint = domService.getSCSSPropertyAsNumber('--content-lower-breakpoint');

        this.relatedClosed = !this.#isDrawerOpen(DrawerId.RELATED_DOCUMENTS);
        this.tocClosed = !this.#isDrawerOpen(DrawerId.TABLE_OF_CONTENTS);

        this.localStorageService
            .valueChanged$(getDrawerLocalStorageKey(DrawerId.RELATED_DOCUMENTS))
            .pipe(
                takeUntilDestroyed(),
                map(value => coerceBooleanProperty(value)),
                tap(isOpen => (this.relatedClosed = !isOpen)),
            )
            .subscribe();

        this.localStorageService
            .valueChanged$(getDrawerLocalStorageKey(DrawerId.TABLE_OF_CONTENTS))
            .pipe(
                takeUntilDestroyed(),
                map(value => coerceBooleanProperty(value)),
                tap(isOpen => (this.tocClosed = !isOpen)),
            )
            .subscribe();

        fromEvent(window, 'resize')
            .pipe(
                takeUntilDestroyed(),
                debounceTime(100),
                tap(() => this.#adjustLayoutToLowerBreakpoint()),
            )
            .subscribe();
    }

    ngAfterViewInit(): void {
        // We also perform the layout adjustment on init to ensure a good UX on small screens.
        this.#adjustLayoutToLowerBreakpoint();
    }

    #isDrawerOpen(drawerId: DrawerId): boolean {
        if (!this.localStorageService.contains(getDrawerLocalStorageKey(drawerId))) {
            // Default value is true
            return true;
        }
        return coerceBooleanProperty(this.localStorageService.get(getDrawerLocalStorageKey(drawerId)));
    }

    #adjustLayoutToLowerBreakpoint(): void {
        const betterCloseRelatedDocs: boolean = this.domService.innerWidth < this.#contentLowerBreakpoint;
        const relatedDocsFitsOnScreen = !betterCloseRelatedDocs;

        if (betterCloseRelatedDocs && (this.tocClosed || this.relatedClosed)) {
            logger.debug(
                this.#logPrefix,
                'adjustLayoutToLowerBreakpoint',
                'no need to adjust layout, because toc or related docs are already closed',
            );
            return;
        }

        if (relatedDocsFitsOnScreen && !this.relatedClosed) {
            logger.debug(
                this.#logPrefix,
                'adjustLayoutToLowerBreakpoint',
                'no need to adjust layout, because related docs fits on screen and is already open',
            );
            return;
        }

        if (betterCloseRelatedDocs) {
            logger.debug(
                this.#logPrefix,
                'adjustLayoutToLowerBreakpoint',
                'adjusting layout, because related docs does not fit on screen',
            );
            this.localStorageService.set(getDrawerLocalStorageKey(DrawerId.RELATED_DOCUMENTS), String(false));
            this.localStorageService.set(LocalStorageKey.RELATED_DOCS_CLOSED_DUE_TO_RESIZE, String(true));
        }

        if (
            relatedDocsFitsOnScreen &&
            this.localStorageService.get(LocalStorageKey.RELATED_DOCS_CLOSED_DUE_TO_RESIZE)
        ) {
            logger.debug(
                this.#logPrefix,
                'adjustLayoutToLowerBreakpoint',
                'adjusting layout, because related docs fits on screen and was closed due to resize',
            );
            this.localStorageService.set(getDrawerLocalStorageKey(DrawerId.RELATED_DOCUMENTS), String(true));
            this.localStorageService.set(LocalStorageKey.RELATED_DOCS_CLOSED_DUE_TO_RESIZE, String(false));
        }
    }
}
