import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EcondaTarget, EcondaTargetGroup, EcondaTargetName } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { GtmArea, GtmIdDirective } from '@idr/ui/tracking';
import { RxPush } from '@rx-angular/template/push';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { EcondaTrackingService } from '../../../core/tracking/econda-tracking.service';

export const Type = {
    GlobalSearch: 'global-search',
    DocumentSearch: 'document-search',
};

export type Type = (typeof Type)[keyof typeof Type];

export interface AriaAttributes {
    readonly placeholder: string;
    readonly label: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatTooltipModule, RxPush, GtmIdDirective],
    selector: 'idr-search-input',
    standalone: true,
    styleUrls: ['./search-input.component.scss'],
    templateUrl: './search-input.component.html',
})
export class SearchInputComponent {
    @ViewChild('searchBox') public searchBox: ElementRef<HTMLInputElement>;

    /**
     * You need to set this in order to have tracking enabled in this component.
     * It is used as prefix/context for tracking events.
     * Its value depends on the context your component is living in.
     * For example inside:
     * - the document searchBar it should be set to `EcondaTargetGroup.DOCUMENT`
     * - the header searchBar it should be set to `EcondaTargetGroup.HEADER`
     */
    @Input() public trackingContext: EcondaTargetGroup;

    @Input() public searchButtonTitle = 'Suche starten';

    /**
     * Since we are using this component both for the global search and the in-document search
     * we need to be able to distinguish between the two for tracking purposes.
     */
    @Input() public elementIdPrefix: string;
    @Input({ required: true }) public gtmArea: GtmArea;

    /**
     * Is used when suggestions are displayed and one of the selected suggestions
     * should be submitted instead of the value that is currently int he input field.
     */
    @Input() public ignoreEnterKey = false;

    // @Input() public type: Type = Type.GlobalSearch;

    /**
     * Will emit query value on every key press.
     */
    @Output() public queryChange: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Similar to queryChange but will only publish the query string when submit is called.
     */
    @Output() public submitCalled: EventEmitter<string> = new EventEmitter<string>();

    @Output() public focusChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly query$: Observable<string>;

    private readonly _query$: BehaviorSubject<string> = new BehaviorSubject('');

    private _ariaAttributes: AriaAttributes;

    private _focused: boolean;

    private _type: Type;

    constructor(private readonly econdaTrackingService: EcondaTrackingService) {
        this.query$ = this._query$.pipe(distinctUntilChanged());
        this.type = Type.GlobalSearch;
    }

    public get ariaAttributes(): AriaAttributes {
        return this._ariaAttributes;
    }

    public get query(): string {
        return this._query$.value;
    }

    @Input()
    public set query(query: string) {
        if (this.query === query) {
            return;
        }
        this._query$.next(query ?? '');
    }

    public get type(): Type {
        return this._type;
    }

    @Input()
    public set type(type: Type) {
        this._type = type;

        if (type === Type.DocumentSearch) {
            this._ariaAttributes = {
                label: 'Eingabe für die Suche im aktuellen Dokument',
                placeholder: 'Suchen im Dokument...',
            };
            return;
        }

        this._ariaAttributes = {
            label: 'Eingabe für die globale Suche im Produkt',
            placeholder: 'Suchen nach...',
        };
    }

    @HostBinding('class.focused')
    public set focused(isFocused: boolean) {
        if (this._focused === isFocused) {
            return;
        }
        this._focused = isFocused;
        this.focusChange.next(isFocused);
    }

    @HostListener('keydown.arrowup', ['$event'])
    @HostListener('keydown.arrowdown', ['$event'])
    public handleKeyboardEvent(event: KeyboardEvent): void {
        event.returnValue = false;
        event.preventDefault();
    }

    public onKeyboardEnter(query: string): void {
        if (this.ignoreEnterKey) {
            return;
        }
        logger.debug('SearchInputComponent.onKeyUpSearchInput > onSubmit');
        this.submit(query);
    }

    /**
     * Parameter is only used for tests. (to test resetSearch)
     * Not optimal, but I rather have a default parameter that leave
     * resetSearch() public as it was before.
     */
    public onClickReset(triggeredByUser = true): void {
        logger.debug(`SearchInputComponent.onClickReset`);
        this.resetSearch(triggeredByUser);
    }

    public onClickSearch(query: string): void {
        this.submit(query);
    }

    public onInput(query: string): void {
        logger.debug(`SearchInputComponent.onInput > ${query}`);
        this.query = query;
        this.queryChange.emit(query);
    }

    public submit(query: string): void {
        logger.debug(`SearchInputComponent.onSubmit > query=${query}`);

        this.submitCalled.emit(query);

        if (query.length === 0) {
            this.focusInput();
        } else {
            this.blurInput();
        }
    }

    public get isQueryAvailable(): boolean {
        return this.query.length > 0;
    }

    private resetSearch(triggeredByUser?: boolean): void {
        logger.debug(`SearchInputComponent.resetSearch > triggeredByUser=${triggeredByUser}`);
        this.query = '';
        this.submit('');
        if (triggeredByUser) {
            this.trackTarget();
        }
    }

    private trackTarget(): void {
        if (!this.trackingContext) {
            return;
        }
        const target: EcondaTarget = new EcondaTarget(this.trackingContext, EcondaTargetName.SearchReset);
        this.econdaTrackingService.trackTarget(target);
    }

    private focusInput(): void {
        this.searchBox.nativeElement.focus();
    }

    private blurInput(): void {
        this.searchBox.nativeElement.blur();
    }
}
