export const DocType = {
    ARBEITSHILFE: 'Arbeitshilfe',
    BEITRAG: 'Beitrag',
    BIBLIOGRAPHIE: 'Bibliografie',
    BUCHUNGSSATZ: 'Buchungssatz',
    ENTSCH: 'Entscheidung',
    HELP: 'Hilfe',
    KOMMENTAR: 'Kommentar',
    KONTIERUNGSABC: 'Kontierungs-ABC',
    LOHNPFAENDUNG: 'Lohnpf\u00e4ndung',
    MINIJOB: 'Minijobfragebogen/Bewertung',
    MUSTERDOKUMENT: 'Musterdokument',
    NORM: 'Norm',
    RECHTSQUELLE: 'Rechtsquelle',
    THEMENSEITE: 'Themenseite',
    VERFAHREN: 'Verfahren',
    NEWS: 'News',
    TOPTHEMA: 'Top-Thema',
    ONLINESEMINAR: 'Onlineseminar',
    VIDEO: 'Video',
    ETRAINING: 'E-Training',
    UNKNOWN: 'Unbekannt',
};

export type DocTypeId = keyof typeof DocType;

export type DocType = (typeof DocType)[DocTypeId];

export const DocCategory = {
    ABKOMMEN: 'Abkommen',
    ANSCHREIBEN: 'Anschreiben',
    ANWENDUNG: 'Anwendung',
    ARBEIT: 'Arbeit',
    BEITRAG: 'Beitrag',
    BESPR: 'Besprechungsergebnis',
    BETRIEBSVEREINBARUNG: 'Betriebsvereinbarung',
    BFH: 'BFH',
    BVERFG: 'BVERFG',
    BUCHUNGSSATZ: 'Buchungssatz',
    CHECKLISTE: 'Checkliste',
    EINSPRUCHSMUSTER: 'Einspruchsmuster',
    ELEARNING: 'E-Training',
    ENTSCHEIDUNG: 'Entscheidung',
    ERKLAERUNG: 'Erkl\u00e4rung',
    ETRAINING: 'eTraining',
    EU: 'EU',
    EUGH: 'EUGH',
    EXPERTENTOOL: 'Expertentool',
    FACHWOERTER: 'Internationale Fachw\u00f6rter',
    FAQ: 'FAQ',
    FINANZ: 'Finanz',
    FORMULAR: 'Formular',
    GESETZ: 'Gesetz',
    INFO: 'Informationen',
    INFO_DATEI: 'Informationen',
    INSTRUMENTENSTECKBRIEF: 'Instrumentensteckbrief',
    INTERAKTIVEGRAFIK: 'Interaktive Grafik',
    LEXIKON: 'Lexikonstichwort',
    MERKBLATT: 'Merkblatt',
    MUSTER_AGB: 'Muster-AGB',
    MUSTERLOESUNG: 'Musterl\u00f6sung',
    MUSTERREDE: 'Musterrede',
    MUSTERTEXT: 'Mustertext',
    MUSTERVERTRAG: 'Mustervertrag',
    MUSTERVORLAGE: 'Mustervorlage',
    NORM: 'Norm',
    NOTENWECHSEL: 'Gesetz',
    ORDENTL: 'ORDENTL',
    PRAESENTATION: 'Pr\u00e4sentation',
    PROTOKOLL: 'Protokoll',
    PROZESSMUSTER: 'Prozessmuster',
    RECHNER: 'Rechner',
    REDE: 'Rede',
    RICHTLINIE: 'Richtlinie',
    RUNDSCHR: 'Rundschreiben',
    SONST: 'SONST',
    SONSTIGEARBEITSHILFE: 'Sonstige Arbeitshilfe',
    SONSTIGES: 'Sonstiges',
    SOZIAL: 'Sozial',
    TABELLE: 'Tabelle',
    TARIFVERTRAG: 'Tarifvertrag',
    TECHRICHTLINIE: 'Technische Richtlinie',
    UEBERSICHT: '\u00dcbersicht',
    VERF: 'VERF',
    VERTRAG: 'Vertrag',
    VERW: 'VERW',
    VERWALTUNGSANWEISUNG: 'Verwaltungsanweisung',
    VIDEO: 'Video',
    ZEUGNIS: 'Zeugnis',
};

export type DocCategoryId = keyof typeof DocCategory;

export type DocCategory = (typeof DocCategory)[DocCategoryId];

export const DocClassification = {
    ENTSCHEIDUNG: 'Urteilskommentierung',
    VERWALTUNGSANWEISUNG: 'Kommentierung',
    NORM: 'Kommentar',
};

export type DocClassificationId = keyof typeof DocClassification;

export type DocClassification = (typeof DocClassification)[DocClassificationId];
