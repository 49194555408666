import { APP_INITIALIZER, Provider } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { SVG_ICONS } from '@idr/ui/shared';

export const MATERIAL_PROVIDERS: Provider[] = [
    {
        provide: MAT_SELECT_CONFIG,
        useValue: {
            disableOptionCentering: true,
            overlayPanelClass: 'mat-select-overlay-panel',
            hideSingleSelectionIndicator: true,
        },
    },
    {
        provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
        useValue: {
            showDelay: 300,
            hideDelay: 300,
            touchendHideDelay: 300,
        },
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (matIconRegistry, domSanitizer) => () => {
            SVG_ICONS.forEach((icon: string) => {
                matIconRegistry.addSvgIcon(
                    icon,
                    domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`),
                );
            });
        },
        deps: [MatIconRegistry, DomSanitizer],
        multi: true,
    },
];
