import { enableProdMode } from '@angular/core';
import { toggleNormVersion } from '@idr/ui/shared';
import { environment } from './environments/environment';
import { loadSettingsAndInitApplication } from './init/load-settings-and-init-application';
import { patchEncodeUriComponent } from './init/patch-encode-uri-component';

patchEncodeUriComponent();

// needed for norm specific elements in crs documents
window['toggleNormVersion'] = toggleNormVersion;

if (environment.production) {
    enableProdMode();
}

loadSettingsAndInitApplication(environment);
