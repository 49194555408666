import { computed, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ProductId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { rxState } from '@rx-angular/state';
import { rxEffects } from '@rx-angular/state/effects';
import { Map as ImmutableMap } from 'immutable';
import { filter, first } from 'rxjs';
import { ActivatedProductGroupSignal } from '../routing/activated-product-group';
import { ProductSelectOption } from './product-select-option';

/**
 * An index that manages the list of available {@link ProductSelectOption} in your scope.
 *
 * You only need to extend this and define {@link configuration} to get the full functionality.
 * Use this when ever you have some config that is product related & the user needs to define which product(s) is/are involved.
 *
 * E.g. partner logo configuration
 *
 * @see ProductSelectComponent
 */
export abstract class ProductSelectOptionsState {
    readonly #logPrefix = '[ProductSelectOptionsState]';

    readonly #state = rxState<{
        readonly index: ImmutableMap<ProductId, boolean>;
    }>(({ set }) => set({ index: ImmutableMap() }));

    /**
     * The list of values within this index
     */
    readonly values: Signal<ProductSelectOption[]>;

    protected constructor(active: ActivatedProductGroupSignal) {
        const onInitialize$ = toObservable(computed(() => !!active())).pipe(filter(Boolean), first());

        // Ensuring consistent initial state
        rxEffects(({ register }) =>
            register(onInitialize$, () => {
                const configured = this.configuration() ?? [];
                const used: ProductId[] = [];
                for (const entry of configured) {
                    (entry.products ?? []).forEach(id => used.push(id));
                }

                for (const option of this.values()) {
                    this.set(option.id, used.includes(option.id));
                }
            }),
        );

        this.values = computed(() =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (active()!.products ?? []).map(
                product =>
                    <ProductSelectOption>{
                        ...product,
                        blocked: this.#state.computed(state => state.index().get(product.id) === true),
                    },
            ),
        );
    }

    protected abstract get configuration(): Signal<{ readonly products?: ProductId[] }[] | undefined>;

    /**
     * Updates the {@link ProductSelectOption.blocked} state within this index.
     * @param id id of the product that shall be updated
     * @param blocked the blocked value
     */
    set(id: ProductId, blocked: boolean) {
        logger.debug(this.#logPrefix, 'set ->', id, blocked);
        this.#state.set({ index: this.#state.get('index').set(id, blocked) });
    }
}
