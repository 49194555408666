<ng-container [ngTemplateOutlet]="selected.content" />
@if (steps.length > 1) {
    <nav [ngStyle]="{ margin: navMargin(), 'justify-content': navAlign() }">
        <button
            class="arrow prev"
            (click)="prev()"
            [hgGtmId]="[gtmArea(), 'select', 'arrow']"
            [hgGtmValues]="arrowButtonGtmValues()">
            <mat-icon svgIcon="chevron" />
        </button>
        <div>
            @for (step of steps.toArray(); let i = $index; track i) {
                <button
                    class="step"
                    (click)="onClick(step)"
                    [hgGtmId]="[gtmArea(), 'select', 'step']"
                    [hgGtmValues]="getStepButtonGtmValues(i + 1)"
                    [class.is-active]="i === selectedIndex"></button>
            }
        </div>
        <button
            class="arrow"
            (click)="next()"
            [hgGtmId]="[gtmArea(), 'select', 'arrow']"
            [hgGtmValues]="arrowButtonGtmValues()">
            <mat-icon svgIcon="chevron" />
        </button>
    </nav>
}
