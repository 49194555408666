import { Directive, Inject } from '@angular/core';
import { NPS_CONFIG, Product } from '@idr/shared/model';
import { getTimeStampOffsetByDays, logger } from '@idr/shared/utils';
import { ActiveProduct, DialogService, NPS_ID, ProfileService, UserSettingsService } from '@idr/ui/shared';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { INJECT_SURVEY_IFRAME_SRC } from './dialog/nps-dialog.component';

export const buildSurveyIframeSrc = (npsId: string, product: Product, encodedUserId: string): string => {
    const surveyParams = `&nps_id=${npsId}&eid=${product.shortName};${product.id};${encodedUserId}`;
    return `${NPS_CONFIG.surveyIFrameSrc}${surveyParams}`;
};

@Directive({
    selector: '[idrNps]',
    standalone: true,
})
export class NpsDirective {
    constructor(
        private readonly userSettingsService: UserSettingsService,
        private readonly profileService: ProfileService,
        private readonly dialogService: DialogService,
        activeProduct: ActiveProduct,
        @Inject(NPS_ID) npsId: string,
    ) {
        logger.debug('[NpsDirective] init');

        this.#shouldOpenDialog$
            .pipe(
                filter(shouldOpenDialog => shouldOpenDialog),
                switchMap(() => combineLatest([profileService.encodedUserId$, activeProduct.product$])),
                filter(([encodedUserId, product]) => !!encodedUserId && !!product && !!npsId),
                map(([encodedUserId, product]) => buildSurveyIframeSrc(npsId, product, encodedUserId)),
                tap(surveyIframeSrc => this.#openDialog(surveyIframeSrc)),
            )
            .subscribe();
    }

    async #openDialog(surveyIframeSrc: string): Promise<void> {
        logger.debug('[NpsDirective] #openDialog');

        const component = (await import('./dialog/nps-dialog.component')).NpsDialogComponent;
        await this.dialogService.openWithComponent(component, [
            {
                provide: INJECT_SURVEY_IFRAME_SRC,
                useValue: surveyIframeSrc,
            },
        ]);
    }

    get #shouldOpenDialog$(): Observable<boolean> {
        return combineLatest([this.userSettingsService.settings$, this.profileService.isAnonymous$]).pipe(
            filter(([userSettings, isUserAnonymous]) => {
                if (isUserAnonymous) {
                    return false;
                }
                logger.debug(
                    '[NpsDirective] #shouldOpenDialog$',
                    userSettings?.visitCount,
                    NPS_CONFIG.showAfterMinVisits,
                );
                const visitCountFulfilled = userSettings?.visitCount >= NPS_CONFIG.showAfterMinVisits;
                const firstVisitFulfilled =
                    getTimeStampOffsetByDays(-NPS_CONFIG.showAfterMinDays) > userSettings?.firstVisitDate;
                logger.debug(
                    '[NpsDirective] shouldOpenDialog$ `visitCountFulfilled` & `firstVisitFulfilled`',
                    visitCountFulfilled,
                    firstVisitFulfilled,
                );
                return visitCountFulfilled && firstVisitFulfilled;
            }),
            switchMap(([userSettings]) => {
                if (!userSettings?.nps?.nextSurveyAfterDate) {
                    const nextSurveyAfterDate = getTimeStampOffsetByDays(
                        Math.ceil(Math.random() * NPS_CONFIG.initialRandomizationBaseNum),
                    );

                    logger.debug(
                        '[NpsDirective] shouldOpenDialog$ -> will update user settings with new `nextSurveyAfterDate` data',
                        nextSurveyAfterDate,
                    );
                    return this.userSettingsService
                        .updateSettings$({ nps: { nextSurveyAfterDate } })
                        .pipe(map(() => nextSurveyAfterDate));
                }

                return of(userSettings.nps.nextSurveyAfterDate);
            }),
            map(nextSurveyAfterDate => nextSurveyAfterDate < Date.now()),
            tap(shouldOpen => logger.debug('[NpsDirective] #shouldOpenDialog$ ->', shouldOpen)),
        );
    }
}
