import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiName,
    BauplanId,
    Breadcrumb,
    ContentHierarchyDTO,
    CrsContentHierarchyPathsDTO,
    CrsExceptionDTO,
    DocumentId,
    DocumentIdHelper,
    Link,
    NodeDTO,
    Product,
    ProductId,
} from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ActiveProduct, MessageService } from '@idr/ui/shared';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { convertLinkFromNodeDTO } from '../model/converter';
import { CrsBaseService } from '@idr/ui/crs-facade';

export const BauplanRequestType = {
    RequestMeta: 0,
    RequestChildren: 1,
};

export type BauplanRequestType = (typeof BauplanRequestType)[keyof typeof BauplanRequestType];

export interface BauplanFilterOptions {
    /**
     * a prefix search with given string on title of questionable node
     */
    readonly title?: string;
}

/**
 * @returns the given query encoded specifically for CRS...
 */
const getEncodedQuery = (query: string): string => {
    // https://gitlab.haufedev.systems/aurora/backend/aurora.core/-/blob/master/aua-al-impl/src/main/java/de/haufe/aurora/dataaccess/crs/provider/CRSURIBuilder.java
    // => CRS does encode 2 times... we need to do that as well so we don't get into trouble with special characters, like '/', '%', ...
    return encodeURIComponent(encodeURIComponent(query));
};

@Injectable({ providedIn: 'root' })
export class CrsBauplanService extends CrsBaseService {
    constructor(
        private readonly activeProduct: ActiveProduct,
        messageService: MessageService,
        httpClient: HttpClient,
    ) {
        super(messageService, httpClient);
    }

    /**
     *
     * @param id the id of the node you want to request
     * @param type backend only allows `0` or `1`
     *         `0` => you'll get the meta information of requested node
     *         `1` => you'll get the entries for the requested node (the child nodes)
     * @param filterOptions you may pass filter options to filter requested children (only active when you request children)
     */
    getBauplanNode$(
        id: BauplanId,
        type: BauplanRequestType,
        filterOptions?: BauplanFilterOptions,
    ): Observable<NodeDTO> {
        if (id === undefined || type === undefined) {
            return throwError(() => new Error(`'Expected \`id\` (${id}) and \`type\` (${type}) to be set.`));
        }

        const product: Product = this.activeProduct.product;

        // https://gitlab.haufedev.systems/aurora/crs/aurora.crs.Products.ContentretrievalREST/-/blob/master/src/Products/ContentretrievalREST/browser/v2/doc/contentHierarchy.md
        const requestChildren: boolean = type === BauplanRequestType.RequestChildren;
        const isFilteredByTitle: boolean = requestChildren && filterOptions && !!filterOptions.title;
        const titleParam: string = isFilteredByTitle ? `/title/${getEncodedQuery(filterOptions.title)}` : '';
        const url = `${ApiName.CRS}/auroraContentHierarchy/product/${product.id}/ID/${id}/depth/${type}${titleParam}`;

        return this.makeCrsGetRequest<ContentHierarchyDTO | CrsExceptionDTO>(url).pipe(
            map((contentHierarchyDTO: ContentHierarchyDTO) => {
                return contentHierarchyDTO.node[0];
            }),
        );
    }

    getBauplanMeta$(id: BauplanId): Observable<NodeDTO> {
        return this.getBauplanNode$(id, BauplanRequestType.RequestMeta);
    }

    getBauplanChildren$(id: BauplanId, startsWith?: string): Observable<NodeDTO[]> {
        const request: Observable<NodeDTO> = startsWith
            ? this.getBauplanNode$(id, BauplanRequestType.RequestChildren, { title: startsWith })
            : this.getBauplanNode$(id, BauplanRequestType.RequestChildren);

        return request.pipe(map((node: NodeDTO) => node.subnodes.node));
    }

    getBauplanChildrenAsLink$(id: BauplanId): Observable<Link[]> {
        return this.getBauplanNode$(id, BauplanRequestType.RequestChildren).pipe(
            map(data => data.subnodes.node.map(node => convertLinkFromNodeDTO(node))),
        );
    }

    getBreadcrumbForBauplanId$(bauplanId: BauplanId, productId: ProductId): Observable<Breadcrumb[]> {
        const path = `ID/${bauplanId}`;
        return this.#getBreadcrumb$(path, productId);
    }

    getBreadcrumbForDocumentId$(documentId: DocumentId, productId: ProductId): Observable<Breadcrumb[]> {
        const path = `docid/${DocumentIdHelper.cutAnchor(documentId)}`;
        return this.#getBreadcrumb$(path, productId);
    }

    #getBreadcrumb$(path: string, productId: ProductId): Observable<Breadcrumb[]> {
        logger.debug('[CrsBauplanService] getBreadcrumb$ ->', path, productId);
        return this.makeCrsGetRequest<CrsContentHierarchyPathsDTO | CrsExceptionDTO>(
            `${ApiName.CRS}/contentHierarchyPaths/product/${productId}/${path}`,
        ).pipe(map((data: CrsContentHierarchyPathsDTO) => Breadcrumb.fromJson(data)));
    }
}
