import { inject, InjectionToken } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { LocalStorageKey, LocalStorageService } from '../local-storage';
import { LocationService } from '../location';
import { REALM_PARAM_NAME } from './auth-params';
import { Realm } from './realms';

/**
 * This token handles the CSSO realm of the app.
 *
 * The realm defines which idp is used for the login and is defined by the query param "auth_realm" when visiting the application.
 * see {@link Realm} for the possible values.
 *
 * The realm is stored in the state of the app and in the local storage (in case the user wants to refresh the page).
 */
export const REALM: InjectionToken<Realm> = new InjectionToken('Realm', {
    providedIn: 'root',
    factory: () => {
        const localStorage = inject(LocalStorageService);
        const location = inject(LocationService);

        const ensureSetInLocalStorage = (realm: Realm) => localStorage.set(LocalStorageKey.REALM, realm);

        const realm: Realm | null = location.searchParams.get(REALM_PARAM_NAME);
        if (!realm) {
            const storedRealm: Realm | undefined = localStorage.get(LocalStorageKey.REALM);
            if (!storedRealm) {
                logger.debug('[REALM] -> no realm found in; falling back to default', Realm.DEFAULT);
                ensureSetInLocalStorage(Realm.DEFAULT);
                return Realm.DEFAULT;
            }

            logger.debug('[REALM] -> found in  local storage', storedRealm);
            ensureSetInLocalStorage(storedRealm);
            return storedRealm;
        }

        ensureSetInLocalStorage(realm);
        logger.debug('[REALM] -> found in search params', realm);
        return realm;
    },
});
