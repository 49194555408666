import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { stripHtml } from '../utils';

@Pipe({ name: 'hgStripHtml', standalone: true })
export class StripHtmlPipe implements PipeTransform {
    // it might be of type `SafeHtml` as well but it can be cast safely to string
    // (although in type definition `SafeHtml` is only an empty interface)
    public transform(value: string | SafeHtml): string {
        return stripHtml(value); // replace tags
    }
}
