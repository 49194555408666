import { Directive, Inject, NgZone } from '@angular/core';
import { DialogService, WINDOW } from '@idr/ui/shared';

/**
 * Directive to open the user feedback dialog from outside angular.
 *
 * Usually the feedback dialog is opened when user selects text in the document view.
 * But we also have the use case to open the dialog from the UserSnap menu.
 * For that we need to expose a function that can be called by UserSnap.
 */
@Directive({
    selector: '[idrUserFeedback]',
    standalone: true,
})
export class UserFeedbackDirective {
    constructor(
        zone: NgZone,
        @Inject(WINDOW) windowRef: Window,
        private readonly dialogService: DialogService,
    ) {
        windowRef['userFeedback'] = async () => {
            await zone.runGuarded(() => this.openFeedbackDialog());
        };
    }

    private async openFeedbackDialog(): Promise<void> {
        const component = (await import('../../../feedback/feedback-dialog.component')).FeedbackDialogComponent;
        this.dialogService.openWithComponent(component);
    }
}
