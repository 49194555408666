import { BauplanId } from './bauplan/bauplan-id';
import { DocumentId } from './document/document-id';
import { HasLabel } from './has-label';
import { HasSource } from './has-source';

export const LinkType = {
    BAUPLAN_INDEX: 'bauplan',
    DOCUMENT: 'document',
    URL: 'url',
    NEW_DOCUMENTS: 'newDocuments',
    RECOMMENDATIONS: 'recommendations',
    TOPIC_PAGE: 'topicPage',
};

export type LinkType = (typeof LinkType)[keyof typeof LinkType];

export interface Link extends HasLabel, HasSource {
    readonly source: BauplanId | DocumentId | string;
    readonly type: LinkType;
    readonly imageUrl?: string;
    readonly description?: string;
    readonly daysBefore?: number;
    readonly context?: BauplanId;
}
