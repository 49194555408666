import { Injectable } from '@angular/core';
import { DEFAULT_PRODUCT_GROUP, Product, PRODUCT_GROUPS } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ProductService } from '@idr/ui/shared';
import { List as ImmutableList } from 'immutable';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductGroupService {
    private readonly logPrefix = '[ProductGroupService]';

    constructor(private readonly productService: ProductService) {}

    /**
     * @returns Observable of the licensed {@link Product}'s (i.e. the products that the user has access to) for the given product group id
     * @throws Error if no product group can be found for the given product group id
     */
    public getLicensedProductsForGroupId$(productGroupId: string): Observable<ImmutableList<Product>> {
        const allLicensedProducts$ = this.productService.products$.pipe(
            take(1),
            map(licensed => ImmutableList.of(...licensed)),
        );

        if (productGroupId === DEFAULT_PRODUCT_GROUP) {
            return allLicensedProducts$;
        }

        const productGroup = PRODUCT_GROUPS.find(group => group.id === productGroupId);
        if (!productGroup) {
            const errorMessage = `No product group found for the requested (${productGroupId}) product group id.`;
            logger.warn(
                this.logPrefix,
                `getLicensedProductsForGroupId$ -> will throw error`,
                productGroupId,
                errorMessage,
            );
            throw new Error(errorMessage);
        }

        return allLicensedProducts$.pipe(
            map(licensed => licensed.filter(product => productGroup.productIds.includes(product.id))),
            tap(licensed => logger.debug(this.logPrefix, 'getLicensedProductsForGroupId$->', productGroupId, licensed)),
        );
    }
}
