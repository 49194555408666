import { Directive, Inject, TemplateRef, ViewContainerRef } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { rxEffects } from '@rx-angular/state/effects';
import { Observable } from 'rxjs';
import { USER_IS_ALLOWED$ } from '../services/user-is-allowed';

/**
 * This directive will hide the element that is attached to for anonymous users.
 */
@Directive({ selector: '[mcHideForUnauthorized]', standalone: true })
export class HideForUnauthorizedDirective {
    private readonly logPrefix = '[HideForUnauthorizedDirective]';

    constructor(
        @Inject(USER_IS_ALLOWED$) userIsAllowed$: Observable<boolean>,
        viewContainerRef: ViewContainerRef,
        templateRef: TemplateRef<unknown>,
    ) {
        rxEffects(({ register }) =>
            register(userIsAllowed$, allowed => {
                if (!allowed) {
                    logger.warn(this.logPrefix, "current user isn't allowed. Will clear view");
                    viewContainerRef.clear();
                    return;
                }

                logger.debug(this.logPrefix, 'current user is allowed. Will show assigned template.');
                viewContainerRef.createEmbeddedView(templateRef);
            }),
        );
    }
}
