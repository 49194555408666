import { CrsDocumentDTO, CrsHitlistDTO, CrsProductHitsDTO, DocumentId } from '@idr/shared/model';
import { FlatDocumentPart, getFlatDocumentParts } from './flat-document-handling';
import { List as ImmutableList } from 'immutable';
import { DocumentSearchResult } from '@idr/ui/shared';

export const createDocumentSearchResultFromFlatDocDto = (query: string, dto: CrsDocumentDTO): DocumentSearchResult => {
    const parts: FlatDocumentPart[] = getFlatDocumentParts(dto);
    const searchHits: DocumentId[] = parts.reduce((acc: DocumentId[], part) => {
        // hits are embed in a <span class="hl">...</span> element by backend
        const documentContainsHit = /<span class="[^"]*hl/.test(part.content);
        if (documentContainsHit) {
            return [...acc, part.id];
        }
        return acc;
    }, []);

    if (searchHits.length === 0) {
        return createNotFoundDocumentSearchResult(query);
    }

    return new DocumentSearchResult(ImmutableList(searchHits), query);
};

export const createDocumentSearchResultFromHitListDto = (
    query: string,
    crsHitlistDTO: CrsHitlistDTO,
): DocumentSearchResult => {
    const crsProductDTO: CrsProductHitsDTO | undefined = crsHitlistDTO?.product?.[0];
    if (crsProductDTO) {
        return new DocumentSearchResult(ImmutableList(crsProductDTO?.hit?.map(hit => hit.docid)), query);
    }

    return createNotFoundDocumentSearchResult(query);
};

export const createDocumentSearchResultFromCrsError = (query: string, errorMessage: string): DocumentSearchResult => {
    // not relevant if it is 'Syntaxfehler:' or 'Fehlerhafte Anfrage:'
    if (errorMessage.indexOf(':') > 0) {
        errorMessage = errorMessage.substring(errorMessage.indexOf(':') + 1).trim();
    }
    return new DocumentSearchResult(ImmutableList(), query, errorMessage);
};

export const createNotFoundDocumentSearchResult = (query: string): DocumentSearchResult => {
    return new DocumentSearchResult(ImmutableList(), query, 'Nicht gefunden!');
};
