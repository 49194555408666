export type BrandLabelDTO = 'Haufe' | 'Lexware' | 'Schäffer Poeschel';
export type BrandLabel = 'Haufe' | 'Lexware' | 'Schäffer Poeschel';

// this is used to get the correct company logo from the svg sprite
export const BrandId = {
    Haufe: 'haufe',
    Lexware: 'lexware',
    SchaefferPoeschel: 'sp',
};

export type BrandId = (typeof BrandId)[keyof typeof BrandId];

export const BRAND_IDS: BrandId[] = Object.values(BrandId);
