import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { EnvironmentProviders, Provider, importProvidersFrom } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NoPreloading, provideRouter, withPreloading, withRouterConfig } from '@angular/router';
import { routes } from '../app/app/app.routes';
import { APP_INITIALIZER_PROVIDERS } from './providers/app-initializer-providers';
import { HTTP_INTERCEPTOR_PROVIDERS } from './providers/http-interceptor-providers';
import { HTTP_INTERCEPTORS } from './providers/http-interceptors';
import { INJECTION_TOKENS } from './providers/injection-tokens';
import { MATERIAL_PROVIDERS } from './providers/material-providers';
import { MODULES } from './providers/modules';

/**
 * Contains all the providers that are needed by the main.ts file in order to bootstrap the application.
 */
export const MAIN_PROVIDERS: (EnvironmentProviders | Provider)[] = [
    // deferring it safes us initial bundle size
    // => https://blog.angular.io/introducing-angular-v17-4d7033312e4b
    // animations are needed by material components, like e.g. mat-select
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors(HTTP_INTERCEPTORS), withInterceptorsFromDi()),
    provideRouter(
        routes,
        // withDebugTracing(), // for debugging; don't use it in production
        // we once had `PreloadAllModules` here ... but, that one causes problems with e2e cypress tests
        // e.g. the `search-page-hot.cy.ts` with a topic page in first position
        // => the resolver run already BEFORE the lazy loaded `TopicPageTeaserComponent` got loaded
        //    AND this resulted in a blank page. I only saw this happening in Cypress context
        //    (I couldn't reproduce it as a user)
        // Since the snippets are small and,
        //  we assume good latency from our users no preloading is also fine
        withPreloading(NoPreloading),
        withRouterConfig({ onSameUrlNavigation: 'reload' }),
    ),
    importProvidersFrom(MODULES),
    ...MATERIAL_PROVIDERS,
    ...HTTP_INTERCEPTOR_PROVIDERS,
    ...APP_INITIALIZER_PROVIDERS,
    ...INJECTION_TOKENS,
];
