import { ChangeDetectionStrategy, Component, computed, effect, input, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Params } from '@angular/router';
import { noop } from 'rxjs';
import { LocationService } from '../../services';

export class PagingState {
    readonly disableToFirstPage: boolean;
    readonly disableToLastPage: boolean;
    readonly pageCount: number;

    constructor(
        readonly hitsCount = 0,
        readonly pageSize = 20,
        readonly page = 1,
        readonly pages: number[],
        readonly hitRange: string,
    ) {
        this.pageCount = Math.ceil(hitsCount / pageSize);
        this.disableToFirstPage = page === 1;
        this.disableToLastPage = page === this.pageCount;
    }
}

const extractParam = (queryParams: Params | undefined, key: string, defaultValue: number): number =>
    +queryParams?.[key] || defaultValue;

const extractPageParam = (queryParams: Params | undefined): number => extractParam(queryParams, 'page', 1);

const extractPageSizeParam = (queryParams: Params | undefined): number => extractParam(queryParams, 'pageSize', 40);

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule],
    selector: 'hg-paging',
    standalone: true,
    styleUrls: ['./paging.component.scss'],
    templateUrl: './paging.component.html',
})
export class PagingComponent {
    readonly hitsCount = input.required<number>();
    readonly noop = noop;
    readonly pageSizeOptions: number[] = [20, 40, 60];
    readonly state: Signal<PagingState>;

    constructor(
        activatedRoute: ActivatedRoute,
        private readonly location: LocationService,
    ) {
        const queryParams = toSignal(activatedRoute.queryParams);
        this.state = computed(() => this.#createPageState(queryParams(), this.hitsCount()));

        effect(() => {
            const page = extractPageParam(queryParams());
            const state = this.state();
            // NAUA-5414: url has also to be adjusted so that the table is loaded correctly
            if (state.page !== page) {
                this.pageClicked(state.page);
            }
        });
    }

    pageClicked(page: number): void {
        this.location.updateQueryParameters({ page });
    }

    pageSizeClicked(pageSize: number): void {
        this.location.updateQueryParameters({ pageSize });
    }

    #createPageState(queryParameter: Params | undefined, hitsCount: number): PagingState {
        const pageSize: number = extractPageSizeParam(queryParameter);
        const pageCount: number = Math.ceil(hitsCount / pageSize);
        const page: number = Math.min(extractPageParam(queryParameter), pageCount);
        const pages: number[] = hitsCount > 0 ? this.#createPages(page, pageCount) : [];

        const hitRange = `${(page - 1) * pageSize + 1} - ${Math.min(page * pageSize, hitsCount)}`;
        return new PagingState(hitsCount, pageSize, page, pages, hitRange);
    }

    #createPages(page: number, pageCount: number): number[] {
        let pages: number[] = [];

        const innerPages: number[] = Array.from({ length: 5 }, (_, i) => i + page - 2).filter(
            i => i > 1 && i < pageCount,
        );

        pages.push(1);
        if (innerPages[0] > 2) {
            pages.push(-1);
        }
        pages = pages.concat(innerPages);
        if (innerPages[innerPages.length - 1] < pageCount - 1) {
            pages.push(-1);
        }
        if (pageCount > 1) {
            pages.push(pageCount);
        }

        return pages;
    }
}
