import { logger } from '@idr/shared/utils';

/**
 * A simple helper that will ensure that given image url is prefetched by browser.
 */
export const prefetchImage = (url: string) => {
    if (!url) {
        return;
    }

    const img: HTMLImageElement = new Image();
    img.onload = () => logger.debug('[prefetchImage] -> image prefetched', url);
    img.src = url;
};
