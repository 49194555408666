import { Injectable, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Used to inform user about the connection status.
 */
@Injectable({ providedIn: 'root' })
export class OnlineAccessService {
    readonly isOnline$: Observable<boolean>;

    readonly #isOnline$ = new BehaviorSubject(navigator.onLine);

    constructor(rendererFactory: RendererFactory2) {
        const renderer = rendererFactory.createRenderer(null, null);
        renderer.listen('window', 'offline', () => this.#isOnline$.next(false));
        renderer.listen('window', 'online', () => this.#isOnline$.next(true));
        this.isOnline$ = this.#isOnline$.pipe(distinctUntilChanged());
    }
}
