import { Inject, Injectable, NgZone } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { logger } from '@idr/shared/utils';
import { WINDOW } from './window';

/**
 * Service that holds DOM utilities, like encapsulating low level Window, scroll helpers for elements, ....
 *
 * Helps to improve testability of services like ScrollService which heavily relies on the window object
 */
@Injectable({ providedIn: 'root' })
export class DomService {
    readonly #logPrefix = '[DomService]';

    constructor(
        @Inject(WINDOW) private readonly windowRef: Window,
        private readonly ngZone: NgZone,
    ) {}

    get document(): Document {
        return this.windowRef.document;
    }

    get mainOffsetHeight(): number {
        return this.query<HTMLElement>('main')?.offsetHeight ?? 0;
    }

    get innerHeight(): number {
        return this.windowRef.innerHeight;
    }

    get innerWidth(): number {
        return this.windowRef.innerWidth;
    }

    /**
     * Will call {@link Element.scrollIntoView} for given element if needed...
     * For given element this will check if it is visible on screen inside our apps boundary already. If yes, nothing will happen.
     */
    ensureScrolledIntoView(
        element: Element,
        block: ScrollLogicalPosition = 'start',
        behavior: ScrollBehavior = 'smooth',
        inside?: HTMLElement,
        callback?: () => void,
    ): void {
        if (!element) {
            return;
        }

        const containerHeight = inside?.offsetHeight ?? this.innerHeight;
        const elementBottom = element.getBoundingClientRect().bottom;

        // we ignore the last ~1/5 of the screen...
        // => when an element is only at the "very bottom" of screen we scroll up again
        const scrollIt = elementBottom > (4 / 5) * containerHeight || elementBottom < (1 / 5) * containerHeight;

        if (scrollIt) {
            element.scrollIntoView({ block, behavior });
        }

        // ensuring that if needed we call the callback after smooth scroll has ended
        if (callback && scrollIt && behavior === 'smooth') {
            this.ngZone.runOutsideAngular(() => {
                let scrollContainer: Document | HTMLElement | undefined = inside ?? this.document;
                let scrollEndHandler: EventListener | undefined = () => {
                    logger.debug(this.#logPrefix, 'ensureScrolledIntoView.onScrollEnd');
                    scrollContainer?.removeEventListener('scrollend', scrollEndHandler as EventListener);
                    scrollEndHandler = undefined;
                    scrollContainer = undefined;
                    callback();
                };

                scrollContainer.addEventListener('scrollend', scrollEndHandler);
            });
            return;
        }

        // in any other case (not scrolled or scrolled instant) we should call the callback directly
        callback?.();
    }

    /**
     * finds the last child of layered dom elements
     *
     * @param element one element out of elements
     * @param elements the layered elements
     */
    findLastChild(element: Element, elements: Element[]): Element {
        const childElement: Element | undefined = elements.find(e => e.parentElement === element);
        return childElement !== undefined ? this.findLastChild(childElement, elements) : element;
    }

    /**
     * @see Document.getElementById
     */
    getElementById(elementId: string): HTMLElement | null {
        return this.document.getElementById(elementId);
    }

    getSCSSPropertyAsNumber(property: string): number {
        return Number.parseInt(getComputedStyle(this.document.body).getPropertyValue(property), 10);
    }

    /**
     * checks if the element parent is a child of the element child (also over several layers)
     */
    isChildDescendantFromParent(parent: Element, child: Element): boolean {
        return !!parent && parent.contains(child);
    }

    /**
     * Same as DomService.setTimeout() but will run outside Angular and thus will not trigger change detection.
     * For example when you want to change some dom elements directly.
     * In such a case we don't want any change detection.
     */
    setTimeoutOutsideAngular(handler: TimerHandler, timeout = 0): void {
        this.ngZone.runOutsideAngular(() => setTimeout(handler, timeout));
    }

    /** @see Document.querySelector */
    query<E extends Element>(selector: string): E | null {
        return this.document.querySelector(selector);
    }

    /** @see Document.querySelectorAll */
    queryAll<E extends Element>(selector: string): NodeListOf<E> {
        return this.document.querySelectorAll(selector);
    }

    /**
     * Sets `href` of our favicon element to given {@param href}.
     */
    setFaviconHref(href: SafeResourceUrl): void {
        const faviconElement: HTMLLinkElement | null = this.document.querySelector('link[rel~="icon"]');
        if (faviconElement) {
            faviconElement.href = `${href}`;
        }
    }

    openExternalUrl(url: string): void {
        this.windowRef.open(url, '_blank');
    }
}
