import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    ApiName,
    ContentHubContentType,
    ContentHubDocumentDTO,
    ContentType,
    HotConfig,
    HotDocumentsDTO,
    MetaDTO,
    NewsAuthorMetaDTO,
    NewsConfig,
    NewsDocumentsDTO,
    NewsMetaDTO,
} from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import {
    ContentHubApplication,
    ContentHubAuthor,
    contentHubAuthorFromDto,
    ContentHubDocumentType,
    ContentHubImage,
    ContentHubQueryOptions,
    ContentHubSearchQuery,
    HotDocuments,
    HotDocumentsWrapper,
    NewsDocuments,
    NewsDocumentsWrapper,
} from '@idr/ui/content-hub';
import { REALM, Realm } from '@idr/ui/shared';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActiveStartPage } from '../product/active-start-page';

@Injectable({ providedIn: 'root' })
export class ContentHubService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly activeStartPage: ActiveStartPage,
        @Inject(REALM) private readonly realm: Realm,
    ) {}

    requestContentHubDocument$<Meta extends Partial<MetaDTO>>(
        contentHubId: string,
    ): Observable<ContentHubDocumentDTO<Meta>> {
        return this.httpClient.get<ContentHubDocumentDTO<Meta>>(
            `${ApiName.CONTENTHUB}/retrieval/baseline?contentHubId=${contentHubId}`,
            { headers: { Accept: '*/*' } },
        );
    }

    requestContentHubImage$(contentHubId: string): Observable<ContentHubImage> {
        return this.requestContentHubDocument$<NewsMetaDTO>(contentHubId).pipe(
            map(response => ContentHubImage.fromDTO(response)),
        );
    }

    requestContentHubAuthors$(contentHubId: string): Observable<ContentHubAuthor> {
        return this.requestContentHubDocument$<NewsAuthorMetaDTO>(contentHubId).pipe(
            map(response => contentHubAuthorFromDto(response)),
        );
    }

    requestHotDocuments$(options?: ContentHubQueryOptions): Observable<HotDocumentsWrapper> {
        return this.activeStartPage.getConfig$<HotConfig>(ContentType.HOT).pipe(
            switchMap(hotConfig => {
                if (!hotConfig) {
                    logger.debug('[ContentHubService] #requestHotDocuments$() no hotConfig');
                    return of({ error: new Error('No HOT config found.') });
                }

                // adding hotConfig specific options
                options = {
                    ...options,
                    application: ContentHubApplication.HOT,
                    // packageId is basically a preconfigured filter that is prepared and set in product config so that we can easily filter results.
                    packageId: options?.packageId ?? hotConfig.service,
                    hotCategories: options?.hotCategories ?? hotConfig.categories,
                };

                const url = ContentHubSearchQuery.buildUrl(options);
                return this.httpClient.get<HotDocumentsDTO>(url).pipe(
                    map(response => {
                        return { documents: HotDocuments.fromDTO(response, this.realm, hotConfig) };
                    }),
                );
            }),
            catchError(error => {
                logger.error(
                    `[ContentHubService] #requestHotDocuments$(${JSON.stringify(options)}) failed with`,
                    error,
                );
                return of({ error });
            }),
        );
    }

    requestHotDocumentsSearchPage$(options?: ContentHubQueryOptions): Observable<HotDocumentsWrapper> {
        return this.activeStartPage.getConfig$<HotConfig>(ContentType.HOT).pipe(
            switchMap(hotConfig => {
                if (!hotConfig) {
                    logger.debug('[ContentHubService] #requestHotDocumentsSearchPage$() no hotConfig');
                    return of({ error: new Error('No HOT config found.') });
                }

                options = {
                    ...options,
                    application: ContentHubApplication.HOT,
                    facet: 'documentType',
                    packageId: hotConfig.service,
                    hotCategories: hotConfig.categories,
                };
                const url = ContentHubSearchQuery.buildUrl(options);
                return this.httpClient.get<HotDocumentsDTO>(url).pipe(
                    map(response => ({ documents: HotDocuments.fromDTO(response, this.realm, hotConfig) })),
                    catchError(error => {
                        logger.error(
                            `[ContentHubService] #requestHotDocuments$(${JSON.stringify(options)}) failed with`,
                            error,
                        );
                        return of({ error });
                    }),
                );
            }),
        );
    }

    requestNewsDocuments$(options: ContentHubQueryOptions): Observable<NewsDocumentsWrapper> {
        return this.activeStartPage.getConfig$<NewsConfig>(ContentType.NEWS).pipe(
            switchMap(newsConfig => {
                // There are cases that the product doesn't have a news configuration specified
                if (!newsConfig) {
                    logger.debug('[ContentHubService] #requestNewsDocuments$() no newsConfig');
                    return of({ error: new Error('No news config found.') });
                }

                const foundPortalConfig = newsConfig.items?.find(item => item.type === ContentHubContentType.PORTAL);
                if (!foundPortalConfig) {
                    logger.debug('[ContentHubService] #requestNewsDocuments$() no news portal config');
                    return of({ error: new Error('No news portal config found.') });
                }

                return of(foundPortalConfig).pipe(
                    switchMap(newsItem => {
                        // We need to get news up to 6 months old.
                        const sortDate: Date = new Date();
                        sortDate.setMonth(sortDate.getMonth() - 6);

                        options = {
                            ...options,
                            application: ContentHubApplication.PORTALS,
                            documentTypes: [
                                ContentHubDocumentType.NEWS,
                                ContentHubDocumentType.NEWS_PROXY,
                                ContentHubDocumentType.INSTALLMENT,
                                ContentHubDocumentType.INSTALLMENT_PROXY,
                            ],
                            sortDate: `>=${sortDate.toISOString()}`,
                            visibleInSuite: true,
                            portalsCategories: newsItem.portals,
                        };
                        const url = ContentHubSearchQuery.buildUrl(options);
                        return this.httpClient.get<NewsDocumentsDTO>(url);
                    }),
                    map(newsDocumentsDTO => ({
                        documents: NewsDocuments.fromDTO(newsDocumentsDTO, this.realm),
                    })),
                );
            }),
            catchError(error => {
                logger.error(
                    `[ContentHubService] #requestNewsDocuments$(${JSON.stringify(options)}) failed with`,
                    error,
                );
                return of({ error });
            }),
        );
    }
}
