import { ReloadedUserSettingsDTO } from './user-settings';

/**
 * ReloadedUserSettings represents the user settings in the Reloaded app.
 * The settings stored in the user settings API contain more than just the settings for reloaded.
 * The settings for reloaded are stored in the `reloaded` property.
 * There is a class called {@link UserSettings} that represents the whole user settings object which is stored in the API.
 */
export class ReloadedUserSettings {
    constructor(public readonly values: ReloadedUserSettingsDTO = {}) {}

    public static withInitialSettings(userSettings: ReloadedUserSettingsDTO): ReloadedUserSettings {
        return new ReloadedUserSettings(userSettings);
    }

    public withFallbackSettings(): ReloadedUserSettings {
        return new ReloadedUserSettings()
            .withDisabledTour()
            .withDisabledBetaTestingElements()
            .withoutSearchHitlistFeedbackComponent();
    }

    public withDisabledTour(): ReloadedUserSettings {
        return new ReloadedUserSettings({
            ...this.values,
            productTourFinished: {
                startPage: true,
                documentPage: true,
                searchPage: true,
            },
        });
    }

    public withDisabledBetaTestingElements(): ReloadedUserSettings {
        return new ReloadedUserSettings({
            ...this.values,
            onboarding: {
                surveyPopupShown: true,
                thankYouPopupShown: true,
            },
        });
    }

    public withBetaTestingDialogShown(dialog: 'survey' | 'thankYou', value: boolean): ReloadedUserSettings {
        return new ReloadedUserSettings({
            ...this.values,
            onboarding: {
                ...this.values.onboarding,
                [`${dialog}PopupShown`]: value,
            },
        });
    }

    public withVisitCount(count: number): ReloadedUserSettings {
        return new ReloadedUserSettings({
            ...this.values,
            visitCount: count,
        });
    }

    public withoutSearchHitlistFeedbackComponent(): ReloadedUserSettings {
        return new ReloadedUserSettings({
            ...this.values,
            search: {
                showSearchFeedbackAgainAt: new Date('2222-02-02').getTime(),
            },
        });
    }
}
