import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Injector,
    Output,
    runInInjectionContext,
    ViewChild,
} from '@angular/core';
import { EcondaTarget, EcondaTargetGroup, EcondaTargetName } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { RouterEvents } from '@idr/ui/shared';
import { rxEffects } from '@rx-angular/state/effects';
import { RxPush } from '@rx-angular/template/push';
import { delay, map } from 'rxjs/operators';
import { EcondaTrackingService } from '../../../../core/tracking/econda-tracking.service';
import { SearchInputComponent } from '../../../../shared/components/search-input/search-input.component';
import { SuggestionsComponent } from '../../../../shared/components/search-input/suggestion/suggestions.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RxPush, SearchInputComponent, SuggestionsComponent],
    selector: 'idr-header-search-input',
    standalone: true,
    styles: [],
    templateUrl: './header-search-input.component.html',
})
export class HeaderSearchInputComponent implements AfterViewInit {
    @Output() public readonly submitCalled: EventEmitter<string> = new EventEmitter();

    @ViewChild('searchInput') public searchInput: SearchInputComponent;

    public readonly econdaTargetGroup: EcondaTargetGroup = EcondaTargetGroup.Header;

    private readonly logPrefix = '[HeaderSearchInputComponent]';

    constructor(
        private readonly injector: Injector,
        private readonly routerEvents: RouterEvents,
        private readonly econdaTrackingService: EcondaTrackingService,
    ) {}

    public ngAfterViewInit(): void {
        runInInjectionContext(this.injector, () => {
            // Why do we assign the query manually instead of using the [query]="query$ | async" in the template?
            // If we do so, the query is not updated in the following scenario:
            // 1. User is in the search page where query param is undefined
            // 2. User searches for "estg 3" which is a quick jump to document
            // 3. routeParams$ emits again undefined -> angular sees the same value in the @Input() and never triggers re-rendering of search input
            // so that the input field gets cleared.
            // I have already tried to manually trigger the change detection, but it still doesn't work
            // Thus this solution is the less intricate one.
            rxEffects(({ register }) =>
                register(
                    this.routerEvents.routeParams$.pipe(
                        delay(0), // to escape https://angular.io/errors/NG0100
                        map(params => params.query ?? ''),
                        // intentionally not using distinctUntilChanged here for the reason mentioned above
                    ),
                    query => (this.searchInput.query = query),
                ),
            );
        });
    }

    public onSubmitCalled(query: string): void {
        logger.debug(this.logPrefix, '[HeaderComponent] onSubmitCalled ->', query);
        this.trackOnSubmit();
        this.submitCalled.emit(query);
    }

    private trackOnSubmit(): void {
        const targetNameSuffix = 'normal';
        const targetName = `${EcondaTargetName.SearchTriggered}/${targetNameSuffix}`;
        const target = new EcondaTarget(EcondaTargetGroup.Header, targetName);
        this.econdaTrackingService.trackTarget(target);
    }
}
