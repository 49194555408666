export interface FeatureDTO {
    readonly name: FeatureId;
    readonly startDate?: string;
    readonly endDate?: string;
}

export const FeatureId = {
    DocScrollPreview: 'docScrollPreview',
    Survey: 'survey',
    TocSearchIndicator: 'tocSearchIndicator',
    DebugGtmIds: 'debugGtmIds',
};

export type FeatureId = (typeof FeatureId)[keyof typeof FeatureId];

export interface Feature {
    readonly id: FeatureId;
    /**
     * If set this feature should only be active after this date.
     */
    readonly startDate?: Date;
    /**
     * If set this feature should only be active before this date.
     */
    readonly endDate?: Date;
}

export const convertToFeature = (dto: FeatureDTO): Feature => ({
    id: dto.name,
    startDate: dto.startDate ? new Date(dto.startDate) : undefined,
    endDate: dto.endDate ? new Date(dto.endDate) : undefined,
});
