import { LOCALE_ID } from '@angular/core';
import { ENVIRONMENT } from '@idr/ui/shared';
import { environment } from '../../environments/environment';

export const INJECTION_TOKENS = [
    // Not officially documented, but this is how you can disable the noise in logs from GTM.
    // This is especially useful in cases of errors... we don't want to spam user client with those.
    // Tracking should always happen in the dark. If it errors the app should still work and not complain.
    { provide: 'googleTagManagerMode', useValue: 'silent' },
    { provide: LOCALE_ID, useValue: 'de' }, // https://angular.io/api/core/LOCALE_ID
    { provide: ENVIRONMENT, useValue: environment },
];
