import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { logger } from '@idr/shared/utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { USER_IS_ALLOWED$ } from '../services/user-is-allowed';

export const IsUserAllowedGuard: CanActivateFn = (): Observable<boolean> => {
    const logPrefix = '[IsUserAllowedGuard]';
    const router = inject(Router);
    return inject(USER_IS_ALLOWED$).pipe(
        tap(allowed => {
            if (allowed) {
                logger.debug(logPrefix, 'user is allowed');
                return;
            }

            logger.warn(logPrefix, "user isn't allowed; will block navigation and return back to root");
            router.navigate(['..']);
        }),
    );
};
