import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { PickProductGroupComponent } from '../../components/pick-product-group/pick-product-group.component';
import { ActivatedProductGroupProvider } from '../activated-product-group';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [HeaderComponent, PickProductGroupComponent],
    providers: [ActivatedProductGroupProvider],
    selector: 'mc-start',
    standalone: true,
    styleUrl: './start.component.scss',
    template: `<mc-header /> <mc-pick-product-group />`,
})
export class StartComponent {}
