<ng-container *rxLet="isOpen$; let isOpen">
    <header
        [class]="panelPosition()"
        (click)="toggle()">
        <span>{{ isOpen ? summaryLabel() : "&nbsp;" }}</span>
        <hg-wing-panel-button
            [gtmArea]="gtmArea()"
            [matTooltip]="tooltip()"
            matTooltipPosition="below"
            [attr.aria-controls]="drawerId()"
            [attr.aria-expanded]="isOpen"
            [attr.aria-label]="tooltip()"
            [class]="panelPosition()"
            [position]="panelPosition()"
            [opened]="isOpen">
        </hg-wing-panel-button>
    </header>
    @if (isOpen) {
        <section [id]="drawerId()">
            <ng-content />
        </section>
    }
</ng-container>
