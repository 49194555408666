import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { logger } from '@idr/shared/utils';
import { map, Observable, tap } from 'rxjs';
import { ConfigApiService } from '../services/config-api.service';

export const GroupsAvailableGuard: CanActivateFn = (): Observable<boolean> => {
    const router = inject(Router);
    const api = inject(ConfigApiService);
    return api.productGroups$.pipe(
        map(groups => groups.length > 0),
        tap(available => {
            if (!available) {
                logger.warn('[GroupsAvailableGuard] -> no groups are available');
                router.navigate(['/config']);
            }
        }),
    );
};
