import { AfterContentInit, Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

export const CALCULATION_DEBOUNCE_TIME = 500;

@Directive({
    selector: '[hgElementOverflowsElement]',
    standalone: true,
})
export class ElementOverflowsDirective implements AfterContentInit, OnDestroy {
    @Output()
    readonly isOverflowing: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly #calculate$: Subject<void> = new Subject();

    constructor(private readonly elementRef: ElementRef<HTMLElement>) {
        this.#calculate$
            .pipe(
                debounceTime(CALCULATION_DEBOUNCE_TIME),
                tap(() => this.#updateTooltipState()),
            )
            .subscribe();
    }

    ngAfterContentInit(): void {
        this.isOverflowing.emit(false);
        this.#calculate$.next();
    }

    ngOnDestroy(): void {
        this.#calculate$.complete();
        this.#calculate$.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.#calculate$.next();
    }

    #updateTooltipState(): void {
        const element = this.elementRef.nativeElement;
        const isOverflowing = element.scrollWidth > element.clientWidth;
        this.isOverflowing.emit(isOverflowing);
    }
}
