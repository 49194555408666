import { inject, Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { REALM, Realm, urlWithRealmQueryParam } from '../services';

/**
 * This pipe adds the realm to the given url string if it is an external url and is Haufe related.
 * @see {@link urlWithRealmQueryParam}
 */
@Pipe({
    name: 'hgWithRealm',
    standalone: true,
})
export class WithRealmPipe implements PipeTransform {
    private readonly realm: Realm = inject(REALM);

    public transform(originalUrl: string | SafeUrl | undefined): string | undefined {
        // There are cases like an Addison link in bauplan items where the provided originalUrl is undefined.
        // In order to avoid errors by the methods that process the url below we just return undefined.
        if (!originalUrl) {
            return undefined;
        }
        return urlWithRealmQueryParam(originalUrl.toString(), this.realm);
    }
}
