import { NewsDocumentsDTO, NewsMetaDTO } from '@idr/shared/model';
import { Realm } from '@idr/ui/shared';
import { ContentHubDocuments } from './content-hub-documents';
import { NewsDocument } from './news-document';

export class NewsDocuments extends ContentHubDocuments<NewsDocument, NewsMetaDTO> {
    private constructor(dto: NewsDocumentsDTO, realm: Realm) {
        super(dto, entryDto => NewsDocument.fromEntryDTO(entryDto, realm));
    }

    static fromDTO(dto: NewsDocumentsDTO, realm: Realm) {
        return new NewsDocuments(dto, realm);
    }
}
