/* eslint-disable @typescript-eslint/naming-convention */

import { ContentHubDocumentDTO, EntryDTO, NewsDocumentsDTO, NewsMetaDTO } from '@idr/shared/model';

const NEWS_ENTRY_MOCK = {
    'chs:searchHitType': {
        score: 0,
        fitness: 0,
        confidence: 0,
        content: 'FullTextSearchHit',
    },
    link: {
        rel: 'self',
        href: 'http://localhost:8080/content/v1/retrieval/baseline?contentHubId=contenthub://portals/content/348304',
    },
    id: 'contenthub://portals/content/348304',
    published: '2016-04-28T02:30:00Z',
    title: 'So wenige Azubis – so viele Ideen',
    updated: '2016-04-28T02:30:00Z',
    content: {
        'ch:haufe-document': {
            'xmlns:ch': 'https://contenthub.haufe-lexware.com/haufe-document',
            'ch:meta': {
                'ch:tag': 'Ausbildung',
                'ch:appDocId': 'content/348304',
                'pr:subcategory': {
                    'xmlns:pr': 'https://www.haufe.de/namespaces/portals/contenthub/metadata',
                    content: 'HR-Management',
                },
                'ch:ingestOnly': false,
                'ch:title': [
                    {
                        name: 'default',
                        content: 'So wenige Azubis – so viele Ideen',
                    },
                    {
                        name: 'short_title',
                        content: 'So wenige Azubis – so viele Ideen',
                    },
                ],
                'ch:revisionDate': '2016-04-28T04:30:00+02:00',
                'pr:feedertime': {
                    'xmlns:pr': 'https://www.haufe.de/namespaces/portals/contenthub/metadata',
                    'xs:type': 'dateTime',
                    'xmlns:xs': 'https://www.w3.org/2001/XMLSchema',
                    content: '2021-08-05T11:41:06.89596+02:00',
                },
                'pr:advertisement': {
                    'xmlns:pr': 'https://www.haufe.de/namespaces/portals/contenthub/metadata',
                    content: false,
                },
                'pr:rightsHolder': {
                    'xmlns:pr': 'https://www.haufe.de/namespaces/portals/contenthub/metadata',
                    content: 'dpa',
                },
                'pr:category': {
                    'xmlns:pr': 'https://www.haufe.de/namespaces/portals/contenthub/metadata',
                    content: 'Personal',
                },
                'ch:canonicalUrl':
                    'https://www.haufe.de/personal/hr-management/berufsausbildung-noch-nie-gab-es-so-wenige-azubis_80_348304.html',
                'pr:trackingPath': {
                    'xmlns:pr': 'https://www.haufe.de/namespaces/portals/contenthub/metadata',
                    content: 'personal/hrmanagement',
                },
                'ch:visible': true,
                'ch:application': 'portals',
                'ch:contentHubId': 'contenthub://portals/content/348304',
                'pr:overline': {
                    'xmlns:pr': 'https://www.haufe.de/namespaces/portals/contenthub/metadata',
                    content: 'Reaktionen auf "Berufsbildungsbericht 2016"',
                },
                'ch:chronologicalSortDate': '2016-04-28T04:30:00+02:00',
                'pr:feederstate': {
                    'xmlns:pr': 'https://www.haufe.de/namespaces/portals/contenthub/metadata',
                    content: 'SUCCESS',
                },
                'ch:publicationDate': '2016-04-28T04:30:00+02:00',
                'ch:contentLanguage': 'de',
                'ch:documentType': 'News',
                'pr:image': {
                    'xmlns:pr': 'https://www.haufe.de/namespaces/portals/contenthub/metadata',
                    content: 'contenthub://portals/content/348306',
                },
                'pr:visibleInSuite': {
                    'xmlns:pr': 'https://www.haufe.de/namespaces/portals/contenthub/metadata',
                    content: true,
                },
            },
        },
        type: 'application/vnd.haufegroup.chsingledocingest+xml',
        'ch:preview': {
            content: '<p>This is a preview</p>',
        },
    },
    'chs:snippet': {
        xmlns: 'https://www.w3.org/1999/xhtml',
        'chs:location': 'baselineContent',
        type: 'application/xhtml+xml',
        content: '<p>This is some preview</p>',
    },
};

const createNewsDTO = (
    entries?: EntryDTO<ContentHubDocumentDTO<NewsMetaDTO>> | EntryDTO<ContentHubDocumentDTO<NewsMetaDTO>>[],
): NewsDocumentsDTO => {
    return {
        feed: {
            'opensearch:Query': {
                startIndex: 1,
                role: 'request',
                searchTerms: 'application:portals documentType:News portals.category:Personal',
                count: 1,
            },
            author: {
                name: 'Haufe ContentHub Search',
                uri: 'https://api.contenthub.haufe.io/content/v1/search',
            },
            link: [
                {
                    rel: 'self',
                    href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&offset=1&limit=1',
                },
                {
                    rel: 'first',
                    href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&offset=1&limit=1',
                },
                {
                    rel: 'next',
                    href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&offset=2&limit=1',
                },
            ],
            generator: {
                uri: 'https://api.contenthub.haufe.io/content/v1/search',
            },
            'xmlns:chs': 'https://contenthub.haufe.de/search/',
            'opensearch:startIndex': 1,
            'opensearch:totalResults': (entries as EntryDTO<ContentHubDocumentDTO<NewsMetaDTO>>[])?.length ?? 1,
            'chs:facets': {
                'chs:facet': [
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=academy.subjectArea',
                        },
                        constraint: 'academy.subjectArea',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=application',
                        },
                        constraint: 'application',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=creator',
                        },
                        constraint: 'creator',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=documentType',
                        },
                        constraint: 'documentType',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=haufeshop.mediaType',
                        },
                        constraint: 'haufeshop.mediaType',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=haufeshop.topProduct',
                        },
                        constraint: 'haufeshop.topProduct',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=hot.category_id',
                        },
                        constraint: 'hot.category_id',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=hot.category_title',
                        },
                        constraint: 'hot.category_title',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=idesk.documentCategory',
                        },
                        constraint: 'idesk.documentCategory',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=idesk.documentClassification',
                        },
                        constraint: 'idesk.documentClassification',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=idesk.documentSubcategory',
                        },
                        constraint: 'idesk.documentSubcategory',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=idesk.documentType',
                        },
                        constraint: 'idesk.documentType',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=idesk.subjectAreaId',
                        },
                        constraint: 'idesk.subjectAreaId',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=packageId',
                        },
                        constraint: 'packageId',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=portals.category',
                        },
                        constraint: 'portals.category',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=portals.subcategory',
                        },
                        constraint: 'portals.subcategory',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=portals.visibleInSuite',
                        },
                        constraint: 'portals.visibleInSuite',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=publisher',
                        },
                        constraint: 'publisher',
                    },
                    {
                        link: {
                            rel: 'related',
                            href: 'https://api.contenthub.haufe.io/content/v1/search?q=application:portals&q=documentType:News&q=portals.category:Personal&facet=tag',
                        },
                        constraint: 'tag',
                    },
                ],
            },
            entry: entries,
            xmlns: 'http://www.w3.org/2005/Atom',
            'opensearch:itemsPerPage': 1,
            updated: '2021-09-24T09:34:48Z',
            'xmlns:opensearch': 'http://a9.com/-/spec/opensearch/1.1/',
        },
    };
};

export const NEWS_ENTRIES_COUNT_MOCK = 20;

export const CONTENT_HUB_NEWS_DOCUMENTS_DTO_MULTIPLE_ENTRIES_MOCK: NewsDocumentsDTO = createNewsDTO(
    Array.from({ length: NEWS_ENTRIES_COUNT_MOCK }, () => NEWS_ENTRY_MOCK),
);

export const CONTENT_HUB_NEWS_DOCUMENTS_DTO_ONE_ENTRY_MOCK: NewsDocumentsDTO = createNewsDTO(NEWS_ENTRY_MOCK);

export const CONTENT_HUB_NEWS_DOCUMENTS_DTO_NO_ENTRIES_MOCK: NewsDocumentsDTO = createNewsDTO([]);
