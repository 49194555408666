import { DataSource } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { LoadingWheelComponent } from '@idr/ui/shared';
import { ConfigModel, TableData } from '../../model/config-model';
import { ConfigNavigator } from '../../routing/config-navigator';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'mc-pick-product-group',
    standalone: true,
    imports: [DatePipe, RouterLink, MatIcon, MatTableModule, LoadingWheelComponent],
    templateUrl: './pick-product-group.component.html',
    styleUrl: './pick-product-group.component.scss',
})
export class PickProductGroupComponent {
    readonly columns: (keyof TableData)[] = ['name', 'isDraft', 'modifiedBy', 'modifiedOn'];
    readonly dataSource: DataSource<TableData>;

    constructor(
        public readonly navigator: ConfigNavigator,
        public readonly model: ConfigModel,
    ) {
        this.dataSource = {
            connect: () => model.tableData$,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            disconnect: () => {},
        };
    }
}
