import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
// eslint-disable-next-line barrel-files/avoid-importing-barrel-files
import { MatSelectModule } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { IS_DRAFT, ProductGroupConfiguration } from '@idr/model/config';
import { ConfigModel } from '../../model/config-model';
import { ConfigNavigator } from '../../routing/config-navigator';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DatePipe, RouterLink, MatIcon, MatSelectModule, MatTooltip],
    selector: 'mc-edit-context',
    standalone: true,
    styleUrl: './edit-context.component.scss',
    templateUrl: './edit-context.component.html',
})
export class EditContextComponent {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    readonly IS_DRAFT = IS_DRAFT;

    active = input.required<ProductGroupConfiguration | undefined>();

    readonly isDraft = computed(() => this.active()?.state === IS_DRAFT);

    readonly meta = computed(() => this.active()?.meta);

    constructor(
        public readonly model: ConfigModel,
        public readonly navigator: ConfigNavigator,
    ) {}
}
