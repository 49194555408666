import { InjectionToken } from '@angular/core';

/**
 * Type for environment.ts files...
 */
export interface Environment {
    readonly local: boolean;
    readonly production: boolean;
}

// an injection token that you can use in your component... this is safer for unit testing it afterwards...
// (since you don't want to overwrite the global environment... it can get messy if you change that one and forget to clean up)
export const ENVIRONMENT: InjectionToken<Environment> = new InjectionToken<Environment>('env');
