<!--
    Template is inspired by W3C example.
    @see https://www.w3.org/TR/wai-aria-practices-1.1/examples/breadcrumb/index.html
-->
<span *rxIf="lastSearchRouterLink$; let routerLink">
    <mat-icon svgIcon="chevron" />
    <a
        class="lastSearch"
        [hgGtmId]="['breadcrumb', 'navigate', 'backToHitlist']"
        [routerLink]="routerLink">
        Zurück zur Trefferliste
    </a>
</span>
<ng-container *rxLet="sitemap$; let sitemap; suspense: loading">
    @if (sitemap !== undefined) {
        <ng-container *rxLet="isSearch$; let forSearch">
            @for (item of sitemap; track item.routerLink; let isLast = $last) {
                <div
                    [class.abbreviate]="forSearch === false"
                    [class.lastItem]="isLast">
                    @if (item.routerLink !== undefined) {
                        <a
                            [hgGtmId]="['breadcrumb', 'navigate', 'breadcrumbLink']"
                            [routerLink]="item.routerLink"
                            [attr.aria-current]="isLast ? 'page' : ''"
                            [innerHTML]="item.label"></a>
                    } @else {
                        <p [innerHTML]="item.label"></p>
                    }
                </div>
                @if (!isLast) {
                    <mat-icon svgIcon="navigation" />
                }
            }
        </ng-container>
    } @else {
        <hg-loading-dots />
    }
</ng-container>

<ng-template #loading>
    <hg-loading-dots />
</ng-template>
