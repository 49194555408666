import { CrsBookInfoDTO, CrsDocumentDTO, CrsRelatedDocumentLinkDTO, OdinDocumentBookInfo } from '@idr/shared/model';

export const createBookInfoFromCrsDocument = (dto: CrsDocumentDTO): OdinDocumentBookInfo | undefined => {
    const title = getBookInfoTitle(dto.bookInfo, dto.title);
    return title ? { title } : undefined;
};

export const createBookInfoFromCrsRelatedDocumentLink = (
    dto: CrsRelatedDocumentLinkDTO,
): OdinDocumentBookInfo | undefined => {
    const title = getBookInfoTitle(dto.resource.bookInfo, dto.title);
    return title ? { title } : undefined;
};

const getBookInfoTitle = (bookInfo: CrsBookInfoDTO | undefined, documentTitle: string): string | undefined => {
    if (!bookInfo) {
        return undefined;
    }
    if (bookInfo.edition) {
        return `${bookInfo.title}, ${bookInfo.edition}, ${documentTitle}`;
    }
    return `${bookInfo.title}, ${documentTitle}`;
};
