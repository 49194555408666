import { logger } from '@idr/shared/utils';
import { BrandLabelDTO } from '../brand';
import { DocumentId } from '../document/document-id';
import {
    Brand,
    getBrandFromLabel,
    ProductConfigurationDTO,
    ProductDto,
    ProductLogo,
    PropertiesDTO,
    StartPageConfiguration,
    TargetDTO,
    TargetPathDTO,
} from '../product-configuration';
import { ProductId } from './product-id';

/**
 * Returns true if the given string is a valid product id.
 * Useful, in order to distinguish between product ids and product groups.
 */
export const isProductIdFormat = (requestedProductId: string): boolean => {
    return /^PI\d+$/.test(requestedProductId);
};

const getConfigurationTarget = (configuration: TargetDTO, path: TargetPathDTO): TargetDTO | undefined => {
    const target = configuration.children?.find(_target => _target.targetPath === path);
    if (!target) {
        logger.warn(`[getConfigurationTarget] -> Didn't find target for path`, path);
    }
    return target;
};

const identifyImprintTarget = (target: TargetDTO | undefined): TargetDTO | undefined => {
    if (target?.children) {
        // we don't know exactly which position the related child has
        for (const child of target.children) {
            const matches = identifyImprintTarget(child);
            if (matches) {
                return matches;
            }
        }
    }

    // we can only identify it this way since there is no explicit property used for imprint in productConfig
    if (
        target?.source &&
        /HI\d+/.test(target.source) &&
        target?.properties?.title &&
        /Impressum/.test(target.properties.title)
    ) {
        return target;
    }

    return undefined;
};

const findImprintId = (configuration: ProductConfigurationDTO): DocumentId | undefined => {
    const helpTarget = configuration.units[0].children?.find(target => target.targetPath === 'helpMenu');
    return identifyImprintTarget(helpTarget)?.source;
};

export class Product {
    constructor(
        public readonly id: ProductId,
        public readonly name: string | undefined,
        public readonly shortName: string, // e.g. HSO_online for Haufe Steuer Office Basic
        public readonly brand: Brand | undefined, // undefined if unknown brand is given
        public readonly imprint: string | undefined,
        public readonly version: string,
        public readonly publishDate: Date | undefined,
        public readonly productLogo: ProductLogo,
        public readonly configuration: TargetDTO,
        public readonly startPage: StartPageConfiguration,
    ) {
        // brandLabel & 'Online' should be cut from a product name...
        // honestly I don't know how likely it is that a product name still is spelled with those... but let's keep the cleaning
        this.name = this.cleanProductName();
    }

    public static fromProductDto(dto: ProductDto): Product | undefined {
        const configuration = dto.details.units[0];
        const properties = configuration.properties as PropertiesDTO;
        const id: ProductId = configuration.source as ProductId;
        const brand = getBrandFromLabel(properties.brand as BrandLabelDTO);
        const publicationDate = dto.metadata?.publishDate ? new Date(dto.metadata.publishDate) : undefined;
        const productLogo = getConfigurationTarget(configuration, 'companyLogo')?.properties as ProductLogo;
        return new Product(
            id,
            properties.title as string,
            properties.shortName as string,
            brand,
            findImprintId(dto.details),
            dto.metadata?.version,
            publicationDate,
            productLogo,
            configuration,
            dto.startpage,
        );
    }

    public getConfigurationTarget(path: TargetPathDTO): TargetDTO | undefined {
        return getConfigurationTarget(this.configuration, path);
    }

    private cleanProductName(): string | undefined {
        const regExp = new RegExp(`^(${this.brand?.label} )*(.+?)( Online)*$`);
        return this.name?.trim()?.match(regExp)?.[2];
    }
}
