export class ResolverError implements Error {
    public name = 'ResolverError';
    public stack?: string;

    constructor(public message: string) {}

    public toString(): string {
        return `[${this.name}]: ${this.message}`;
    }
}
