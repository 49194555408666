import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiName, VERSION_INFO_ENDPOINT } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../api';
import { CACHE_IT_HTTP_HEADER_KEY } from './caching-http-interceptor.service';
import { CONSUME_GENERIC_ERROR_HANDLER } from './error.http-interceptor';

@Injectable()
export class MicroServiceHttpInterceptor implements HttpInterceptor {
    constructor(private readonly apiService: ApiService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const apiName: ApiName | undefined = this.apiService.findApiNameForUrl(request.url);
        if (!apiName) {
            return next.handle(request);
        }

        logger.debug(`[MicroServiceHttpInterceptor] intercept will handle ->`, request.url);
        const modifiedRequest = this.#modifyRequest(request, apiName);
        return next.handle(modifiedRequest).pipe(catchError(error => this.#handleError(request, error)));
    }

    #modifyRequest(request: HttpRequest<unknown>, apiName: ApiName): HttpRequest<unknown> {
        let headers: HttpHeaders = request.headers;
        // The CONSUME_GENERIC_ERROR_HANDLER is used by the error interceptor to consume the error that is thrown by the request
        // We set it here to true to make all API failed requests to be handled by the error interceptor
        // if no such header is set already explicitly for a microservice call
        if (!headers.has(CONSUME_GENERIC_ERROR_HANDLER)) {
            headers = headers.set(CONSUME_GENERIC_ERROR_HANDLER, 'true');
        }

        // let's cache all get requests if the setting isn't configured already
        if (!headers.has(CACHE_IT_HTTP_HEADER_KEY) && request.method === 'GET') {
            headers = headers.set(CACHE_IT_HTTP_HEADER_KEY, 'true');
        }

        const initialUrl: string = request.url;

        const apiBaseUrl = request.url.endsWith(`/${VERSION_INFO_ENDPOINT}`)
            ? this.apiService.getVersionInfoApiEndpoint(apiName)
            : this.apiService.getApiEndpointUrl(apiName);

        logger.debug(`[MicroServiceHttpInterceptor] got apiBaseUrl`, apiBaseUrl, 'for', apiName);
        const updatedUrl = request.url.replace(new RegExp(`^${apiName}/`), `${apiBaseUrl}/`);
        logger.debug(`[MicroServiceHttpInterceptor] replaced url`, initialUrl, 'with', updatedUrl);
        return request.clone({ headers, url: updatedUrl });
    }

    #handleError(request: HttpRequest<unknown>, error: HttpErrorResponse): Observable<HttpEvent<unknown>> {
        logger.warn(`[MicroServiceHttpInterceptor] got unexpected error for ${request.url}`, error);
        return throwError(() => error);
    }
}
