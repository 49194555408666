import { ContentHubContentLayout, ContentHubId, DocumentId } from '@idr/shared/model';
import { RoutePaths } from './route-paths';

export const getPreviewPath = (documentId: DocumentId | ContentHubId): string[] => {
    if (documentId === undefined) {
        return [];
    }

    if (documentId.startsWith('contenthub://')) {
        // TODO: we should check for a more intuitive and elegant way to differentiate HOT and news paths
        return [
            documentId.startsWith('contenthub://hot') ? RoutePaths.Hot : RoutePaths.News,
            ContentHubContentLayout.preview,
            documentId,
        ];
    }

    if (documentId.endsWith('_ueb')) {
        return [RoutePaths.TopicPage, documentId];
    }

    return [RoutePaths.Document, documentId];
};
