import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiName, Product, ProductDto } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductService {
    /**
     * A long living observable of available products.
     */
    public readonly products$: Observable<Product[]>;

    private readonly logPrefix = '[ProductService]';

    constructor(private readonly http: HttpClient) {
        this.products$ = this.initProducts$;
    }

    private get initProducts$(): Observable<Product[]> {
        const url = `${ApiName.PRODUCT_CONFIG}/details`;
        return this.http.get<{ products: ProductDto[] }>(url).pipe(
            map(publication => publication.products ?? []),
            tap(products => logger.debug(this.logPrefix, `initProducts$ -> got`, products)),
            map(dtos => dtos.map(Product.fromProductDto).filter(Boolean) as Product[]),
            tap(products => logger.debug(this.logPrefix, `initProducts$ -> SUCCESS`, products)),
            shareReplay(1),
        );
    }
}
