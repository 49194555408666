<div class="paging-container">
    <div class="paging-start flex-row">
        @for (pageSizeOption of pageSizeOptions; track pageSizeOption) {
            <a
                data-cy="page-size"
                class="pageSizeOption"
                (click)="pageSizeClicked(pageSizeOption)"
                [class.activated]="pageSizeOption === state().pageSize"
                [attr.aria-label]="'Stelle Anzahl der Einträge pro Seite auf ' + pageSizeOption"
                >{{ pageSizeOption }}</a
            >
        }
        <div class="page-size-label">
            <div>Treffer pro Seite</div>
        </div>
    </div>

    <div
        data-cy="page-select"
        class="paging-select">
        <div class="paging-items-start">
            <a
                data-cy="prev"
                class="btn paging-item"
                (click)="state().disableToFirstPage ? noop() : pageClicked(state().page - 1)"
                [attr.aria-disabled]="state().disableToFirstPage"
                [attr.aria-label]="'Zur vorherigen Seite'">
                <mat-icon
                    svgIcon="chevron"
                    class="rotate180" />
            </a>

            @for (page of state().pages; track page) {
                <div class="paging-item">
                    @if (page > 0) {
                        <a
                            data-cy="page"
                            class="pageOption"
                            (click)="pageClicked(page)"
                            [class.activated]="page === state().page"
                            [attr.aria-label]="'Zur ' + page + 'ten Seite'"
                            [attr.aria-posinset]="page"
                            >{{ page }}</a
                        >
                    } @else {
                        <div class="dots">...</div>
                    }
                </div>
            }

            <a
                data-cy="next"
                class="btn"
                (click)="state().disableToLastPage ? noop() : pageClicked(state().page + 1)"
                [attr.aria-disabled]="state().disableToLastPage"
                [attr.aria-label]="'Zur nächsten Seite'">
                <mat-icon svgIcon="chevron" />
            </a>
        </div>
    </div>

    <div
        data-cy="context"
        class="paging-end flex-row">
        <div>Treffer {{ state().hitRange }} von {{ state().hitsCount }}</div>
    </div>
</div>
