import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'hg-error-message',
    standalone: true,
    styleUrls: ['error-message.component.scss'],
    template: '<ng-content/>',
})
export class ErrorMessageComponent {}
