import { Inject, Injectable, Signal, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { GroupConfigurationWithPia, ProductConfiguration } from '@idr/model/config';
import { ReloadedUserSettingsDTO } from '@idr/shared/model';
import {
    containsFilterParam,
    containsHot,
    containsLexicon,
    containsNews,
    containsTopicPage,
    documentIdFromUrl,
    logger,
    startsWithProductId,
} from '@idr/shared/utils';
import { RoutedDocument } from '@idr/ui/document';
import { CURRENT_PRODUCT_CONFIGURATION_TOKEN } from '@idr/ui/services';
import { ProfileService, Realm, REALM, RouterEvents, stripHtml, UserSettingsService } from '@idr/ui/shared';
import {
    GtmCurrentDocumentData,
    GtmEvents,
    GtmFilterTableEvent,
    GtmFilterTableValues,
    GtmLexiconFilterPageChange,
    GtmOutputmanagerEvent,
    GtmOutputmanagerEventType,
    GtmOutputValues,
    GtmPageChangeEvent,
    GtmSearchEvent,
    GtmUserIdEvent,
    GtmUserSettingsChangedEvent,
    isAllowedToBeTracked,
} from '@idr/ui/tracking';
import { rxEffects } from '@rx-angular/state/effects';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { distinctUntilChanged, filter, firstValueFrom, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { take, withLatestFrom } from 'rxjs/operators';
import { HotService } from '../../hot-page/services/hot.service';
import { NewsService } from '../../news-page/services/news.service';
import { SearchResult } from '../../search-page/model/search-result';
import { SearchSuggestion } from '../../shared/components/search-input/suggestion/search-suggestion';
import { IDeskTopicPage } from '../../topic-page/model/topic-page';
import { findInData } from '../../utils/route/activated-route-helpers';
import { queueUntil } from '../../utils/rxjs/queue-until';
import { ConsentTrackingService } from '../consent/consent-tracking.service';
import { SuggestionService } from '../suggestion/suggestion.service';
import { createGtmCurrentDocumentData, distinctSearchResult } from './tracking-helpers';

@Injectable({ providedIn: 'root' })
export class GtmTrackingService {
    readonly #logPrefix = '[GtmTrackingService]';
    readonly #searchResults = signal<SearchResult>(undefined);

    searchResults$ = toObservable(this.#searchResults).pipe(
        filter(it => !!it),
        distinctUntilChanged(distinctSearchResult),
    );

    constructor(
        private readonly gtmService: GoogleTagManagerService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly hotService: HotService,
        private readonly newsService: NewsService,
        @Inject(CURRENT_PRODUCT_CONFIGURATION_TOKEN)
        readonly productConfiguration: Signal<ProductConfiguration | undefined>,
        routerEvents: RouterEvents,
        profileService: ProfileService,
        consentService: ConsentTrackingService,
        userSettingsService: UserSettingsService,
        @Inject(REALM) authRealm: Realm,
        suggestionService: SuggestionService,
    ) {
        const user$: Observable<Pick<GtmUserIdEvent, 'userId' | 'isHaufeInternal' | 'profileProperties'>> =
            profileService.isAnonymous$.pipe(
                queueUntil(consentService.consent$),
                switchMap(isAnonymous => (isAnonymous ? of('anonymous') : profileService.userId$)),
                withLatestFrom(profileService.isEmailHaufeInternal$, profileService.profileProperties$),
                map(([userId, isHaufeInternal, profileProperties]) => ({ userId, isHaufeInternal, profileProperties })),
                shareReplay(1),
            );

        const userSettings$ = userSettingsService.settings$.pipe(
            queueUntil(user$),
            distinctUntilChanged(),
            shareReplay(1),
        );

        const pageChange$: Observable<GtmPageChangeEvent> = routerEvents.navigationEnd$.pipe(
            queueUntil(user$),
            map((event: NavigationEnd) => event.url),
            filter(url => startsWithProductId(url)),
            tap(url => logger.debug(this.#logPrefix, 'got navigationEnd$ url ->', url)),
            switchMap(url => this.#createPageChangeEvent(url)),
            filter(it => isAllowedToBeTracked(it)),
            shareReplay(1),
        );

        const pageChangeWithoutFilterParam$ = pageChange$.pipe(
            distinctUntilChanged((prev, curr) => {
                if (containsLexicon(curr.url)) {
                    return prev?.currentDocument?.rootId === curr?.currentDocument?.rootId;
                }
                return prev.url === curr.url;
            }),
        );

        const pageChangeWithFilterParam$ = pageChange$.pipe(filter(it => containsFilterParam(it.url)));

        rxEffects(({ register }) => {
            register(pageChangeWithoutFilterParam$, event => this.#sendPageChange(event));
            register(pageChangeWithFilterParam$, event => this.#sendLexiconFilterPageChange(event));
            register(user$, user => this.#sendUserId(user, authRealm));
            register(userSettings$, userSettings => this.#sendUserSettings(userSettings));

            register(this.searchResults$, searchResult =>
                this.#sendSearchEvent(searchResult, suggestionService.suggestions()),
            );
        });
    }

    get #documentFromRoute(): RoutedDocument | undefined {
        return findInData<RoutedDocument>(this.activatedRoute, 'document');
    }

    get #topicPage(): IDeskTopicPage {
        return findInData<IDeskTopicPage>(this.activatedRoute, 'topicPage');
    }

    setSearchResult(searchResult: SearchResult) {
        if (!searchResult) {
            return;
        }
        this.#searchResults.set(searchResult);
    }

    #sendSearchEvent(searchResult: SearchResult, suggestions: SearchSuggestion[]) {
        const searchEvent: GtmSearchEvent = {
            event: 'SearchEvent',
            appliedSuggestion: suggestions.some(it => it.selected),
            searchTermBeforeAutoSuggest: suggestions.find(it => it.selected)?.prefix,
            suggestions,
            searchResult,
        };

        logger.debug(this.#logPrefix, 'send SearchEvent ->', searchEvent);
        return this.gtmService.pushTag(searchEvent);
    }

    sendOutputManagerEvent(formValues: GtmOutputValues, eventType: GtmOutputmanagerEventType) {
        const gtmEvent: GtmOutputmanagerEvent = {
            event: 'OutputmanagerEvent',
            eventType,
            formValues,
        };

        logger.debug(this.#logPrefix, 'send OutputmanagerEvent ->', gtmEvent);
        return this.gtmService.pushTag(gtmEvent);
    }

    async sendFilterTableEvent(filterTableValues: GtmFilterTableValues): Promise<void> {
        const gtmEvent: GtmFilterTableEvent = {
            event: 'FilterTableEvent',
            filterTableValues,
        };
        logger.debug(this.#logPrefix, 'send FormSubmitEvent ->', gtmEvent);
        await this.gtmService.pushTag({
            event: 'reset',
            filterTableValues: undefined,
        });
        await this.gtmService.pushTag(gtmEvent);
    }

    #sendUserSettings(userSettings: ReloadedUserSettingsDTO) {
        const event: GtmUserSettingsChangedEvent = {
            event: GtmEvents.UserSettingsChanged,
            userSettings,
        };
        logger.debug(this.#logPrefix, 'send UserSettings ->', event);
        return this.gtmService.pushTag(event);
    }

    #sendUserId(
        {
            userId,
            isHaufeInternal,
            profileProperties,
        }: Pick<GtmUserIdEvent, 'userId' | 'isHaufeInternal' | 'profileProperties'>,
        realm: Realm,
    ) {
        const event: GtmUserIdEvent = {
            event: GtmEvents.UserId,
            userId,
            isHaufeInternal,
            authRealm: realm,
            profileProperties,
        };
        logger.debug(this.#logPrefix, 'send UserId ->', event);
        return this.gtmService.pushTag(event);
    }

    async #sendPageChange(event: GtmPageChangeEvent) {
        logger.debug(this.#logPrefix, 'send GtmPageChangeEvent ->', event);
        return this.gtmService.pushTag(event);
    }

    async #sendLexiconFilterPageChange(event: GtmPageChangeEvent) {
        const filterEvent: GtmLexiconFilterPageChange = {
            ...event,
            event: 'LexiconFilterPageChange',
        };
        logger.debug(this.#logPrefix, 'send LexiconFilterPageChange ->', filterEvent);
        return this.gtmService.pushTag(filterEvent);
    }

    #getNewsData(): Observable<GtmCurrentDocumentData> {
        return this.newsService.newsDocument$.pipe(
            map(data => ({
                rootTitle: data.title,
                rootId: data.contentHubId,
                docType: data.classification?.type.toUpperCase(),
                docDisplayType: data.classification?.description,
                docSubType: data.classification.subType.toUpperCase(),
            })),
        );
    }

    #getHotData(): Observable<GtmCurrentDocumentData> {
        return this.hotService.hotDocument$.pipe(
            take(1),
            map(data => ({
                rootTitle: data.title,
                rootId: data.contentHubId,
                docType: 'HOT',
                docDisplayType: data.classification?.description,
                docSubType: data.classification?.subType.toUpperCase(),
            })),
        );
    }

    #getTopicPageData(topicPage: IDeskTopicPage): GtmCurrentDocumentData {
        return {
            rootId: topicPage.docId,
            rootTitle: stripHtml(topicPage.title),
            docType: 'THEMENSEITE',
            docDisplayType: 'Themenseite',
            docSubType: 'THEMENSEITE',
        };
    }

    async #createPageChangeEvent(url: string): Promise<GtmPageChangeEvent> {
        let currentDocument: GtmCurrentDocumentData;

        if (containsHot(url)) {
            currentDocument = await firstValueFrom(this.#getHotData());
        } else if (containsNews(url)) {
            currentDocument = await firstValueFrom(this.#getNewsData());
        } else if (containsTopicPage(url) && !documentIdFromUrl(url)) {
            currentDocument = this.#getTopicPageData(this.#topicPage);
        } else {
            currentDocument = createGtmCurrentDocumentData(this.#documentFromRoute);
        }
        const { groupId, groupName } = this.productConfiguration().group as GroupConfigurationWithPia;
        return {
            event: GtmEvents.PageChange,
            url: url,
            groupId,
            groupName,
            currentDocument: currentDocument,
        };
    }
}
