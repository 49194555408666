import { logger } from './logger';

export const DEFAULT_MAX_SIZE = 5000;

export const DEFAULT_TTL_IN_MILLISECONDS = 5000;

export interface TtlCacheOptions {
    readonly maxSize?: number;
    readonly ttl?: number;
}

export interface ReadonlyTtlCache<K, V> {
    get isFull(): boolean;

    get size(): number;

    get(key: K): V | undefined;

    has(key: K): boolean;

    keys(): IterableIterator<K>;
}

const slice = (value: string): string => {
    if (value.length > 25) {
        return `${value.slice(0, 25)}...`;
    }
    return value;
};

// inspired by https://github.com/isaacs/node-lru-cache#storage-bounds-safety
export class TtlCache<K, V extends { toString: () => string }> implements ReadonlyTtlCache<K, V> {
    private readonly data: Map<K, V> = new Map();
    private readonly timers: Map<K, number> = new Map();

    private readonly maxSize: number;

    /** time to live in milliseconds */
    private readonly ttl: number;

    private readonly logPrefix: string;

    constructor(options: TtlCacheOptions = { maxSize: DEFAULT_MAX_SIZE, ttl: DEFAULT_TTL_IN_MILLISECONDS }) {
        this.maxSize = options.maxSize ?? DEFAULT_MAX_SIZE;
        this.ttl = options.ttl ?? DEFAULT_TTL_IN_MILLISECONDS;
        this.logPrefix = `[TtlCache{maxSize:${this.maxSize},ttl:${this.ttl}}]`;
    }

    public get isFull(): boolean {
        return this.maxSize > 0 && this.size === this.maxSize;
    }

    public get size(): number {
        return this.data.size;
    }

    public set(key: K, value: V, ttl: number = this.ttl ?? DEFAULT_TTL_IN_MILLISECONDS): void {
        const sliced = slice(value.toString());
        logger.debug(this.logPrefix, `set -> ${key}:${sliced}:${ttl}ms`);
        if (this.timers.has(key)) {
            window.clearTimeout(this.timers.get(key));
        }

        // when configured `maxSize` is reached, we stop adding elements
        if (this.isFull) {
            return;
        }

        this.timers.set(
            key,
            window.setTimeout(() => {
                logger.warn(this.logPrefix, `ttl for ${key}:${sliced} reached. Will delete entry.`);
                this.delete(key);
            }, ttl),
        );
        this.data.set(key, value);
    }

    public clear(): void {
        logger.debug(this.logPrefix, 'clear');
        this.data.clear();
        for (const id of this.timers.values()) {
            this.clearTimerById(id);
        }
        this.timers.clear();
    }

    public delete(key: K): boolean {
        logger.debug(this.logPrefix, 'delete ->', key);
        if (this.timers.has(key)) {
            this.clearTimerByKey(key);
        }
        this.timers.delete(key);
        return this.data.delete(key);
    }

    public get(key: K): V | undefined {
        return this.data.get(key);
    }

    public has(key: K): boolean {
        return this.data.has(key);
    }

    public keys(): IterableIterator<K> {
        return this.data.keys();
    }

    private clearTimerByKey(key: K): void {
        window.clearTimeout(this.timers.get(key));
    }

    private clearTimerById(id: number): void {
        window.clearTimeout(id);
    }
}
