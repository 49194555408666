import { ALL_HOT_TYPES, ApiName, CrsSortBy, HotType } from '@idr/shared/model';
import { SearchParams } from '@idr/ui/shared';

export const ContentHubDocumentType = {
    ...HotType,
    NEWS: 'News',
    NEWS_PROXY: 'NewsProxy',
    INSTALLMENT: 'Installment',
    INSTALLMENT_PROXY: 'InstallmentProxy', // capitalization is important!
};
export type ContentHubDocumentType = (typeof ContentHubDocumentType)[keyof typeof ContentHubDocumentType];

export const ContentHubSearchSortBy = {
    SORT_DATE_DESC: '-sortDate',
    SORT_DATE_ASC: 'sortDate',
};
export type ContentHubSearchSortBy = (typeof ContentHubSearchSortBy)[keyof typeof ContentHubSearchSortBy];

export const ContentHubApplication = {
    PORTALS: 'portals',
    HOT: 'hot',
};
export type ContentHubApplication = (typeof ContentHubApplication)[keyof typeof ContentHubApplication];

/**
 * Read more about the options here {@link https://portal.contenthub.haufe.io/apis/search}
 */
export interface ContentHubQueryOptions {
    /**
     * The type of content to search for. 'portals' is for news
     */
    readonly application?: ContentHubApplication;

    readonly tags?: string[];

    /**
     * References the service defined in {@link HotConfig}
     */
    readonly packageId?: string;

    /**
     * References the categories defined in {@link HotConfig}
     */
    readonly hotCategories?: string[];

    /**
     * References the categories defined in {@link NewsConfig}
     */
    readonly portalsCategories?: string[];

    /**
     * Referencing the chronologicalSortDate element of content-hub results.
     * Can be denoted either by a <=, >=, < or > prefix in front of a word or a ... infix between two words
     *
     * @example '<=2022-12-31' will match documents created or updated before end of the year 2022
     * @example '2021...2022-12-31' will match documents created or updated between begin of the year 2021 and end of the year 2022.
     */
    readonly sortDate?: string;

    /**
     * References the {@link HotType}
     *
     * IMPORTANT: For documentType {@link HotType.ONLINE_SEMINAR} it will fetch ONLY future seminars
     * since it doesn't make sense to display expired seminars to the user.
     */
    readonly documentTypes?: HotType[];

    /**
     * Defines the sorting order of the results.
     *
     * @example '-sortDate' to sort by date in descending order
     * @example 'sortDate' **(without +)** to sort by date in ascending order
     */
    readonly sortBy?: ContentHubSearchSortBy;

    /**
     * Used for pagination.
     *
     * @min 1
     * Note: 0 has the same effect as 1, but let's use 1 for consistency
     */
    readonly offset?: number;

    /**
     * How many results to return. Used for pagination.
     */
    readonly limit?: number;

    /**
     * The search facet that will be used. Read more about it here: {@link https://portal.contenthub.haufe.io/apis/search}
     */
    readonly facet?: string;

    /**
     * Matches documents containing the given word somewhere in their text body.
     * (More precisely: Somewhere in a field specified by the query context or in the default search locations.)
     * E.g. if the query is 'income' it will match all documents containing the word 'income', somewhere in the applicable search locations.
     * You can also use wildcards in the query. (e.g. '*income*')
     * Word matching is case-insensitive, e.g. searching for bang will match documents containing any of 'bang' or 'Bang' or 'BANG'.
     * By default, the text body of a document is the content of its default title, its baselineContent, and its (hidden) baselineSearchableText elements
     * (see {@link https://portal.contenthub.haufe.io/apis/search#queries-and-search-expressions}
     */
    readonly searchTerm?: string;

    /**
     * This property is used to filter out documents that should not be visible.
     * I noticed that we have some news that have ch:visible: false and this property will filter them out.
     * I didn't find any HOT documents that have ch:visible: false and thus I decided to set the property to true by default.
     *
     * @default true
     */
    readonly visible?: boolean;

    /**
     * This is only used in {@link ContentHubDocumentType.NEWS} and we set it to true.
     * Unfortunately I couldn't find any documentation for it nor could I figure out how it affects the results...
     * Feedback from the editors: it is necessary to filter out news with visibleInSuite=false for legal reasons
     */
    readonly visibleInSuite?: boolean;

    /**
     * Indicates whether the results should include the preview for their entries.
     * It seems that is only works for HOT. For News, it doesn't add any preview content in the results.
     *
     * @default true
     */
    readonly preview?: boolean;

    /**
     * Indicates how many snippets (i.e. matching parts of the document) should be returned for each result.
     * Note: The snippets are matching nodes that contain the search term. They can be the "title" or the "baselineContent" of a document.
     * For example if we choose to have 2 snippets we will end up with 1 title and 1 baselineContent snippets
     * or 2 baselineContent snippets if there is no match in the title of the document.
     */
    readonly snippetCount?: number;

    /**
     * The snippetTokenCount size is roughly equivalent to the maximum number of tokens (typically words)
     * per matching node that surround the highlighted term(s) in the snippet.
     */
    readonly snippetTokenCount?: number;

    readonly isSoldOut?: boolean;
}

export const ERROR_NO_APPLICATION_SPECIFIED = `Property "application" is required. Please use "${ContentHubApplication.HOT}" or "${ContentHubApplication.PORTALS}"`;
export const ERROR_INVALID_OFFSET = `Property "offset" must be greater than 0.`;

const cleanQuery = (query: string): string =>
    query
        // users don't search based on phrases, so we remove the quotes
        // any other replaced character has a special meaning in query language of ContentHub and should be removed as well
        // "-" only has special meaning as negation at the beginning of the query; we ignore this case; I doubt this will ever happen to a user
        // https://jira.haufe.io/browse/NAUA-7433
        // https://gitlab.haufedev.systems/ContentHub/Contenthub.Core/-/blob/9e442e6a62920bf61ad8371eaebff40d9ec16478/commons/expression-lang/src/main/antlr/com/haufe/contenthub/commons/expression/lang/grammar/SearchQueryGrammar.g4#L40
        // https://regex101.com/r/IzuiUa/2
        .replace(/["()*<>=+:\s]|\.{2,}/g, ' ')
        .replace(/\s{2}/, ' ')
        .trim();

/**
 * Prepares the query for the content hub search api.
 * More info about the available params can be found here: {@link https://portal.contenthub.haufe.io/apis/search}
 */
export class ContentHubSearchQuery {
    static contentHubQueryOptionsFromSearchParams(
        params: SearchParams,
        limit: number,
        isSoldOut: boolean,
    ): ContentHubQueryOptions {
        if (!params) {
            throw new Error('Params are required');
        }

        const page = params.page ?? 1;
        // offset starts from 1 instead of 0 for ContentHub Search API
        const offset = (page - 1) * limit + 1;

        let reqParams: ContentHubQueryOptions = {
            documentTypes: params.documentType ? [params.documentType] : ALL_HOT_TYPES,
            offset,
            limit,
            searchTerm: params.query,
            snippetTokenCount: 10,
            snippetCount: 2,
            sortDate: params.sortDate,
        };
        if (params.sort) {
            reqParams = {
                ...reqParams,
                sortBy: ContentHubSearchQuery.convertCrsSortByToContentHubSortBy(params.sort),
            };
        }
        if (isSoldOut) {
            reqParams = {
                ...reqParams,
                isSoldOut: params.documentType === HotType.ONLINE_SEMINAR ? false : undefined,
            };
        }
        return reqParams;
    }

    public static buildUrl(options: ContentHubQueryOptions): string {
        // Note: be careful with p.append() because if you try
        // p.append('q', undefined) or p.append('q', null) or p.append('q', '')
        // it will actually append those values resulting in a query string like ?q=undefined&q=null&q=
        const p = new URLSearchParams();

        if (!options.application) {
            throw new Error(ERROR_NO_APPLICATION_SPECIFIED);
        }

        p.append('q', `application:${options.application}`);

        if (options.searchTerm) {
            p.append('q', cleanQuery(options.searchTerm));
        }

        const filterQueries = [];
        if (options.packageId) {
            filterQueries.push(`packageId:SI${options.packageId}`);
        }
        if (options.hotCategories && options.hotCategories.length > 0) {
            // NAUA-7078 - Content hub searches only for child categories if we don't specify hot.parent_category_id.
            // However, some products are configured to use parent categories. Since we are not able to identify which categories specified
            // in the config are parent categories and which are child categories, we will search for both types for every id.
            // we need to have hot.category_id:... or hot.parent_category_id:... in the query
            const categoryIdsQuery = options.hotCategories.map(
                id => `hot.category_id:${id}+OR+hot.parent_category_id:${id}`,
            );
            filterQueries.push(categoryIdsQuery.join('+OR+'));
        }
        if (filterQueries.length > 0) {
            // Note: ContentHub search API will combine all the "q" params into one query and join them with AND operator
            // Thus we have to explicitly combine the filters that we want with OR operator
            p.append('q', filterQueries.join('+OR+'));
        }

        if (options.portalsCategories && options.portalsCategories.length > 0) {
            // We should enclose the categories in quotes to make sure that contentHub receives the correct category.
            // Otherwise, it will return 0 results, like NAUA-6946 where we used portals.category:Öffentlicher Dienst without quotes
            const quotedCategories = options.portalsCategories.map(cat => `"${cat}"`);
            p.append('q', `portals.category:${quotedCategories.join('+OR+portals.category:')}`);
        }

        if (options.sortDate) {
            p.append('q', `sortDate:${options.sortDate}`);
        }

        if (options.documentTypes && options.documentTypes.length > 0) {
            // We need a special logic here. Since it doesn't make sense to return online-seminars of the past we always request online-seminars >= now.
            const docTypesQuery = options.documentTypes
                .map(type => {
                    if (type === HotType.ONLINE_SEMINAR || type === HotType.ONLINE_SCHULUNG) {
                        return `(documentType:${type} sortDate:>=${new Date().toISOString()})`;
                    }
                    return `documentType:${type}`;
                })
                .join(' OR ');
            p.append('q', docTypesQuery);
        }

        if (options.tags && options.tags.length > 0) {
            p.append('q', `tag:${options.tags.join('+OR+tag:')}`);
        }

        if (options.sortBy) {
            p.append('sorting', options.sortBy);
        }

        if (options.offset !== undefined) {
            if (options.offset < 1) {
                throw new Error(ERROR_INVALID_OFFSET);
            }
            p.append('offset', options.offset.toString());
        }

        if (options.limit) {
            p.append('limit', options.limit.toString());
        }

        if (options.facet) {
            p.append('facet', options.facet);
        }

        // This will set preview to true (default) if not set in options
        p.append('preview', (options.preview === undefined || options.preview === true).toString());

        // This will set visible to true (default) if not set in options
        p.append('q', `visible:${(options.visible === undefined || options.visible === true).toString()}`);

        if (options.visibleInSuite !== undefined) {
            p.append('q', `portals.visibleInSuite:${options.visibleInSuite.toString()}`);
        }

        if (options.isSoldOut !== undefined) {
            p.append('q', `hot.sold_out:${options.isSoldOut}`);
        }

        const snippetQuery = [];
        if (options.snippetCount) {
            snippetQuery.push(`count:${options.snippetCount}`);
        }
        if (options.snippetTokenCount) {
            snippetQuery.push(`numberOfTokens:${options.snippetTokenCount}`);
        }
        if (snippetQuery.length > 0) {
            p.append('snippets', snippetQuery.join(','));
        }

        return `${ApiName.CONTENTHUB}/search?${p.toString()}`;
    }

    /**
     * Converts CRS sort-params to ContentHub sort-params.
     */
    static convertCrsSortByToContentHubSortBy(
        crsSortBy: CrsSortBy,
        hotDocumentType?: HotType,
    ): ContentHubSearchSortBy | undefined {
        switch (crsSortBy) {
            case undefined:
                return undefined;
            case CrsSortBy.RELEVANCE_DESCENDING:
                // This is the default sorting for contenthub. It will return the most relevant results first.
                return undefined;
            case CrsSortBy.DATE_DESCENDING:
                return hotDocumentType === ContentHubDocumentType.ONLINE_SEMINAR
                    ? // inverse sorting for online-seminars to get the most recent ones first
                      ContentHubSearchSortBy.SORT_DATE_ASC
                    : ContentHubSearchSortBy.SORT_DATE_DESC;
            case CrsSortBy.DATE_ASCENDING:
                return ContentHubSearchSortBy.SORT_DATE_ASC;
            default:
                throw new Error(
                    `CRS sort by "${crsSortBy}" cannot be converted to ContentHub sort by. Please extend convertCrsSortByToContentHubSortBy method to support more conversions.`,
                );
        }
    }
}
