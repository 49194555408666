import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule],
    selector: 'hg-reload-button',
    standalone: true,
    styleUrls: ['./reload-button.component.scss'],
    template: ` <button>
        {{ label() }}
        <mat-icon svgIcon="refresh" />
    </button>`,
})
export class ReloadButtonComponent {
    label = input.required<string>();
}
