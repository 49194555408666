import { Injectable } from '@angular/core';
import { DocumentId, Product, ProductId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { List as ImmutableList } from 'immutable';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CrsDocumentService } from '@idr/ui/crs-facade';

/**
 * Introduced with NAUA-8456.
 *
 * Used to resolve a product from a group.
 * This service is able to detect the correct product based on additional logic, like requesting backend
 * in which product the requested/routed content is actually available.
 * @see resolve
 */
@Injectable({ providedIn: 'root' })
export class RouteSpecificProductResolver {
    private readonly logPrefix = '[RouteSpecificProductResolver]';

    constructor(private readonly legacyContentBackend: CrsDocumentService) {}

    /**
     * Give me your {@param productCandidates} and all the requested {@param urlPathParts} and
     * I tell you which product contains the requested content.
     *
     * @param productCandidates
     * @param urlPathParts you can get those via {@link urlAsParts} from current route.
     */
    public resolve(productCandidates: ImmutableList<Product>, urlPathParts: string[]): Observable<Product> {
        switch (urlPathParts[1]) {
            // NAUA-8456
            case 'document':
                return from(this.resolveForDocumentRoute$(productCandidates, urlPathParts[2]));
        }

        return of(productCandidates.first());
    }

    private async resolveForDocumentRoute$(
        productCandidates: ImmutableList<Product>,
        documentId: DocumentId,
    ): Promise<Product> {
        let _candidates = productCandidates;
        let documentExistsInProduct = false;
        let product: Product;
        while (!documentExistsInProduct && _candidates.size > 0) {
            product = _candidates.first();
            _candidates = _candidates.remove(0);
            logger.debug(this.logPrefix, 'resolveForDocumentRoute$ -> will try', documentId, 'in', product.id, 'next');
            documentExistsInProduct = await firstValueFrom(this.doesDocumentExistInProduct$(product.id, documentId));
        }

        return documentExistsInProduct ? product : productCandidates.first();
    }

    private doesDocumentExistInProduct$(productId: ProductId, documentId: DocumentId): Observable<boolean> {
        return this.legacyContentBackend.getDocument$(documentId, '', productId).pipe(
            map(document => {
                if (!document) {
                    logger.warn(
                        this.logPrefix,
                        "doesDocumentExistInProduct -> couldn't find document",
                        document,
                        'in',
                        productId,
                    );
                    return false;
                }
                return true;
            }),
        );
    }
}
