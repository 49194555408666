import { OdinDateString, RenderDateString } from '../odin-date-string';

/**
 * A helper function to format the date to the desired format (i.e. dd.MM.yyyy).
 * The API returns the date in ISO 8601 ('yyyy-MM-dd' or 'yyyy-MM-ddThh-mm-ssz') format and we need to display it in 'dd.MM.yyyy' format.
 * @param date Any valid date string
 * @returns The date formatted as 'dd.MM.yyyy'
 */
export const getRenderDate = (date: OdinDateString | undefined): RenderDateString | '' => {
    if (!date) {
        return '';
    }

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
        throw new Error(`Invalid date: ${date}`);
    }
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return parsedDate.toLocaleDateString('de-DE', options) as RenderDateString; // 'de-DE' for German format (dd.MM.yyyy)
};
