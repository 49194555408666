import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[hgSetFocus]',
    standalone: true,
})
export class SetFocusDirective implements OnInit {
    @Input() focus = true;

    constructor(private readonly el: ElementRef<HTMLElement>) {}

    ngOnInit(): void {
        if (!this.focus) {
            return;
        }

        this.el.nativeElement.focus();
    }
}
