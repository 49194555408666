import { SafeHtml } from '@angular/platform-browser';
import {
    createDocumentClassificationFromDto,
    CrsDocFieldDTO,
    CrsDocumentHitDTO,
    CrsHitField,
    DocumentId,
    DocumentProvider,
    HotType,
    OdinDocumentBookInfo,
    OdinDocumentClassification,
} from '@idr/shared/model';
import { HotDocument, NewsDocument } from '@idr/ui/content-hub';
import { hash, ValueObject } from 'immutable';
import { createBookInfoFromCrsDocumentHit } from '../utils/create-book-info-from-crs-document-hit';

export type SearchHit = CrsSearchHit | NewsSearchHit | HotSearchHit;

interface SearchHitInterface extends ValueObject {
    readonly documentId: DocumentId;
    readonly preview: SafeHtml | undefined;
    readonly subTitle: string;
    readonly title: string;
    readonly query: string;
    readonly selectedInstance: SearchHit;
    readonly trackBy: number;
    readonly active: boolean;
}

export class CrsSearchHit implements SearchHitInterface {
    readonly documentId: DocumentId;
    readonly preview: SafeHtml | undefined;
    readonly trackBy: number;
    readonly topic: string | undefined;
    readonly vaInfo: string | undefined;
    readonly provider: DocumentProvider | undefined;
    readonly bookInfo: OdinDocumentBookInfo | undefined;

    private constructor(
        private readonly crsDocumentHit: CrsDocumentHitDTO,
        public readonly classification: OdinDocumentClassification,
        public readonly query: string,
        public readonly active = false,
    ) {
        const docFields: CrsDocFieldDTO[] = crsDocumentHit.hit[0].docfield ? crsDocumentHit.hit[0].docfield : [];
        const topicField: CrsDocFieldDTO = docFields.find(field => field.name === CrsHitField.LEGAL_QUESTION);
        const vaInfoField: CrsDocFieldDTO = docFields.find(field => field.name === CrsHitField.VA_INFO);
        const providerField: CrsDocFieldDTO = docFields.find(field => field.name === CrsHitField.PROVIDER_ID);

        this.documentId = crsDocumentHit.hit[0].docid;
        this.preview = crsDocumentHit.hit[0].hitcontext;
        this.trackBy = hash(`${crsDocumentHit.docid}_${active}`);

        this.topic = topicField !== undefined ? topicField.value : undefined;
        this.vaInfo = vaInfoField !== undefined ? vaInfoField.value : undefined;

        this.provider = DocumentProvider.fromJson(providerField !== undefined ? providerField.value : undefined);
        this.bookInfo = createBookInfoFromCrsDocumentHit(crsDocumentHit);
    }

    /**
     * Also known as "rootTile".
     */
    get title(): string {
        // if `bookInfo` is set we need to render the title of `bookInfo` instead since it provides more information
        // this feature is adopted from old iDesk2

        if (this.bookInfo !== undefined) {
            return this.bookInfo.title;
        }

        return this.crsDocumentHit.docShortTitle;
    }

    /**
     * It may be a different one for "structured" documents if the !first! is inside a sub-chapter.
     */
    get subTitle(): string {
        if (this.bookInfo !== undefined) {
            return this.bookInfo.subTitle;
        }

        if (this.title !== this.crsDocumentHit.hit[0].doctitle) {
            return this.crsDocumentHit.hit[0].doctitle;
        }

        return this.vaInfo;
    }

    /**
     * Returns an instance of this SearchHit with the property active set to true.
     *
     * It is better for performance to have immutable objects. So for example instead of changing
     * one property and re-use the object it is cheaper to just create a new one. New object => rerender.
     * It also makes the code simpler. No need to keep track of the selection in extra variables.
     * The search-hits-component doesn't know anything about selections, it just renders the list it is given.
     * See SearchPageService where it is used.
     */
    get selectedInstance(): CrsSearchHit {
        return new CrsSearchHit(this.crsDocumentHit, this.classification, this.query, true);
    }

    equals(other: unknown): boolean {
        return (
            this.trackBy === (other as SearchHitInterface)?.trackBy &&
            this.query === (other as SearchHitInterface)?.query &&
            this.preview === (other as CrsSearchHit)?.preview &&
            this.topic === (other as CrsSearchHit)?.topic &&
            this.vaInfo === (other as CrsSearchHit)?.vaInfo
        );
    }

    hashCode(): number {
        return this.trackBy;
    }

    static fromJson(documentHit: CrsDocumentHitDTO, query?: string): CrsSearchHit | undefined {
        if (!documentHit || !documentHit.hit || documentHit.hit.length === 0) {
            return undefined;
        }

        const classification: OdinDocumentClassification = createDocumentClassificationFromDto(
            documentHit.docclass,
            documentHit.hit[0].bookInfo !== undefined,
        );
        if (!classification) {
            return undefined;
        }

        query = query ? query : '';

        return new CrsSearchHit(documentHit, classification, query);
    }
}

export class NewsSearchHit implements SearchHitInterface {
    readonly trackBy: number;

    private constructor(
        public readonly documentId: DocumentId,
        public readonly preview: SafeHtml | undefined,
        public readonly subTitle: string,
        public readonly title: string,
        public readonly query: string,
        public readonly active = false,
    ) {
        this.trackBy = hash(`${documentId}_${active}`);
    }

    get selectedInstance(): NewsSearchHit {
        return new NewsSearchHit(this.documentId, this.preview, this.subTitle, this.title, this.query, true);
    }

    equals(other: unknown): boolean {
        return (
            this.trackBy === (other as SearchHitInterface)?.trackBy &&
            this.query === (other as SearchHitInterface)?.query
        );
    }

    hashCode(): number {
        return this.trackBy;
    }

    static fromNewsDocument(newsDocument: NewsDocument, query: string): NewsSearchHit {
        return new NewsSearchHit(
            newsDocument.contentHubId,
            newsDocument.preview,
            newsDocument.shortTitle,
            newsDocument.title,
            query,
        );
    }
}

export class HotSearchHit implements SearchHitInterface {
    readonly trackBy: number;

    private constructor(
        public readonly documentId: DocumentId,
        public readonly preview: SafeHtml | undefined,
        public readonly subTitle: string,
        public readonly title: string,
        public readonly query: string,
        public readonly type: HotType,
        public readonly authors: string[] = [],
        public readonly startDateTime: Date,
        public readonly active = false,
    ) {
        this.trackBy = hash(`${documentId}_${active}`);
    }

    get selectedInstance(): HotSearchHit {
        return new HotSearchHit(
            this.documentId,
            this.preview,
            this.subTitle,
            this.title,
            this.query,
            this.type,
            this.authors,
            this.startDateTime,
            true,
        );
    }

    equals(other: unknown): boolean {
        return (
            this.trackBy === (other as SearchHitInterface)?.trackBy &&
            this.query === (other as SearchHitInterface)?.query
        );
    }

    hashCode(): number {
        return this.trackBy;
    }

    static fromHotDocument(hotDocument: HotDocument, query: string): HotSearchHit {
        return new HotSearchHit(
            hotDocument.contentHubId,
            hotDocument.preview,
            // We want to avoid showing a subTitle that is the same as the title. (there were some cases where this happened)
            hotDocument.shortTitle === hotDocument.title ? undefined : hotDocument.shortTitle,
            hotDocument.title,
            query,
            hotDocument.type,
            hotDocument.authors,
            hotDocument.startDateTime,
        );
    }
}
