import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonNavigationService, RouterEvents } from '../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatTooltipModule],
    selector: 'hg-document-close-button',
    standalone: true,
    styleUrls: ['./document-close-button.component.scss'],
    template: ` <button
        (click)="close()"
        matTooltip="Vollansicht schließen"
        matTooltipPosition="below">
        <mat-icon svgIcon="doc-close" />
    </button>`,
})
export class DocumentCloseButtonComponent {
    constructor(
        private readonly location: Location,
        private readonly routerEvents: RouterEvents,
        private readonly commonNavigation: CommonNavigationService,
    ) {}

    close(): void {
        const isFirstPageView = !this.routerEvents.hasPreviousUrl;
        if (isFirstPageView) {
            this.commonNavigation.toStartpage();
            return;
        }

        this.location.back();
    }
}
