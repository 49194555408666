import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[hgLazyLoadOnScroll]',
    standalone: true,
})
export class LazyLoadOnScrollDirective {
    /**
     * Set this to true while the lazy loading is in progress.
     * This will prevent triggering additional lazy-loading while the old is still in progress.
     */
    @Input() isLoadingMore = false;

    @Output() readonly loadMore = new EventEmitter<void>();

    @HostListener('scroll', ['$event.target'])
    onScroll(target: Element): void {
        if (target.clientHeight + target.scrollTop >= target.scrollHeight && !this.isLoadingMore) {
            this.loadMore.emit();
        }
    }
}
