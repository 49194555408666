import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ACTIVATED_PRODUCT_GROUP, ActivatedProductGroupSignal } from '../../routing/activated-product-group';
import { CONFIG_FEATURES, NavigationEntry } from './navigation-entry';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, RouterLinkActive],
    selector: 'mc-navigation',
    standalone: true,
    styleUrl: './navigation.component.scss',
    template: ` <div>
        @for (feature of configFeatures; track feature.label) {
            <a
                data-cy="config-navigation-links"
                [routerLink]="['/config', active()?.groupId, feature.page]"
                routerLinkActive="is-active">
                {{ feature.label }}
            </a>
        }
    </div>`,
})
export class NavigationComponent {
    readonly configFeatures: NavigationEntry[] = CONFIG_FEATURES;

    constructor(@Inject(ACTIVATED_PRODUCT_GROUP) public readonly active: ActivatedProductGroupSignal) {}
}
