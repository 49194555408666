import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { inject, InjectionToken, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { filter, map, startWith } from 'rxjs';

/**
 * Gives you a boolean signal telling you if draft mode was requested (via url param) or not.
 *
 * Once turned on via url param e.g. `draft=true` or `draft=1`),
 * it stays active until it gets turned off (e.g. `draft=false`, `draft=0`) again.
 */
export const DRAFT_MODE_IS_REQUESTED: InjectionToken<Signal<boolean>> = new InjectionToken('DRAFT_MODE_TOKEN', {
    providedIn: 'root',
    factory: () => {
        const route = inject(ActivatedRoute);
        return toSignal(
            route.queryParams.pipe(
                map(params => params['draft']),
                filter(Boolean), // the parameter must be defined for us to take it into account
                map(draftParam => (draftParam !== '0' ? coerceBooleanProperty(draftParam) : false)),
                startWith(false), // in any case we should start of with `false`
            ),
        );
    },
});
