import { ActivatedRoute } from '@angular/router';
import { Params } from '@idr/shared/model';

/**
 * updateMatrixParams should update matrixParameters.
 * The problem is that there is no way to get just the matrix params.
 * activatedRoute.snapshot.params will also contain the path parameter.
 * eg: if I want to update sel in /search/steuer;sel=HI10616238 snapshot.params will also contain
 * query: steuer.
 */
export const getMatrixParamsOnly = (route: ActivatedRoute): Params => {
    const params = { ...route.snapshot.params };
    const pathParamKey = route.routeConfig?.path?.replace(':', '');
    if (pathParamKey && params[pathParamKey]) {
        delete params[pathParamKey];
    }
    return params;
};

export const getUpdatedMatrixParams = (route: ActivatedRoute, toUpdate: Params): Params => {
    const oldMatrixParams: Params = getMatrixParamsOnly(route);
    const updatedParams: Params = {
        ...oldMatrixParams,
        ...toUpdate,
    };
    return removeEmptyParams(updatedParams);
};

export const removeEmptyParams = (params: Params): Params => {
    if (!params) {
        return {};
    }

    // eslint-disable-next-line functional/prefer-readonly-type
    const filteredParams: { [p: string]: unknown } = {};
    for (const [key, value] of Object.entries(params)) {
        if (!value || value.length === 0) {
            continue;
        }
        filteredParams[key] = value;
    }

    return filteredParams;
};
