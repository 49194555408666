import { SafeHtml } from '@angular/platform-browser';
import { Breadcrumb, DeeplinkPathId } from '@idr/shared/model';
import { stripHtml } from '@idr/ui/shared';

export class SitemapItem {
    public readonly label: SafeHtml | string;

    /**
     * @param label rendered as text inside our sitemap component.
     * @param routerLink (optional) There might be entries in our sitemap that aren't a link.
     *                   Usually the last entry of the sitemap is such an entry.
     * @param renderAsHtml whether or not given {@link label} shall be rendered as html or not
     */
    constructor(
        label: SafeHtml | string,
        public readonly routerLink?: string,
        renderAsHtml = false,
    ) {
        this.label = renderAsHtml ? label : stripHtml(label);
    }

    /**
     * Create a {@link Sitemap}from breadcrumb items and document title.
     *
     * @param breadcrumb converted to `/column/<LIxyz>` links using the attributes of {@link Breadcrumb}
     * @param documentTitle the last item of the Breadcrumb and is not clickable
     */
    public static fromBreadcrumb(breadcrumb: Breadcrumb[], documentTitle?: string): Sitemap {
        const sitemap: Sitemap = breadcrumb.map(
            item => new SitemapItem(item.title, `${DeeplinkPathId.Column}/${item.linkIndex}`),
        );
        if (documentTitle) {
            sitemap.push(new SitemapItem(documentTitle));
        } else if (sitemap.length > 0) {
            // last child should not be clickable...
            sitemap[sitemap.length - 1] = new SitemapItem(sitemap[sitemap.length - 1].label);
        }
        return sitemap;
    }
}

export type Sitemap = SitemapItem[];
