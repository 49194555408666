import { BucketDTO, ContentHubDocumentDTO, ContentHubDocumentsDTO, EntryDTO, MetaDTO } from '@idr/shared/model';
import { arrayFromVar } from '@idr/shared/utils';

export abstract class ContentHubDocuments<EntryType, MetaDtoType extends MetaDTO> {
    public readonly startIndex: number;
    public readonly count: number;
    public readonly totalCount: number;
    public readonly entries: EntryType[];
    public readonly subCategories: BucketDTO[];

    protected constructor(
        dto: ContentHubDocumentsDTO<ContentHubDocumentDTO<MetaDtoType>>,
        entryConverter: (entryDto: EntryDTO<ContentHubDocumentDTO<MetaDtoType>>) => EntryType,
    ) {
        this.startIndex = dto.feed['opensearch:startIndex'];
        this.count = dto.feed['opensearch:Query'].count;
        this.totalCount = dto.feed['opensearch:totalResults'];
        this.entries = !dto.feed.entry
            ? []
            : Array.isArray(dto.feed.entry)
              ? dto.feed.entry.map(value => entryConverter(value))
              : [entryConverter(dto.feed.entry)];

        // subcategories from the contenthub for hot content etc. can be obtained via faceted search
        // in the response the subcategories can be found under chs:facets > chs:facet > chs:bucket
        // this chs:bucket can either hold a subcategory object or an array of objects
        const entry = dto.feed['chs:facets']['chs:facet']?.find(e => e.constraint === 'documentType')?.['chs:bucket'];
        // Note: arrayFromVar will return undefined if entry is not defined instead of [undefined] so the expression below is safe
        this.subCategories = arrayFromVar(entry) ?? [];
    }
}
