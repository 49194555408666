import { BauplanId, DocumentId, isTopicPageDocument, Link, LinkType, NodeDTO } from '@idr/shared/model';

export const convertLinkFromNodeDTO = (node: NodeDTO): Link => {
    let linkType: LinkType = LinkType.BAUPLAN_INDEX;
    let id: BauplanId | DocumentId;
    let title: string;
    if (node.document) {
        if (isTopicPageDocument(node.document.docclass?.type, node.document.docid)) {
            linkType = LinkType.TOPIC_PAGE;
        } else {
            linkType = LinkType.DOCUMENT;
        }
        id = node.document.docid;
        title = node.document.title;
    } else {
        // node has type BauplanLinkDTO
        id = node.id;
        title = node.title;
    }
    return { label: title, type: linkType, source: id };
};
