export * from './bauplan';
export * from './brand';
export * from './breadcrumb';
export * from './content-hub';
export * from './crs';
export * from './deeplinks';
export * from './document';
export * from './product';
export * from './product-configuration';
export * from './product-group';
export * from './product-tour';
export * from './profile';
export * from './search';
export * from './settings';
export * from './api';
export * from './beta-testing';
export * from './econda-tracking';
export * from './has-label';
export * from './has-source';
export * from './hot-type';
export * from './lexinform-caller';
export * from './link';
export * from './nps-config';
export * from './params';
export * from './route-paths';
export * from './topic-page';
export * from './odin';

export const ALPHABET: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
