import { logger } from '@idr/shared/utils';
import { hash, ValueObject } from 'immutable';
import { DocumentId } from './document';
import { Product, ProductId } from './product';

type EcondaQuery = string;

type EcondaClusterTitle = string;

type EcondaHitRank = number;

type EcondaDocumentType = string;

export type EcondaSearchHit = [
    DocumentId,
    ProductId,
    EcondaQuery,
    EcondaClusterTitle,
    EcondaHitRank,
    EcondaDocumentType,
];

export interface EcondaSearchTrackingData {
    /**
     * Define this when you wanna track a new search.
     * This should be `undefined` when it is only a pageChange on the search page (that means search page is already visible
     * but user changed the category for example).
     */
    readonly newSearch?: {
        /** Used to set {@link EcondaSearchRelatedData.search}. */
        readonly query: string;
        /** Used to set {@link EcondaSearchRelatedData.search}. */
        readonly hitCount: number;
        /** Used to set {@link EcondaSearchRelatedData.sfilter} with 'Vorauswahl' as `filter`. */
        readonly preselectedCluster?: string;
    };
    /**
     * Used to fill a search related custom dimension.
     *
     * @optional this is only set when we have a search result. Obsolete in case of an empty search.
     * @see EcondaSearchRelatedData.suche
     */
    readonly hits?: EcondaSearchHit[];
}

/**
 * We are still using the same site id as we do for the old iDesk2
 * because econda team required to have the same site id for analyses purposes.
 * We have added an "application" property to distinguish between the old iDesk2 and iDesk Reloaded.
 */
export const ECONDA_SITE_ID = 'iDesk2 Online Produkte';

export const ECONDA_APPLICATION = 'iDeskReloaded';

/**
 * We actually use the site id as a prefix to match the one that we had in iDesk2
 */
export const ECONDA_PATH_PREFIX = ECONDA_SITE_ID;

/** Special prefix for targets. Again, we use old value from iDesk2 since data shall be merge-able. */
export const ECONDA_TARGET_PREFIX = 'iDesk2';

export const ECONDA_NO_TRACKING_KEY = 'no-tracking';

/**
 * Defines supported groups for targets.
 *
 * A "group" is a cluster for targets in a common area.
 * For example document specific targets should all have the group {@link EcondaTargetGroup.Document} in common.
 *
 * @see EcondaTarget
 */
export const EcondaTargetGroup = {
    Document: 'Dokument',
    DocumentHot: 'Dokument/HOT',
    DocumentNews: 'Dokument/News',
    Search: 'Suche',
    Startpage: 'Startseite',
    Header: 'Header',
    ServiceMenu: 'Service-Menü',
    Survey: 'Umfrage',
    Scrolling: 'Scrollen',
};

export type EcondaTargetGroup = (typeof EcondaTargetGroup)[keyof typeof EcondaTargetGroup];

export const EcondaTargetName = {
    Close: 'Schließen',

    OpenFullView: 'Vollansicht öffnen',
    Print: 'Drucken',
    Save: 'Download',

    ProductName: 'Produktname',
    SearchClosed: 'Suche/geschlossen',
    SearchOpened: 'Suche/geöffnet',
    SearchReset: 'Suche/Zurücksetzen',
    SearchSuggestion: 'Suche/Suggestion',
    SearchTriggered: 'Suche/ausgelöst',

    Feedback: 'Feedback',
    FeedbackClosed: 'Feedback/geschlossen',

    FirstPage: 'Erste Seite',
    LastPage: 'Letzte Seite',
    PreviousPage: 'Vorherige Seite',
    NextPage: 'Nächste Seite',
    Page: 'Seite Nr',

    // User Menu - Static menu entries only - Dynamic ones are tracked using their dynamic German labels
    ProductTourStart: 'Produkttour starten',
    GenderInfo: 'Gender-Hinweis',
    ShowImprint: 'Impressum',
    VersionInfo: 'Versionsinformation',
    ShowManual: 'Handbuch ansehen',
    ContactSupport: 'Support kontaktieren',
    Logout: 'Abmelden',

    BetaSurveyNotNow: 'Beta/Jetzt nicht',
    ToBetaSurvey: 'Beta/Zur Umfrage',

    LegacyProductSwitch: 'Produktwechsel/Klassisch',
    BetaProductSwitch: 'Produktwechsel/Beta',

    PaidContent: 'Kostenpflichtig',
    IncludedContent: 'Im Abo enthalten',
};

export type EcondaTargetName = (typeof EcondaTargetName)[keyof typeof EcondaTargetName];

/**
 * Defines supported conversion values for targets.
 *
 * @see EcondaTarget
 * @see https://support.econda.de/display/INEN/Targets
 */
export const EcondaConversionValue = {
    POSITIVE: 1,
    NEGATIVE: 0,
};

export type EcondaConversionValue = (typeof EcondaConversionValue)[keyof typeof EcondaConversionValue];

/**
 * @see EcondaTarget
 */
export const EcondaTargetRule = {
    default: 'd',
    count_once_per_visit: 's',
    count_all_per_visit: 'a',
} as const;

export type EcondaTargetRule = (typeof EcondaTargetRule)[keyof typeof EcondaTargetRule];

export type EcondaTargetArray = [string, string, number, EcondaTargetRule];

const LEXICON_LABELS: string[] = ['Lexikon', 'Themen A-Z', 'Themen von A-Z', 'Inhalte von A-Z', 'Themenseiten'];

/**
 * Creates value for {@link EcondaPageChangeEventDto.pageId} based on given {@param content}.
 * In case of `pageId` we try to find a more common id for our current page for best possible support in click monitor.
 * E.g. for click monitor it doesn't really matter which document was opened, only that a document was opened... same for search & A-Z view.
 */
export const createEcondaPageId = (content: string): string => {
    logger.debug('[econda-tracking.createEcondaPageId] ->', content);
    // see https://regex101.com/r/ILKAXs/1
    const match = new RegExp(`PI\\d+/(${LEXICON_LABELS.join('|')}|Suche|Dokument)`, 'g').exec(content);
    // content already contains the ECONDA_PATH_PREFIX, so we should not add it again
    return match && match.length >= 1 ? `${ECONDA_PATH_PREFIX}/${match[0]}` : content;
};

/**
 * @see https://docs.econda.de/de/INDE/analytics+integrieren/econda-funktionen/webanalyse/ziele.html
 */
export class EcondaTarget {
    /**
     * @param group see {@link EcondaTargetGroup}
     * @param name the concrete name of this target
     * @param value see {@link EcondaConversionValue}
     *              you can also use your own specific value as long as it fulfils this condition: 0 <= value <= 2
     */
    constructor(
        private readonly group: EcondaTargetGroup | string,
        private readonly name: EcondaTargetName | string,
        private readonly value: EcondaConversionValue | number = EcondaConversionValue.POSITIVE,
    ) {}

    public convertForEcondaEvent(): EcondaTargetArray {
        return [
            `${ECONDA_TARGET_PREFIX}/${this.group.toString()}`,
            this.name.toString(),
            this.value.valueOf(),
            EcondaTargetRule.count_all_per_visit,
        ];
    }

    public isConvertible(): boolean {
        const valueIsSet: boolean = !!this.value || this.value === 0;
        const valueInAllowedRange: boolean = this.value >= 0 && this.value <= 2;
        return !!this.group && !!this.name && valueIsSet && valueInAllowedRange;
    }
}

export declare class EcondaScript {
    /**
     * Relevant for "click monitor".
     * In our case it should always be set to the {@link EcondaPageChangeEventDto.content} of a page change.
     *
     * @see https://docs.econda.de/en/INDE/integration-click-monitor/integration+click+monitor.html
     */
    public pageId: string;

    public send(event: EcondaEventDto): void;
}

export type EcondaEventDto = EcondaPageChangeEventDto | EcondaTargetEventDto;

export interface EcondaSearchRelatedData {
    // https://docs.econda.de/en/MONDE/plug-in-center/advanced-search-plug-in/advanced+search+plug-in+einrichten.html
    readonly search?: [string, number];

    // https://docs.econda.de/en/MONDE/plug-in-center/advanced-search-plug-in/advanced+search+plug-in+einrichten.html
    readonly sfilter?: [string, string][];

    /**
     * Haufe custom dimension added on econda side in order to track Aurora's hitlist
     */
    readonly suche?: EcondaSearchHit[];
}

export interface EcondaCommentEventDto {
    // https://jira.haufe.io/browse/NAUA-1836

    /**
     * Haufe custom dimension that tells under which category tracking data shall be stored.
     *
     * For Reloaded this is always `Online Produkte`.
     */
    readonly countryid: 'Online Produkte';
    // https://jira.haufe.io/browse/NAUA-1836
    // https://jira.haufe.io/secure/attachment/83242/Haufe_20150702_econda-Integration-Freie_dims_BrandFunktion.pdf

    /**
     * Haufe custom dimension that tells under which brand tracking data shall be stored.
     *
     * It comes from the product configuration.
     * Usually this is equals `Haufe`. But other values like `Lexware` are possible too.
     */
    readonly langid: string;

    /**
     * @see https://docs.econda.de/en/INDE/analytics+integrieren/econda-funktionen/webanalyse/seitenname+contentlabel.html
     */
    readonly content: string;

    /** @see EcondaScript.pageId */
    readonly pageId: string;

    // https://support.econda.de/display/INEN/Markers
    // https://jira.haufe.io/secure/attachment/83242/Haufe_20150702_econda-Integration-Freie_dims_BrandFunktion.pdf
    /**
     * Haufe custom dimension showing (in a nice way) which product (Haufe Steuer Office, Haufe Personal Office, ...) was used.
     */
    readonly pprod: string[][]; // the name `pprod` is on purpose...

    // https://jira.haufe.io/browse/NAUA-2004
    readonly requrl: string[][];

    // https://jira.haufe.io/browse/NAUA-2251
    readonly siteid: string;

    // https://jira.haufe.io/browse/NAUA-6845
    readonly application: string;

    // https://jira.haufe.io/browse/NAUA-7378
    readonly nps_id: string;

    // https://jira.haufe.io/browse/NAUA-7982
    readonly nutzerididesk: string[][]; // the name `nutzerididesk` is on purpose...
}

export interface EcondaPageChangeEventDto extends EcondaCommentEventDto, EcondaSearchRelatedData {
    // https://jira.haufe.io/secure/attachment/93624/93624_econda_hipath.pdf
    /**
     * Haufe custom dimension that showing which documents were viewed considering rootDocId <> subDocId relationship.
     */
    readonly hipath?: string[][];
}

export interface EcondaTargetEventDto extends EcondaCommentEventDto {
    /**
     * @see EcondaTarget
     * @see EcondaTarget#convertForEcondaEvent
     * @see https://docs.econda.de/de/INDE/analytics+integrieren/econda-funktionen/webanalyse/ziele.html
     */
    readonly Target: EcondaTargetArray; // eslint-disable-line @typescript-eslint/naming-convention

    // https://docs.econda.de/de/INDE/analytics+integrieren/grundlagen-funktionsweise/hidden%2Bpi.html
    // needed for target and search events
    readonly rqtype: 'hiddenpi';
}

export class EcondaEvent implements ValueObject {
    private readonly hash: number;

    constructor(
        /** @see EcondaPageChangeEventDto.content */
        public readonly content: string,
        /**
         * Used to construct some econda attributes.
         *
         * @see EcondaPageChangeEventDto.langid
         * @see EcondaPageChangeEventDto.pprod
         */
        private readonly product: Product,
        private readonly npsId: string,
        private readonly userId: string,
        private readonly params?: {
            /** @see EcondaEvent.hipath */
            readonly hipath?: string;
            /** @see EcondaEvent.search */
            readonly search?: EcondaSearchTrackingData;
            /** @see EcondaPageChangeEventDto.target */
            readonly target?: EcondaTargetArray;
        },
    ) {
        this.hash = hash(JSON.stringify({ content, productId: product.id }));
    }

    public forTarget(target: EcondaTargetArray): EcondaEvent {
        return new EcondaEvent(this.content, this.product, this.npsId, this.userId, { ...this.params, target });
    }

    public asDto(): EcondaEventDto {
        const createCommonData = (): EcondaEventDto => {
            const langid = this.product.brand?.label ?? '';
            const pprod: string[][] = [[`(${this.product.id}) ${this.product.name}`]];
            const requrl: string[][] = [[window.location.href]];
            return {
                siteid: ECONDA_SITE_ID,
                application: ECONDA_APPLICATION,
                countryid: 'Online Produkte',
                langid,
                pprod,
                requrl,
                content: this.content,
                pageId: createEcondaPageId(this.content),
                nps_id: this.npsId,
                nutzerididesk: [[this.userId]],
            };
        };

        let dto: EcondaEventDto = createCommonData();
        if (this.params?.target) {
            dto = {
                ...dto,
                // targets shall be sent as so-called "hidden pi" events
                // see https://docs.econda.de/de/INDE/analytics+integrieren/grundlagen-funktionsweise/hidden%2Bpi.html
                rqtype: 'hiddenpi',
                Target: this.params.target,
            };
            logger.debug('[EcondaEvent] asDto', dto);
            return dto;
        }

        const hipath = this.hipath;
        const search = this.search;
        dto = {
            ...dto,
            ...(hipath ? { ...hipath } : {}),
            ...(search ? { ...search } : {}),
        };

        logger.debug('[EcondaEvent] asDto', dto);
        return dto;
    }

    public equals(other: EcondaEvent): boolean {
        return this.hashCode() === other.hashCode() && EcondaEvent.equalsTarget(this, other);
    }

    public hashCode(): number {
        return this.hash;
    }

    private static equalsTarget(one: EcondaEvent, other: EcondaEvent): boolean {
        if (one.params?.target === undefined && other.params?.target === undefined) {
            return true;
        }
        return (
            one.params?.target !== undefined &&
            other.params?.target !== undefined &&
            one.params.target[0] === other.params.target[0] &&
            one.params.target[1] === other.params.target[1] &&
            one.params.target[2] === other.params.target[2] &&
            one.params.target[3] === other.params.target[3]
        );
    }

    /** @see EcondaPageChangeEventDto.hipath */
    private get hipath(): { readonly hipath: string[][] } | undefined {
        const sanitized = this.params?.hipath?.trim();
        if (!sanitized) {
            return undefined;
        }
        return { hipath: [[sanitized]] };
    }

    private get search(): EcondaSearchRelatedData | undefined {
        if (!this.params?.search) {
            return undefined;
        }
        const newSearch = this.params.search.newSearch;
        return {
            ...(newSearch ? { search: [newSearch.query, newSearch.hitCount] } : {}),
            ...(newSearch?.preselectedCluster ? { sfilter: [['Vorauswahl', newSearch.preselectedCluster]] } : {}),
            suche: this.params.search.hits,
        };
    }
}

export type EcondaPath = string;
