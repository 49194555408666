export const HotType = {
    ONLINE_SEMINAR: 'online-seminar',
    VIDEO: 'video',
    E_TRAINING: 'etraining',
    ONLINE_SCHULUNG: 'online-schulung',
    PRODUKT_SCHULUNG: 'produkt-schulung',
    EINSTEIGER_VIDEO: 'einsteigervideo',
    PODCAST: 'podcast',
};

export type HotTypeId = keyof typeof HotType;

export type HotType = (typeof HotType)[HotTypeId];

export const ALL_HOT_TYPES = [
    HotType.ONLINE_SEMINAR,
    HotType.VIDEO,
    HotType.E_TRAINING,
    HotType.ONLINE_SCHULUNG,
    HotType.PRODUKT_SCHULUNG,
    HotType.EINSTEIGER_VIDEO,
    HotType.PODCAST,
];

export const HotTypeLabel: Map<HotType, string> = new Map([
    ['online-seminar', 'Online-Seminar'],
    ['video', 'Video'],
    ['etraining', 'E-Training'],
    ['online-schulung', 'Online-Schulung'],
    ['produkt-schulung', 'Produkt-Schulung'],
    ['einsteigervideo', 'Einsteigervideo'],
    ['podcast', 'Podcast'],
]);
