/**
 * Part of the {@link SVG_ICONS} that should be displayed in white. Those are used as icons for buttons that are generated within the CrsTransformationService.
 * The reason that we cannot use "currentColor" for their stroke is that these icons are linked via css (using content: url()) and not displayed as inline svg.
 */
export const WHITE_SVG_ICONS: string[] = [
    'pdf',
    'excel',
    'word',
    'ppt',
    'exe',
    'mdb',
    'zip',
    'jar',
    'chm',
    'mp3',
    'swf',
    'calc',
    'hfm',
];

export const SVG_ICONS: string[] = [
    'A-',
    'A+',
    'a-z',
    'bank-circle',
    'bibliography',
    'book',
    'book-bank',
    'book-geolocation',
    'book-HR',
    'book-star',
    'book-trend',
    'book-tv',
    'booking-rates',
    'calc',
    'calendar',
    'certificate',
    'charge',
    'check',
    'checked-list',
    'chevron',
    'chm',
    'clock',
    'close',
    'comment',
    'construction',
    'contact',
    'controlling',
    'delete',
    'doc-close',
    'docId',
    'document-gavel',
    'document-microphone',
    'double-comment',
    'double-comment+',
    'duration',
    'edit-folder',
    'euro-flag',
    'excel',
    'exe',
    'factory-a-z',
    'fallout-avatar',
    'filter',
    'folder',
    'fullscreen',
    'fullview',
    'gear-circle',
    'generic',
    'hfm',
    'hide',
    'info-clock',
    'jar',
    'judgement-database',
    'letter',
    'letter-checked',
    'letter-paragraph',
    'letter-percent',
    'letter-star',
    'lexinform',
    'libra',
    'library',
    'lightbulb',
    'lightbulb-checked',
    'lightbulb-euro',
    'lightbulb-percent',
    'lightbulb-star',
    'magazine',
    'magnifier',
    'magnifier-euro',
    'mdb',
    'minimize',
    'mp3',
    'navigation',
    'newsletter',
    'onepage',
    'paragraphs',
    'pdf',
    'ppt',
    'print',
    'refresh',
    'save',
    'screen-libra',
    'security',
    'star',
    'start',
    'submit',
    'swf',
    'tag',
    'thumb_up',
    'thumb_down',
    'thumbnail',
    'tools',
    'training',
    'upload',
    'usermenu',
    'versioning',
    'warning',
    'warning-big',
    'word',
    'zip',
    'companyLogo_haufe',
    'companyLogo_lexware',
    'companyLogo_sp',
];
