@if (logo(); as logo) {
    <img
        loading="eager"
        [alt]="'Firmenlogo von ' + brand().label"
        [height]="logo.height"
        [width]="logo.width"
        [src]="logo.logo | hgImageTransform" />
} @else {
    <svg
        viewBox="0 0 87 10"
        preserveAspectRatio="xMinYMin">
        <g
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd">
            <path
                fill="#003F71"
                d="M83.5268775,7.75 C83.5268775,7.28571429 83.8019763,7.10714286 84.5928854,7.10714286 L85.9339921,7.10714286 C86.7249012,7.10714286 87,7.28571429 87,7.75 L87,8.89285714 C87,9.35714286 86.7249012,9.53571429 85.9339921,9.53571429 L84.5928854,9.53571429 C83.8019763,9.53571429 83.5268775,9.35714286 83.5268775,8.89285714 L83.5268775,7.75 Z" />
            <g fill="#232A36">
                <path
                    d="M40.4395257,9.75 C35.2814229,9.75 33.6996047,8.32142857 33.6996047,6.5 L33.6996047,0 L37.1039526,0 L37.1039526,6.14285714 C37.1039526,7.64285714 38.616996,8.03571429 40.6114625,8.03571429 C41.9869565,8.03571429 43.0185771,8 43.7750988,7.89285714 L43.7750988,0 L47.1794466,0 L47.1794466,9.32142857 C45.9071146,9.53571429 43.3624506,9.75 40.4395257,9.75 Z" />
                <path
                    d="M54.8478261,1.89285714 L63.9948617,1.89285714 L63.9948617,0 L54.1256917,0 C51.4434783,0 50.5494071,0.5 50.5494071,1.92857143 L50.5494071,9.53571429 L53.9537549,9.53571429 L53.9537549,5.53571429 L64.029249,5.53571429 L64.029249,3.85714286 L53.9537549,3.85714286 L53.9537549,2.5 C53.9193676,2.14285714 54.1256917,1.89285714 54.8478261,1.89285714 Z" />
                <path
                    d="M70.9411067,1.89285714 C70.2189723,1.89285714 70.0126482,2.14285714 70.0126482,2.46428571 L70.0126482,3.85714286 L80.0881423,3.85714286 L80.0881423,5.5 L70.0126482,5.5 L70.0126482,7.03571429 C70.0126482,7.39285714 70.2189723,7.60714286 70.9411067,7.60714286 L80.0881423,7.60714286 L80.0881423,9.5 L70.2189723,9.5 C67.5367589,9.5 66.6083004,9 66.6083004,7.57142857 L66.6083004,1.92857143 C66.6083004,0.5 67.5367589,0 70.2189723,0 L80.0881423,0 L80.0881423,1.89285714 L70.9411067,1.89285714 Z" />
                <path
                    d="M23.7272727,3.64285714 C21.5608696,3.64285714 19.6007905,3.85714286 18.2596838,4.10714286 C17.2624506,4.28571429 16.8498024,4.64285714 16.8498024,5.5 L16.8498024,7.89285714 C16.8498024,8.75 17.2624506,9.14285714 18.2596838,9.28571429 C19.6007905,9.53571429 21.5608696,9.71428571 23.7272727,9.75 C26.5814229,9.75 29.0573123,9.53571429 30.2952569,9.32142857 L30.2952569,3.14285714 C30.2952569,0.178571429 29.1948617,0 25.4466403,0 L17.9158103,0 L17.9158103,1.64285714 L25.3778656,1.64285714 C26.787747,1.64285714 26.8909091,2.03571429 26.8909091,2.92857143 L26.8909091,3.67857143 L23.7272727,3.67857143 L23.7272727,3.64285714 Z M23.7272727,8.17857143 C22.6612648,8.17857143 21.6296443,8.10714286 20.9418972,7.96428571 C20.4604743,7.89285714 20.2541502,7.67857143 20.2541502,7.28571429 L20.2541502,6.17857143 C20.2541502,5.75 20.4604743,5.57142857 20.9418972,5.5 C21.6296443,5.35714286 22.6612648,5.28571429 23.7272727,5.28571429 L26.8909091,5.28571429 L26.8909091,8.10714286 C26.2375494,8.14285714 24.9996047,8.17857143 23.7272727,8.17857143 L23.7272727,8.17857143 Z" />
                <polygon
                    points="10.0754941 9.53571429 10.0754941 5.60714286 3.40434783 5.60714286 3.40434783 9.53571429 0 9.53571429 0 0 3.40434783 0 3.40434783 3.89285714 10.0754941 3.89285714 10.0754941 0 13.4454545 0 13.4454545 9.53571429" />
            </g>
        </g>
    </svg>
}
