import { catchError, map, Observable, of, ReplaySubject, take, tap } from 'rxjs';
import { logger } from '@idr/shared/utils';
import { UserSettingsService } from '@idr/ui/shared';
import { Injectable } from '@angular/core';

const DEFAULT_SHOW_HIT_ENVIRONMENT_STATE = true;

@Injectable({
    providedIn: 'root',
})
export class SearchHitPreviewStateService {
    public readonly showHitEnvironment$: Observable<boolean>;
    private readonly _showHitEnvironment$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    private readonly loggerPrefix: string = '[SearchHitPreviewStateService] -';

    constructor(private readonly userSettings: UserSettingsService) {
        this.showHitEnvironment$ = this._showHitEnvironment$.asObservable();
        this.initState();
    }

    public setHitEnvironmentValue(value: boolean): void {
        this._showHitEnvironment$.next(value);
        this.userSettings
            .updateSettings$({
                search: {
                    showSearchHitPreview: value,
                },
            })
            .pipe(
                take(1),
                // No need to catch error because the user settings service already does that.
            )
            .subscribe();
    }

    private initState(): void {
        this.userSettings.settings$
            .pipe(
                take(1),
                map(settings => settings?.search?.showSearchHitPreview),
                map(showHitEnvironment => showHitEnvironment ?? DEFAULT_SHOW_HIT_ENVIRONMENT_STATE),
                catchError(error => {
                    logger.debug(
                        this.loggerPrefix,
                        'failed to get state from API, falling back to default value',
                        error,
                    );
                    return of(DEFAULT_SHOW_HIT_ENVIRONMENT_STATE);
                }),
                tap(showHitEnvironment => this._showHitEnvironment$.next(showHitEnvironment)),
                tap(showHitEnvironment => logger.debug(this.loggerPrefix, 'initState', showHitEnvironment)),
            )
            .subscribe();
    }
}
