import { ProductId, TargetDTO } from '@idr/shared/model';
import { findIcon, NavigationIcon } from './navigation-icon';

/**
 * NavigationItem represents a link in the SideBarComponent.
 *
 * An instance of NavigationItem is immutable - all properties are readonly.
 * When you need to make an item active call item.getActivatedInstance() and you will get
 * a new instance where active==true.
 */
export class NavigationItem {
    readonly routerLink: string;
    /**
     * The "name" of this link. It'll be rendered next to the visual representation of {@link NavigationIcon}.
     */
    readonly label: string;
    /**
     * The description is used for accessibility.
     * It should be a more precise text of what this link will do if the user follows/activates it..
     */
    readonly description: string;
    readonly icon: NavigationIcon;
    readonly active?: boolean;
    readonly debug?: boolean;

    constructor({ routerLink, label, description, icon, active = false, debug = false }: NavigationItem) {
        this.routerLink = routerLink;
        this.label = label;
        this.description = description;
        this.active = active;
        this.icon = icon;
        this.debug = debug;
    }

    static fromDto(productId: ProductId, linkConfig: TargetDTO): NavigationItem | undefined {
        let page: string | undefined;
        switch (linkConfig.targetPath) {
            case 'spalten':
                page = 'column';
                break;
            case 'alphaIndex':
            case 'haufeAlphaIndex':
                page = 'lexicon';
                break;
        }

        if (!page) {
            return undefined;
        }

        const title = linkConfig.properties?.title as string;
        return new NavigationItem({
            routerLink: `/${productId}/${page}/${linkConfig.source}`,
            label: title,
            description: `${title} öffnen`,
            icon: findIcon(title),
        });
    }

    static startPageInstance(productId: ProductId): NavigationItem {
        return new NavigationItem({
            routerLink: `/${productId}`,
            label: 'Startseite',
            description: 'Startseite aufrufen',
            icon: NavigationIcon.START,
        });
    }

    static getDevLinks(productId: ProductId): NavigationItem[] {
        return [
            new NavigationItem({
                routerLink: `/${productId}/docu/styling`,
                label: 'Styling helpers',
                description: 'some insights into our styling (just for dev)',
                icon: NavigationIcon.LIGHTBULB,
                debug: true,
            }),
            new NavigationItem({
                routerLink: `/${productId}/docu/possible-navi-icons`,
                label: 'All possible navigation icons',
                description: 'Shows you all possible navigation icons (for product engineering)',
                icon: NavigationIcon.JUDGEMENT_DATABASE,
                debug: true,
            }),
        ];
    }

    /**
     * Returns a copy of the current Item but with property active set to true
     */
    activate?(): NavigationItem {
        return new NavigationItem({ ...this, active: true });
    }

    trackBy?(): string {
        return `${this.routerLink}_${this.active}`;
    }

    isUrlActive?(url: string): boolean {
        const routerLink: string = this.routerLink;
        if (this.icon === 'start') {
            return routerLink === url;
        }

        let isActive: boolean = url.startsWith(routerLink.toString());
        // Special case: Lexicon.
        // We need to stay active as long as the user stays on the lexicon page.
        // But with selecting a different document or filtering the lexicon the url will change...
        // => the `routerLink === url` check doesn't work for that case
        if (!isActive) {
            const lexiconRegExp = /\/PI\d+\/(lexicon|column)\/(LI\d+)/;
            const lexiconMatch = lexiconRegExp.exec(routerLink.toString());
            if (lexiconMatch) {
                const processedRouterLink: string = lexiconMatch[0].replace(/column/, 'lexicon');
                isActive = url.startsWith(processedRouterLink);
            }
        }

        // Special case: Nested Column
        // In case that we redirect to a nested column,
        // we only need to stay active for the first LI in the new route
        if (!isActive) {
            const nestedColumnRouteRegExp = /\/PI\d+\/column\/LI\d+\/LI\d+/;
            const nestedColumnRouteMatch = nestedColumnRouteRegExp.exec(url);
            if (nestedColumnRouteMatch) {
                const columnRegExp = /\/PI\d+\/column\/(LI\d+)/;
                const columnMatch = columnRegExp.exec(routerLink.toString());
                if (columnMatch) {
                    isActive = url.startsWith(`${columnMatch[0]}`);
                }
            }
        }

        return isActive;
    }
}
