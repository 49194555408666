import { BauplanId } from '../bauplan/bauplan-id';
import { HasLabel } from '../has-label';
import { Link } from '../link';

export interface StartPageConfiguration {
    readonly columns: Column[];
    readonly links: Link[];
}

export interface HasMaxItems {
    /**
     * The maximum number of items that shall be shown in the related list.
     */
    readonly maxItems: number;
}

export interface HasShowMoreLink {
    readonly showMoreLink?: ShowMoreLink;
}

export interface Column {
    readonly framePortlets: FramePortlet[];
}

/**
 * Relates to "frame" in the "mainpage" product configuration.
 * We decided to use our own naming to be more precise and less confusing.
 */
export interface FramePortlet {
    readonly title: string;
    readonly boxSections: BoxSection[];
}

/**
 * A {@link BoxSection} describes the content of a {@link FramePortlet}.
 * Also a {@link FramePortlet} can have multiple {@link BoxSection}s.
 * The naming derives from the HTML5 tag `<section>`.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/section
 */
export interface BoxSection {
    /**
     * Whether or not this section is followed by a separator (just a visual spacer, we use "css margin" for that) or not.
     * Usually this is `false` most of the time since most framePortlets use only one {@link BoxSection} or
     * multiple {@link BoxSection}s but without separators in between.
     */
    readonly hasSeparator?: boolean;

    readonly content: AlphabeticalIndex | BauplanIndex | Links | HotConfig | NewsConfig;
    /** It is allowed to configure a {@link BoxSection} without a {@link Header}.*/
    readonly header?: Header;
}

/**
 * The header is used for 2 use cases:
 *  (1) introducing the {@link Content} of a the first {@link BoxSection} with an image and a description additionally to the
 *      title of the parent {@link FramePortlet}.
 *  (2) multiple {@link BoxSection}s in the same {@link FramePortlet} while prepending a subsequent {@link BoxSection} with a headline
 *      (in this case usually without image and description).
 */
export interface Header {
    readonly title?: string;
    /*
     * The product engineers are allowed to configure HTML into the product configuration and we have to just render it..
     * EVERYTHING IS POSSIBLE, yeah... https://gph.is/2xrPkTM
     */
    readonly text?: string;
    readonly imageUrl?: string;
}

export const ContentType = {
    ALPHABETICAL_INDEX: 'AlphabeticalIndex',
    BAUPLAN_INDEX: 'BauplanIndex',
    LINKS: 'Links',
    HOT: 'OnlineTraining',
    NEWS: 'News',
};

export type ContentType = (typeof ContentType)[keyof typeof ContentType];

export interface IsContent {
    readonly type: ContentType;
}

export const Layout = {
    LIST: 'LIST',
    CAROUSEL: 'CAROUSEL',
    SLIDER: 'SLIDER',
};

export type Layout = (typeof Layout)[keyof typeof Layout];

export interface AlphabeticalIndex extends HasShowMoreLink, IsContent {
    readonly id: BauplanId;
}

export interface BauplanIndex extends IsContent, HasMaxItems, HasShowMoreLink {
    readonly id: BauplanId;
}

export interface Links extends IsContent {
    readonly links: Link[];
    readonly layout: Layout;
}

export interface HotConfig extends IsContent, HasMaxItems {
    readonly service: string;
    readonly layout: string;
    readonly showSpeakerImage: boolean;
    readonly showAdditionalContents: boolean;
    readonly maxAdditionalContents: number;
    readonly categories: string[];
}

export interface NewsConfig extends IsContent, HasMaxItems {
    readonly items: ContentHubContentItem[];
}

export const ContentHubContentType = {
    PORTAL: 'PORTAL',
    RSS: 'RSS',
};

export type ContentHubContentType = (typeof ContentHubContentType)[keyof typeof ContentHubContentType];

export interface ContentHubContentItem {
    readonly label: string;
    readonly source: string;
    readonly type: ContentHubContentType;
    readonly portals: string[];
}

/**
 * This link should lead to an overview of the topic that is introduced in the related {@link BoxSection}
 * while {@link BoxSection.content} precedes this link.
 */
export interface ShowMoreLink extends HasLabel {
    readonly label: string;
    readonly visibility: ShowMoreLinkVisibility;
}

export const ShowMoreLinkVisibility = {
    ALWAYS: 'ALWAYS',
    IF_NEEDED: 'IF_NEEDED',
    NEVER: 'NEVER',
};

export type ShowMoreLinkVisibility = (typeof ShowMoreLinkVisibility)[keyof typeof ShowMoreLinkVisibility];
