import { CrsDocumentProviderIdDTO } from '../crs';

export const ProviderDescription = {
    // should be actually OSV but we get 'OVS' from backend, so I kept that naming...
    ESV: 'Erich Schmidt Verlag',
    OVS: 'Verlag Dr. Otto Schmidt KG',
    SP: 'Schäffer-Poeschel Verlag',
    STF: 'Stollfuß Medien GmbH & Co. KG',
    HAUFE: 'Haufe-Lexware GmbH & Co. KG',
};

export type ProviderDescriptionKey = keyof typeof ProviderDescription;

export type ProviderDescription = (typeof ProviderDescription)[ProviderDescriptionKey];

export class DocumentProvider {
    private constructor(
        public readonly id: CrsDocumentProviderIdDTO,
        public readonly description: ProviderDescription,
        public readonly iconAlt: string,
        public readonly iconTitle: string,
        public readonly iconSource: string,
    ) {}

    public static fromJson(id: CrsDocumentProviderIdDTO | undefined): DocumentProvider | undefined {
        if (!id) {
            return undefined;
        }

        // ... somehow there may be data that doesn't contain 'OVS' as `providerId` but 'Otto Schmidt' instead -_-
        if (id === 'Otto Schmidt') {
            id = 'OVS';
        }

        // this is to not show 'undefined' as title when no `providerTitle` is found & add a not found image as logo
        const description = ProviderDescription[id.toUpperCase() as ProviderDescriptionKey];
        if (!description) {
            return undefined;
        }

        const iconAlt = `Favicon von "${description}"`;
        const iconTitle = `"${description}" ist der Anbieter dieses Inhalts.`;
        const iconSource = `/assets/brands/${id.toLowerCase()}_favicon.svg`;

        return new DocumentProvider(id.toUpperCase(), description, iconAlt, iconTitle, iconSource);
    }
}
