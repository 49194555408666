import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import { logger } from '@idr/shared/utils';
import { removeEmptyParams } from '../../utils';

/**
 * Wrapper for `window.location`.
 *
 * Also, it provides a useful utility: a map of parsed (key, value) pairs of the hash in the URL (#hashValues).
 */
@Injectable({ providedIn: 'root' })
export class LocationService {
    readonly #logPrefix = '[LocationService]';

    #redirectPending = false;

    constructor(
        private readonly location: Location,
        private readonly router: Router,
    ) {}

    /**
     * `true` once #redirect(string) was called at least once
     */
    get redirectPending(): boolean {
        return this.#redirectPending;
    }

    /**
     * @see Location.pathname
     */
    get path(): string {
        return window.location.pathname;
    }

    get href(): string {
        return window.location.href;
    }

    /**
     * @see Location.origin
     */
    get origin(): string {
        return window.location.origin;
    }

    /**
     * @see Location.search
     */
    get query(): string {
        return window.location.search;
    }

    /**
     * @see URLSearchParams
     */
    get searchParams(): URLSearchParams {
        return new URLSearchParams(this.query);
    }

    /**
     * Redirects to given url. Once called #redirectPending will equal `true`.
     */
    redirect(url: string): void {
        logger.debug(`[LocationService] #redirect(); to ${url}`);
        this.#redirectPending = true;
        window.location.replace(url);
    }

    /**
     * Will update the query parameters without changing the rest of the url.
     *
     * If a param qparam=undefined or qparam='' is passed the parameter is removed from the url
     *
     * eg { documentId: 'myid', someOtherParam: 'value' } will add
     * ...?documentId=myid&someOtherParam=value to the url
     *
     * @param queryParams query parameters.
     * @param noBrowserHistory then url change will not be tracked in the browser history
     */
    async updateQueryParameters(
        queryParams: Params,
        noBrowserHistory?: {
            /**
             * (1) If `true` the history update is done via Angular's router.
             *      That will make sure you trigger also configured resolvers.
             *
             * (2) If `false` the history update instead is done without Angular's router.
             *      That way you still replace the browser history but DO NOT trigger any configured resolvers.
             */
            readonly triggerResolvers: boolean;
        },
    ): Promise<boolean> {
        const urlTree: UrlTree = this.router.createUrlTree([], {
            queryParams,
            queryParamsHandling: 'merge',
        });
        urlTree.queryParams = removeEmptyParams(urlTree.queryParams);

        logger.debug(this.#logPrefix, `updateQueryParameters -> current route`, this.router.routerState.snapshot.url);
        logger.debug(
            this.#logPrefix,
            `updateQueryParameters -> merging current route with`,
            urlTree.queryParams,
            noBrowserHistory,
        );

        if (noBrowserHistory?.triggerResolvers === false) {
            this.location.replaceState(urlTree.toString());
            return true;
        }

        if (noBrowserHistory?.triggerResolvers) {
            return this.router.navigateByUrl(urlTree, { replaceUrl: true });
        }

        return this.router.navigateByUrl(urlTree);
    }
}
