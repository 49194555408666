/**
 * Exception types for the CRS API.
 * This list only contains the errors that we handle with some logic in the frontend.
 */
export const CrsExceptionTypes = {
    /**
     * This exception is thrown when the user tries to quick-jump to a document and that action fails. What is a quick-jump -> (e.g. when somebody searches for "estg 3" -> the app jumps to the document "ESTG § 3")
     */
    QuickjumpFailed: 'QuickjumpFailed',
    /**
     * The OSRParser exception is thrown when opti-search (the search engine behind CRS) fails to parse the search query. (e.g. if you use special characters like + in the query)
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    OSRParser: 'OSRParser',
};

export type CrsExceptionType = (typeof CrsExceptionTypes)[keyof typeof CrsExceptionTypes];
