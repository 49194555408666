import { ActivatedRouteSnapshot } from '@angular/router';
import { CrsSortBy } from '@idr/shared/model';

export class SearchParams {
    readonly query: string;
    readonly page: number;
    /**
     * originalQuery is only set when there is a correction to the query that the user entered.
     * In that case the query is updated with the corrected value and originalQuery holds the original input from user.
     */
    readonly originalQuery?: string;
    readonly hitPath?: string;
    readonly sort?: CrsSortBy;
    readonly sortDate?: string;
    readonly newSearch?: boolean;
    readonly documentType?: string;
    readonly searchCategory: string | undefined;

    constructor({
        query,
        page,
        originalQuery,
        hitPath,
        sort,
        newSearch,
        documentType,
        searchCategory,
    }: Omit<SearchParams, 'setCorrectedQuery'>) {
        this.query = query;
        this.page = isNaN(page) ? 1 : page;
        this.originalQuery = originalQuery;
        this.hitPath = hitPath ? decodeURI(hitPath) : undefined;
        this.sort = sort;
        this.newSearch = !!newSearch;
        this.documentType = documentType;
        this.searchCategory = searchCategory;
    }

    static fromRouteSnapshot(route: ActivatedRouteSnapshot): SearchParams {
        return new SearchParams({
            query: route.params['query'],
            page: Number.parseInt(route.params['page'], 10),
            hitPath: route.params['path'],
            sort: route.params['sort'],
            newSearch: route.queryParamMap.has('new-search'),
            documentType: route?.params?.['documentType'],
            searchCategory: route.queryParamMap.get('search-category') ?? undefined,
        });
    }

    setCorrectedQuery(correctedQuery: string): SearchParams {
        return new SearchParams({ ...this, originalQuery: this.query, query: correctedQuery });
    }
}
