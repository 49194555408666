import { ContentHubDocumentDTO, EntryDTO, MetaDTO, NewsMetaDTO, OdinDocumentClassification } from '@idr/shared/model';
import { arrayFromVar } from '@idr/shared/utils';
import { Realm, urlWithRealmQueryParam } from '@idr/ui/shared';

interface FallBackProps {
    readonly preview?: string;
    readonly baselineContent?: string;
}

export abstract class ContentHubDocument {
    readonly contentHubId: string;
    readonly title: string;

    readonly authors?: string[];
    readonly baselineContent?: string;
    readonly chronologicalSortDate?: Date;
    readonly preview?: string;
    readonly shortTitle?: string;

    protected constructor(
        documentDTO: ContentHubDocumentDTO<MetaDTO>,
        entryDTO: EntryDTO<ContentHubDocumentDTO<MetaDTO>> | undefined,
        readonly classification: OdinDocumentClassification,
        realm: Realm,
        fallBackProps: FallBackProps = {},
    ) {
        const meta: NewsMetaDTO = documentDTO['ch:haufe-document']['ch:meta'];

        // If we are in a search context, and we have chs:snippet, we have to use it to highlight the title and the preview
        // Otherwise we fall back to the normal title and preview
        // Note: We have to use arrayFromVar here, because contenthub will return an array ONLY if we have more than one entry, otherwise it will return just the entry
        const highlightedTitle = arrayFromVar(entryDTO?.['chs:snippet'])?.find(
            el => el?.['chs:location'] === 'title',
        )?.content;
        const highlightedPreview = arrayFromVar(entryDTO?.['chs:snippet'])?.find(
            el => el?.['chs:location'] === 'baselineContent',
        )?.content;

        const chTitle: string = Array.isArray(meta['ch:title'])
            ? (meta['ch:title'].find(value => value.name === 'default')?.content as string)
            : (meta['ch:title']?.content as string);

        const chShortTitle = Array.isArray(meta['ch:title'])
            ? meta['ch:title'].find(value => value.name === 'short_title')?.content
            : undefined;

        this.title = highlightedTitle ?? chTitle;

        this.shortTitle = chShortTitle;

        this.contentHubId = meta['ch:contentHubId'];

        this.preview =
            highlightedPreview ?? documentDTO?.['ch:haufe-document']?.['chb:preview']?.content ?? fallBackProps.preview;

        this.authors = arrayFromVar(meta['ch:creator']);

        this.chronologicalSortDate = meta['ch:chronologicalSortDate']
            ? new Date(meta['ch:chronologicalSortDate'])
            : undefined;

        const initialBaselineContent =
            documentDTO['ch:haufe-document']?.['chb:baselineContent']?.content ?? fallBackProps?.baselineContent;

        const baselineContentWithRealm = this.#addRealmToExternalUrls(initialBaselineContent, realm);
        this.baselineContent = this.#updateLinksToHaveTargetBlank(baselineContentWithRealm);
    }

    /**
     * Adds target="_blank" (or replaces target="") to all links in the content that are not absolute urls or ContentHub urls.
     */
    #updateLinksToHaveTargetBlank(baselineContent: string | undefined): string | undefined {
        if (!baselineContent) {
            return undefined;
        }

        const targetBlankReplacer = (match: string, href: string) => {
            if (!href.startsWith('/') && !href.startsWith('contenthub://')) {
                const regExpTarget = /(.*?target=")[^"]*(".*)/;
                if (regExpTarget.test(match)) {
                    return match.replace(regExpTarget, (_, before, after) => `${before}_blank${after}`);
                }
                return `${match.substring(0, match.length - 1)} target="_blank">`;
            }
            return match;
        };

        const regExpATag = /<a[^>]*\s+href="([^"]*)"[^>]*>/gm;
        return baselineContent.replace(regExpATag, targetBlankReplacer);
    }

    /**
     * This method will set the auth realm parameter for external links to other Haufe applications
     * This is required so that the other application knows which realm the user is logged in to.
     */
    #addRealmToExternalUrls(baselineContent: string | undefined, realm: Realm): string | undefined {
        if (!baselineContent) {
            return undefined;
        }
        return baselineContent.replace(
            /href="([^"]*)"/gm,
            (_, href) => `href="${urlWithRealmQueryParam(href, realm)}"`,
        );
    }
}
