import { EnvironmentInjector, Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import {
    CrsDocumentDTO,
    CrsExceptionDTO,
    CrsOutlineDTO,
    CrsOutlinesWrapperDTO,
    DocumentId,
    FootnoteId,
    OdinDateString,
    OdinDocumentState,
} from '@idr/shared/model';
import { logger, TtlCache } from '@idr/shared/utils';
import { DocumentService, IDeskDocument, RoutedDocument, Toc, TocEntry } from '@idr/ui/document';
import { ActiveProduct } from '@idr/ui/shared';
import { Map as ImmutableMap } from 'immutable';
import { forkJoin, MonoTypeOperatorFunction, Observable, of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CrsDocumentService } from '../services/crs/crs-document.service';
import { createDocumentMeta } from '../utils/create-document-meta';
import { createIdeskDocumentFromMultipartDto } from '../utils/create-idesk-document-from-multipart-dto';
import { createToc } from '../utils/create-toc';
import { convertCrsDateToYYYYMMDD } from '../utils/crs-date-conversion';
import { CrsDocumentSearchService } from './crs/crs-document-search.service';

const ONE_HOUR = 1000 * 60 * 60;

type DocIdIndex = ImmutableMap<DocumentId, number>;

interface TocCacheValue {
    readonly toc: Toc;
    readonly docIdIndex: ImmutableMap<DocumentId, number>;
    /**
     * Used for flat documents.
     * Before facade refactoring this was done with the help of table-of-contents.
     * Now, I added this workaround to be able to assign the right titles for split chapters.
     * It's needed because a flat document is returned as one big chunk (one document).
     * That in return is split on client side.
     * But, during that split we don't assign the correct title for the respective chapter(s).
     * From backend side we only have the root title available.
     * We could try to parse the title from the content but, that is silly & also I don't know whether titles always
     * follow the same pattern. And then there is potential search highlighting present as well...
     *
     * So, it's easier to abuse the table-of-contents again (just like before).
     * Only, I don't change table-of-contents logic back. Instead, I build this tiny little map & use it for setting
     * the correctly title after split independent of {@link CrsDocumentService}.
     *
     * In the end this whole thing here is throw away code anyway, so it can be a bit dirty.
     */
    readonly docTitles: ImmutableMap<DocumentId, string>;
}

const validSearchButNotInRouted = (routed: RoutedDocument, allowEmptySearch: boolean): boolean =>
    !allowEmptySearch &&
    (routed.search?.resultsFound ?? false) &&
    !(routed.search?.docIds.includes(routed.document.id) ?? false);

@Injectable({ providedIn: 'root' })
export class LegacyDocumentService implements DocumentService {
    readonly #tocCache: TtlCache<
        DocumentId, // we use the root id of related document for this cache
        TocCacheValue
    > = new TtlCache({ maxSize: 100, ttl: ONE_HOUR });

    readonly #logPrefix = '[LegacyDocumentService]';

    /**
     * A map that contains the index of chapter in relation to its document id.
     *
     *  0 = first chapter
     *  1 = second chapter
     *  ...
     * @private
     */
    #docIdIndex: DocIdIndex = ImmutableMap();

    constructor(
        private readonly injector: EnvironmentInjector,
        private readonly activeProduct: ActiveProduct,
        private readonly crsDocumentService: CrsDocumentService,
        private readonly crsDocumentSearchService: CrsDocumentSearchService,
    ) {}

    getDocuments$(ids: DocumentId[], query?: string): Observable<IDeskDocument[]> {
        return this.crsDocumentService.getDocuments$(ids, query).pipe(
            map(dtos => {
                const convertedAsList: IDeskDocument[] = [];
                dtos.forEach(dto => {
                    const converted: IDeskDocument | undefined = this.#convertCrsDocumentDtoToIdeskDocument(dto, {
                        query,
                        title: this.#getTitleFor(dto),
                    });
                    if (!converted) {
                        return;
                    }
                    convertedAsList.push(converted);
                });
                return convertedAsList;
            }),
            tap(documents => logger.debug(this.#logPrefix, 'getDocuments$ -> SUCCESS', documents)),
        );
    }

    getFootnote$(footnoteId: FootnoteId): Observable<SafeHtml | undefined> {
        const footnoteRegExp = /FNR_([^#]+)_(\d+)$/;
        const parsedFootnote: RegExpExecArray | null = footnoteRegExp.exec(footnoteId);
        if (!parsedFootnote) {
            logger.warn(this.#logPrefix, 'getFootnote$ -> invalid footnote id', footnoteId);
            return of(undefined);
        }
        const documentId: DocumentId | undefined = parsedFootnote[1];
        const index: number = Number.parseInt(parsedFootnote[2], 10);
        return this.crsDocumentService.getFootnote$(documentId, index);
    }

    // This method is not fully tested... Testing all the edge cases would create a chaos of a setup.
    // We have some e2e already for it, so I will not invest time in it. (throw-away code...)
    // Ideally it would need to be simplified by extracting the logic into smaller functions that can be independently tested.
    getRoutedDocument$(
        documentId: DocumentId,
        query?: string,
        allowEmptySearch = false,
    ): Observable<RoutedDocument | undefined> {
        logger.debug(this.#logPrefix, 'getRoutedDocument$ ->', documentId, query, allowEmptySearch);

        return this.#getDocument$(documentId, query).pipe(
            switchMap(dto => {
                logger.debug(this.#logPrefix, `getRoutedDocument$ got`, dto);
                if (dto === undefined) {
                    return of(undefined);
                }
                return this.#convertMultipartToRoutedDocument$(dto, query, allowEmptySearch);
            }),
            tap(data => logger.debug(this.#logPrefix, 'getRoutedDocument$ ->', data ? 'SUCCESS' : 'FAILED', data)),
            catchError(error => {
                logger.error(this.#logPrefix, `getRoutedDocument$ -> FAILED`, { documentId, query }, error);
                return of(undefined);
            }),
        );
    }

    #getState$(id: DocumentId): Observable<string | undefined> {
        logger.debug(this.#logPrefix, 'getState$ ->', id);
        return this.crsDocumentService.getDocument$(id).pipe(
            map(dto => dto.text),
            tap(state => logger.debug(this.#logPrefix, 'getState$ -> SUCCESS', id, state)),
        );
    }

    #convertMultipartToRoutedDocument$(
        dto: CrsDocumentDTO,
        query: string | undefined,
        allowEmptySearch: boolean | undefined,
    ): Observable<RoutedDocument> {
        const rootIsRouted = dto.docid === dto.rootid;
        const documentDto$ = !rootIsRouted ? of(dto) : this.#getDocument$(dto.docid, query);
        const rootDocDto$ = rootIsRouted ? of(dto) : this.#getDocument$(dto.rootid, query);
        const documentStateDocId = dto.docstate?.link?.resource?.docid;
        const documentStateContent$ = documentStateDocId ? this.#getState$(documentStateDocId) : of(undefined);

        logger.debug(this.#logPrefix, '#convertMultipartToRoutedDocument$ ->', dto, { rootIsRouted });
        const searchResults$ = query
            ? this.crsDocumentSearchService.getDocumentSearchResults$(dto, query)
            : of(undefined);

        return forkJoin([this.#getToc$(dto), documentDto$, rootDocDto$, searchResults$, documentStateContent$]).pipe(
            map(([toc, documentDto, rootDto, search, stateContent]) => {
                if (!this.activeProduct.id) {
                    logger.error(this.#logPrefix, '#convertMultipartToRoutedDocument$ -> active product id is missing');
                    throw new Error('active product id is missing');
                }

                // careful: `docIdIndex` is only filled as expected AFTER `getToc$` run successfully
                // AP: This mutation logic for the docIdIndex is so bad... We rely on the series of calls to be in the correct order...
                // But since this is legacy - throw-away code I will refrain from refactoring it.
                const docIdIndex: number | undefined = this.#docIdIndex.get(dto.docid);
                const rootDocument = createIdeskDocumentFromMultipartDto(this.injector, rootDto, 0, { query });
                const document = rootIsRouted
                    ? rootDocument
                    : createIdeskDocumentFromMultipartDto(this.injector, documentDto, docIdIndex, {
                          query,
                          title: this.#getTitleFor(documentDto),
                      });

                // The new API has a date format of 'yyyy-MM-dd' for the document state.
                // We need to convert it to that format because we have a new Date() constructor in the DocumentStateWidget that cannot parse the old format.
                const stateDate: OdinDateString | undefined = convertCrsDateToYYYYMMDD(dto.docstate?.date);
                const hasState: boolean = !!stateDate || !!stateContent;
                const documentState: OdinDocumentState | undefined = hasState
                    ? {
                          date: stateDate,
                          content: stateContent,
                      }
                    : undefined;

                return {
                    document,
                    meta: {
                        // root is needed for book related meta information
                        ...createDocumentMeta(
                            this.activeProduct.id,
                            rootDto,
                            rootDocument,
                            toc,
                            document.id,
                            documentState,
                        ),
                        // the title shouldn't be picked from the root ... this will lead to unexpected UI state
                        title: documentDto.title,
                    },
                    ...(search ? { search } : {}),
                } as RoutedDocument;
            }),
            this.#considerSearchResultInDifferentChapter(query, allowEmptySearch),
            tap(result => logger.debug(this.#logPrefix, '#convertMultipartToRoutedDocument$ -> SUCCESS', result)),
        );
    }

    #considerSearchResultInDifferentChapter(
        query?: string,
        allowEmptySearch = false,
    ): MonoTypeOperatorFunction<RoutedDocument> {
        return source$ =>
            source$.pipe(
                switchMap(routed => {
                    // we need to cover an edge case here
                    // => when user searched in current routed document, it can happen that there is no result in the chapter
                    //    when this happens we need to jump to the first chapter that contains hits
                    if (validSearchButNotInRouted(routed, allowEmptySearch)) {
                        const chapterIdWithHits: string | undefined = routed?.search?.firstDocId;
                        if (!chapterIdWithHits) {
                            logger.warn(
                                this.#logPrefix,
                                '#considerSearchResultInDifferentChapter -> search triggered in a chapter without hits; no chapter with hits found',
                            );
                            return of(routed);
                        }

                        if (!this.activeProduct.id) {
                            logger.error(
                                this.#logPrefix,
                                '#considerSearchResultInDifferentChapter -> active product id is missing',
                            );
                            throw new Error('active product id is missing');
                        }

                        const docIdIndex: number | undefined = this.#docIdIndex.get(chapterIdWithHits);
                        if (docIdIndex === undefined) {
                            logger.error(
                                this.#logPrefix,
                                '#considerSearchResultInDifferentChapter -> internal index is missing. Was `getToc$` called for requested document?',
                                chapterIdWithHits,
                            );
                            throw new Error('internal index is missing');
                        }

                        logger.debug(
                            this.#logPrefix,
                            '#considerSearchResultInDifferentChapter -> search triggered in a chapter without hits; will jump to first chapter with hits:',
                            chapterIdWithHits,
                        );
                        return this.crsDocumentService.getDocument$(chapterIdWithHits, query).pipe(
                            map(
                                chapterDto =>
                                    ({
                                        ...routed,
                                        document: createIdeskDocumentFromMultipartDto(
                                            this.injector,
                                            chapterDto,
                                            docIdIndex,
                                            { query, title: this.#getTitleFor(chapterDto) },
                                        ),
                                        meta: createDocumentMeta(
                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                            this.activeProduct.id!,
                                            chapterDto,
                                            routed.meta.root,
                                            routed.meta.toc,
                                            chapterDto.docid,
                                            routed.meta.state,
                                        ),
                                    }) as RoutedDocument,
                            ),
                        );
                    }

                    logger.debug(
                        this.#logPrefix,
                        '#considerSearchResultInDifferentChapter -> everything is fine. Router chapter contains hits.',
                    );
                    return of(routed);
                }),
            );
    }

    #getTitleFor(dto: CrsDocumentDTO): string | undefined {
        return this.#tocCache.get(dto.rootid)?.docTitles?.get(dto.docid);
    }

    #getDocument$(id: DocumentId, query?: string): Observable<CrsDocumentDTO> {
        return this.crsDocumentService.getDocument$(id, query).pipe(
            // it can happen, that for given query we get an exception (=no document in return)
            // => let's try again without query if that happens
            switchMap(dto => {
                if (query && dto === undefined) {
                    logger.warn(this.#logPrefix, `#getDocument$ failed with given query. Trying again without.`);
                    return this.crsDocumentService.getDocument$(id);
                }
                return of(dto);
            }),
            tap(doc => logger.debug(this.#logPrefix, '#getDocument$ ->', doc ? 'SUCCESS' : 'FAILED', doc)),
        );
    }

    #getToc$(dto: CrsDocumentDTO): Observable<Toc> {
        const rootDocId: DocumentId = dto.rootid;
        const cached = this.#tocCache.get(rootDocId);
        if (cached) {
            logger.debug(this.#logPrefix, '#getToc$ -> already cached', cached);
            this.#docIdIndex = cached.docIdIndex;
            return of(cached.toc);
        }

        logger.debug(this.#logPrefix, '#getToc$ -> uncached; fill request from backend', rootDocId);
        return this.crsDocumentService.getToc$(rootDocId, true).pipe(
            map(data =>
                (data as CrsExceptionDTO).exception ? undefined : (data as CrsOutlinesWrapperDTO).product[0].outline[0],
            ),
            switchMap((outline: CrsOutlineDTO | undefined) => {
                if (!outline) {
                    logger.warn(this.#logPrefix, '#getToc$ -> no outline found for', rootDocId);
                    throw new Error(`no outline found for ${rootDocId}`);
                }

                // Here we need to cover an edge case. There are flat documents that don't have a toc.
                // However, the way that document rendering works always requires a toc so that the chapters (other than the root)
                // can be created as dummy chapters and lazy loaded. Therefore, we need to create a "hidden" toc for the flat documents
                // that are not supposed to display a toc.
                if (!dto.isMultipart && outline.entry.length === 0) {
                    logger.debug(
                        this.#logPrefix,
                        '#getToc$ -> flat document without toc; creating hidden toc for',
                        rootDocId,
                    );

                    return this.crsDocumentService.getTocForFlatDocumentWithoutToc$(rootDocId);
                }

                return of(createToc(outline));
            }),
            tap(toc => {
                this.#setDocIdIndexFromToc(toc, rootDocId);
                const tocCacheValue: TocCacheValue = {
                    toc,
                    docIdIndex: this.#docIdIndex,
                    // we only need this for flat documents...
                    // fallback when accessing the title will always be the original chapter title
                    docTitles: dto.isMultipart
                        ? ImmutableMap()
                        : ImmutableMap(toc.entries.toArray().map(tocEntry => [tocEntry.documentId, tocEntry.title])),
                };
                this.#tocCache.set(rootDocId, tocCacheValue);
                logger.debug(this.#logPrefix, '#getToc$ -> SUCCESS', rootDocId, {
                    toc,
                    docIdIndex: this.#docIdIndex.toJS(),
                    docTitles: tocCacheValue.docTitles.toJS(),
                });
            }),
        );
    }

    #setDocIdIndexFromToc(toc: Toc, rootDocId: DocumentId): void {
        // We have to manually set the index for the root document as it's not included in the toc
        let docIdIndex: DocIdIndex = ImmutableMap([[rootDocId, 0]]);

        let index = 1;

        const fillIndexForEntry = (_docIdIndex: DocIdIndex, entry: TocEntry): DocIdIndex => {
            _docIdIndex = _docIdIndex.set(entry.documentId, index++);
            if (!entry.hasChildren) {
                return _docIdIndex;
            }

            entry.children.forEach(child => (_docIdIndex = fillIndexForEntry(_docIdIndex, child)));
            return _docIdIndex;
        };

        toc.entries.forEach(entry => (docIdIndex = fillIndexForEntry(docIdIndex, entry)));
        this.#docIdIndex = docIdIndex;
        logger.debug(
            this.#logPrefix,
            '#setDocIdIndexFromToc -> filled index for',
            rootDocId,
            'index:',
            this.#docIdIndex.toJS(),
        );
    }

    #convertCrsDocumentDtoToIdeskDocument(
        dto: CrsDocumentDTO,
        params?: {
            readonly query?: string;
            readonly title?: string;
        },
    ): IDeskDocument | undefined {
        if (!dto?.docid || !dto?.rootid) {
            logger.warn(this.#logPrefix, '#convertCrsDocumentDtoToIdeskDocument.convertDto -> invalid dto', dto);
            return undefined;
        }

        if (this.#docIdIndex?.size === 0) {
            logger.warn(
                this.#logPrefix,
                "#convertCrsDocumentDtoToIdeskDocument.convertDto -> internal index isn't filled. Was `getToc$` called for requested document?",
                dto.docid,
            );
            return undefined;
        }

        const index: number | undefined = this.#docIdIndex.get(dto.docid);
        const indexIsSet: boolean = index ? index >= 0 : false;
        // For flat documents that are converted to multipart we have a case where we are requesting for the root document
        // That happens if you are scrolled to a chapter at the bottom and then try to go to the top (root doc).
        // In that case we are requesting the root doc. However, that doc is not part of the docIndex and we should not return undefined in that case.
        // Returning undefined would make the root document chapter stick to the loading state.
        const isRootDoc = dto.docid === dto.rootid;
        if ((!indexIsSet || index === undefined) && !isRootDoc) {
            logger.warn(
                this.#logPrefix,
                "#convertCrsDocumentDtoToIdeskDocument.convertDto -> couldn't find related index for",
                dto.docid,
                dto.title,
                index,
            );
            return undefined;
        }

        return createIdeskDocumentFromMultipartDto(this.injector, dto, index, params);
    }
}
