import { Directive, HostListener, Renderer2 } from '@angular/core';
import { DomService } from '@idr/ui/shared';

const TAB_KEY_USED_CSS_CLASS = 'tabKey-used';

@Directive({
    selector: '[idrTabKey]',
    standalone: true,
})
export class TabKeyDirective {
    constructor(
        private readonly renderer: Renderer2,
        private readonly domService: DomService,
    ) {}

    @HostListener('document:keydown.tab')
    public onTabbing(): void {
        this.renderer.addClass(this.domService.document.body, TAB_KEY_USED_CSS_CLASS);
    }

    @HostListener('document:click')
    public onClickSomewhere(): void {
        this.renderer.removeClass(this.domService.document.body, TAB_KEY_USED_CSS_CLASS);
    }
}
