<section>
    <button
        #deleteButton
        data-cy="delete"
        class="warning"
        (click)="deleteButton.blur(); delete(deleteDialogContent)">
        Entwurf löschen
    </button>
    <button
        #resetButton
        data-cy="reset"
        class="secondary"
        [disabled]="!active().changed"
        (click)="resetButton.blur(); reset(resetDialogContent)">
        Zurücksetzen
    </button>
</section>
<section>
    <mat-select
        #productSelect
        matTooltip="Produkt wechseln"
        matTooltipPosition="above"
        data-cy="product-select"
        [value]="products()[0].id">
        @for (product of products(); track product.id) {
            <mat-option
                class="productGroups"
                [value]="product.id">
                {{ product.name }}
            </mat-option>
        }
    </mat-select>
    <button
        data-cy="preview"
        class="primary"
        (click)="navigator.goToProductDraft(productSelect.value)">
        Vorschau
    </button>
    <button
        #publishButton
        data-cy="publish"
        class="secondary"
        (click)="publishButton.blur(); publish(publishDialogContent)">
        Live schalten
    </button>
</section>

<ng-template #deleteDialogContent>
    <div>Diesen Entwurf wirklich löschen?</div>
</ng-template>

<ng-template #publishDialogContent>
    <div>
        <p>Wirklich diese Änderungen Live schalten?</p>
        <p>Folgende Produkte sind betroffen:</p>
        <ul>
            @for (product of active().products; track product.id) {
                <li>{{ product.name }}</li>
            }
        </ul>
    </div>
</ng-template>

<ng-template #resetDialogContent>
    <div>
        <p>Möchtest du deine letzten Änderungen löschen?</p>
        <p>
            Die Konfiguration wird auf den Stand gesetzt auf dem sie war als du sie zuletzt geöffnet hast. Dies betrifft
            auch Änderungen die von anderen Benutzern in der Zwischenzeit gemacht wurden.
        </p>
    </div>
</ng-template>
