<idr-side-bar
    #navigation
    role="navigation"
    (toggled)="onSideBarToggle($event)" />
<header
    role="region"
    aria-label="Header der Anwendung. Hier können sie suchen und das Benutzermenu öffnen.">
    <idr-header [showProductLogo]="pinSideBar || (!pinSideBar && extendMain)" />
    <idr-sitemap />
</header>
<main>
    <router-outlet />
</main>
<div></div>
<!-- this is here for the main-border grid cell (just styling) -->
