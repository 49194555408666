<button
    *hgHideForAnonymous
    data-cy="open-bookmarks"
    [hgGtmId]="['headerMenu', 'open', 'bookmarks']"
    matTooltip="Favoriten & Notizen"
    matTooltipPosition="below"
    (click)="showFoldersAndNotes()">
    <mat-icon svgIcon="star" />
</button>
<button
    matTooltip="Zuletzt geöffnet und gesucht"
    matTooltipPosition="below"
    data-cy="open-history"
    [hgGtmId]="['headerMenu', 'open', 'history']"
    hgPopup
    #historyPopupDirective="hgPopup"
    [template]="historyTemplate"
    [width]="PopupWidthClass.None"
    [showCloseButton]="true">
    <mat-icon svgIcon="clock" />
</button>
<button
    id="info-menu-button"
    matTooltip="Service-Menü"
    matTooltipPosition="below"
    data-cy="open-info-menu"
    [hgGtmId]="['headerMenu', 'open', 'serviceMenu']"
    hgPopup
    #infoMenuPopupDirective="hgPopup"
    [template]="infoMenuTemplate"
    [width]="PopupWidthClass.Narrow"
    [showCloseButton]="true">
    <mat-icon svgIcon="usermenu" />
</button>

<ng-template #historyTemplate>
    <idr-history [popup]="historyPopupDirective" />
</ng-template>

<ng-template #infoMenuTemplate>
    <idr-info-menu-loader [popup]="infoMenuPopupDirective" />
</ng-template>
