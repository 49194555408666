import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { isHaufeDomainUrl } from '@idr/ui/consts';

import { getGtmId } from './models/index';

@Pipe({
    name: 'hgWithGtmIdExternalHaufeLinkInHtml',
    standalone: true,
})
export class WithGtmIdExternalHaufeLinkInHtmlPipe implements PipeTransform {
    transform(value: SafeHtml, portletTitle: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(value.toString(), 'text/html');
        const anchorTags = doc.getElementsByTagName('a');

        for (const anchorTag of anchorTags) {
            const href = anchorTag.getAttribute('href');

            if (!href || anchorTag.hasAttribute('data-id')) {
                continue;
            }
            const url = new URL(href);
            if (isHaufeDomainUrl(url)) {
                anchorTag.setAttribute('data-id', getGtmId('startPage', 'open', 'externalHaufeLink'));
                anchorTag.setAttribute('data-startpageSection_title', portletTitle);
                anchorTag.setAttribute('data-startpagelink_title', portletTitle);
            }
        }
        return doc.body.innerHTML;
    }
}
