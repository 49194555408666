import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Injector,
    Input,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { RxIf } from '@rx-angular/template/if';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadingWheelComponent, PopupDirective } from '@idr/ui/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RxIf, LoadingWheelComponent],
    selector: 'idr-info-menu-loader',
    standalone: true,
    template: '<hg-loading-wheel *rxIf="loading$"/><ng-container #menu/>',
})
export class InfoMenuLoaderComponent implements AfterViewInit {
    @Input() public popup: PopupDirective;

    @ViewChild('menu', { read: ViewContainerRef }) public readonly menuContainer: ViewContainerRef;

    public readonly loading$: Observable<boolean>;

    private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(private readonly injector: Injector) {
        this.loading$ = this._loading$.asObservable();
    }

    public ngAfterViewInit(): void {
        this.loadInfoMenu().then(() => {
            this._loading$.next(false);
            this._loading$.complete();
        });
    }

    private async loadInfoMenu(): Promise<void> {
        if (!this._loading$.value) {
            return;
        }

        const { InfoMenuComponent } = await import('../../info-menu/info-menu.component');
        const componentRef = this.menuContainer.createComponent(InfoMenuComponent, { injector: this.injector });
        componentRef.instance.popup = this.popup;
    }
}
