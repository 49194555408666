import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router, UrlSegment } from '@angular/router';
import { BauplanId, ContentHubContentLayout, ContentHubId, DocumentId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ActiveProduct, getUpdatedMatrixParams, LocationService, RouterEvents } from '@idr/ui/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getPreviewPath } from './get-preview-path';
import { RoutePaths } from './route-paths';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    readonly contentHubId$: Observable<ContentHubId | undefined>;

    readonly #logPrefix = '[NavigationService]';

    constructor(
        private readonly router: Router,
        private readonly activeProduct: ActiveProduct,
        private readonly location: Location,
        private readonly locationService: LocationService,
        routerEvents: RouterEvents,
    ) {
        this.contentHubId$ = routerEvents.routeParams$.pipe(map(params => params?.contentHubId));
    }

    static isValidContentHubId(contentHubId: ContentHubId | undefined) {
        return (contentHubId ?? '').toLowerCase().startsWith('contenthub:');
    }

    getDocumentPath(documentId: DocumentId, anchorId?: string): string[] {
        const docPath: string[] = ['/', this.activeProduct.product.id, RoutePaths.Document, documentId];

        if (anchorId) {
            docPath.push(anchorId);
        }

        return docPath;
    }

    /**
     * @returns all query parameters of current url as a string (i.e. ?param1=paramValue1&param2=paramValue2)
     * or empty string if no query params exist on current url
     */
    get queryParametersAsString(): string {
        return this.locationService.query;
    }

    isSearchPage(activatedRoute: ActivatedRoute): boolean {
        const activatedRoutes: ActivatedRoute[] = activatedRoute.pathFromRoot;
        if (activatedRoutes?.length > 2) {
            const urls: UrlSegment[] = activatedRoutes[2].snapshot.url;
            if (urls.length > 0) {
                return urls[0].path === RoutePaths.Search;
            }
        }
        return false;
    }

    /**
     * This is a helper function to replace the current url without creating an entry to history
     * IMPORTANT: When this function is used instead of router.navigateByUrl the resolvers of the route will NOT be executed and the
     * activatedRoute.snapshot.params and activatedRoute.snapshot.queryParams will not be updated.
     * This is useful to be able to dynamically update the url of the focused document in full view of a document so that
     * we keep the scroll position when navigating back and forward. We cannot use router.navigateByUrl because the resolvers will run and
     * they will load documentMeta etc again.
     *
     * @param newDocumentId the document id that will be in the replaced url
     * @param anchor the anchor id that will be in the replaced url
     */
    replaceDocumentIdAndAnchorIdInUrl(newDocumentId: DocumentId, anchor?: string): void {
        logger.debug(this.#logPrefix, `replaceDocumentIdAndAnchorIdInUrl ->`, newDocumentId, { anchor });
        const docPath: string[] = this.getDocumentPath(newDocumentId, anchor);
        const newFocusedDocUrl: string = this.router.serializeUrl(this.router.createUrlTree(docPath));
        const query = this.queryParametersAsString;
        logger.debug(
            this.#logPrefix,
            `replaceDocumentIdAndAnchorIdInUrl -> replacing location state`,
            `${newFocusedDocUrl}${query}`,
        );
        this.location.replaceState(newFocusedDocUrl, query);
    }

    /**
     * Will navigate to /currentProductId/document/<documentId>?<queryParams>
     *
     * @param documentId id of the document we navigate to. Required parameter.
     * @param queryParams optional query parameters
     * @param reuseState can be used for keeping the state within document model
     *                   (this is helpful when you trigger a navigation within the same document and want to keep the search state)
     */
    async toDocument(documentId: DocumentId, queryParams?: Params, reuseState = false): Promise<boolean> {
        const documentPath = this.getDocumentPath(documentId);

        const extras: NavigationExtras = {
            replaceUrl: false,
            ...(reuseState ? { state: { reuseState } } : {}),
            ...(queryParams ? { queryParams } : {}),
        };

        logger.debug(this.#logPrefix, 'toDocument ->', documentPath, queryParams);
        return this.router.navigate(documentPath, extras);
    }

    async toError(): Promise<boolean> {
        logger.debug(this.#logPrefix, 'toError');
        return this.router.navigate(['/error'], {
            queryParams: { reload: true },
        });
    }

    async toFilter(id: BauplanId, queryParams: Params, replaceUrl = false): Promise<boolean> {
        const filterPath: string[] = ['/', this.activeProduct.product.id, RoutePaths.Filter, id];

        logger.debug(this.#logPrefix, `toFilter -> ${filterPath.slice(1).join('/')} with replaceUrl: ${replaceUrl}`);
        return this.router.navigate(filterPath, { queryParams, replaceUrl });
    }

    async toLexicon(id: BauplanId, replaceUrl = false): Promise<boolean> {
        const lexiconPath: string[] = ['/', this.activeProduct.product.id, RoutePaths.Lexicon, id];
        logger.debug(this.#logPrefix, `toLexicon -> ${lexiconPath.slice(1).join('/')} with replaceUrl: ${replaceUrl}`);
        return this.router.navigate(lexiconPath, { replaceUrl });
    }

    async toPreview(
        relativeTo: ActivatedRoute,
        documentId: DocumentId | ContentHubId,
        queryParams: Params = {},
        noBrowserHistory = false,
    ): Promise<boolean> {
        // FIXME this is missing
        //  urlTree.queryParams = this.removeEmptyParams(urlTree.queryParams);

        const previewPath: string[] = getPreviewPath(documentId);
        logger.debug(this.#logPrefix, `toPreview ->`, previewPath, relativeTo.snapshot?.url);
        return this.router.navigate(previewPath, {
            relativeTo,
            replaceUrl: noBrowserHistory,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    getSearchPath(query: string, path?: string) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const searchPath: any[] = ['/', this.activeProduct.product.id, RoutePaths.Search, query];
        if (path) {
            searchPath.push({ path });
        }
        return searchPath;
    }

    /**
     * Submit a new search + go to search page
     *
     * The search resolver is triggered in two cases:
     * - When a NEW search is submitted from the search header. (= calling this method)
     * - When an existing search is adjusted by clicking on a search category
     *
     * The first case also happens when the user is already on the search page and submits a new search.
     * Then we need a complete reset of the search. (collapse already expanded categories and so on)
     * That's why the "new-search" needs temporarily to be added to the url.
     */
    async toSearchPage(query: string, path?: string, searchCategory?: string): Promise<boolean> {
        const searchPath = this.getSearchPath(query, path);
        const extras: NavigationExtras = {
            queryParams: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'new-search': true,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'search-category': searchCategory,
            },
        };
        logger.debug(this.#logPrefix, `toSearchPage -> ${searchPath.slice(1).join('/')}`);
        return this.router.navigate(searchPath, extras);
    }

    getTopicPagePath(topicPageId: DocumentId, selectedCategory = 0): string[] {
        return ['/', this.activeProduct.product.id, RoutePaths.TopicPage, topicPageId, String(selectedCategory)];
    }

    async toTopicPage(topicPageId: DocumentId, selectedCategory = 0, replaceUrl = false): Promise<boolean> {
        if (topicPageId) {
            const topicPath = this.getTopicPagePath(topicPageId, selectedCategory);
            return this.router.navigate(topicPath, { replaceUrl });
        }
        return Promise.resolve(true);
    }

    /**
     * Keep all existing params and add or update with the new ones.
     * If you want to remove a param just pass something like { paramName: undefined }
     */
    async updateMatrixParams(params: Params, relativeTo: ActivatedRoute, noBrowserHistory = false): Promise<boolean> {
        logger.debug(this.#logPrefix, 'updateMatrixParams ->', params, relativeTo.snapshot?.url);

        const path = this.getMatrixParamsPath(params, relativeTo);
        const navigationExtras: NavigationExtras = {
            replaceUrl: noBrowserHistory,
            relativeTo,
        };
        return this.router.navigate(path, navigationExtras);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getMatrixParamsPath(params: Params, relativeTo: ActivatedRoute): any[] {
        const updatedParams: Params = getUpdatedMatrixParams(relativeTo, params);

        return ['.', updatedParams];
    }

    async toContentHubDocId(
        routePath: RoutePaths,
        contentHubId: string,
        layout: ContentHubContentLayout = ContentHubContentLayout.page,
        replaceUrl = false,
    ): Promise<boolean> {
        const path = this.getContentHubPath(routePath, contentHubId, layout);
        logger.debug(
            this.#logPrefix,
            'toContentHubDocId ->',
            routePath,
            contentHubId,
            layout,
            replaceUrl,
            'path:',
            path,
        );
        return this.router.navigate(path, { replaceUrl });
    }

    getContentHubPath(
        routePath: RoutePaths,
        contentHubId?: string,
        layout: ContentHubContentLayout = ContentHubContentLayout.page,
    ): string[] {
        const path: string[] = ['/', this.activeProduct.product.id, routePath];
        if (layout) {
            path.push(layout);
        }
        if (contentHubId) {
            path.push(contentHubId);
        }

        return path;
    }

    /**
     * This method searches for the given pathName in the url and returns true if found.
     *
     * @example with an url like 'PI10413/lexicon/LI13401844/alle/topicPage/HI13953398_ueb/3/'
     * and urlIncludes('topicPage') it will return true and urlIncludes('search') will return false
     */
    urlIncludes(pathName: string): boolean {
        return this.router.url.includes(pathName);
    }

    /**
     * Only redirects when there is no redirect pending.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async navigateToIfNoRedirectIsPending(commands: any[], extras?: NavigationExtras): Promise<void> {
        if (!this.locationService.redirectPending) {
            logger.debug(this.#logPrefix, 'navigateIfNoRedirectIsPending ->', commands, extras);
            await this.router.navigate(commands, extras);
            return;
        }

        logger.warn(this.#logPrefix, 'navigateIfNoRedirectIsPending -> Ignored due to pending redirect.');
    }
}
