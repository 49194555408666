import { CrsOutlineDTO, CrsOutlineEntryDTO } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { Toc, TocEntry } from '@idr/ui/document';
import { List as ImmutableList } from 'immutable';

export const createToc = (outline: CrsOutlineDTO, hiddenToc = false): Toc => {
    const entries: ImmutableList<TocEntry> = ImmutableList.of(...createTocEntries(outline.entry));
    const toc = new Toc(entries, hiddenToc);
    logger.debug('[createTocFromDto] created ->', toc);
    return toc;
};

const createTocEntries = (
    entries: CrsOutlineEntryDTO[],
    level = 1, // root level is 0, but root document is not included in toc
    parent: TocEntry | undefined = undefined,
): TocEntry[] => {
    return entries.map(entry => {
        const documentId = entry.anchor ? `${entry.docid}-${entry.anchor}` : entry.docid;
        const tocEntry = new TocEntry(documentId, entry.docsize ?? 0, entry.title, level, parent);
        tocEntry.children = ImmutableList.of(...createTocEntries(entry.entry ?? [], level + 1, tocEntry));
        return tocEntry;
    });
};
