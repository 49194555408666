<dialog
    open
    cdkTrapFocus
    cdkTrapFocusAutoCapture
    aria-labelledby="npsDialogTitle"
    data-cy="nps-dialog">
    <header>
        <div id="npsDialogTitle">Ihre Meinung ist uns wichtig</div>
        <button
            aria-label="Schließen"
            (click)="cancelDialog()">
            <mat-icon svgIcon="close" />
        </button>
    </header>
    <main>
        <div>
            <iframe [src]="surveyIframeSrc | hgSafe: 'resourceUrl'"></iframe>
        </div>
    </main>
    <footer>
        <p>
            Sollten Sie im Textfeld (optional) personenbezogene Daten übermitteln, beachten Sie bitte unsere
            <a
                href="https://www.haufe.de/datenschutzerklaerung_24_534492.html"
                target="_blank"
                >Datenschutzerklärung</a
            >.
        </p>
        <button
            class="primary"
            data-cy="postpone-nps"
            (click)="cancelDialog()">
            Nicht jetzt
        </button>
        <button
            class="primary"
            data-cy="finish-nps"
            (click)="finishDialog()">
            Fertig
        </button>
    </footer>
</dialog>
