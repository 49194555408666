<section
    hgDragAndDropFiles
    (fileDropped)="onFilePicked($event)">
    <div>
        <mat-icon svgIcon="upload" />
        <p>
            Zum Hochladen Datei hier ablegen <br />
            max. {{ maxSize() }} MB | Formate: {{ formats() }}
        </p>
    </div>

    <button
        class="secondary"
        (click)="fileInput.click()">
        {{ label() }}
    </button>

    <input
        #fileInput
        hidden
        type="file"
        [accept]="accept()"
        (change)="onFilePicked(fileInput.files)" />
</section>

@if (showError()) {
    <p class="error">
        <mat-icon svgIcon="warning" />
        {{ errorMessage() }}
    </p>
}
