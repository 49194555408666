import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigCluster, Product, TargetDTO } from '@idr/shared/model';
import { ActiveProduct, SettingsService } from '@idr/ui/shared';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { LegacyProductConfigurationService } from '../../../core/legacy-product-configuration/legacy-product-configuration.service';
import { NavigationItem } from '../model/navigation-item';

/**
 * NavigationItemService provides NavigationItems for the sidebar.
 *
 * The service has two "inputs" from which it creates the NavigationItems.
 * - Links that are configured for the current product. Are updated when product changes
 * - Current url. Used to highlight the selected NavigationItem
 */
@Injectable()
export class NavigationItemService {
    public readonly navigationItems$: Observable<NavigationItem[]>;

    constructor(
        private readonly activeProduct: ActiveProduct,
        private readonly productConfigService: LegacyProductConfigurationService,
        private readonly settings: SettingsService,
        private readonly router: Router,
    ) {
        this.navigationItems$ = combineLatest([this.allNavigationItems$, this.currentUrl$]).pipe(
            map(([navigationItems, url]) => this.updateSelection(navigationItems, url)),
        );
    }

    private updateSelection(navigationItems: NavigationItem[], currentUrl: string): NavigationItem[] {
        return navigationItems.map((navigationItem: NavigationItem) => {
            if (navigationItem.isUrlActive(currentUrl)) {
                return navigationItem.activate();
            }
            return navigationItem;
        });
    }

    /**
     * Will contain all NavigationItems
     * - "StartPage" link (always there)
     * - All links configured for the active product
     * - (optional) dev links
     */
    private get allNavigationItems$(): Observable<NavigationItem[]> {
        return this.configuredLinks$.pipe(
            map((configuredLinks: NavigationItem[]) => {
                const activeProduct: Product = this.activeProduct.product;
                const startPageNavItem: NavigationItem = NavigationItem.startPageInstance(activeProduct.id);
                return [startPageNavItem, ...configuredLinks, ...this.getDevLinks()];
            }),
        );
    }

    private get currentUrl$(): Observable<string> {
        return this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map((event: NavigationEnd) => event.url),
            // this is to ensure that after browser reload we also have a value
            // => the `NavigationEnd` was already there was that case and `router.events` isn't a `BehaviorSubject`...
            // @see https://www.learnrxjs.io/operators/combination/startwith.html
            startWith(this.router.url),
            distinctUntilChanged(),
        );
    }

    /**
     * This will request based on the current active product
     * (if the product gets changed, it'll request backend again) the navigation
     * from the product-configuration
     */
    private get configuredLinks$(): Observable<NavigationItem[]> {
        return this.productConfigService.getTarget$(ConfigCluster.NAVIGATION).pipe(
            map((target: TargetDTO) => {
                if (!target) {
                    return [];
                }
                return target.children[0].children;
            }),
            map((targetDTOs: TargetDTO[]) => this.navigationItemsFromTargetDTOs(targetDTOs)),
        );
    }

    private navigationItemsFromTargetDTOs(children: TargetDTO[]): NavigationItem[] {
        const activeProduct: Product = this.activeProduct.product;
        return children
            .map((linkConfig: TargetDTO) => NavigationItem.fromDto(activeProduct.id, linkConfig))
            .filter((navItem: NavigationItem) => !!navItem?.label);
    }

    private getDevLinks(): NavigationItem[] {
        const showDevLinks = !!this.settings.debug?.sideBar_showDevLinks;
        if (!showDevLinks) {
            return [];
        }
        return NavigationItem.getDevLinks(this.activeProduct.product.id);
    }
}
