import { A11yModule } from '@angular/cdk/a11y';
import { Location, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, InjectionToken } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DialogService } from '../../services/dialog/dialog.service';
import { ErrorId, Message } from '../../services/message/model';
import { MessageContentComponent } from '../message-content/message-content.component';

export const INJECT_MESSAGE_BOX_MESSAGE: InjectionToken<Message> = new InjectionToken<Message>(
    'INJECT_MESSAGE_BOX_MESSAGE',
);

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [A11yModule, NgIf, MatIconModule, MessageContentComponent],
    selector: 'hg-message-box',
    standalone: true,
    styleUrls: ['./message-box.component.scss'],
    templateUrl: './message-box.component.html',
})
export class MessageBoxComponent {
    triggerLocationBack: boolean;

    constructor(
        @Inject(INJECT_MESSAGE_BOX_MESSAGE) readonly message: Message | undefined,
        private readonly location: Location,
        private readonly dialogService: DialogService,
    ) {
        this.triggerLocationBack =
            message?.errorId === ErrorId.DOC_REQUEST_FAILED || message?.errorId === ErrorId.NO_IMPRINT_CONFIGURED;
    }

    @HostListener('document:keydown.escape')
    close(): void {
        this.dialogService.closeAll();
    }

    @HostListener('document:keydown.enter')
    submit(): void {
        this.close();
        if (this.triggerLocationBack) {
            this.location.back();
        }
    }
}
