import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentProvider } from '@idr/shared/model';
import { ActiveProduct } from '../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatTooltipModule],
    selector: 'hg-provider-icon',
    standalone: true,
    styles: `
        img {
            height: 100%;
        }
    `,
    template: `@if (shouldShowProvider()) {
        <img
            [alt]="provider().iconAlt"
            [matTooltip]="provider().iconTitle"
            matTooltipPosition="below"
            [src]="provider().iconSource" />
    }`,
})
export class ProviderIconComponent {
    provider = input.required<DocumentProvider>();

    // Note: Provider icon is only shown if the product brand is different from the provider
    shouldShowProvider = computed(() => {
        const active = this.activeProduct.productSignal();
        if (!active?.brand?.id) {
            return false;
        }
        const provider = this.provider();
        return active.brand.id.toLowerCase() !== provider.id.toLowerCase();
    });

    constructor(private readonly activeProduct: ActiveProduct) {}
}
