import { logger } from '@idr/shared/utils';
import { REALM_PARAM_NAME, REDIRECT_TO_AFTER_LOGIN_PARAM_NAME, REQUESTED_SCOPE_PARAM_NAME } from './auth-params';
import { Realm } from './realms';
import { ThirdPartyToken } from './third-party-token';

interface LoginOptions {
    readonly url: string;
    readonly queryParams?: Record<string, string>;
}

export interface LoginUrlOptions {
    readonly thirdPartyToken?: string;
    readonly thirdPartyTokenName?: ThirdPartyToken;
    readonly requestedScope?: string;
}

/**
 * Provides the login url for the given auth context.
 */
export class LoginUrl {
    readonly #logPrefix = '[LoginUrl]';

    constructor(
        private readonly bffHost: string,
        private readonly locationHref: string,
        private readonly realm: Realm,
        private readonly loginUrlOptions: LoginUrlOptions = {},
    ) {
        logger.debug(
            this.#logPrefix,
            `-> Creating login url for realm ${realm} and options ${JSON.stringify(loginUrlOptions)}`,
        );
    }

    get url(): string {
        const loginUrlSearchParams: URLSearchParams = new URLSearchParams(
            // We pre-set the auth related query params
            this.#loginOptions.queryParams,
        );
        // Then we append the redirect to param so that BFF knows where to redirect the user after login.
        loginUrlSearchParams.set(REDIRECT_TO_AFTER_LOGIN_PARAM_NAME, this.#currentUrlWithoutAuthParams);
        const url = `${this.#loginOptions.url}?${loginUrlSearchParams.toString()}`;
        logger.debug(this.#logPrefix, `url -> ${url}`);
        return url;
    }

    /**
     * Returns the current url without the auth related query params.
     */
    get #currentUrlWithoutAuthParams(): string {
        const redirectTo: URL = new URL(this.locationHref);
        const redirectToSearchParams: URLSearchParams = redirectTo.searchParams;

        redirectToSearchParams.delete(REALM_PARAM_NAME);
        redirectToSearchParams.delete(REQUESTED_SCOPE_PARAM_NAME);
        if (this.loginUrlOptions.thirdPartyTokenName) {
            redirectToSearchParams.delete(this.loginUrlOptions.thirdPartyTokenName);
        }

        // We need to prepend the ? to the search params string only if there are params left.
        const searchParamsAsString =
            redirectToSearchParams.toString().length > 0 ? `?${redirectToSearchParams.toString()}` : '';
        const cleanedUrl = `${redirectTo.origin}${redirectTo.pathname}${searchParamsAsString}`;
        logger.debug(this.#logPrefix, `#currentUrlWithoutAuthParams -> ${cleanedUrl}`);
        return cleanedUrl;
    }

    get #loginOptions(): LoginOptions {
        logger.debug(this.#logPrefix, `#loginOptions -> ${this.realm} login`);
        switch (this.realm) {
            case Realm.HAUFE_INTERNAL_IDESK:
                return this.#internalLoginOptions;
            case Realm.DATEV:
            case Realm.PARTNER:
            case Realm.OFFLINE:
                return this.#thirdPartyLoginOptions;
            default:
                return {
                    url: this.#getBaseLoginUrl(),
                };
        }
    }

    get #internalLoginOptions(): LoginOptions {
        const scope = this.loginUrlOptions.requestedScope;
        return {
            url: this.#getBaseLoginUrl(),
            queryParams: scope ? { [REQUESTED_SCOPE_PARAM_NAME]: this.loginUrlOptions.requestedScope } : {},
        };
    }

    get #thirdPartyLoginOptions(): LoginOptions {
        // There is an edge case that we cover here:
        // The user visits the application with a third party token set in the url as query param.
        // Then navigates within the app and the third party token is removed from the url (it is still kept in the state of the app).
        // If the session expires this redirectToLoginPage method will be called and the session will be refreshed without issues because we have the third party token in the application state.
        // However, if the user refreshes the page and there is no third party token in the query params -> there is no third party token in the state -> the user will be redirected to the login page without a third party token and the login will fail.
        if (!this.loginUrlOptions.thirdPartyToken) {
            logger.error(
                this.#logPrefix,
                `getThirdPartyLoginOptions -> ${this.loginUrlOptions.thirdPartyTokenName} not found in url, redirecting to default login`,
            );
            return {
                url: this.#getBaseLoginUrl(Realm.DEFAULT),
            };
        }

        logger.debug(
            this.#logPrefix,
            `#thirdPartyLoginOptions -> ${this.loginUrlOptions.thirdPartyTokenName} found in url, redirecting to partner login`,
        );
        return {
            url: this.#getBaseLoginUrl(),
            queryParams: {
                [this.loginUrlOptions.thirdPartyTokenName as string]: this.loginUrlOptions.thirdPartyToken,
            },
        };
    }

    #getBaseLoginUrl(realm: Realm = this.realm): string {
        if (realm === Realm.LOCAL) {
            return `${this.bffHost}/authenticate/login`;
        }
        return `${this.bffHost}/authenticate/context/${realm}/login`;
    }
}
