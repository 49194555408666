import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    AuthHttpInterceptor,
    CachingHttpInterceptor,
    ErrorHttpInterceptor,
    LogIdsHeaderHttpInterceptor,
    MicroServiceHttpInterceptor,
} from '@idr/ui/shared';

/**
 * Important: The order of the interceptors is crucial!
 * Please make sure to add new interceptors at the correct position.
 */
export const HTTP_INTERCEPTOR_PROVIDERS = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthHttpInterceptor,
        multi: true,
    },
    // IMPORTANT: Keep the LogIdsHeaderHttpInterceptor above the MicroServiceHttpInterceptorService
    // It depends on the url prefix that MicroServiceHttpInterceptorService is striping off.
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LogIdsHeaderHttpInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MicroServiceHttpInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorHttpInterceptor,
        multi: true,
    },
    // Note: The MicroServiceHttpInterceptorService is adding some caching headers to the request if needed,
    // so it has to be declared before the CachingHttpInterceptor.
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CachingHttpInterceptor,
        multi: true,
    },
];
