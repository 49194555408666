import {
    ConnectedPosition,
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayConfig,
    OverlayPositionBuilder,
    OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    StaticProvider,
    TemplateRef,
} from '@angular/core';
import {
    POPUP_CONFIG,
    PopupAdditionalClass,
    PopupContentComponent,
    PopupNoseClass,
    PopupOptions,
    PopupWidthClass,
} from '../../components/popup-content/popup-content.component';

// TODO there are no tests...
@Directive({
    exportAs: 'hgPopup',
    selector: '[hgPopup]',
    standalone: true,
})
export class PopupDirective implements OnInit, OnDestroy {
    /**
     * Content of the template.
     */
    @Input() template?: TemplateRef<unknown>;

    @Input() nose: PopupNoseClass = 'off';

    @Input() width: PopupWidthClass = PopupWidthClass.Standard;

    @Input() additionalClass: PopupAdditionalClass = PopupAdditionalClass.default;

    @Input() showCloseButton = false;

    @Input() mouseOverPopUp = false;

    @Input() hasBackdrop = true;

    #mouseOutTimer?: ReturnType<typeof setTimeout>;

    #overlayRef?: OverlayRef;

    constructor(
        private readonly overlay: Overlay,
        private readonly overlayPositionBuilder: OverlayPositionBuilder,
        private readonly elementRef: ElementRef,
        private readonly injector: Injector,
    ) {}

    /**
     * It is possible to define multiple positions then CDK overlay logic will try them out
     * one by one until one fits. For example, we could add a position that will be used
     * when there is no place to open the popup below the mouse pointer.
     */
    get #standardPosition(): ConnectedPosition[] {
        return [
            {
                originX: 'center',
                originY: 'bottom',
                offsetX: this.nose === 'top-left' ? -50 : 50,
                overlayX: this.nose === 'top-left' ? 'start' : 'end',
                overlayY: 'top',
                offsetY: 8,
            },
        ];
    }

    /**
     * The menu popup only opens in one direction,
     * with the right top corner right above the menu icon.
     */
    get #menuPositions(): ConnectedPosition[] {
        return [
            {
                originX: 'center',
                originY: 'bottom',
                offsetX: 20,
                overlayX: 'end',
                overlayY: 'top',
                offsetY: -30,
            },
        ];
    }

    @HostListener('mouseover')
    showMouseOver(): void {
        if (!this.#overlayRef || !this.mouseOverPopUp) {
            return;
        }
        if (this.#mouseOutTimer) {
            clearTimeout(this.#mouseOutTimer);
        }
        if (this.#overlayRef.hasAttached()) {
            return;
        }
        this.show();
    }

    @HostListener('mouseout')
    hideMouseOut(): void {
        if (!this.mouseOverPopUp) {
            return;
        }
        this.#mouseOutTimer = setTimeout(() => this.closeByMouseOut(), 500);
    }

    @HostListener('click')
    show(): void {
        if (!this.#overlayRef) {
            return;
        }

        if (this.#overlayRef.hasAttached()) {
            return;
        }

        const popupOptions: PopupOptions = {
            nose: this.nose,
            width: this.width,
            additionalClass: this.additionalClass,
            showCloseButton: this.showCloseButton,
            overlayRef: this.#overlayRef,
            mouseOverPopUp: this.mouseOverPopUp,
            mouseOverHandler: this.mouseOverPopUp ? this.showMouseOver.bind(this) : undefined,
            mouseOutHandler: this.mouseOverPopUp ? this.hideMouseOut.bind(this) : undefined,
        };

        const componentPortal: ComponentPortal<PopupContentComponent> = new ComponentPortal(
            PopupContentComponent,
            null,
            this.#createInjector(popupOptions),
        );

        const popupRef: ComponentRef<PopupContentComponent> = this.#overlayRef.attach(componentPortal);

        popupRef.instance.template = this.template;
    }

    @HostListener('document:click', ['$event'])
    hideWhenClickOutside(event: Event): void {
        if (!this.#overlayRef) {
            return;
        }

        if (!this.#overlayRef.hasAttached()) {
            return;
        }

        if (this.hasBackdrop) {
            const backDropClicked: boolean = this.#overlayRef.backdropElement === event.target;
            if (backDropClicked) {
                this.close();
            }
        }
        const anchorClicked: boolean = event.target instanceof HTMLAnchorElement;
        if (anchorClicked) {
            this.close();
        }
    }

    @HostListener('document:keydown.escape')
    onEscape(): void {
        this.close();
    }

    closeByMouseOut() {
        this.close();
    }

    ngOnInit(): void {
        // this logic only works as long the menu popup is the only one with a [x] close button
        const positions: ConnectedPosition[] = this.showCloseButton ? this.#menuPositions : this.#standardPosition;
        const positionStrategy: FlexibleConnectedPositionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions(positions);

        const overlaySetup: OverlayConfig = {
            positionStrategy,
            // needed so that we can click on backdrop to close the tooltip
            hasBackdrop: this.hasBackdrop,
        };
        if (this.hasBackdrop) {
            overlaySetup.backdropClass = 'popup-backdrop'; // popup.scss
        }

        this.#overlayRef = this.overlay.create(overlaySetup);
    }

    ngOnDestroy(): void {
        // if there is any mouseout timer left, clear and remove it to prevent memory leaks
        if (this.#mouseOutTimer) {
            clearTimeout(this.#mouseOutTimer);
            this.#mouseOutTimer = undefined;
        }
        this.close();
    }

    /**
     * This method will close the tooltip by detaching the component from the overlay
     */
    close(): void {
        if (this.#mouseOutTimer) {
            clearTimeout(this.#mouseOutTimer);
            this.#mouseOutTimer = undefined;
        }
        this.#overlayRef?.detach();
    }

    #createInjector(dataToPass: PopupOptions): Injector {
        const providers: StaticProvider[] = [{ provide: POPUP_CONFIG, useValue: dataToPass }];
        return Injector.create({
            parent: this.injector,
            providers,
        });
    }
}
