<div class="search">
    <section role="search">
        <input
            #searchBox
            id="{{ elementIdPrefix }}-input"
            name="search-query"
            type="search"
            autocomplete="off"
            [placeholder]="ariaAttributes.placeholder"
            [attr.aria-label]="ariaAttributes.label"
            required
            role="searchbox"
            (blur)="focused = false"
            (focus)="focused = true"
            (input)="onInput(searchBox.value)"
            (keyup.enter)="onKeyboardEnter(searchBox.value)"
            [value]="query$ | push" />
        <button
            type="reset"
            id="{{ elementIdPrefix }}-reset"
            matTooltip="Eingabe löschen"
            aria-controls="suggestions"
            [attr.aria-hidden]="!isQueryAvailable"
            [disabled]="!isQueryAvailable"
            (click)="onClickReset()"
            [hgGtmId]="[gtmArea, 'delete', 'searchTerm']">
            <mat-icon svgIcon="close" />
        </button>
        <button
            type="submit"
            id="{{ elementIdPrefix }}-submit"
            [matTooltip]="searchButtonTitle"
            (click)="onClickSearch(searchBox.value)">
            <mat-icon svgIcon="magnifier" />
        </button>
    </section>
    <ng-content />
</div>
