import { getEncodedQueryForCrs } from './get-encoded-query-for-crs';

export class DocumentQuery {
    public readonly value: string;

    constructor(query: string | undefined) {
        this.value = (query ?? '').trim();
    }

    public get empty(): boolean {
        return this.value.length === 0;
    }

    public get asQueryParam(): string {
        if (this.empty) {
            return '';
        }
        return `/searchterm/${getEncodedQueryForCrs(this.value, true)}`;
    }
}
