import { OverlayRef } from '@angular/cdk/overlay';
import { NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    HostListener,
    Inject,
    InjectionToken,
    TemplateRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export const POPUP_CONFIG: InjectionToken<PopupOptions> = new InjectionToken<PopupOptions>('PORTAL_CHILD_DATA');

export type PopupNoseClass = 'off' | 'top-center' | 'top-right' | 'top-left';

export const PopupWidthClass = {
    Standard: 'standard',
    Narrow: 'narrow',
    None: '',
};

export type PopupWidthClass = (typeof PopupWidthClass)[keyof typeof PopupWidthClass];

export const PopupAdditionalClass = {
    default: '',
    tooltip: 'tooltip',
};

export type PopupAdditionalClass = (typeof PopupAdditionalClass)[keyof typeof PopupAdditionalClass];

export interface PopupOptions {
    readonly nose: PopupNoseClass;
    readonly width: PopupWidthClass;
    readonly additionalClass: PopupAdditionalClass;
    readonly showCloseButton: boolean;
    readonly overlayRef: OverlayRef;
    readonly mouseOverPopUp?: boolean;
    readonly mouseOutHandler?: () => void;
    readonly mouseOverHandler?: () => void;
}

/**
 * This component is used together with the PopupDirective to display popups.
 * PopupContentComponent should not be used alone it is only instantiated by the directive.
 * The component is only the wrapper for the content and helps to style things
 * like "nose", borders, width
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgTemplateOutlet, MatIconModule],
    selector: 'hg-popup-component',
    standalone: true,
    styleUrls: ['./popup-content.component.scss'],
    template: `@if (showCloseButton) {
            <button (click)="close()">
                <mat-icon svgIcon="close" />
            </button>
        }
        <ng-container *ngTemplateOutlet="template" />`,
})
export class PopupContentComponent {
    @HostBinding('attr.class') cssClasses: string;

    template?: TemplateRef<unknown>;

    readonly showCloseButton: boolean;

    readonly mouseOverPopUp: boolean;

    readonly mouseOverHandler?: () => void;
    readonly mouseOutHandler?: () => void;

    /**
     * The injected popupConfig is used to pass config parameters from the
     * popup.directive that is initiating the opening of the popup.
     */
    constructor(@Inject(POPUP_CONFIG) readonly popupConfig: PopupOptions) {
        this.cssClasses = [popupConfig.nose, popupConfig.width, popupConfig.additionalClass]
            .filter(_class => !!_class)
            .join(' ');

        this.showCloseButton = popupConfig.showCloseButton;

        this.mouseOverPopUp = popupConfig?.mouseOverPopUp ?? false;
        this.mouseOverHandler = popupConfig?.mouseOverHandler;
        this.mouseOutHandler = popupConfig?.mouseOutHandler;
    }

    @HostListener('mouseover')
    showMouseOver(): void {
        if (this.popupConfig.mouseOverPopUp) {
            this.popupConfig?.mouseOverHandler?.();
        }
    }

    @HostListener('mouseout')
    hideMouseOut(): void {
        if (this.popupConfig.mouseOverPopUp) {
            this.popupConfig?.mouseOutHandler?.();
        }
    }

    close(): void {
        this.popupConfig.overlayRef?.detach();
    }
}
