import { ElementRef } from '@angular/core';

export const removeAllClasses = (className: string, parent: Document | HTMLElement = document): void => {
    const selector = `.${className}`;
    parent.querySelectorAll(selector).forEach(element => {
        element.classList.remove(className);
    });
};

/**
 * @returns `true` if given element has given className in its class list set, `false` if not
 */
export const hasCssClass = (element: Element, className: string): boolean => {
    const classAttribute = element.getAttribute('class');
    const classes: string[] = !!element && !!classAttribute ? classAttribute.split(' ') : [];
    return classes.indexOf(className) >= 0;
};

export const isClickInside = (elementRef: ElementRef, event: Event): boolean => {
    if (!elementRef || !event) {
        return false;
    }
    return elementRef.nativeElement.contains(event.target as Node);
};

export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

// this function needs to be present in DOM because some CRS delivered content uses this function HARDCODED
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toggleNormVersion = (element: any): void => {
    if (element.className === 'normPeriodStart') {
        element.className = 'normPeriodStartHide';
        element.nextSibling.style.display = 'inline';
        return;
    }

    element.className = 'normPeriodStart';
    element.nextSibling.style.display = 'none';
};
