import { Directive } from '@angular/core';
import { SafeResourceUrl, Title } from '@angular/platform-browser';
import { BrandId } from '@idr/shared/model';
import { ActiveProduct, DomService } from '@idr/ui/shared';
import { rxEffects } from '@rx-angular/state/effects';
import { filter } from 'rxjs/operators';

const getFaviconUrl = (id: BrandId): SafeResourceUrl => {
    switch (id) {
        case BrandId.Haufe:
            return '/assets/brands/haufe_favicon.svg';
        case BrandId.Lexware:
            return '/assets/brands/lexware_favicon.ico';
        case BrandId.SchaefferPoeschel:
            return '/assets/brands/sp_favicon.svg';
        default:
            return '';
    }
};

@Directive({ selector: '[idrBranding]', standalone: true })
export class BrandingDirective {
    constructor(activeProduct: ActiveProduct, domService: DomService, titleService: Title) {
        // ensuring correct branding of browser window
        rxEffects(({ register }) =>
            register(activeProduct.product$.pipe(filter(Boolean)), product => {
                titleService.setTitle(`iDesk - ${product.brand.label} ${product.name}`);
                domService.setFaviconHref(getFaviconUrl(product.brand.id));
            }),
        );
    }
}
