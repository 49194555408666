<dialog
    open
    cdkTrapFocus
    cdkTrapFocusAutoCapture>
    <header>
        <div class="title">{{ message?.header ?? "Entschuldigung" }}</div>
        <button (click)="close()">
            <mat-icon svgIcon="close" />
        </button>
    </header>
    <main>
        <hg-message-content [message]="message" />
    </main>
    <footer>
        <button
            class="primary"
            type="submit"
            (click)="submit()">
            {{ triggerLocationBack ? "Zurück" : "Okay" }}
        </button>
    </footer>
</dialog>
