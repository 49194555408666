import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BauplanId, Breadcrumb, DeeplinkPathId, ProductId } from '@idr/shared/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreadcrumbService } from '../../breadcrumb/breadcrumb.service';
import { extractUrlParts } from '../extract-url-parts';

@Injectable({ providedIn: 'root' })
export class ColRedirectCanActivateGuard {
    constructor(
        private readonly breadcrumbService: BreadcrumbService,
        private readonly router: Router,
    ) {}

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree> {
        const matches = extractUrlParts(state.url, DeeplinkPathId.Column);
        const productId: ProductId = matches[1] as ProductId;
        const bauplanId: BauplanId = matches[2];
        const context: BauplanId = next.queryParamMap.get('context');

        return this.breadcrumbService.getBreadcrumbForBauplanId$(bauplanId, productId).pipe(
            map((breadcrumb: Breadcrumb[]) => {
                // NAUA-5557 when no breadcrumb is returned, we redirect to the li itself
                let lis: string[];
                if (breadcrumb?.length > 0) {
                    lis = breadcrumb.map(value => value.linkIndex);
                } else if (context) {
                    lis = [context, bauplanId];
                } else {
                    lis = [bauplanId];
                }
                const path: string = lis.join('/');
                return this.router.parseUrl(`/${productId}/column/${path}`);
            }),
        );
    }
}
