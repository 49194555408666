import { BrandId, BrandLabel, BrandLabelDTO } from '../brand';

export interface Brand {
    readonly id: BrandId | undefined;
    readonly label: BrandLabel;
}

export const getBrandFromLabel = (label: BrandLabelDTO): Brand => {
    switch (label) {
        case 'Haufe':
            return { id: 'haufe', label };
        case 'Lexware':
            return { id: 'lexware', label };
        case 'Schäffer Poeschel':
            return { id: 'sp', label };
        default:
            return { id: undefined, label };
    }
};

export const getLabelFromBrand = (brandId: BrandId): BrandLabel | undefined => {
    switch (brandId) {
        case 'haufe':
            return 'Haufe';
        case 'lexware':
            return 'Lexware';
        case 'sp':
            return 'Schäffer Poeschel';
        default:
            return undefined;
    }
};
